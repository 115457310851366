import React from "react";
import {
  Modal as MuiModal,
  ModalProps as MiuModalProps,
  Box,
  SxProps,
} from "@mui/material";
import Close from "@mui/icons-material/Close";

export interface ModalProps extends Omit<MiuModalProps, "onClose"> {
  onClose: () => void;
  header?: React.ReactElement;
  body?: React.ReactElement;
  footer?: React.ReactElement;
  width?: number;
  iconHeader?: React.ReactNode;
  isVisibleCloseIcon?: boolean;
}

const Modal = ({
  onClose,
  width = 400,
  header,
  body,
  footer,
  isVisibleCloseIcon,
  iconHeader,
  sx = [],
  ...props
}: ModalProps) => {
  const style = {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width,
    bgcolor: "background.paper",
    border: "1px solid #CED4DA",
    boxShadow: "0px 6px 12px 0px #0000001F",
    padding: "24px",
    borderRadius: "4px",
  };

  return (
    <MuiModal {...props}>
      <Box sx={[style, ...(Array.isArray(sx) ? sx : [sx])]}>
        <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
          {iconHeader}
          {header}
          <div style={{ flexGrow: 1 }}></div>
          {isVisibleCloseIcon && (
            <Close
              sx={[
                (theme) => ({
                  "&:hover": {
                    cursor: "pointer",
                    color: theme.palette.primary.main,
                  },
                }),
              ]}
              onClick={onClose}
            />
          )}
        </div>
        {body}
        {footer}
      </Box>
    </MuiModal>
  );
};

export default Modal;
