import { Box, Typography } from "../../../components"
import bg from "../../shared/assets/img/not-found-bg.svg";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import OverlayLoader from "../adminPanel/paymentTransactions/OverlayLoader";
import { useState, useEffect } from "react";
import { req } from "../../api";
import { useSearchParams } from "react-router-dom";
import { useAlert } from "../../store/alert";

function Img({ position }:{position: "left" | "right"}) {
    return(
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundImage: `url(${bg})`,
                backgroundPosition: position,
                width: '15%',
                backgroundSize: 'auto 100%',
                height: '100%',
            }}
        >
        </Box>
    );
}

export default function Paid() {
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    const [ loader, setLoader ] = useState(true);
    const [ message, setMessage ] = useState<string|null>("Платеж успешно совершен");
    const [ searchParams ] = useSearchParams();
    const { openAlert } = useAlert();

    const orderId = searchParams.get("orderId");

    useEffect(() => {
        if (orderId) {
            req.billing
                .verifyPayment(orderId)
                .then(res => {
                    if (!res.success) {
                        setMessage(res.errors.join(","));
                    } else if (res.data.errorMessage) {
                        setMessage(res.data.errorMessage);
                    }
                    setLoader(false);
                })
                .catch(() => {
                    openAlert({
                      text: "Ошибка платежа",
                      isVisibleCloseIcon: true,
                      autoHide: true,
                      type: "error",
                    });
                  });
        }
    }, []);

    return (<Box sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "row",
        alignContent: "space-between",
        flexWrap: "wrap",
        justifyContent: "space-between",
    }}>
       
        <Img position={"right"}/>
    
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '100px',
                marginTop: '100px',
            }}>
                { loader ? <OverlayLoader/> : <Box sx={{
                    minHeight: '340px',
                    fontSize: desktop ? '30px' : '17px',
                    width: '100%',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    verticalAlign: 'middle',
                    flexDirection:'column'
                }}>{message}</Box> }
            </Box>
        </Box>
       
        <Img position={"left"}/>
        
    </Box>);
}