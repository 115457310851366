import {
    Box,
    Typography,
  } from "../../../../components";
import { TColumn, TSearchData, TSearchFilterItem } from "../../../component/DataTable/types";
import { AgentResponseItem } from "./types";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery, SxProps, Theme } from "@mui/material";
import { ReactNode, useContext, useEffect, useState } from "react";
import DataTable from "../../../component/DataTable";
import { agent } from "../../../api/request";
import { Link, useNavigate } from "react-router-dom";
import WrapperButton from "../../../component/Button/WrappedButton";
import { AdminPanelContext } from "..";
import WrapperInput from "../../../component/Input/WrappedInput";
import FormControl from "@mui/material/FormControl";
import WrappedSelect from "../../../component/Select";
import { formatPhoneNumber } from "../../../utils";

const desktopColumns: TColumn<AgentResponseItem>[] = [
    { name: 'ФИО', id: 'fullName' },
    { name: 'Телефон', id: 'phoneNumber', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ value }</Box> },
    { name: 'Активность', id: 'isActive', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ value ? "Да" : "Нет" }</Box>  },
    { name: 'Промокод', id: 'promoCodes', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ value ? value.length : "-" }</Box>  },
];

const mobileColumns: TColumn<AgentResponseItem>[] = [
    { name: 'ФИО', id: 'fullName' },
    { name: 'Телефон', id: 'phoneNumber', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ value }</Box> },
    { name: 'Активность', id: 'isActive', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ value ? "Да" : "Нет" }</Box>  },
    { name: 'Промокод', id: 'promoCodes', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ value ? value.length : "-" }</Box>  },
];

type FilterData = Omit<TSearchData, "page" | "count">;
type FilterItems = Omit<FilterData, "sortItem">;

function LabelWrapper({
    label,
    children,
    desktop,
    sx
  }: {
    label: string;
    children: React.ReactElement;
    desktop: boolean;
    sx: SxProps<Theme>;
  }) {

    const defaultSx: SxProps<Theme> = {
        display: "flex",
        alignItems: desktop ? "center" : "start",
        gap: desktop ? "10px" : "2px",
        flexDirection: desktop ? "row" : "column",
        width: desktop ? "unset" : "100%",
    };
    const p = {...defaultSx,...sx}; 

    return (
      <Box sx={p}>
        <Typography variant="caption" color="#0D0C22">{label}</Typography>
        {children}
      </Box>
    );
  }

const optionsSelect = [{ value: "false", label: "Нет" }, { value: "true", label: "Да" },{ value: "", label: ""}];

function Filter({ applyFilter, value, desktop }: { applyFilter: (filter: FilterItems) => void, value: FilterData, desktop: boolean}) : ReactNode {

    const getFilterValue = (fieldName: string) : string => {
        const field = value.filterItems.find(x => x.fieldName === fieldName);

        if (!field) return "";

        return field.value;
    }

    const [phoneNumber, setPhoneNumber ] = useState(getFilterValue("phoneNumber"));
    const [fullName, setName ] = useState(getFilterValue("fullName"));
    const [isActive, setActive] = useState<string>(getFilterValue("isActive"));
    
    const addFilter = (fieldName: string, val: string, condition: 0|5, items: TSearchFilterItem[]) => {
        if (!val) return;
        const field = items.find(x => x.fieldName === fieldName);
        if (field) {
            field.value = val!;
        } else {
            items.push({
                condition: condition!,
                fieldName: fieldName!,
                value: val!,
                valueTo: ""
            });
        }
    }

    const saveFilter = () => {
        const items: TSearchFilterItem[] = [];

        addFilter("phoneNumber", phoneNumber, 5, items);
        addFilter("fullName", fullName, 5, items);
        addFilter("isActive", isActive, 0, items);

        applyFilter({filterItems: items});
    }

    const clearFilter = () => {
        setPhoneNumber("");
        setActive("");
        setName("");
        applyFilter({filterItems: []});
     }

    return (<Box
        sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "start",
            width: "100%"
        }}
    >
        <Box
            sx={{
                display: "flex",
                flexDirection: desktop ? "row" : "column",
                gap: "20px",
                alignItems: "start",
                width: "100%"
            }}
        >
            <LabelWrapper
                desktop={desktop}
                label="ФИО"
                sx={{
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <WrapperInput
                        backgroundColor="#FFFFFF"
                        innerHeight={11}
                        fullWidth
                        sx={{
                            "& .MuiInputBase-root": {
                            height: "34px",
                            color: "#0D0C22 !important",
                            width: desktop ? "425px": "100%",
                            },
                        }}
                        onChange={(e: any) => { setName(e.target.value); }}
                        value={fullName}
                    />
                </Box>
            </LabelWrapper>
            <LabelWrapper
                desktop={desktop}
                label="Телефон"
                sx={{
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <WrapperInput
                        backgroundColor="#FFFFFF"
                        fullWidth
                        sx={{
                            "& .MuiInputBase-root": {
                            height: "34px",
                            color: "#0D0C22 !important",
                            width: desktop ? "227px" : "100%",
                            },
                        }}
                        onInput={(e: any) => {
                            e.target.value = formatPhoneNumber(
                              e.target.value.replace(/[^0-9+\-()\s]/g, ""),
                            );
                          }}
                        onChange={(e: any) => { setPhoneNumber(e.target.value); }}
                        value={phoneNumber}
                    />
                </Box>
            </LabelWrapper>    
        </Box>
        <Box
            sx={{
                display: "flex",
                flexDirection: desktop ? "row" : "column",
                gap: "20px",
                alignItems: desktop ? "center" : "start",
                width: "100%"
            }}
        >
            <LabelWrapper
                desktop={desktop}
                label="Активность"
                sx={{
                    justifyContent: "space-between",
                }}
            >
                <FormControl sx={{ width: desktop ? "250px" : "100%" }}>
                    <WrappedSelect
                        multiple={false}
                        options={optionsSelect}
                        value={isActive}
                        onChange={(e) => {
                            const changeValue = e.target.value as string;
                            setActive(changeValue);
                        }}
                        sx={{ height: "36px" }}
                    />
                </FormControl>
            </LabelWrapper>
                
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: desktop ? "row" : "column",
                    alignItems: "end",
                    width: desktop ? "50%" : "100%",
                    gap: "15px"
                }}
            >
                <WrapperButton
                    label="Применить"
                    variant="contained"
                    sx={{
                        marginLeft: desktop ? "auto" : "unset",
                        height: "36px",
                        width: desktop ? "156px" : "100%",
                        whiteSpace: "nowrap",
                    }}
                    onClick={saveFilter}
                />

                <WrapperButton
                    label="Сбросить фильтр"
                    variant="contained"
                    typeBorder="RoundGradient"
                    sx={{
                        minWidth: 156,
                        color: "#F72642",
                        backgroundColor: "#FFFFFF",
                        width: desktop ? "156px" : "100%",
                        whiteSpace: "nowrap",
                    }}
                    onClick={clearFilter}
                />
            </Box>
        </Box>
    </Box>);
}

const CreateAgent = ({desktop}: {desktop: boolean }) => (
    <Link to="/adminpanel/sales/agent/create">
        <WrapperButton
            //@ts-ignore
            label="Создать&nbsp;агента"
            variant="contained"
            size={desktop ? "large" : "normal"}
            iconPosition="left"
            sx={{
                width: '156px',
                height: '36px',
                margin: desktop ? 0 : '10px 0',
                fontSize: '0.875rem',
                fontWeight: 500,
                lineHeight: '1.857rem'
            }}
        />
    </Link>
);

function Agents() {
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    const { setHeaderSlot } = useContext(AdminPanelContext);
    const [ filterDataString, setFilter ] = useState(JSON.stringify({
        sortItem: {
            fieldName: "fullName", 
            direction: 0
        },
        filterItems: []
    }));


    const filterData: FilterData = JSON.parse(filterDataString);
    const applyFilter = (filter: FilterItems) => {
        filterData.filterItems = filter.filterItems;
        setFilter(JSON.stringify(filterData));
    }

    useEffect(() => {
        setHeaderSlot?.(<CreateAgent desktop={desktop} />);
    }, [ setHeaderSlot, desktop ]);

    const navigate = useNavigate();

    return(
        <Box
            sx={{
                width: '100%',
                display: desktop ? 'flex' : 'block',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '30px'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: desktop ? 'flex' : 'block',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px'
                }}
            >
                <Filter applyFilter={applyFilter} desktop={desktop} value={filterData} />
            </Box>
            <DataTable<AgentResponseItem>
                requestData={agent.search}
                columns={desktop ? desktopColumns : mobileColumns}
                desktopTableProps={{
                    bodyCell: () => ({
                        sx: { cursor: 'pointer' }
                    }),
                    row: ({row}) => ({
                        onClick: () => {
                            if (row) navigate(`/adminPanel/sales/agents/${row['id']}`)   //вместо старого пропа onRowClicked
                        }
                    }),
                    headCell: ({ column }) => ({
                        sx: (column?.id === 'modified') ?
                            { minWidth: '155px' } : {}
                    })
                }}
                mobileTableProps={{
                    row: ({row}) => ({
                        onClick: () => {
                            if (row) navigate(`${row['id']}`)
                        }
                    }),
                }}
                searchOptions={filterData}
            />
        </Box>
    );
}

export default Agents;