import { Typography, Box } from "../../../components";
import WrapperButton from "../../component/Button/WrappedButton";
import WrappedInput from "../../component/Input/WrappedInput";
import { darkTheme } from "../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { HintPopoverIcon } from "../../component/HintPopover";

type ConfirmationByCodeProps = {
  code: [string, string, string, string];
  setCode: React.Dispatch<
    React.SetStateAction<[string, string, string, string]>
  >;
  requestCode: () => void;
  checkCode: () => void;
  buttonLabel: string;
};

const ConfirmationByCode: React.FC<ConfirmationByCodeProps> = ({
  code,
  setCode,
  requestCode,
  checkCode,
  buttonLabel,
}) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <>
      <Typography
        sx={{
          width: "100%",
          fontSize: `${desktop ? "16px" : "14px"}`,
          lineHeight: "26px",
          position: `${desktop ? "absolute" : "static"}`,
          top: "-51px",
          marginBottom: `${desktop ? "0" : "14px"}`,
        }}
      >
        Код подтверждения
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: desktop ? "30px" : "6px",
          position: "relative",
          alignItems: "center",
        }}
      >
        {code.map((value, index) => {
          return (
            <WrappedInput
              key={index}
              label=""
              colorType="black"
              sx={{
                "& .MuiInputBase-root": {
                  height: "36px",
                  color: "#FFFFFF !important",
                  textAlign: "center",
                },
                "& .MuiInputBase-input": {
                  textAlign: "center !important",
                },
              }}
              inputProps={{ maxLength: 1 }}
              onInput={(e: any) => {
                //@ts-ignore
                return (e.target.value = e.target.value.replace(/[^0-9]/g, ""));
              }}
              value={value}
              onChange={(e: { target: { value: string } }) => {
                setCode((prev) => {
                  const newArray = [...prev];
                  newArray.splice(index, 1, e.target.value);
                  return newArray;
                });
              }}
            />
          );
        })}
        <HintPopoverIcon>
          <Typography>
            Вам поступит звонок от робота на указанный при регистрации номер
          </Typography>
          <Typography>
            телефона. Необходимо ввести 4 последние цифры входящего номера
          </Typography>
        </HintPopoverIcon>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        <Typography
          sx={{
            textDecoration: "underline",
            textDecorationStyle: "dashed",
            textUnderlineOffset: "2px",
            cursor: "pointer",
            ":hover": {
              opacity: 0.8,
            },
            marginBottom: `${desktop ? "0px" : "14px"}`,
          }}
          onClick={requestCode}
        >
          Запросить код повторно
        </Typography>
        <WrapperButton
          label={buttonLabel}
          fullWidth
          variant="contained"
          onClick={checkCode}
          disabled={code.filter((cod) => cod === "").length > 0}
          size="normal"
          sx={(theme: any) => ({
            maxWidth: `${desktop ? "50%" : "100%"}`,
            marginBottom: desktop ? "0px" : "40px",
            minWidth: "max-content",
          })}
        />
      </Box>
    </>
  );
};

export default ConfirmationByCode;
