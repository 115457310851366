import { Modal, Box, Typography, IconButton } from "../../../../../components";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import WrapperButton from "../../../../component/Button/WrappedButton";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../../theme/theme";
interface ImpersonalizeModalInterface {
  onImpersonalize: () => void;
  onClose: () => void;
  user: any;
}

const ImpersonalizeModal = ({
  onImpersonalize,
  onClose,
  user,
}: ImpersonalizeModalInterface) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Modal
      open
      onClose={onClose}
      sx={[
        (theme) => ({
          width: "100%",
          maxWidth: desktop ? "387px" : "100%",
          left: desktop ? "" : "0",
          right: desktop ? "" : "unset",
          bottom: desktop ? "" : 0,
          top: desktop ? "" : "unset",
          position: desktop ? "relative" : "fixed",
          transform: desktop ? "" : "unset",
          backgroundColor: theme.palette.primary.main,
          padding: "25px !important",
          borderRadius: "10px",
          borderColor: theme.palette.primary.main,
          gap: "15px",
          borderBottomLeftRadius: desktop ? "" : "unset",
          borderBottomRightRadius: desktop ? "" : "unset",
        }),
      ]}
      header={
        <Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 500,
                color: "#FFFFFF",
                textWrap: "balance",
                whiteSpace: "break-spaces",
              }}
            >{`Войти под пользователем `}</Typography>
            <Typography
              variant="subtitle2"
              sx={{ fontWeight: 500, color: "#FFFFFF", textWrap: "balance" }}
            >
              {user?.phoneNumber}
            </Typography>
          </Box>
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", top: "2px", right: "2px" }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Box>
      }
      footer={
        <Box
          sx={{ display: "flex", gap: "25px", justifyContent: "space-between" }}
        >
          <WrapperButton
            label={"Отмена"}
            variant="contained"
            size={"normal"}
            onClick={onClose}
            sx={() => ({
              color: "#F72642",
              background: "#090909",
            })}
            typeBorder="RoundGradient"
            gradientBorderColor="linear-gradient(-45deg, #674099,#ED6985)"
          />
          <WrapperButton
            label={"Войти"}
            variant="contained"
            size={"normal"}
            onClick={onImpersonalize}
          />
        </Box>
      }
    >
      <span></span>
    </Modal>
  );
};

export default ImpersonalizeModal;
