import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  SxProps,
  Theme,
} from "@mui/material";

type Sizes = "small" | "normal";

export interface CheckboxInterface extends Omit<CheckboxProps, "size"> {
  size?: Sizes;
}

const getLayout = (size: Sizes): SxProps<Theme> => {
  switch (size) {
    case "small": {
      return {
        transform: "scale(0.666)",
        padding: "0px",
      };
    }
    case "normal": {
      return { transform: "scale(1)", padding: "0px" };
    }

    default:
      return null;
  }
};

const Checkbox = ({ size = "small", sx = [], ...props }: CheckboxInterface) => {
  return (
    <MuiCheckbox
      {...props}
      sx={[
        (theme) => ({
          ...getLayout(size),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default Checkbox;
