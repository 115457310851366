import {
  Modal,
  Box,
  Typography,
  IconButton,
} from "../../../../components";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import {  useState } from "react";
import WrapperInput from "../../../component/Input/WrappedInput";
import WrapperButton from "../../../component/Button/WrappedButton";
import { daysWeek } from "../type";
import WrappedCheckbox from "../../../component/Checkbox/WrappedCheckbox";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import { WorkHoursType } from "../../../api/request/auth";

type ChangeOpeningHoursModalInterface = {
  workhours: WorkHoursType;
  onClose: () => void;
  updateUserWithModal: (
    value: WorkHoursType,
    textMessageSuccess: string,
  ) => void;
};

const ChangeOpeningHoursModal = ({
  onClose,
  workhours: currentOpeningHours,
  updateUserWithModal,
}: ChangeOpeningHoursModalInterface) => {
  const [openingHours, setOpeningHours] = useState(currentOpeningHours);
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  const handlerRequest = () => {
    updateUserWithModal(
      openingHours,
      "Часы работы заведения успешно изменены",
    );
    onClose();
  };

  return (
    <Modal
      open
      onClose={onClose}
      sx={[
        (theme) => ({
          left: desktop ? "" : "0",
          right: desktop ? "" : "unset",
          bottom: desktop ? "" : 0,
          top: desktop ? "" : "unset",
          position: desktop ? "relative" : "fixed",
          transform: desktop ? "" : "unset",
          backgroundColor: theme.palette.primary.main,
          padding: "25px !important",
          width: desktop ? "450px" : "100%",
          borderRadius: "10px",
          borderColor: theme.palette.primary.main,
          gap: "15px",
          borderBottomLeftRadius: desktop ? "" : "unset",
          borderBottomRightRadius: desktop ? "" : "unset",
        }),
      ]}
      header={
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 500, color: "#FFFFFF" }}
          >
            {"Укажите режим работы"}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", top: "2px", right: "2px" }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Box>
      }
      body={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          {openingHours && Object.entries(openingHours).map(([key, value], index) => {
            const keyWeek = key as keyof typeof daysWeek;

            return (
              <Box
                key={keyWeek}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px",
                  minHeight: "36px",
                }}
              >
                <WrappedCheckbox
                  sx={{
                    ".MuiCheckbox-root": {
                      color: "#9E9E9E",
                    },
                    "& span:last-child": {
                      color: "#9E9E9E",
                    },
                  }}
                  checked={!!openingHours[keyWeek]}
                  onCheckChange={(event) => {
                    setOpeningHours((prev) => {
                      const items = Object.entries(prev).map(([,v]) => v).filter(x => x && x.dayOfWeek <= index);
                      const prevHours = index === 0 || items.length === 0 ? { start: "00:00", end: "00:00"} : items[items.length-1];

                      return {
                        ...prev,
                        [keyWeek]: event
                          ? { start: prevHours?.start , end: prevHours?.end, dayOfWeek: index+1 }
                          : null,
                      };
                    });
                  }}
                  label={
                    desktop
                      ? daysWeek[keyWeek].split(" ")[0]
                      : daysWeek[keyWeek].split(" ")[1]
                  }
                />
                {openingHours[keyWeek] && (
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                  >
                    <Typography sx={{ color: "#9E9E9E" }}>С</Typography>
                    <WrapperInput
                      id="time"
                      type="time"
                      defaultValue={value?.start}
                      value={value?.start && null}
                      onClick={(e) => desktop && e.preventDefault()}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "36px",
                          paddingRight: "0px !important",
                        },
                        "& .MuiInputBase-input": {
                          padding: "16px 10px !important",
                        },
                        '& input[type="time"]::-webkit-calendar-picker-indicator':
                          {
                            filter: "invert(100%)",
                          },
                      }}
                      onChange={(value) => {
                        setOpeningHours((prev) => {
                          return {
                            ...prev,
                            [keyWeek]: {
                              ...prev[keyWeek],
                              start: value.target.value,
                            },
                          };
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                    <Typography sx={{ color: "#9E9E9E" }}>По</Typography>
                    <WrapperInput
                      id="time"
                      type="time"
                      onClick={(e) => desktop && e.preventDefault()}
                      defaultValue={value?.end}
                      value={value?.end && null}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "36px",
                          paddingRight: "0px !important",
                        },
                        "& .MuiInputBase-input": {
                          padding: "16px 10px !important",
                        },
                        '& input[type="time"]::-webkit-calendar-picker-indicator':
                          {
                            filter: "invert(100%)",
                          },
                      }}
                      onChange={(value) => {
                        setOpeningHours((prev) => {
                          return {
                            ...prev,
                            [keyWeek]: {
                              ...prev[keyWeek],
                              end: value.target.value,
                            },
                          };
                        });
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 300, // 5 min
                      }}
                    />
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      }
      footer={
        <WrapperButton
          label={"Сохранить"}
          variant="contained"
          sx={{
            display: "flex",
            marginLeft: "auto",
            justifyContent: "flex-end",
            height: "36px",
          }}
          onClick={handlerRequest}
        />
      }
    >
      <span></span>
    </Modal>
  );
};

export default ChangeOpeningHoursModal;
