import { Box, Typography } from "../../../../../components";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../../theme/theme";
import { useEffect, useState } from "react";
import { req } from "../../../../api";
import DataTable from "../../../../component/DataTable";
import {
  PaymentTableDto,
} from "../../../../api/request/analitics";
import { TColumn } from "../../../../component/DataTable/types";
import moment from "moment";
import WrapperDatePicker from "../../../../component/DatePicker/WrappedDatePicker";
import WrappedSelect from "../../../../component/Select";
import WrapperButton from "../../../../component/Button/WrappedButton";
import DiagrammWrapper from "../../../../component/Diagramm/diagrammWrapper";

function Payment() {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const mobile = !desktop;
  const [data, setData] = useState<{ name: string; Количество: number }[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [tariffData, setTariffData] = useState<Record<string, number>>({});
  const [dateFrom, setDateFrom] = useState<moment.Moment | null>(
    moment().add(-1, "months"),
  );
  const [dateTo, setDateTo] = useState<moment.Moment | null>(moment());
  const [changeDateFrom, setChangeDateFrom] = useState<moment.Moment | null>(
    dateFrom,
  );
  const [changeDateTo, setChangeDateTo] = useState<moment.Moment | null>(
    dateTo,
  );
  const [selectValue, setSelectValue] = useState(2);

  const optionsSelect = [
    { value: 1, label: "Неделя" },
    { value: 2, label: "Месяц" },
    { value: 3, label: "Год" },
  ];

  const columns: TColumn<PaymentTableDto>[] = [
    {
      name: "ФИО",
      id: "userFullName",
    },
    {
      name: "Дата заказа",
      id: "orderDate",
      renderComponent: ({ value, row }) => {
        return <>{`${value ? moment(value).format("DD.MM.YYYY") : "-"}`}</>;
      },
    },
    { name: "Тариф", id: "tariffName", sortId: "tariffId" },
    {
      name: "Сумма заказа",
      id: "price",
      renderComponent: ({ value }) => {
        return <>{`${value}₽`}</>;
      },
    },
    {
      name: "Период подписки",
      id: "periodName",
    },
    {
      name: "Автоматическая оплата",
      id: "autoProlongation",
      renderComponent: ({ value }) => {
        return <>{`${value ? "Да" : "Нет"}`}</>;
      },
    },
  ];

  const getDateDiagramm = () => {
    if (dateFrom && dateTo) {
      setChangeDateFrom(dateFrom);
      setChangeDateTo(dateTo);
      req.analitics
        .getPaymentDiagram(
          dateFrom.format("YYYY-MM-DD"),
          dateTo.format("YYYY-MM-DD"),
        )
        .then((res) => {
          if (res.success && res.data) {
            const data = res.data.diagramData.map(({ item1, item2 }, index) => {
              return { name: item1, Количество: item2 };
            });
            setData(data);
            setTotalCount(res.data.total);
            setTariffData(res.data.orderByTariffData);
          }
        });
    }
  };

  useEffect(() => {
    if (dateFrom && dateTo) {
      getDateDiagramm();
    }
  }, []);

  const handlerSelect = (value: number) => {
    setDateTo(moment());
    if (value === 1) {
      setDateFrom(moment().add(-1, "weeks").add(1, "days"));
    } else if (value === 2) {
      setDateFrom(moment().add(-1, "months").add(1, "days"));
    } else {
      setDateFrom(moment().add(-1, "years").add(1, "days"));
    }
    setSelectValue(value);
  };

  function LabelWrapper({
    label,
    children,
    fullwidth,
  }: {
    label: string;
    children: React.ReactElement;
    fullwidth?: boolean;
  }) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: desktop ? "center" : "start",
          gap: desktop ? "10px" : "2px",
          flexDirection: desktop ? "row" : "column",
          width: fullwidth ? "100%" : "unset",
        }}
      >
        <Typography variant="caption">{label}</Typography>
        {children}
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            justifyContent: "space-between",
            gap: desktop ? "0px" : "10px",
          }}
        >
          <DiagrammWrapper
            diagrammsData={[
              {
                label: "Оплаты",
                dataDiagramm: data,
              },
            ]}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography fontSize="60px" fontWeight={900} color="#F82440">
              {`${totalCount}₽`}
            </Typography>
            <Typography
              fontSize="14px"
              fontWeight={700}
              color="#090909"
              textAlign="center"
            >
              Оплат за выбранный период
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            gap: "20px",
            alignItems: desktop ? "center" : "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: desktop ? "normal" : "space-between",
              width: !desktop ? "100%" : "unset",
              gap: "20px",
            }}
          >
            <LabelWrapper
              label="С"
              children={
                <WrapperDatePicker
                  value={dateFrom}
                  onChange={(value) => setDateFrom(value)}
                  maxDate={dateTo ? dateTo : moment()}
                  sx={{
                    width: "150px",
                  }}
                />
              }
            />
            <LabelWrapper
              label="По"
              children={
                <WrapperDatePicker
                  value={dateTo}
                  onChange={(value) => setDateTo(value)}
                  minDate={dateFrom ? dateFrom : undefined}
                  maxDate={moment()}
                  sx={{
                    width: "150px",
                  }}
                />
              }
            />
          </Box>
          <LabelWrapper
            label="Период:"
            children={
              <WrappedSelect
                options={optionsSelect}
                value={selectValue}
                onChange={(e) => handlerSelect(e.target.value as number)}
                sx={{ height: "36px", width: desktop ? "150px" : "100%" }}
              />
            }
            fullwidth={!desktop ? true : false}
          />
          <WrapperButton
            label="Применить"
            variant="contained"
            sx={{
              marginLeft: desktop ? "auto" : "unset",
              height: "36px",
              whiteSpace: "nowrap",
            }}
            disabled={!dateFrom || !dateTo}
            onClick={getDateDiagramm}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
        <Typography
          fontSize="14px"
          fontWeight={400}
          fontStyle="italic"
          color="#848484"
          textAlign="center"
          marginLeft={desktop ? "auto" : "unset"}
          sx={{
            textAlign: "start",
          }}
        >
          {`Всего оплат за период: `}
          {Object.keys(tariffData).map((key, index, array) => {
            return (
              <>
                {key + " - "}
                <strong>{tariffData[key]}₽</strong>
                {index < array.length - 1 ? ", " : ""}
              </>
            );
          })}
        </Typography>
        <DataTable<PaymentTableDto>
          requestData={req.analitics.getPaymentTable}
          columns={columns}
          searchOptions={{
            filterItems: [
              {
                fieldName: "date",
                condition: 0,
                value: changeDateFrom
                  ? changeDateFrom.format("YYYY-MM-DD")
                  : "",
                valueTo: changeDateTo ? changeDateTo.format("YYYY-MM-DD") : "",
              },
            ],
          }}
        />
      </Box>
    </Box>
  );
}

export default Payment;
