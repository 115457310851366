import { Typography } from "@mui/material";
import { PropsWithChildren } from "react";

export type TextProps = { color: string } & PropsWithChildren;

function Text({ color, children }: TextProps) {
    return(
        <Typography variant="body1" component='div'>
            <div style={{ color }}>{ children }</div>
        </Typography>
    );
}

export default Text;