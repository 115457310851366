import { Grid, Typography } from "../../../components";
import { darkTheme } from "../../theme/theme";
import { useMediaQuery } from "@mui/material";
import logo from "../../shared/assets/img/on-menu.svg";
import AutorizationFields from "./autorizationFields";
import { useEffect, useState } from "react";
import RegistrationFields from "./registrationFields";
import { useSearchParams } from "react-router-dom";

export enum Tab {
  AUTHORIZATION,
  REGISTRATION,
}

function Autorization() {
  const [searchParams, setSearchParams] = useSearchParams();
  const tabFromQueryParams = () => {
    return searchParams.get("tab") && !isNaN(Number(searchParams.get("tab")))
      ? (Number(searchParams.get("tab")) as Tab)
      : Tab.AUTHORIZATION;
  };

  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [selectedTab, setSelectedTab] = useState(tabFromQueryParams());

  useEffect(() => {
    setSelectedTab(tabFromQueryParams());
  }, [searchParams.get("tab")]);

  return (
    <Grid
      container
      sx={{
        maxWidth: "600px",
      }}
    >
      <Grid
        item
        mobile={12}
        desktop={12}
        sx={{ marginBottom: `${desktop ? "140px" : "50px"}` }}
      >
        <img
          style={{ width: "165px", height: "40px" }}
          src={logo}
          alt="Логотип"
        />
      </Grid>

      <Grid item mobile={12} desktop={6}>
        <Typography
          variant="h4"
          gutterBottom
          color="#FFF"
          sx={{
            cursor: "pointer",
            opacity: `${selectedTab === Tab.AUTHORIZATION ? 1 : 0.2}`,
            fontSize: `${desktop ? "40px" : "30px"}`,
            marginBottom: `${desktop ? "78px" : "14px"}`,
          }}
          onClick={() => {
            setSelectedTab(Tab.AUTHORIZATION);
            setSearchParams({ tab: Tab.AUTHORIZATION.toString() });
          }}
        >
          Авторизация
        </Typography>
        {!desktop && selectedTab === Tab.AUTHORIZATION && (
          <AutorizationFields />
        )}
      </Grid>
      <Grid item mobile={12} desktop={6}>
        <Typography
          variant="h4"
          gutterBottom
          color="#FFF"
          sx={{
            cursor: "pointer",
            opacity: `${selectedTab === Tab.REGISTRATION ? 1 : 0.2}`,
            fontSize: `${desktop ? "40px" : "30px"}`,
            marginBottom: `${desktop ? "78px" : "14px"}`,
          }}
          onClick={() => {
            setSelectedTab(Tab.REGISTRATION);
            setSearchParams({ tab: Tab.REGISTRATION.toString() });
          }}
        >
          Регистрация
        </Typography>
        {!desktop && selectedTab === Tab.REGISTRATION && <RegistrationFields />}
      </Grid>
      {desktop &&
        (selectedTab === Tab.AUTHORIZATION ? (
          <AutorizationFields />
        ) : (
          <RegistrationFields />
        ))}
    </Grid>
  );
}

export default Autorization;
