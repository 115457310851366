import { Outlet, useNavigate } from "react-router-dom";
import { menuItems } from "./menuItems";
import CommonContainer from "../../component/CommonContainer";
import { useUserData } from "../../store/user";
import { useEffect } from "react";

function StatisticsEstablishment() {
  const { userData } = useUserData();
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      if (userData?.order?.tariffName !== "Премиум" || userData.role.id === 1) {
        navigate("/personalArea");
      }
    }
  }, [userData]);

  return (
    <CommonContainer menuItems={menuItems} title="Статистика заведения">
      <Outlet />
    </CommonContainer>
  );
}

export default StatisticsEstablishment;
