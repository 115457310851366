import { api } from "../api";
import { Response } from "../type";
import { OrganizationType, WorkHoursType } from "./auth";

export namespace Organization {
    export async function update(id: number, data: OrganizationType) {
        return api.sendRequest<Response<number>>({
            domain: "on-menu",
            method: "put",
            url: `/organization/${id}`,
            data
        }).then(res => res.data);
    }

    export async function updateWorkHours(id: number, data: WorkHoursType) {
        return api.sendRequest<Response<number>>({
            domain: "on-menu",
            method: "put",
            url: `/organization/${id}/workhours`,
            data
        }).then(res => res.data);
    }
}