import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { MenuTemplate, TMenuTemplate } from "../../api/request/menuTemplate";
import { useNavigate } from "react-router-dom";

export type UseMenuDataType = {
    language?: number;
    code?: string;
};

function useMenuData({ language, code }: UseMenuDataType) {
    const [ menuData, setMenuData ] = useState<TMenuTemplate>();
    const [ params ] = useSearchParams();
    const id = params.get('id');
    const navigate = useNavigate();

    useEffect(() => {
        if(code) {
            MenuTemplate.getMenuByNameWithLang(code, String(language))
                .then(result => {
                    setMenuData(result.data.data);
                })
                .catch(error => {
                    if (error?.response?.status === 404) {
                        navigate("/notfound");
                        return;
                    }
                    
                    throw error;
                });
            return;
        }

        navigate("/notfound");
    }, [ id, code, language ]);

    return { menuData };
}

export default useMenuData;