import { Box, Typography } from "../../../../components";
import { GroupType } from "../../../api/request/template";

export default function GroupsList({ groups, activeGroup, setActiveGroup }: 
    {groups: GroupType[], activeGroup: number | null, setActiveGroup: (item:number|null) => void}) {
    return (
      <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
        <Typography
          variant="body1"
          color={activeGroup === null ? "#FF4D65" : "#989CA4"}
          fontSize="14px"
          component={"a"}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => setActiveGroup(null)}
        >
          Все
        </Typography>
        {groups.map((group) => (
          <Typography key={group.id}
            variant="body1"
            color={activeGroup === group.id ? "#FF4D65" : "#989CA4"}
            fontSize="14px"
            component={"a"}
            sx={{
              cursor: "pointer",
            }}
            onClick={() => setActiveGroup(group.id)}
          >
            {group.name}
          </Typography>
        ))}
      </Box>
    );
  }