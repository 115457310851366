import TableSortIcon from "./TableSortIcon";
import { CSSProperties } from "@emotion/serialize";
import { styled, TableCellProps } from '@mui/material';
import { Box, TableCell } from "../../../../components";
import { TColumnId } from "../types";
import { darkTheme } from "../../../theme/theme";
import { useContext } from "react";
import { SortContext } from "..";

const cellStyles: CSSProperties = {
    border: 0,
    textAlign: 'left',
    padding: '23px 10px',
    fontSize: '0.875rem',
    lineHeight: '1.05875rem',
};

export const StyledBodyCell = styled(TableCell)(() => ({
    color: '#343C44',
    textAlign: 'left',
    padding: '23px 10px',
    fontWeight: 400,
    ...cellStyles
}));

export type StyledHeadCellProps = {
    columnId: TColumnId;    //
} & TableCellProps;

export function StyledHeadCell({ columnId, sx, onClick = () => {}, children,
    ...props }: StyledHeadCellProps) {

    const { sortBy, direction, updateSort } = useContext(SortContext);

    return(
        <TableCell
            sx={[
                () => ({
                    backgroundColor: darkTheme.palette.primary.main,
                    color: '#FFFFFF',
                    fontWeight: 700,
                    cursor: 'pointer',
                    userSelect: 'none',
                    ...cellStyles
                }),
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
            { ...props }
            onClick={(e) => {
                if (!columnId) {
                    onClick(e);
                } else {
                    updateSort(columnId);
                    onClick(e);
                }
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', flexGrow: 1 }}>
                    { children }
                </Box>
                <Box
                    sx={{
                        opacity: columnId !== sortBy ? 0 : 1,
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <TableSortIcon reverse={!!direction}/>
                </Box>
            </Box>
        </TableCell>
    );
}