import { createRoot } from "react-dom/client";
import App from "./App";
import React from "react";
import { Provider } from "react-redux";
import { store } from "../store/store";
import { darkTheme, theme } from "../theme/theme";
import { LocalizationProvider, RNSThemeProvider } from "../../components";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { configApi } from "./api";

configApi();

const container = document.getElementById("root");
if (!container) {
  throw new Error("Mount node not found");
}
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <RNSThemeProvider theme={darkTheme}>
      {/* Provider определяющий локаль datePicker */}
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="ru">
        <App />
      </LocalizationProvider>
    </RNSThemeProvider>
  </Provider>,
);
