import { Box, styled } from "../../../../components";
import 'react-alice-carousel/lib/alice-carousel.css';
import { TLangItem } from "./createLangItems";
import { List, ListItem } from "@mui/material";
import arrowDown from "../../../shared/assets/img/arrow-down.svg";
import { useState } from "react";

export type LanguagesMenuProps = {
    items: TLangItem[];
    language: number;
    setLanguage: (language: number) => void;
    lightTheme: boolean;
};

const Icon = styled('img')({
    width: '20px',
    height: '20px',
    borderRadius: '500px',
    marginRight: '10px'
});

function LanguagesMenu({ items, language, setLanguage, lightTheme }: LanguagesMenuProps) {
    const langItems = items.map((item, index) =>
        <Box
            key={item.id}
            sx={{
                display: 'inline',
                paddingLeft: index > 0 ? '5px' : 0,
                cursor: 'pointer'
            }}
            onClick={() => setLanguage(item.id)}
        >
            <Box
                sx={() => ({
                        display: 'inline-flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '5px 12px',
                        backgroundColor: lightTheme ? '#F3F3F4' : '#1C1C1C',
                        color: lightTheme ? '#989CA4' : '#FFFFFF',
                        borderRadius: '500px',
                        opacity: language === item.id ? 1 : 0.5,
                        fontFamily: 'Inter',
                        fontWeight: 700,
                        fontSize: '0.875rem',
                })}
            >
                <Icon src={item.icon} alt={item.title}/>
                
            </Box>
        </Box>
    );

    const [isOpened, setOpened] = useState(false);

    return(
        <Box sx={{
            position: "absolute",
            height: "32px",
            width: "60px",
        }}
            onClick={(e) => {
                setOpened(!isOpened); 
            }}
        >
            <List sx={{
                 position: "absolute",
                 background: "#F3F3F4",
                 width: "60px",
                 borderRadius: "18px",
                 paddingTop: "5px",
                 paddingBottom: "2px"
            }}>
                {items.map((item, index)=> 
                    <ListItem 
                        key={item.id} 
                        sx={{
                            display: language !== item.id && !isOpened ? "none" : "block",
                            padding: "0px",
                            width: "24px",
                            marginLeft: "11px",
                            marginTop: index === 0 || !isOpened ? "0" : "5px"
                        }}
                        onClick={(e) => {
                            setLanguage(item.id);
                        }}
                    >
                        <Icon src={item.icon} alt={item.title}/>
                    </ListItem>
                )}

            </List>
            <Box sx={{
                position: "absolute",
                width: "0px",
                height: "0px",
                right: "20px",
                textAlign: "right"   
            }}>
                <Icon src={arrowDown} alt="Выбрать язык" sx={{
                    width: "10px",
                    height: "7px",
                    marginTop: "13px",
                }}/>
            </Box>
        </Box>
    );
}

export default LanguagesMenu;