/**Правильная форма слова для данного числа
 * 
 * normalizeCount(3, ['файл', 'файла' , 'файлов']); //3 файла
 */
const normalizeCount = (number: number, words_arr: [ string, string, string]) => {
    number = Math.abs(number);
    if (Number.isInteger(number)) {
      let options = [2, 0, 1, 1, 1, 2];
      return `${number} ${
        words_arr[
          number % 100 > 4 && number % 100 < 20
            ? 2
            : options[number % 10 < 5 ? number % 10 : 5]
        ]
      }`;
    }
    return `${number} ${words_arr[1]}`;
};

export default normalizeCount;