import {
  Box,
  Typography,
} from "../../../../components";
import {
  getTemplateData,
  useTemlateCardData,
} from "../../../store/templateCard";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import WrapperInput from "../../../component/Input/WrappedInput";
import WrappedCheckbox from "../../../component/Checkbox/WrappedCheckbox";
import { useEffect, useState } from "react";
import { TemplateDataDto } from "../../../api/request/template";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import InformationTemplate from "./informationTemplate";
import WrapperLabel from "./wrapperLabel";
import WrapperTextarea from "../../../component/Textarea/Textarea";
import WrappedSelect from "../../../component/Select";
import { req } from "../../../api";
import { useControlButtons } from "./controlButtons";
import WrappedControlButton from "../../../component/WrappedControlButton.tsx";
import { useAlert } from "../../../store/alert";
import ConfirmModal from "../../../component/Modal/ConfirmModal";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../store/user";
import { useDispatch } from "../../../store/model/extensions";

export type SettingsType = {};

type OptionSelect = { value: number; label: string }[];

export type typeModal = "delete" | "copy" | "export" | "publish";

/**Общие настройки */
function Settings({}: SettingsType) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const { tempateData, updateTempateData, tempateId } = useTemlateCardData();
  const { currentTariff } = useUserData();
  const [theme, setTheme] = useState<OptionSelect>([]);
  const [language, setLanguage] = useState<OptionSelect>([]);
  const { openAlert } = useAlert();
  const [typeModal, setTypeModal] = useState<typeModal | null>(null);
  const [guidTemplate, setGuidTemplate] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors, isDirty },
    resetField,
    setError,
    clearErrors,
    getValues,
  } = useForm<TemplateDataDto>({
    defaultValues: tempateData,
  });

  useEffect(() => {
    reset(tempateData);
  }, [JSON.stringify(tempateData)]);

  useEffect(() => {
    req.dictionary.getDictionary("MenuThemeEnum").then((res) => {
      if (res.data) {
        setTheme(() => {
          return res.data?.reduce((acc, el) => {
            acc.push({ value: el.id, label: el.title });
            return acc;
          }, [] as OptionSelect);
        });
      }
    });
    req.dictionary.getDictionary("MenuLanguageEnum").then((res) => {
      if (res.data) {
        setLanguage(() => {
          return res.data?.reduce((acc, el) => {
            acc.push({ value: el.id, label: el.title });
            return acc;
          }, [] as OptionSelect);
        });
      }
    });
  }, []);

  const getGuid = () => {
    req.template.getGuid(tempateId).then((res) => {
      if (res.success) {
        setGuidTemplate(res.data);
        setTypeModal("export");
      }
    });
  };

  const { controlButtons } = useControlButtons({
    getGuid,
    setTypeModal,
    isDirty,
  });

  const onSubmit: SubmitHandler<TemplateDataDto> = (data, e: any) => {
    if (e?.nativeEvent?.submitter.name === "cancel") {
      return reset(tempateData);
    }
    req.template
      .saveTemplateData(data, tempateData.id)
      .then((res) => {
        if (res.success) {
          openAlert({
            text: "Данные шаблона успешно сохранены",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
          if (tempateData.id === 0) {
            navigate(`/templateCard/${res.data}/settings`);
          } else {
            dispatch(getTemplateData({ id: tempateData.id.toString() }));
          }
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при сохранении данных шаблона",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const handlerDeleteTemplate = () => {
    req.template
      .deleteTemplate(tempateId)
      .then((res) => {
        if (res.success) {
          openAlert({
            text: "Шаблон успешно удален",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
          navigate("/constructor");
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при удалении шаблона",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const handlerCopyTemplate = () => {
    req.template
      .copyTemplate(tempateId)
      .then((res) => {
        if (res.success) {
          openAlert({
            text: "Шаблон успешно скопирован",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
          navigate(`/templateCard/${res.data}/settings`);
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при копировании шаблона",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const handlerPublishTemplate = () => {
    req.template
      .publishTemplate(tempateId)
      .then((res) => {
        if (res.success) {
          openAlert({
            text: "Шаблон успешно опубликован",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
        } else {
          openAlert({
            text: res.errors.join(";\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при публикации шаблона",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const copyTextToClipboard = (text: string) => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(text)
        .then(function () {
          openAlert({
            text: "Идентификатор шаблона скопирован в буфер обмена!",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
        })
        .catch(function () {
          openAlert({
            text: "Произошла ошибка при копировании идентификатора",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "error",
          });
        });
    } else {
      openAlert({
        text: "Не доступно копировании идентификатора в текущем браузере. Выполните копирование самостоятельно.",
        isVisibleCloseIcon: true,
        autoHide: true,
        type: "error",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        <Box
          sx={{
            marginTop: !desktop ? "30px" : "unset",
            marginBottom: !desktop ? "15px" : "unset",
          }}
        >
          <WrappedControlButton controlButtons={controlButtons} />
        </Box>
        <InformationTemplate />
        <WrapperLabel title="Наименование шаблона" required>
          <Controller
            name="name"
            control={control}
            rules={{ required: "Поле обязательно для заполнения" }}
            render={({
              field: { onChange, value },
              fieldState: { error, isDirty },
              formState,
            }) => (
              <WrapperInput
                label=""
                backgroundColor="#FFFFFF"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: "34px",
                    color: "#0D0C22 !important",
                  },
                }}
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </WrapperLabel>
        <WrapperLabel title="Описание">
          <Controller
            name="description"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error, isDirty },
              formState,
            }) => (
              <WrapperTextarea
                onChange={onChange}
                value={value}
                error={error?.message}
              />
            )}
          />
        </WrapperLabel>
        <Typography
          variant="subtitle2"
          color="#0D0C22"
          sx={{
            fontWeight: "500",
          }}
        >
          Мое заведение
        </Typography>
        <WrapperLabel title="Название заведения" required>
          <Controller
            name="organizationName"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error, isDirty },
              formState,
            }) => (
              <WrapperInput
                label=""
                backgroundColor="#FFFFFF"
                fullWidth
                disabled
                sx={{
                  "& .MuiInputBase-root": {
                    height: "34px",
                    color: "#0D0C22 !important",
                  },
                  "& .Mui-disabled": {
                    WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                  },
                }}
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </WrapperLabel>
        <WrapperLabel title="Логотип" required>
          {watch("organizationLogoId") && (
            <img
              style={{
                width: "66px",
                marginRight: "auto",
              }}
              src={`${process.env.REACT_APP_BASE_URL}/file/downloadGuid?guid=${watch("organizationLogoId")}`}
              alt="Логотип компании"
            />
          )}
        </WrapperLabel>
        <WrapperLabel title="Адрес заведения" required>
          <Controller
            name="organizationAddress"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error, isDirty },
              formState,
            }) => (
              <WrapperInput
                label=""
                backgroundColor="#FFFFFF"
                fullWidth
                disabled
                sx={{
                  "& .MuiInputBase-root": {
                    height: "34px",
                    color: "#0D0C22 !important",
                  },
                  "& .Mui-disabled": {
                    WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                  },
                }}
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </WrapperLabel>
        <WrapperLabel title="Часы работы" required>
          <Controller
            name="workhoursString"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error, isDirty },
              formState,
            }) => (
              <WrapperInput
                label=""
                backgroundColor="#FFFFFF"
                fullWidth
                disabled
                sx={{
                  "& .MuiInputBase-root": {
                    height: "34px",
                    color: "#0D0C22 !important",
                  },
                  "& .Mui-disabled": {
                    WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                  },
                }}
                value={value}
                error={error?.message}
                onChange={onChange}
              />
            )}
          />
        </WrapperLabel>
        {currentTariff === "Премиум" && (
          <>
            <WrapperLabel title="Телефон Вашего заведения" required>
              <Controller
                name="phoneNumber"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error, isDirty },
                  formState,
                }) => (
                  <WrapperInput
                    label=""
                    backgroundColor="#FFFFFF"
                    fullWidth
                    disabled={currentTariff !== "Премиум"}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "34px",
                        color: "#0D0C22 !important",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                      },
                    }}
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
            </WrapperLabel>
            <Controller
              name="displayPhoneNumber"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error, isDirty },
                formState,
              }) => (
                <WrappedCheckbox
                  checked={value}
                  onCheckChange={onChange}
                  disabled={currentTariff !== "Премиум"}
                  label="Отображать телефон в опубликованном меню"
                  sx={{
                    "& .Mui-disabled": {
                      color: "rgba(0,0,0,0.4) !important",
                    },
                  }}
                />
              )}
            />
            <WrapperLabel title="Сайт Вашего заведения" required>
              <Controller
                name="website"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error, isDirty },
                  formState,
                }) => (
                  <WrapperInput
                    label=""
                    backgroundColor="#FFFFFF"
                    fullWidth
                    disabled={currentTariff !== "Премиум"}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "34px",
                        color: "#0D0C22 !important",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                      },
                    }}
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
            </WrapperLabel>
            <Controller
              name="displayWebsite"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error, isDirty },
                formState,
              }) => (
                <WrappedCheckbox
                  checked={value}
                  onCheckChange={onChange}
                  disabled={currentTariff !== "Премиум"}
                  label="Отображать сайт в опубликованном меню"
                  sx={{
                    "& .Mui-disabled": {
                      color: "rgba(0,0,0,0.4) !important",
                    },
                  }}
                />
              )}
            />
            <WrapperLabel title="Telegram Вашего заведения" required>
              <Controller
                name="telegram"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error, isDirty },
                  formState,
                }) => (
                  <WrapperInput
                    label=""
                    backgroundColor="#FFFFFF"
                    fullWidth
                    disabled={currentTariff !== "Премиум"}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "34px",
                        color: "#0D0C22 !important",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                      },
                    }}
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
            </WrapperLabel>
            <Controller
              name="displayTelegram"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error, isDirty },
                formState,
              }) => (
                <WrappedCheckbox
                  sx={{
                    "& .Mui-disabled": {
                      color: "rgba(0,0,0,0.4) !important",
                    },
                  }}
                  checked={value}
                  onCheckChange={onChange}
                  label="Отображать Telegram в опубликованном меню"
                  disabled={currentTariff !== "Премиум"}
                />
              )}
            />
            <WrapperLabel title="Whatsapp Вашего заведения" required>
              <Controller
                name="whatsApp"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error, isDirty },
                  formState,
                }) => (
                  <WrapperInput
                    label=""
                    backgroundColor="#FFFFFF"
                    fullWidth
                    disabled={currentTariff !== "Премиум"}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: "34px",
                        color: "#0D0C22 !important",
                      },
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                      },
                    }}
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
            </WrapperLabel>
            <Controller
              name="displayWhatsApp"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error, isDirty },
                formState,
              }) => (
                <WrappedCheckbox
                  sx={{
                    "& .Mui-disabled": {
                      color: "rgba(0,0,0,0.4) !important",
                    },
                  }}
                  checked={value}
                  onCheckChange={onChange}
                  label="Отображать Whatsapp в опубликованном меню"
                  disabled={currentTariff !== "Премиум"}
                />
              )}
            />
          </>
        )}
        {currentTariff &&
          ["Премиум", "Продвинутый"].includes(currentTariff) && (
            <WrapperLabel title="Тема">
              <Controller
                name="theme"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error, isDirty },
                  formState,
                }) => (
                  <WrappedSelect
                    options={theme}
                    value={value}
                    onChange={onChange}
                    disabled={
                      currentTariff
                        ? !["Премиум", "Продвинутый"].includes(currentTariff)
                        : true
                    }
                    sx={{
                      height: "32px",
                    }}
                  />
                )}
              />
            </WrapperLabel>
          )}
        {currentTariff === "Премиум" && (
          <WrapperLabel title="Язык">
            <Controller
              name="languages"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error, isDirty },
                formState,
              }) => (
                <WrappedSelect
                  options={language}
                  value={value}
                  onChange={onChange}
                  multiple
                  sx={{ height: "32px" }}
                  disabled={currentTariff !== "Премиум"}
                />
              )}
            />
          </WrapperLabel>
        )}
      </Box>
      {typeModal === "delete" && (
        <ConfirmModal
          onClose={() => setTypeModal(null)}
          onAccept={handlerDeleteTemplate}
          buttonAccept="Удалить шаблон"
          headerModal="Удалить шаблон"
          bodyModal={`Вы действительно хотите удалить шаблон ${watch("name")}?`}
        />
      )}
      {typeModal === "copy" && (
        <ConfirmModal
          onClose={() => setTypeModal(null)}
          onAccept={handlerCopyTemplate}
          buttonAccept="Скопировать шаблон"
          headerModal="Скопировать шаблон"
          bodyModal={`Вы действительно хотите скопировать текущий шаблон? При нажатии на кнопку "Спопировать шаблон" будет открыт новый шаблон.`}
        />
      )}
      {typeModal === "export" && (
        <ConfirmModal
          width={470}
          onClose={() => setTypeModal(null)}
          onAccept={() => {
            copyTextToClipboard(guidTemplate);
          }}
          buttonAccept="Скопировать идентификатор"
          headerModal="Экспортировать шаблон"
          bodyModal={
            <WrapperInput
              label=""
              backgroundColor="#0D0C22"
              fullWidth
              disabled
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                  color: "#FFFFFF !important",
                },
              }}
              value={guidTemplate}
            />
          }
        />
      )}
      {typeModal === "publish" && (
        <ConfirmModal
          onClose={() => setTypeModal(null)}
          onAccept={handlerPublishTemplate}
          buttonAccept="Опубликовать шаблон"
          headerModal="Опубликовать шаблон"
          bodyModal={`Вы действительно хотите опубликовать шаблон ${watch("name")}`}
        />
      )}
    </form>
  );
}

export default Settings;
