import { Accordion as MuiAccordion, AccordionProps as MuiAccordionProps } from "@mui/material";

function Accordion({ disableGutters = false, expanded: expandedProp, sx, children,
    ...props }: Omit<MuiAccordionProps, 'elevation' | 'variant'>) {
        
    return(
        <MuiAccordion
            sx={[
                {
                    boxShadow: `0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2)`
                },
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
            disableGutters={disableGutters}
            variant='elevation'
            { ...props }
        >            
            { children }            
        </MuiAccordion>
    );
}

export default Accordion;