import { useParams } from "react-router-dom";
import { Box, Typography } from "../../../components";
import { useEffect } from "react";
import { subscription } from "../../api/request";

export default function Unsubscribe() {
    const { id } = useParams();

    useEffect(() => {
        if (id) 
            subscription.unsubscribe({ id });
    }, []);

    return (<Box sx={{
        display:"block"
    }}>
        <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px"
        }}>
            <Typography sx={{
                fontSize: "1.2rem",
                textAlign: "center"
            }}>Вы успешно отписались от расссылки</Typography>
            </Box>
    </Box>);
}