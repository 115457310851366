import { Box, Typography } from "../../../../components";
import { useEffect, useState } from "react";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { req } from "../../../api";
import moment from "moment";
import WrapperButton from "../../../component/Button/WrappedButton";
import WrapperDatePicker from "../../../component/DatePicker/WrappedDatePicker";
import DiagrammWrapper from "../../../component/Diagramm/diagrammWrapper";

export type StatisticsDinamicsType = {};

/**Рейтинг групп и позиций */
function StatisticsDinamics({}: StatisticsDinamicsType) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [activityDaysData, setActivityDaysData] = useState<
    { name: string; Количество: number }[]
  >([]);
  const [activityHoursData, setActivityHoursData] = useState<
    { name: string; Количество: number }[]
  >([]);
  const [dateFrom, setDateFrom] = useState<moment.Moment | null>(null);
  const [dateTo, setDateTo] = useState<moment.Moment | null>(null);
  const [fixedDate, setFixedDate] = useState<moment.Moment | null>(moment());

  const getStatisticData = () => {
    const startDate = fixedDate ?? dateFrom;
    const endDate = fixedDate ?? dateTo;
    if (startDate && endDate) {
      Promise.all([
        req.stasticsEstablishment.getByDaysOfWeek(),
        req.stasticsEstablishment.getByHours(startDate, endDate),
      ]).then(([ActivityDaysData, ActivityHoursData]) => {
        if (ActivityDaysData.success) {
          setActivityDaysData(
            ActivityDaysData.data.reduce(
              (acc, el) => {
                acc.push({ name: el.name, Количество: el.count });
                return acc;
              },
              [] as { name: string; Количество: number }[],
            ),
          );
        }
        if (ActivityHoursData.success) {
          setActivityHoursData(
            ActivityHoursData.data.reduce(
              (acc, el) => {
                acc.push({ name: el.name, Количество: el.count });
                return acc;
              },
              [] as { name: string; Количество: number }[],
            ),
          );
        }
      });
    }
  };

  useEffect(() => {
    getStatisticData();
  }, []);

  function LabelWrapper({
    label,
    children,
    fullwidth,
  }: {
    label: string;
    children: React.ReactElement;
    fullwidth?: boolean;
  }) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: desktop ? "center" : "start",
          gap: desktop ? "10px" : "2px",
          flexDirection: desktop ? "row" : "column",
          width: fullwidth ? "100%" : "unset",
        }}
      >
        <Typography variant="caption">{label}</Typography>
        {children}
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "60px",
        marginTop: !desktop ? "30px" : "0px",
      }}
    >
      <DiagrammWrapper
        diagrammsData={[
          { label: "Активность по дням", dataDiagramm: activityDaysData },
        ]}
      />
      <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            gap: "20px",
            alignItems: desktop ? "center" : "start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: desktop ? "normal" : "space-between",
              width: !desktop ? "100%" : "unset",
              gap: "20px",
            }}
          >
            <LabelWrapper
              label="Период с"
              children={
                <WrapperDatePicker
                  value={dateFrom}
                  onChange={(value) => {
                    setDateFrom(value);
                    setFixedDate(null);
                  }}
                  maxDate={dateTo ? dateTo : moment()}
                  sx={{
                    width: "150px",
                  }}
                />
              }
            />
            <LabelWrapper
              label="по"
              children={
                <WrapperDatePicker
                  value={dateTo}
                  onChange={(value) => {
                    setDateTo(value);
                    setFixedDate(null);
                  }}
                  minDate={dateFrom ? dateFrom : undefined}
                  maxDate={moment()}
                  sx={{
                    width: "150px",
                  }}
                />
              }
            />
          </Box>
          <LabelWrapper
            label="Дата"
            children={
              <WrapperDatePicker
                value={fixedDate}
                onChange={(value) => {
                  setFixedDate(value);
                  setDateTo(null);
                  setDateFrom(null);
                }}
                maxDate={moment()}
                sx={{
                  width: desktop ? "150px" : "100%",
                }}
              />
            }
            fullwidth={!desktop ? true : false}
          />
          <WrapperButton
            label="Применить"
            variant="contained"
            sx={{
              marginLeft: desktop ? "auto" : "unset",
              height: "36px",
              whiteSpace: "nowrap",
            }}
            disabled={!fixedDate ? !dateFrom || !dateTo : false}
            onClick={getStatisticData}
          />
        </Box>
        <DiagrammWrapper
          diagrammsData={[
            {
              label: "Активность по часам",
              dataDiagramm: activityHoursData,
              typeDiagramm: "AreaChart",
            },
          ]}
        />
      </Box>
    </Box>
  );
}

export default StatisticsDinamics;
