import { MouseEvent, PointerEvent, useState } from "react";

/*Предотвращает клик по ссылке внутри слайдера, если пользователь его прокручивает*/
function usePreventClick() {
    const [ isMoved, setIsMoved ] = useState(false);
    const [ pointerDown, setPointerDown ] = useState(false);

    function onPointerDown() {
        setPointerDown(true);
    }

    function onPointerMove(e: PointerEvent<HTMLAnchorElement>) {
        if (!pointerDown) return;
        e.preventDefault();
        setIsMoved(true);
    }

    function onTouchEnd() {
        setIsMoved(false);
        setPointerDown(false);
    }

    function onMouseLeave() {
        setIsMoved(false);
        setPointerDown(false);
    }

    function onClick(e: MouseEvent) {
        if (isMoved && pointerDown) {
            e.preventDefault();
        }
        setIsMoved(false);
        setPointerDown(false);
    }

    return { onClick, onMouseLeave, onPointerMove, onTouchEnd, onPointerDown };
}

export default usePreventClick;