import { Box, Typography } from "../../../components"
import bg from "../../shared/assets/img/not-found-bg.svg";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";

function Img({ position }:{position: "left" | "right"}) {
    return(
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                backgroundImage: `url(${bg})`,
                backgroundPosition: position,
                width: '15%',
                backgroundSize: 'auto 100%',
                height: '100%',
            }}
        >
        </Box>
    );
}

export default function NotFound() {
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

    return (<Box sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        flexDirection: "row",
        alignContent: "space-between",
        flexWrap: "wrap",
        justifyContent: "space-between",
    }}>
       
        <Img position={"right"}/>
    
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '100px',
                marginTop: '100px',
            }}>
                <Typography sx={{
                    fontSize: desktop ? '300px' : '120px',
                    width: '100%',
                    textAlign: 'center',
                    lineHeight: '1',
                    fontWeight: '600',
                }}>404</Typography>
                <Typography sx={{
                    fontSize: desktop ? '30px' : '17px',
                    width: '100%',
                    textAlign: 'center'
                }}>Ой, здесь ничего нет.</Typography>
                <Typography sx={{
                    fontSize: desktop ? '30px' : '14px',
                    width: '100%',
                    textAlign: 'center',
                    maxWidth: desktop ? '545px' : '248px',
                }}>Попробуйте вернуться на главную и попробовать снова</Typography>
            </Box>
        </Box>
       
        <Img position={"left"}/>
        
    </Box>)
} 