import { api } from "../api";
import { Response } from "../type";

export namespace MenuTemplate {
    export async function getMenuByGuid(guid: string) {
        return api.sendRequest<Response<TMenuTemplate>>({
            domain: "on-menu",
            method: "get",
            url: `/Menu/MenuTemplate/ByGuid/${guid}`
        });
    }

    export async function getMenuByGuidWithLang(guid: string, langId: string) {
        return api.sendRequest<Response<TMenuTemplate>>({
            domain: "on-menu",
            method: "get",
            url: `/Menu/MenuTemplate/ByGuid/${guid}/${langId}`
        });
    }

    export async function getMenuByNameWithLang(name: string, langId: string) {
        return api.sendRequest<Response<TMenuTemplate>>({
            domain: "on-menu",
            method: "get",
            url: `/Menu/MenuTemplate/ByName/${name}/${langId}`
        });
    }

    export async function Click(elementType: EElementType, templateId: number, elementId: number, groupId: number = -1) {
        return api.sendRequest({
            domain: "on-menu",
            method: 'post',
            url: `/Menu/MenuTemplate/${templateId}/Click/${elementType}/${elementId}/${groupId}`
        });
    }
}

export type TMenuPositionPriceItem = {
    amount: string | null,
    value: number
}

export type TMenuPosition = {
    photo: {
        guid: string;
        fileName: string;
        originalName: string;
        contentType: string;
        fileSize: number;
        created: string;
        modified: string;
        content: {
            canRead: boolean;
            canWrite: boolean;
            canSeek: boolean;
            canTimeout: boolean;
            length: number;
            position: number;
            readTimeout: number;
            writeTimeout: number;
        },
        fileSizeMb: number;
        extension: string;
        pageCount: number;
    },
    id: number;
    name: string;
    ingredients: string;
    description: string;
    foodValue: number;
    proteins: number;
    fats: number;
    carbohydrates: number;
    photoId: string;
    stop: boolean;
    order: number;
    features: number[];
    groups: number[];
    prices: TMenuPositionPriceItem[],
    new: boolean,
    spicy: boolean,
    vegan: boolean,
    popular: boolean,
}

export type TMenuGroup = {
    id: number;
    name: string;
    order: number;
}

export type TMenuTemplate = {
    organizationLogoId: string;
    workhoursString: string;
    groups: TMenuGroup[],
    positions: TMenuPosition[],
    id: number;
    status: number;
    statusName: string;
    organizationName: string;
    organizationAddress: string;
    created: string;
    modified: string;
    published: string;
    canPublish: boolean;
    name: string;
    description: string;
    phoneNumber: string;
    displayPhoneNumber: boolean;
    website: string;
    displayWebsite: boolean;
    telegram: string;
    link: string;
    displayTelegram: boolean;
    whatsApp: string;
    displayWhatsApp: boolean;
    theme: number;
    languages: number[];
};

export enum EElementType {
    GROUP = 1,
    POSITION
}