// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes TK71fJsmUng4yDwAgeqW {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    70% {
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.gE_9ToGt9IN_sFNyTHUZ{
    transform: scale(0);
    animation-name: TK71fJsmUng4yDwAgeqW;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    transform-origin: 27px 27px;
}

.dlb3vJhjnloZ2zGormXf{
    animation-delay: 0.25s;
}

.TK71fJsmUng4yDwAgeqW{
    animation-delay: 0s;
}`, "",{"version":3,"sources":["webpack://./../components/Components/Alert/SimpleAlert/AnimatedIcon.module.css"],"names":[],"mappings":"AAAA;IACI;QACI,mBAAmB;QACnB,UAAU;IACd;IACA;QACI,UAAU;IACd;IACA;QACI,mBAAmB;QACnB,UAAU;IACd;AACJ;;AAEA;IACI,mBAAmB;IACnB,oCAAqB;IACrB,mCAAmC;IACnC,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["@keyframes first {\n    0% {\n        transform: scale(0);\n        opacity: 0;\n    }\n    70% {\n        opacity: 1;\n    }\n    100% {\n        transform: scale(1);\n        opacity: 0;\n    }\n}\n\n.animate{\n    transform: scale(0);\n    animation-name: first;\n    animation-iteration-count: infinite;\n    animation-duration: 1s;\n    transform-origin: 27px 27px;\n}\n\n.second{\n    animation-delay: 0.25s;\n}\n\n.first{\n    animation-delay: 0s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animate": `gE_9ToGt9IN_sFNyTHUZ`,
	"first": `TK71fJsmUng4yDwAgeqW`,
	"second": `dlb3vJhjnloZ2zGormXf`
};
export default ___CSS_LOADER_EXPORT___;
