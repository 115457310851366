import { api } from "../api";

type AuthContextStorageData = {
  access_token: string | null;
  refresh_token?: string | null;
};

export const STORAGE_KEY = "onMenuToken";
const IMPERSONALIZATION_STORAGE_KEY = "onMenuImpersonalization";

const defaultStorageData: AuthContextStorageData = {
  access_token: null,
  refresh_token: null,
};

const stringifiedDefaultStorageData = JSON.stringify(defaultStorageData);

export async function getAuthContext(
  storageKey: string,
): Promise<AuthContextStorageData> {
  try {
    return JSON.parse(
      localStorage.getItem(storageKey) ??
        sessionStorage.getItem(storageKey) ??
        stringifiedDefaultStorageData,
    );
  } catch (e) {
    throw new Error("Error reading auth context from localStorage");
  }
}

function getImpersonalization(): number | undefined {
  try {
    const value =
      localStorage.getItem(IMPERSONALIZATION_STORAGE_KEY) ??
        sessionStorage.getItem(IMPERSONALIZATION_STORAGE_KEY) ??
        undefined;

    if (value) {
      return Number(value);
    }

    return undefined;
  } catch (e) {
    throw new Error("Error reading auth context from localStorage");
  }
}

export function setImpersonalization(value: number | null): void {
  if (!value){
    localStorage.removeItem(IMPERSONALIZATION_STORAGE_KEY);
  } else {
    localStorage.setItem(IMPERSONALIZATION_STORAGE_KEY, value.toString());
  }
}

export async function saveAuthContext(
  context: AuthContextStorageData,
  isRememberMe: boolean,
): Promise<void> {
  try {
    if (isRememberMe) {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(context));
    } else {
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(context));
    }
  } catch (e) {
    throw new Error("Error writing auth context to localStorage");
  }
}

export async function deleteAuthContext(): Promise<void> {
  try {
    localStorage.removeItem(STORAGE_KEY);
    sessionStorage.removeItem(STORAGE_KEY);
  } catch (e) {
    throw new Error("Error deleting auth context to localStorage");
  }
}

export function configApi(storageKey: string = STORAGE_KEY) {
  api.config({
    domainConfigs: {
      'on-menu': {
        baseURL: process.env.REACT_APP_BASE_URL as string,
      },
    },
    getToken: async () => {
      const authContext = await getAuthContext(storageKey);
      return {
        access_token: authContext.access_token,
        refresh_token: authContext.refresh_token,
      };
    },
    impersonate: getImpersonalization
  });
}
