import { BoxProps, SxProps, Theme } from "@mui/material";
import { Box, Typography} from "../../../../components";
import { PositionPriceItem } from "../../../api/request/template";
import { numberWithSpaces } from "../../../utils";

export type SummaryContentProps = {
    prices: PositionPriceItem[];
    isOpened: boolean;
} & BoxProps;

export function SingleAmount({price, sx}: {price: number, sx: SxProps<Theme>}) {
    return (<Box
        sx={{
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "1.187rem",
            paddingLeft: "4px",
            whiteSpace: 'nowrap',
            letterSpacing: '0.2px',
            height: "100%",
            ...sx,
        }}
    >
        { numberWithSpaces(price) } ₽
    </Box>);
}

export function MultiAmount({prices, sx}: {prices: PositionPriceItem[], sx: SxProps<Theme>}) {
    return (<Box sx={{
        display: "flex",
        flexFlow: "column",
        marginTop: '3px',
        ...sx
    }}>
        {prices.map((x, index) => <Box key={index} sx={{
            display: "flex",
            flexFlow: "row",
            height: '100%',
            marginTop: index === 1 ? '5px' : '0px'
        }}>
            <Typography sx={{
                display: "inline-block",
                width: "40px",
                textAlign: "right",
                fontSize: "0.625rem",
                height: '100%',
                marginTop: '1px',
            }}>{x.amount}</Typography>
            <Typography sx={{
                display: "inline-block",
                marginLeft: "5px",
                width: "69px",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "1.187rem",
                whiteSpace: "nowrap",
                textAlign: "right",
                letterSpacing: '0.2px',
                height: '100%',
            }}>{ `${numberWithSpaces(x.value)} ₽`}</Typography>
        </Box>)}
    </Box>)
}

/**Название и цена блюда */
function SummaryContent({ prices, isOpened, sx, children, ...props }: SummaryContentProps) {

    const price = prices.length === 1 ? <SingleAmount price={prices[0].value} sx={{
        marginTop: '15px'
    }} /> : <MultiAmount prices={prices} sx={{}} />;

    const style: SxProps<Theme> = !isOpened ? { 
        width: '100%',
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: "2",
        overflow: "hidden",
        maxHeight: '34px',
       
    } : {
        width: '100%',
        padding: "0 0",
    };

    return(
        <Box
            { ...props }
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: isOpened ? 'stretch' : 'stretch',
                lineHeight: '17px',
                minHeight: '48px',
                ...sx,
            }}
        >
            <Box sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'start',
            }}>
                <Box sx={{
                    marginTop: '7px',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '32px',
                }}>
                    <Box sx={style}>
                        { children }
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                height: '100%',
                // position:'relative',
                // paddingTop: isOpened ? '14px' : '0'
            }} >{price}</Box>
        </Box>
    );
}

export default SummaryContent;