import { AccordionDetails as MuiAccordionDetails, AccordionDetailsProps as MuiAccordionDetailsProps, Typography } from "@mui/material";

function AccordionDetails({ sx, children, ...props }: MuiAccordionDetailsProps) {
    return(
        <MuiAccordionDetails
            sx={[
                {
                    padding: '0 24px 16px 24px',
                },
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
            { ...props }
        >
            <Typography variant='body1' component='div'>
                { children }
            </Typography>
        </MuiAccordionDetails>
    );
}

export default AccordionDetails;