import { api } from "../api";
import { Response } from "../type";

export type ItemsStatisticsEstablishmentDTO = {
  name: string;
  count: number;
  children: ItemsStatisticsEstablishmentDTO[];
};

export type ViewDataDTO = {
  items: ItemsStatisticsEstablishmentDTO[];
  groupClickTotalCount: number;
  positionClickTotalCount: number;
  visitTotalCount: number;
};

export type StatisticsDiagrammDTO = {
  name: string;
  count: number;
};

export namespace StatisticsEstablishment {
  export async function getViewData() {
    return api.sendRequest({
      domain: "on-menu",
      method: "get",
      url: `/menu/menuStatistics/View`,
    });
  }
  export async function getRatingTopGroups(daysOfWeek: number[]) {
    return api
      .sendRequest<Response<StatisticsDiagrammDTO[]>>({
        domain: "on-menu",
        method: "post",
        url: `/menu/menuStatistics/GetTopGroups`,
        data: { daysOfWeek },
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function getRatingTopPosition(daysOfWeek: number[]) {
    return api
      .sendRequest<Response<StatisticsDiagrammDTO[]>>({
        domain: "on-menu",
        method: "post",
        url: `/menu/menuStatistics/GetTopPositions`,
        data: { daysOfWeek },
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function getByDaysOfWeek() {
    return api
      .sendRequest<Response<StatisticsDiagrammDTO[]>>({
        domain: "on-menu",
        method: "get",
        url: `/menu/menuStatistics/GetByDaysOfWeek`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function getByHours(start: moment.Moment, end: moment.Moment) {
    return api
      .sendRequest<Response<StatisticsDiagrammDTO[]>>({
        domain: "on-menu",
        method: "get",
        url: `/menu/menuStatistics/getByHours?start=${start.format("YYYY-MM-DD")}&end=${end.format("YYYY-MM-DD")}`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
}
