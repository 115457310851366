declare module "@mui/material" {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    tablet: true;
    desktop: true;
    laptop: true;
  }
}

export const spacingValues = {
  none: "0px",
  "4px": "4px",
  BaseValue: "8px",
  "12px": "12px",
  SmallPadding: "16px",
  "20px": "20px",
  MediumPadding: "24px",
  "28px": "28px",
  LargePadding: "32px",
  "36px": "36px",
  "40px": "40px",
  "44px": "44px",
  SmallSpacing: "48px",
  "52px": "52px",
  "56px": "56px",
  "60px": "60px",
  MediumSpacing: "64px",
  LargeSpacing: "96px",
  ExtraLargeSoacing: "192px",
};

export const spacing = [
  0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 96, 192,
];

export const borderRadius = {
  none: "0px",
  sm: "2px",
  md: "4px",
  lg: "8px",
  xl: "24px",
  pill: "9999px",
  circle: "100px",
};

export const breakpoints = {
  values: {
    laptop: 1000,
    tablet: 640,
    mobile: 320,
    desktop: 1280,
  },
};

export const typography = {
  htmlFontSize: 16,
  h1: {
    fontFamily: "Inter",
    fontSize: "6rem",
    fontWeight: 100,
    lineHeight: 1,
  },
  h2: {
    fontFamily: "Inter",
    fontSize: "4.5rem",
    fontWeight: 300,
    lineHeight: 1.33,
  },
  h3: {
    fontFamily: "Inter",
    fontSize: "3.125rem",
    fontWeight: 300,
    lineHeight: 1,
  },
  h4: {
    fontFamily: "Inter",
    fontSize: "1.25rem",
    fontWeight: 300,
    lineHeight: 1,
  },
  h5: {
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontWeight: 700,
    lineHeight: 1.714285714,
  },
  subtitle1: {
    fontFamily: "Inter",
    fontSize: "1.3125rem",
    fontWeight: 500,
    lineHeight: 1.142857143,
  },
  subtitle2: {
    fontFamily: "Inter",
    fontSize: "1rem",
    fontWeight: 300,
    lineHeight: 1.5,
  },
  body1: {
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 2,
  },
  caption: {
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontWeight: 300,
    lineHeight: 1.25,
  },
  overline: {
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: 2.67,
  },
};

export const primaryGradient = 'linear-gradient(-45deg, #674099,#ED6985)'

