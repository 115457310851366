import {
  defaultPositionValue,
  getTemplateData,
  useTemlateCardData,
} from "../../../store/templateCard";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { Box, FileInput, Typography } from "../../../../components";
import WrapperLabel from "../wrapperLabel";
import WrapperButton from "../../../component/Button/WrappedButton";
import { PositionType, TemplateDataDto } from "../../../api/request/template";
import { ArrowBack } from "@mui/icons-material";
import ConstructorTitle from "../constructorTitle";
import { req } from "../../../api";
import RestaurantInfo from "../RestaurantInfo";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import WrapperInput from "../../../component/Input/WrappedInput";
import WrappedSelect from "../../../component/Select";
import WrapperTextarea from "../../../component/Textarea/Textarea";
import PriceInput from "./priceInput";
import StarIcon from "../../../shared/assets/img/icons/starIcon";
import FireIcon from "../../../shared/assets/img/icons/fireIcon";
import NewIcon from "../../../shared/assets/img/icons/newIcon";
import LeafIcon from "../../../shared/assets/img/icons/leafIcon";
import AttachedFileIcon from "../../../shared/assets/img/icons/AttachedFileIcon";
import ClearIcon from "../../../shared/assets/img/icons/ClearIcon";
import { useState, useEffect } from "react";
import { useAlert } from "../../../store/alert";
import { useDispatch } from "../../../store/model/extensions";
import WrappedCheckbox from "../../../component/Checkbox/WrappedCheckbox";

type OptionSelect = { value: number; label: string }[];

/**Меню заведения */
function PositionEdit() {
  const { tempateData, updateGroups } = useTemlateCardData();
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const { id, positionId } = useParams();
  const navigate = useNavigate();

  let product: PositionType | null = null;
  let groups: OptionSelect = [];
  tempateData.groups.forEach((group) => {
    groups.push({
      value: group.id,
      label: group.name,
    });
  });
  tempateData.positions.forEach((position) => {
    if (position.id === parseInt(positionId ?? "0")) {
      product = position;
    }
  });

  useEffect(() => {
    if ((positionId ?? "0") !== "0" && !product && tempateData) {
      navigate("/notfound");
    }
  }, []);

  const returnButtonText = (
    <>
      <ArrowBack /> Вернуться к списку блюд
    </>
  );

  const buttons = (
    <WrapperButton
      label={returnButtonText}
      variant="contained"
      sx={{
        width: desktop ? "auto" : "100%",
        whiteSpace: "nowrap",
      }}
      onClick={() => navigate(`/constructor/${id}/constructor`)}
    />
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: desktop ? "row" : "column-reverse",
        justifyContent: "space-between",
        gap: desktop ? "100px" : "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "50px",
          flex: 1,
        }}
      >
        <ConstructorTitle buttons={buttons} templateName={tempateData.name} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <ProductEditForm
            product={product}
            groups={groups}
            template={tempateData}
          />
        </Box>
      </Box>
      {desktop && <RestaurantInfo />}
    </Box>
  );
}

export default PositionEdit;

function ProductEditForm({
  product,
  groups,
  template
}: {
  product: PositionType | null;
  groups: OptionSelect;
  template: TemplateDataDto

}) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const navigate = useNavigate();
  const { id } = useParams();
  const [files, setFiles] = useState<File[]>([]);
  const [isChangeLogo, setIsChangeLogo] = useState(false);
  const { openAlert } = useAlert();
  const dispatch = useDispatch();

  const { handleSubmit, watch, control, getValues } = useForm<PositionType>({
    defaultValues: product || defaultPositionValue,
  });
  const onClose = () => [navigate(`/constructor/${id}/constructor`)];

  const onSubmit = (data: PositionType) => {
    const p = Number(id) ? Promise.resolve(Number(id)) : req.template.saveTemplateData(template, 0).then(saveRes => saveRes.data);
    
    p.then(templateId => Promise.all([req.template.savePositionData(data, templateId), templateId]))
      .then(async ([res, templateId]) => {
        if (files.length === 1) {
          const fileRes = await req.file.upload(files[0]);
          if (!fileRes.data.success) {
            throw new Error("Не удалось загрузить фото");
          }
          data.photoId = fileRes.data.data.guid;
          data.id = res.data;
          return await Promise.all([req.template.savePositionData(data, templateId), templateId]);
        }

        return Promise.all([res,templateId]);
      })
      .then(([res, templateId]) => {
        if (res.success) {
          openAlert({
            text: "Данные позиции успешно сохранены",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });

          if (templateId !== Number(id)) {
            navigate(`/constructor/${templateId}/constructor`);
          } else {
            dispatch(getTemplateData({ id: templateId }));
            onClose();
          }
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при сохранении позиции",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const onDelete = () => {
    req.template
      .deletePosition(product?.id!, template.id)
      .then(res => {
        if (res.success) {
          dispatch(getTemplateData({ id: template.id }))
            .then(() => navigate(`/constructor/${template.id}/constructor`));
          ;
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при сохранении позиции",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  }

  const nutritionFields = [
    {
      name: "Энергетическая ценность, кКал",
      field: "foodValue",
    },
    {
      name: "Белки, грамм",
      field: "proteins",
    },
    {
      name: "Жиры, грамм",
      field: "fats",
    },
    {
      name: "Углеводы, грамм",
      field: "carbohydrates",
    },
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "25px",
        }}
      >
        <Controller
          name="name"
          control={control}
          rules={{required: "Поле обязательно для заполнения" }}
          render={({
            field: { onChange, value },
            fieldState: { error, isDirty },
          }) => (
            <WrapperInput
              label=""
              fullWidth
              height="34px"
              colorType="white"
              value={value}
              error={error?.message}
              onChange={onChange}
            />
          )}
        />
        <Typography variant="subtitle2" color="#61646C" fontWeight="500">
          Основная информация
        </Typography>
        <WrapperLabel title="Описание">
          <Controller
            name="description"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error, isDirty },
            }) => (
              <WrapperTextarea
                onChange={onChange}
                value={value}
                error={error?.message}
              />
            )}
          />
        </WrapperLabel>
        <Controller
          name="prices"
          control={control}
          rules={{
            validate: () => {
              const rawPrices = getValues("prices");

              if (rawPrices?.length > 1) {
                return rawPrices.some(x => !x.amount || !x.value) === false;
              }

              return true;
           }
          }}
          render={({ 
            field: { onChange, value },
            fieldState: { error }, 
          }) => (
            <PriceInput onChange={onChange} prices={value} error={error?.message} />
          )}
        />
        <WrapperLabel title="Группа">
          <Controller
            name="groups"
            control={control}
            render={({ field: { onChange, value } }) => (
              <WrappedSelect
                options={groups}
                value={value}
                onChange={onChange}
                multiple
                sx={{
                  height: "32px",
                  maxWidth: desktop ? "none" : "320px",
                }}
              />
            )}
          />
        </WrapperLabel>
        <Typography variant="subtitle2" color="#61646C" fontWeight="500">
          Пищевая ценность
        </Typography>
        {nutritionFields.map((field) => (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: desktop ? "1fr 150px" : "1fr 70px",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Typography variant="caption" color="#0D0C22">
              {field.name}
            </Typography>
            <Controller
              name={field.field}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <WrapperInput
                  label=""
                  height="34px"
                  colorType="white"
                  value={value}
                  error={error?.message}
                  onChange={onChange}
                />
              )}
            />
          </Box>
        ))}
        <Typography variant="subtitle2" color="#61646C" fontWeight="500">
          Фотография
        </Typography>
        {watch("photoId") && !isChangeLogo ? (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <img
              width={desktop ? 200 : "100%"}
              style={{borderRadius:"20px"}}
              src={`${process.env.REACT_APP_BASE_URL}/file/downloadGuid?guid=${watch("photoId")}`}
              alt="Фотография"
            />
            <WrapperButton
              label="Изменить фото"
              variant="contained"
              sx={{ width: "200px" }}
              onClick={() => {
                setIsChangeLogo(true);
              }}
            />
          </Box>
        ) : (
          <FileInput
            accept="image/*"
            AttachIcon={AttachedFileIcon}
            ClearIcon={ClearIcon}
            files={files}
            onChange={(files) => {
              setFiles(files);
            }}
            multiple={false}
            sx={{
              position: "relative",
              borderRadius: "60px !important",
              background: "#FFFFFF",
              height: "34px",
              border: "none",
              fontSize: "14px",
              fontFamily: "Inter",
              color: "#0D0C22!important",
              "& label": {
                fontWeight: "400",
                fontSize: "14px",
                fontFamily: "Inter",
                "& span": {
                  color: "#0D0C22 !important",
                  lineHeight: 1.5,
                },
              },
              "&:after": {
                position: "absolute",
                top: -2,
                left: -2,
                right: -2,
                bottom: -2,
                background: "linear-gradient(-45deg, #674099,#ED6985)",
                content: '""',
                zIndex: -1,
                borderRadius: "60px",
              },
            }}
            containerProps={{ width: "100%" }}
            label="Загрузить файл"
          />
        )}
        <SpecialFields control={control} />
      </Box>
      <Box sx={{
        width: '100%',
        marginTop: '20px'
      }}>
        <WrapperButton
            label="Удалить позицию"
            variant="contained"
            secondary={true}
            sx={{
              flex: "1",
              width: '100%'
            }}
            onClick={onDelete}
          />
      </Box>
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <WrapperButton
          label="Отменить"
          variant="contained"
          secondary={true}
          sx={{
            flex: "1",
          }}
          onClick={onClose}
        />
        <WrapperButton
          label="Сохранить"
          variant="contained"
          sx={{
            flex: "1",
          }}
          type="submit"
        />
      </Box>
    </form>
  );
}

function SpecialFields({ control }) {
  const style = { display: "flex" };
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const specialFields = [
    {
      name: "new",
      label: "новинка",
      icon: <FireIcon sx={style} />,
    },
    {
      name: "popular",
      label: "популярное",
      icon: <NewIcon sx={style} />,
    },
    {
      name: "spicy",
      label: "острое",
      icon: <StarIcon sx={style} />,
    },
    {
      name: "vegan",
      label: "веганское",
      icon: <LeafIcon sx={style} />,
    },
  ];
  return (
    <Box
      sx={{
        marginTop: "20px",
        display: desktop ? "flex" : 'grid',
        gap: "10px",
        gridTemplateColumns: "1fr 1fr",
        justifyContent: "space-between",
      }}
    >
      {specialFields.map((field) => {
        return (
          <Controller
            name={field.name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <WrappedCheckbox
                  checked={!!value}
                  onCheckChange={() => onChange(!value)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "6px",
                        color: value ? "rgba(0,0,0,1)" : "rgba(0,0,0,0.5)",
                      }}
                    >
                      {field.icon}
                      <Typography
                        variant="body2"
                        fontWeight="600"
                        lineHeight="1"
                      >
                        {field.label}
                      </Typography>
                    </Box>
                  }
                />
            )}
          />
        );
      })}
    </Box>
  );
}
