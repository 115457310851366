import React, { useMemo, useState } from "react";
import type { ReactNode } from "react";
import {
    DndContext,
  } from "@dnd-kit/core";
  import type { Active } from "@dnd-kit/core";
  import {
    SortableContext,
    arrayMove,
  } from "@dnd-kit/sortable";
  import { List } from "@mui/material";

  import { DragHandle, SortableItem, SortableOverlay } from "./components";

interface BaseItem {
  id: number | null;
}

interface Props<T extends BaseItem> {
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T): ReactNode;
}

export function SortableList<T extends BaseItem>({
  items,
  onChange,
  renderItem
}: Props<T>) {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );
//   const sensors = useSensors(
//     // useSensor(PointerSensor),
//     // useSensor(KeyboardSensor, {
//     //   coordinateGetter: sortableKeyboardCoordinates
//     // })
//   );

  return (
    <DndContext
      sensors={undefined}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        <List sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "0",
            listStyle: "none",
        }} role="application">
          {items.map((item) => (
            <React.Fragment key={item.id}>{renderItem(item)}</React.Fragment>
          ))}
        </List>
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem) : null}
      </SortableOverlay>
    </DndContext>
  );
}

SortableList.Item = SortableItem;
SortableList.DragHandle = DragHandle;