const getFileNameFromHeaders = (headers: any) => {
  if ("content-disposition" in headers) {
    const disposition = headers["content-disposition"];
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;
    let fileName: string | null = null;
    if (utf8FilenameRegex.test(disposition)) {
      fileName = decodeURIComponent(
        utf8FilenameRegex.exec(disposition)?.[1] ?? "",
      );
    } else {
      // prevent ReDos attacks by anchoring the ascii regex to string start and
      //  slicing off everything before 'filename='
      const filenameStart = disposition.toLowerCase().indexOf("filename=");
      if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition);
        if (matches != null && matches[2]) {
          fileName = matches[2];
        }
      }
    }
    return fileName;
  }
  return "";
};

const downloadBlobFile = (blob: Blob, fileName: string | null = null) => {
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob, fileName || "file");
    return;
  }

  var a = document.createElement("a");
  a.setAttribute("display", "none");
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  if (fileName) a.download = fileName;
  if (typeof a.download === "undefined") {
    a.setAttribute("target", "_blank");
  }
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};

const downloadFile = (href: string) => {
  var a = document.createElement("a");
  document.body.appendChild(a);
  a.setAttribute("display", "none");
  a.href = href;
  a.click();
  document.body.removeChild(a);
};

export default {
  downloadBlobFile,
  downloadFile,
  getFileNameFromHeaders,
};
