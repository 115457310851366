import { TSearchData } from "../../component/DataTable/types";
import { api } from "../api";
import { Response } from "../type";

export type TariffDataDto = {
  period: number;
  periodName: string;
  price: number;
  priceWithDiscount: number;
  tariffId: number;
};

type TariffDto = {
  trialPeriod: number;
  tariffs: {
    base: TariffDataDto[];
    advanced: TariffDataDto[];
    premium: TariffDataDto[];
  };
};

export type TariffUserDiagrammDto = {
  totalCount: number;
  diagramData: Record<string, number>;
};

export type TariffUserTableDto = {
  tariff: number;
  tariffName: string;
  userId: number;
  userFullName: string;
  phoneNumber: string;
  organizationName: string;
  subscriptionStart: Date;
  subscriptionEnd: Date;
  period: number;
  periodName: string;
};

export type NewUserTableDto = {
  tariff: number;
  tariffName: string;
  userId: number;
  userFullName: string;
  phoneNumber: string;
  organizationName: string;
  registrationDate: Date;
};

export type NewUserDiagrammDto = {
  totalCount: number;
  diagramData: { item1: string; item2: number }[];
};

export type PaymentUserDiagrammDto = {
  total: number;
  orderByTariffData: Record<string, number>;
  diagramData: { item1: string; item2: number }[];
};

export type PaymentTableDto = {
  autoProlongation: boolean;
  orderDate: Date;
  period: number;
  periodName: string;
  price: number;
  tariff: number;
  tariffName: string;
  userFullName: string;
  userId: number;
};

export namespace Analitics {
  export async function getTariffUserDiagram() {
    return api
      .sendRequest<Response<TariffUserDiagrammDto>>({
        domain: "on-menu",
        method: "get",
        url: `/analytics/TariffUser/Diagram`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  //Возвращает постраничный список заказов
  export async function getTariffUserTable(data: TSearchData) {
    return api.sendRequest({
      domain: "on-menu",
      method: "post",
      url: `/analytics/TariffUser/Table `,
      data: data,
    });
  }

  export async function getNewUserDiagram(dateFrom: string, dateTo: string) {
    return api
      .sendRequest<Response<NewUserDiagrammDto>>({
        domain: "on-menu",
        method: "get",
        url: `/analytics/NewUser/Diagram?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  export async function getNewUserTable(data: TSearchData) {
    return api.sendRequest({
      domain: "on-menu",
      method: "post",
      url: `/analytics/NewUser/Table `,
      data: data,
    });
  }

  export async function getPaymentDiagram(dateFrom: string, dateTo: string) {
    return api
      .sendRequest<Response<PaymentUserDiagrammDto>>({
        domain: "on-menu",
        method: "get",
        url: `/analytics/OrderByPeriod/Diagram?dateFrom=${dateFrom}&dateTo=${dateTo}`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  export async function getPaymentTable(data: TSearchData) {
    return api.sendRequest({
      domain: "on-menu",
      method: "post",
      url: `/analytics/OrderByPeriod/Table `,
      data: data,
    });
  }
}
