import { Box } from "../../../components";
import Loader from ".";

function ScreenLoader() {
    return(
        <Box
            sx={{
                position: 'fixed',
                width: '100%',
                height: '100vh',
                backgroundColor: '#FFFFFF',
                top: 0,
                left: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Loader/>
        </Box>
    );
}

export default ScreenLoader;