import { api } from "../api";
import { Response } from "../type";

export type TariffDataDto = {
  period: number;
  periodName: string;
  price: number;
  priceWithDiscount: number;
  tariffId: number;
};

type TariffDto = {
  trialPeriod: number;
  tariffs: {
    base: TariffDataDto[];
    advanced: TariffDataDto[];
    premium: TariffDataDto[];
  };
};

export namespace Dictionary {
  export async function getDictionary(name: string) {
    return api
      .sendRequest<Response<{ id: number; name: string; title: string }[]>>({
        domain: "on-menu",
        method: "get",
        url: `/dictionary/${name}/Items`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
}
