import { Box, Typography } from "../../../../components";
import WrapperLabelType from "../wrapperLabel";
import WrapperButton from "../../../component/Button/WrappedButton";
import { Add, Remove } from "@mui/icons-material";
import WrapperInput from "../../../component/Input/WrappedInput";
import { ReactNode, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import { PositionPriceItem } from "../../../api/request/template";

type PriceInputProps = {
  prices: PositionPriceItem[],
  error: string | undefined,
  onChange: (value: PositionPriceItem[]) => void
}

function PriceInput({ prices, onChange }: PriceInputProps ) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [arrayValue, setArrayValue] = useState(prices ?? [{ amount: "", value: 0 }]);

  const addPrice = () => {
    setValue([...arrayValue, { amount: "", value: 0 }]);
  };

  const setValue = (value: PositionPriceItem[]) => {
    setArrayValue(value);
    onChange(value)
  };

  const changePrice = (index: number, amount: string | null, value: number) => {
    arrayValue[index] = {
      amount,
      value
    };

    setValue([...arrayValue]);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
      }}
    >
      {arrayValue.map((priceObj, index) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              justifyContent: "space-between",
              gap: "6px",
            }}
          >
            
            <WrapperLabel title="Размер позиции" required={arrayValue.length > 1} sx={{
              marginRight: "20px"
            }}>
              <WrapperInput
                label=""
                fullWidth
                height="34px"
                colorType="white"
                value={priceObj.amount}
                onChange={(e: { target: { value: string } }) => {
                  changePrice(index, e.target.value, priceObj.value);
                }}
              />
            </WrapperLabel>
            <WrapperLabel title="Стоимость, руб." required={arrayValue.length > 1}>
              <WrapperInput
                label=""
                fullWidth
                height="34px"
                colorType="white"
                value={priceObj.value}
                onInput={(e: { target: { value: string } }) => {
                  return (
                    //@ts-ignore
                    (e.target.value = e.target.value.replace(/[^0-9\.\,\s]/g, ""))
                  );
                }}
                onChange={(e: { target: { value: string } }) => {
                  changePrice(index, priceObj.amount, Number(e.target.value));
                }}
              />
            </WrapperLabel>
            <WrapperButton
              label=""
              size={"normal"}
              variant="contained"
              icon={arrayValue.length > 1 ? <Remove /> : <Add />}
              iconPosition={"only"}
              onClick={
                arrayValue.length === 1
                  ? addPrice
                  : () => {
                      arrayValue.splice(index, 1);
                    setValue([...arrayValue]);
                    }
              }
              sx={{
                width: "44px",
                height: "36px",
              }}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default PriceInput;

 type WrapperLabelType = {
  title: string;
  sx?: {};
  children: ReactNode;
  required?: boolean;
};


function WrapperLabel({ children, title, sx, required = false }: WrapperLabelType) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        alignItems: desktop ? "center" : "start",
        gap: desktop ? "20px" : "5px",
        flexDirection: desktop ? "row" : "column",
        ...sx
      }}
    >
      <Typography
        sx={{
          flexBasis: desktop ? "100%" : "unset",
        }}
        variant="caption"
        color="#0D0C22"
      >
        {title}
        {required ? <Typography variant="caption" color="#F00" fontWeight="700">*</Typography> : <></>}
      </Typography>
      {children}
    </Box>
  );
}
