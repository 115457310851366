export const enum StatusEnum {
  DRAFT = 'Черновик',
  PUBLISHED = 'Опубликовано'
};

export const enum ModalEnum  {
  DELETE = 'delete',
  IMPORT = 'import'
};

export interface ITemplate {
  id: number;
  name: string;
  status: any;
  updatedAt: string;
  controls: string;
}