import { Box, Typography } from "../../../../components";
import ContentContainer from "../../../component/ContentContainer";
import background from "./images/background.svg";

const blockData = [
  {
    title: "Редактировать",
    description: "меню легко с любого устройства",
    isPlus: true,
  },
  {
    title: "Автоматическая",
    description: "генерация QR-кода",
    isPlus: true,
  },
  {
    title: "Статистика",
    description: "по гостям",
    isPlus: true,
  },
  {
    title: "Статистика",
    description: "по блюдам",
    isPlus: true,
  },
  {
    title: "Не нужно платить",
    description: "за свой сайт и хостинг",
    isPlus: true,
  },
  {
    title: "Правильный",
    description: "перевод блюд на любой язык мира",
    isPlus: true,
  },
  {
    title: "Быстрая",
    description: "загрузка",
    isPlus: true,
  },
  {
    title: "Удобное",
    description: "для просмотра меню",
    isPlus: true,
  },
  {
    title: "Читается",
    description: "на любом устройстве",
    isPlus: true,
  },
  {
    title: "Вашему дизайнеру",
    description: "придется искать работу",
  },
];

function PlusAndMinus() {
  return (
    <Box
      sx={[
        (theme) => ({
          backgroundColor: "#080B07",
          width: "100%",
          zIndex: "1",
          position: "relative",
          overflow: "hidden",
          padding: "55px 0px 95px 0px",
        }),
      ]}
    >
      <ContentContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "60px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "40px", color: "#FFFFFF", fontWeight: 600 }}>
            Много плюсов и один минус
          </Typography>
          <Box
            sx={{
              display: "flex",
              paddingTop: "23px",
              gap: "32px",
              flexWrap: "wrap",
              justifyContent: 'center'
            }}
          >
            {blockData.map(({ title, description, isPlus }) => {
              return (
                <Box
                  key={title}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "230px",
                    height: "144px",
                    background: "#2B2B2B",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "12px",
                    gap: "8px",
                  }}
                >
                  {isPlus ? (
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2736_3512)">
                        <circle cx="16.5" cy="16" r="16" fill="#F41F3D" />
                        <path
                          d="M25.0714 17.4286H17.9286V24.5714C17.9286 25.3571 17.2857 26 16.5 26C15.7143 26 15.0714 25.3571 15.0714 24.5714V17.4286H7.92857C7.14286 17.4286 6.5 16.7857 6.5 16C6.5 15.2143 7.14286 14.5714 7.92857 14.5714H15.0714V7.42857C15.0714 6.64286 15.7143 6 16.5 6C17.2857 6 17.9286 6.64286 17.9286 7.42857V14.5714H25.0714C25.8571 14.5714 26.5 15.2143 26.5 16C26.5 16.7857 25.8571 17.4286 25.0714 17.4286Z"
                          fill="#1C1E1D"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2736_3512">
                          <rect
                            width="32"
                            height="32"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="33"
                      height="32"
                      viewBox="0 0 33 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_2736_3602)">
                        <circle cx="16.5" cy="16" r="16" fill="#68429B" />
                        <path
                          d="M25.0714 17.4242H7.92857C7.14286 17.4242 6.5 16.8788 6.5 16.2121C6.5 15.5455 7.14286 15 7.92857 15H25.0714C25.8571 15 26.5 15.5455 26.5 16.2121C26.5 16.8788 25.8571 17.4242 25.0714 17.4242Z"
                          fill="#1B1D1C"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2736_3602">
                          <rect
                            width="32"
                            height="32"
                            fill="white"
                            transform="translate(0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#FFFFFF",
                      fontWeight: 700,
                      lineHeight: "29px",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ color: "#FFFFFF" }}
                    textAlign="center"
                  >
                    {description}
                  </Typography>
                </Box>
              );
            })}
          </Box>
          <img style={{ position: "absolute", bottom: "0" }} src={background} />
        </Box>
      </ContentContainer>
    </Box>
  );
}

export default PlusAndMinus;
