import { Box } from "../../../../components";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import { useTemlateCardData } from "../../../store/templateCard";
import WrapperButton from "../../../component/Button/WrappedButton";
import { useNavigate, useParams } from "react-router-dom";
import Head from "../../publicMenu/head";
import useImage from "../../../utils/useImage";

function RestaurantInfo({ isEditable = true, showWorkHoursOnDraft = false }) {
  const { tempateData } = useTemlateCardData();
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const { id } = useParams();
  const navigate = useNavigate();
  const { photoFile: logo } = useImage({ id: tempateData?.organizationLogoId });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: desktop ? "20px" : "16px",
        width: desktop ? "350px" : "100%",
        paddingTop: "0px",
      }}
    >
       <Head
          workhoursString={tempateData?.status == 2 || showWorkHoursOnDraft ? tempateData.workhoursString : ""}
          organizationAddress={tempateData.organizationAddress}
          organizationName={tempateData.organizationName}
          phoneNumber={tempateData.phoneNumber}
          telegram={tempateData.telegram}
          whatsApp={tempateData.whatsApp}
          website={tempateData.website}
          link={tempateData.link}
          lightTheme={true}
          logo={logo}
          languages={[]}
          language={1}
          setLanguage={() => {}} 
          desktop={desktop}
        ></Head>
      {isEditable && (
        <WrapperButton
          label="Редактировать информацию"
          variant="contained"
          sx={{
            width: desktop ? "250px" : "100%",
          }}
          onClick={() => navigate(`/constructor/${id}/organization`)}
        />
      )}
    </Box>
  );
}

export default RestaurantInfo;
