import {
  Box,
  Typography,
} from "../../../../components";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { useTemlateCardData } from "../../../store/templateCard";
import React from "react";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import moment from "moment";
import WrapperButton from "../../../component/Button/WrappedButton";

export type ChangeHistoryType = {};

/**История изменений */
function ChangeHistory({}: ChangeHistoryType) {
  const { historyData, requestHistoryData, nextHistoryData } =
    useTemlateCardData();
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <List
        sx={{
          marginTop: !desktop ? "30px" : "0px",
          listStyleType: "disc",
          "& li": {
            marginLeft: "20px",
          },
        }}
        subheader={
          <Typography
            variant="subtitle2"
            color="#0D0C22"
            fontWeight="500"
            sx={{ marginBottom: "10px !important" }}
          >
            История изменений
          </Typography>
        }
      >
        {historyData.map(({ modificationDate, userName, historyItems }) => {
          return (
            <ListItem
              key={moment(modificationDate).format("DD.MM.YYYY HH:mm:ss")}
              disablePadding
              sx={{ display: "list-item" }}
            >
              <Typography
                component={"b"}
                variant="body1"
                color="#0D0C22"
                fontWeight="300"
              >
                {`${moment(modificationDate).format("DD.MM.YYYY HH:mm:ss")} ${userName}`}
              </Typography>
              {historyItems.map(
                ({ actionName, itemName, changeLogs }, index) => {
                  return (
                    <React.Fragment key={`${actionName} ${itemName}`}>
                      <Typography
                        paddingLeft={"5px"}
                        component={"b"}
                        variant="body1"
                        color="#0D0C22"
                        fontWeight="700"
                      >
                        {`${index + 1}. ${actionName} ${itemName} ${changeLogs.length ? "->" : ""}`}
                      </Typography>
                      {changeLogs.map(
                        ({ attributeName, newValue, oldValue }, index) => {
                          return (
                            <Typography
                              key={`${oldValue} -> ${newValue}`}
                              paddingLeft={"5px"}
                              component={"b"}
                              variant="body1"
                              color="#0D0C22"
                              fontWeight="700"
                            >
                              {`${attributeName}: ${oldValue} -> ${newValue}${index < changeLogs.length - 1 ? ";" : ""}`}
                            </Typography>
                          );
                        },
                      )}
                    </React.Fragment>
                  );
                },
              )}
            </ListItem>
          );
        })}
      </List>
      {requestHistoryData.pagesTotal > requestHistoryData.page && (
        <WrapperButton
          label="Запросить еще"
          variant="contained"
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            height: desktop ? "36px" : "31px",
            whiteSpace: "nowrap",
          }}
          onClick={() => nextHistoryData()}
        />
      )}
    </Box>
  );
}

export default ChangeHistory;
