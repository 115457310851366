import { Box, Typography } from "../../../components";
import { darkTheme } from "../../theme/theme";
import { useMediaQuery } from "@mui/material";
import {
  AreaChart,
  Area,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

type DiagrammWrapperType = {
  label: string;
  dataDiagramm: {
    name: string;
    Количество: number;
  }[];
  typeDiagramm?: "BarChart" | "AreaChart";
};

function DiagrammWrapper({
  diagrammsData,
}: {
  diagrammsData: DiagrammWrapperType[];
}) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  function CustomizedTick(props: any) {
    const { x, y, stroke, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} fill="#666" fontSize={12}>
          {payload.value.split(" ").map((value: string, index: number) => {
            return (
              <tspan textAnchor="middle" x="0" dy={(index * 5 + 10).toString()}>
                {value}
              </tspan>
            );
          })}
        </text>
      </g>
    );
  }

  const diagrammProperties = (typeDiagramm?: "BarChart" | "AreaChart") => {
    return (
      <>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          fontSize={12}
          dataKey="name"
          angle={desktop ? 0 : -90}
          textAnchor={desktop ? "middle" : "end"}
          tick={desktop ? <CustomizedTick /> : undefined}
        />
        <YAxis fontSize={12} allowDecimals={false} />
        <Tooltip />
        {typeDiagramm && typeDiagramm === "AreaChart" && (
          <Area
            type="monotone"
            dataKey="Количество"
            stroke="#F61735"
            fill="#fee1e5"
            dot={true}
          />
        )}
        <Bar dataKey="Количество" fill="#F61735" />
      </>
    );
  };

  const getMaxLengthNumber = (
    data: Pick<DiagrammWrapperType, "dataDiagramm">,
  ) => {
    let length = 1;
    data.dataDiagramm.forEach(({ Количество }) => {
      length = Math.max(length, Количество.toString().length);
    });

    switch (length) {
      case 1: {
        return -35;
      }
      case 2: {
        return -30;
      }
      case 3: {
        return -25;
      }
      case 4: {
        return -15;
      }
      case 5: {
        return -10;
      }
      case 6: {
        return 0;
      }
      case 7: {
        return 5;
      }
      case 8: {
        return 15;
      }
      case 9: {
        return 20;
      }
      default: {
        return 30;
      }
    }
  };

  return (
    <>
      {diagrammsData.map(({ label, dataDiagramm, typeDiagramm }) => {
        return (
          <Box
            key={label}
            sx={{
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Typography fontSize="24px" fontWeight={600} color="#090909">
              {label}
            </Typography>
            <ResponsiveContainer width={"100%"} height={desktop ? 200 : 300}>
              {!typeDiagramm || typeDiagramm === "BarChart" ? (
                <BarChart
                  data={dataDiagramm}
                  margin={{
                    left: getMaxLengthNumber({ dataDiagramm }),
                    bottom: desktop ? 10 : 70,
                  }}
                >
                  {diagrammProperties(typeDiagramm)}
                </BarChart>
              ) : (
                <AreaChart
                  data={dataDiagramm}
                  margin={{
                    left: -25,
                    bottom: desktop ? 0 : 100,
                  }}
                >
                  {diagrammProperties(typeDiagramm)}
                </AreaChart>
              )}
            </ResponsiveContainer>
          </Box>
        );
      })}
    </>
  );
}

export default DiagrammWrapper;
