import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import { Outlet, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "../../store/model/extensions";
import { getHistoryData, getTemplateData } from "../../store/templateCard";
import ContentContainer from "../../component/ContentContainer";
import Loader from "../../component/Loader";
import { NotFoundError } from "../../utils/errors";

function ConstructorMenuPage() {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      Promise.all([
        dispatch(getTemplateData({ id: Number(id) })),
        dispatch(getHistoryData(id)),
      ])
      .catch(ex => {
        if (ex instanceof NotFoundError) {
          navigate("/notfound");
          return;
        }
       
      })
      .finally(() => setIsLoading(false));
    }
  }, [id]);

  return (
    <ContentContainer
      sx={{
        fontFamily: "Inter",
        paddingTop: desktop ? "60px" : "16px",
        paddingBottom: "60px",
      }}
    >
      {isLoading ? <Loader height="36px" /> : <Outlet />}
    </ContentContainer>
  );
}

export default ConstructorMenuPage;
