import React, { ReactNode } from "react";
import {
  Button as MuiButton,
  ButtonProps as MiuButtonProps,
  SxProps,
} from "@mui/material";
import { Theme } from "@emotion/react";

export interface ButtonProps extends Omit<MiuButtonProps, "size"> {
  label: ReactNode;
  size?: "x-small" | "small" | "normal" | "large" | "x-large";
  iconPosition?: "left" | "right" | "only" | "rounded";
  icon?: ReactNode;
}

const Button = ({
  icon,
  label,
  size = "normal",
  iconPosition = "left",
  sx = [],
  ...props
}: ButtonProps) => {
  const getHeight = () => {
    switch (size) {
      case "x-small":
        return "24px";
      case "small":
        return "32px";
      case "normal":
        return "40px";
      case "large":
        return "48px";
      case "x-large":
        return "56px";
      default:
        return "40px";
    }
  };

  const getFontSize = () => {
    switch (size) {
      case "x-small":
        return "10px";
      case "small":
        return "10px";
      case "normal":
        return "10px";
      case "large":
        return "10px";
      case "x-large":
        return "12px";
      default:
        return "10px";
    }
  };

  const getFontSizeIcon = () => {
    switch (size) {
      case "x-small":
      case "small":
        return "16px";
      default:
        return "24px";
    }
  };

  const getHeightAndSize: SxProps<Theme> = (theme) => {
    switch (size) {
      case "x-small":
        return {
          height: getHeight(),
          fontSize: getFontSize(),
          fontWeight: "600",
        };
      case "small":
        return {
          height: getHeight(),
          fontSize: getFontSize(),
          fontWeight: "600",
        };
      case "normal":
        return {
          height: getHeight(),
          fontSize: getFontSize(),
          fontWeight: "600",
        };
      case "large":
        return {
          height: getHeight(),
          fontSize: getFontSize(),
          fontWeight: "600",
        };
      case "x-large":
        return {
          height: getHeight(),
          fontSize: getFontSize(),
          fontWeight: "600",
        };
      default:
        return {
          height: getHeight(),
          fontSize: getFontSize(),
          fontWeight: "600",
        };
    }
  };

  const getWidth = () => {
    if (iconPosition === "only" || iconPosition === "rounded") {
      return {
        minWidth: getHeight(),
        padding: 0,
      };
    }
  };

  return (
    <MuiButton
      sx={[
        (theme) => ({
          ...getHeightAndSize(theme),
          ...getWidth(),
          borderRadius: iconPosition === "rounded" ? "100px" : "4px",
          boxShadow: "none",
          "& svg": {
            fontSize: `${getFontSizeIcon()} !important`,
          },
        }),
        { textTransform: "none" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      startIcon={iconPosition === "left" && icon}
      endIcon={iconPosition === "right" && icon}
      {...props}
    >
      {iconPosition === "only" || iconPosition === "rounded" ? icon : label}
    </MuiButton>
  );
};

export default Button;
