import React, { useCallback, useState } from "react";
import { Box, IconButton, InputAdornment, Typography } from "../../../components";
import WrappedInput from "../../component/Input/WrappedInput";
import { SubmitHandler, useForm, Controller, Path } from "react-hook-form";
import { useDispatch } from "react-redux";
import { Popover, useMediaQuery } from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { darkTheme } from "../../theme/theme";
import WrappedCheckbox from "../../component/Checkbox/WrappedCheckbox";
import VisibilityIcon from "../../shared/assets/img/icons/visibilityIcon";
import HelpIcon from "../../shared/assets/img/icons/helpIcon";
import WrappedButton from "../../component/Button/WrappedButton";
import ConfirmationByCode from "./confirmationByCode";
import { req } from "../../api";
import { useAlert } from "../../store/alert";
import { useNavigate } from "react-router-dom";
import { Tab } from "./autorization";
import { RigisterDataInfo } from "../../api/request/user";
import { formatPhoneNumber } from "../../utils";
import { PasswordHintPopover, NickHintPopover } from "../../component/PasswordHintPopover";

enum StageEnum {
  REGISTRATION,
  CODE_CONFIRMATION,
}

const RegistrationFields = () => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [stage, setStage] = useState<StageEnum>(StageEnum.REGISTRATION);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [anchorEl2, setAnchorEl2] = useState<HTMLElement | null>(null);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation((show) => !show);
  const [code, setCode] = useState<[string, string, string, string]>([
    "",
    "",
    "",
    "",
  ]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handlePopoverOpen2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handlePopoverClose2 = useCallback(() => {
    setAnchorEl2(null);
  }, []);

  const dispatch = useDispatch();
  const { openAlert } = useAlert();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    watch,
  } = useForm<
    RigisterDataInfo & { passwordConfirmation: string; acceptTerms: boolean }
  >();

  const passwordValue = watch("password");

  const onSubmit: SubmitHandler<RigisterDataInfo> = (data) => {
    req.user
      .register(data)
      .then((res) => {
        if (!res.success) {
          openAlert({
            text: res.errors.join(";\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
          if (res.data && res.data.errors && !Array.isArray(res.data.errors)) {
            Object.keys(res.data.errors).forEach((el) => {
              const key = el.replace(
                el[0],
                el[0].toLowerCase(),
              ) as Path<RigisterDataInfo>;
              setError(key, { message: res.data.errors[el].join(";\n") });
            });
          }
        } else {
          setStage(StageEnum.CODE_CONFIRMATION);
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при регистрации",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const requestCode = () => {
    req.user.resendRegistrationCode(watch("phoneNumber")).then((res) => {
      if (!res.success) {
        openAlert({
          text: res.errors.join(";\n"),
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      }
    });
  };

  const checkCode = () => {
    req.user
      .confirmRegistrationCode(watch("phoneNumber"), code.join(""))
      .then((res) => {
        if (!res.success) {
          openAlert({
            text: res.errors.join(";\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
        } else {
          // по завершению регистрации пользователь редиректится на авторизацию
          navigate("/login?tab=" + Tab.AUTHORIZATION);
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при подтверждении кода",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  return (
    <>
      {stage === StageEnum.REGISTRATION && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%" }}
          autoComplete="new-password"
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              gap: "30px",
              flexDirection: "column",
              alignItems: "center",
              borderRadius: "10px",
              boxShadow: "0px 0px 15px 0px #00000040",
              width: "100%",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  fontSize: `${desktop ? "16px" : "14px"}`,
                  lineHeight: "26px",
                  position: `${desktop ? "absolute" : "static"}`,
                  top: "-51px",
                  marginBottom: `${desktop ? "0" : "14px"}`,
                }}
              >
                Все поля обязательны для заполнения
              </Typography>
              <Controller
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: "Введите имя",
                }}
                render={({ field: { onChange, value } }) => (
                  <WrappedInput
                    label="Имя"
                    fullWidth={true}
                    error={errors.name && errors.name.message}
                    value={value}
                    onChange={onChange}
                    shadowHeight={30}
                    inputPadding={16.5}
                    colorType="black"
                    onInput={(e:any) => {
                      return (
                        //@ts-ignore
                        (e.target.value = e.target.value.replace(
                          /[^а-яА-ЯЁёa-zA-Z-\s]/g,
                          "",
                        ))
                      );
                    }}
                  />
                )}
              />
            </Box>
            <Controller
              name="organization.name"
              control={control}
              defaultValue=""
              rules={{ required: "Введите название заведения" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <WrappedInput
                  label="Название Вашего Заведения"
                  fullWidth={true}
                  error={error?.message}
                  shadowHeight={30}
                  inputPadding={16.5}
                  colorType="black"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="organization.address"
              control={control}
              defaultValue=""
              rules={{ required: "Введите адрес заведения" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <WrappedInput
                  label="Адрес Вашего заведения"
                  fullWidth={true}
                  error={error?.message}
                  shadowHeight={30}
                  inputPadding={16.5}
                  colorType="black"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{
                required: "Введите E-mail",
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: "Неверный формат E-mail",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <WrappedInput
                  label="E-mail"
                  fullWidth={true}
                  error={error?.message}
                  shadowHeight={30}
                  inputPadding={16.5}
                  colorType="black"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Controller
                name="organization.serviceName"
                control={control}
                defaultValue=""
                rules={{
                  pattern: {
                    value: /[a-zA-Z\d]+/,
                    message: "Неверный формат сетевого имени",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <WrappedInput
                    label="Сетевое имя"
                    fullWidth={true}
                    shadowHeight={30}
                    inputPadding={16.5}
                    colorType="black"
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <>
                <IconButton
                  aria-owns={open2 ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen2}
                  onMouseLeave={handlePopoverClose2}
                  sx={[
                    (theme) => ({
                      padding: 0,
                      fontSize: 0,
                      marginRight: 0,
                      position: desktop ? "absolute" : "static",
                      right: desktop ? "-36px" : "0",
                      marginLeft: desktop ? "0" : "12px",
                    }),
                  ]}
                >
                  <HelpIcon />
                </IconButton>
                <NickHintPopover
                  open={open2} anchorEl={anchorEl2} handlePopoverClose={handlePopoverClose2}
                />
              </>
            </Box>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              rules={{
                required: "Введите номер телефона",
                pattern: {
                  value: /^\+\d\s\d{3}\s\d{3}-\d{2}-\d{2}$/,
                  message: "Неверный формат",
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <WrappedInput
                  label="Телефон для связи"
                  fullWidth={true}
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  shadowHeight={30}
                  inputPadding={16.5}
                  colorType="black"
                  inputProps={{ maxLength: 16 }}
                  onInput={(e: any) => {
                    e.target.value = formatPhoneNumber(
                      e.target.value.replace(/[^0-9+\-()\s]/g, ""),
                    );
                  }}
                />
              )}
            />
            <Box
              sx={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                position: "relative",
              }}
            >
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{
                  required: "Введите пароль",
                  minLength: {
                    value: 8,
                    message: "Пароль должен содержать минимум 8 символов",
                  },
                  maxLength: {
                    value: 128,
                    message: "Пароль должен содержать максимум 128 символов",
                  },
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <WrappedInput
                    label="Пароль"
                    fullWidth={true}
                    error={error?.message}
                    value={value}
                    shadowHeight={30}
                    inputPadding={16.5}
                    colorType="black"
                    onChange={onChange}
                    autoComplete="new-password"
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                      autoComplete: "new-password",
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <>
                <IconButton
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  sx={[
                    (theme) => ({
                      padding: 0,
                      fontSize: 0,
                      marginRight: 0,
                      position: desktop ? "absolute" : "static",
                      right: desktop ? "-36px" : "0",
                      marginLeft: desktop ? "0" : "12px",
                    }),
                  ]}
                >
                  <HelpIcon />
                </IconButton>
                <PasswordHintPopover
                  {...{ open, anchorEl, handlePopoverClose }}
                />
              </>
            </Box>
            <Controller
              name="passwordConfirmation"
              defaultValue=""
              control={control}
              rules={{
                required: "Введите подтверждение пароля",
                validate: (value) =>
                  value === passwordValue || "Пароли не совпадают",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <WrappedInput
                  label="Подтверждение пароля"
                  fullWidth={true}
                  error={error?.message}
                  shadowHeight={30}
                  inputPadding={16.5}
                  colorType="black"
                  value={value}
                  onChange={onChange}
                  type={showPasswordConfirmation ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPasswordConfirmation}
                          onMouseDown={handleClickShowPasswordConfirmation}
                          edge="end"
                        >
                          {showPasswordConfirmation ? (
                            <VisibilityOff />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  name={"password2-autoComplete-off"}
                  autoComplete="new-password"
                />
              )}
            />
            <Controller
              name="acceptTerms"
              control={control}
              defaultValue={false}
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => (
                <WrappedCheckbox
                  checked={value}
                  onCheckChange={onChange}
                  sx={{
                    ".MuiCheckbox-root": {
                      color: "rgba(255, 255, 255, 0.7)",
                    },
                  }}
                  label={
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: darkTheme.palette.outline.default,
                      }}
                    >
                      Я принимаю условия{" "}
                      <a
                        style={{
                          color: "white",
                          textDecoration: "none",
                          borderBottom: "1px dashed white",
                        }}
                      >
                        Пользовательского соглашения
                      </a>{" "}
                      и даю своё согласие на обработку моих персональных данных
                      в соответствии с Федеральным законом от 27.07.2006 года №
                      152-ФЗ «О персональных данных» на условиях и для целей,
                      определённых{" "}
                      <a
                        style={{
                          color: "white",
                          textDecoration: "none",
                          borderBottom: "1px dashed white",
                        }}
                      >
                        Политикой конфиденциальности
                      </a>
                    </Typography>
                  }
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Box
              alignContent={"end"}
              sx={{
                width: `${desktop ? "50%" : "100%"}`,
                alignSelf: "self-end",
              }}
            >
              <WrappedButton
                label="Зарегистрироваться"
                variant="contained"
                disabled={!watch("acceptTerms")}
                sx={{
                  color: "#FFFFFF",
                  background:
                    "linear-gradient(94deg, #FF6F83 0.78%, #F61735 105.69%)",
                  borderRadius: "60px",
                  marginTop: "20px",
                  width: "100%",
                }}
                type="submit"
              />
            </Box>
          </Box>
        </form>
      )}
      {stage === StageEnum.CODE_CONFIRMATION && (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            display: "flex",
            gap: "30px",
            flexDirection: "column",
          }}
        >
          <ConfirmationByCode
            {...{
              code,
              setCode,
              requestCode,
              checkCode,
              buttonLabel: "Подтвердить регистрацию",
            }}
          />
        </Box>
      )}
    </>
  );
};

export default RegistrationFields;
