import { Box } from "../../../../../components";
import { BoxProps } from "@mui/material";

const NewIcon = ({ ...props }: BoxProps) => (
  <Box {...props}>
    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.78613 10.2048V7.90796C4.78613 7.63092 4.75339 7.43699 4.68791 7.32618C4.62243 7.21536 4.51161 7.15996 4.35546 7.15996C4.2245 7.15996 4.11117 7.20025 4.01546 7.28084C3.91976 7.36144 3.85176 7.46218 3.81146 7.58307V10.2048H2.91235V6.42707H3.63013L3.73591 6.86529H3.75858C3.84924 6.71921 3.97265 6.59581 4.1288 6.49507C4.28495 6.38929 4.48895 6.3364 4.7408 6.3364C4.89191 6.3364 5.02539 6.35907 5.14124 6.4044C5.26213 6.44973 5.36287 6.52529 5.44346 6.63107C5.52406 6.73181 5.5845 6.87033 5.6248 7.04662C5.66509 7.21788 5.68524 7.43196 5.68524 7.68884V10.2048H4.78613Z" fill="currentColor"/>
      <path d="M8.70912 9.92529C8.58319 10.0361 8.41193 10.1268 8.19534 10.1973C7.97875 10.2678 7.75208 10.3031 7.51534 10.3031C7.25341 10.3031 7.02675 10.2577 6.83534 10.1671C6.64897 10.0764 6.49534 9.94544 6.37445 9.77418C6.25356 9.60292 6.1629 9.3964 6.10245 9.15462C6.04704 8.90781 6.01934 8.62825 6.01934 8.31596C6.01934 7.63596 6.15282 7.13477 6.41978 6.8124C6.69178 6.48499 7.06704 6.32129 7.54556 6.32129C7.70675 6.32129 7.8629 6.34647 8.01401 6.39684C8.16512 6.44218 8.2986 6.52277 8.41445 6.63862C8.53534 6.74944 8.63104 6.90055 8.70156 7.09196C8.77712 7.27833 8.8149 7.51507 8.8149 7.80218C8.8149 7.91299 8.80734 8.03136 8.79223 8.15729C8.78215 8.28321 8.76452 8.41921 8.73934 8.56529H6.90334C6.91341 8.87759 6.9789 9.11684 7.09978 9.28307C7.22067 9.44929 7.4146 9.5324 7.68156 9.5324C7.84275 9.5324 7.98882 9.50722 8.11978 9.45684C8.25578 9.40647 8.35904 9.35359 8.42956 9.29818L8.70912 9.92529ZM7.53045 7.09196C7.33904 7.09196 7.19549 7.17003 7.09978 7.32618C7.00408 7.47729 6.94867 7.69136 6.93356 7.9684H7.97623C7.99134 7.68129 7.96112 7.4647 7.88556 7.31862C7.81001 7.16751 7.69164 7.09196 7.53045 7.09196Z" fill="currentColor"/>
      <path d="M11.5707 6.42707L12.0996 8.36129L12.1902 8.9884H12.2204L12.296 8.35373L12.6511 6.42707H13.4671L12.5378 10.2426H11.8276L11.2609 8.13462L11.2004 7.65107H11.1551L11.1022 8.14973L10.5582 10.2426H9.82533L8.85822 6.42707H9.83289L10.2409 8.32351L10.3089 8.99596H10.3391L10.4373 8.31596L10.8982 6.42707H11.5707Z" fill="currentColor"/>
      <circle cx="8" cy="8.09863" r="7.35" stroke="currentColor" strokeWidth="1.3"/>
    </svg>
  </Box>
);

export default NewIcon;
