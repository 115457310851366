import React from "react";
import { Box, Typography } from "../../../components";
import { styled } from "@mui/system";

const Textarea = styled("textarea")(() => ``);

type WrapperTextareaType = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  error?: string;
  disabled?: boolean;
};

const WrapperTextarea: React.FC<WrapperTextareaType> = ({
  error,
  disabled,
  ...props
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: "100%",
        "&:after": {
          position: "absolute",
          top: -2,
          left: -2,
          right: -2,
          bottom: -2,
          background: !error
            ? "linear-gradient(-45deg, #674099,#ED6985)"
            : "#FF5252",
          content: '""',
          zIndex: -1,
          borderRadius: "20px",
          opacity: disabled ? 0.4 : 1,
        },
        "&:focus-within:after": {
          filter: "blur(2px)",
        },
      }}
    >
      <Textarea
        disabled={disabled ? true : false}
        sx={[
          (theme) => ({
            position: "relative",
            borderRadius: "20px",
            width: "100%",
            padding: "10px 12px 0 12px",
            resize: "vertical",
            minHeight: "67px",
            background: "#FFFFFF",
            border: "none",
            outline: "none",
            WebkitBoxShadow: "none",
            boxShadow: "none",
            fontSize: "14px",
            fontFamily: "Inter",
            color: disabled
              ? "rgba(0,0,0,0.4) !important"
              : "#0D0C22!important",
          }),
        ]}
        {...props}
      />
      {!!error && (
        <Typography
          variant="body1"
          color="#FF5252"
          sx={{
            whiteSpace: "pre-wrap",
            marginRight: "auto",
            paddingLeft: "10px",
            position: "absolute",
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default WrapperTextarea;
