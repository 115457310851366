import { cloneDeep } from "lodash";

export type GetPropertyByStringName = {
    stringName: string,
    object: object
};

/**Получить значение из объекта по строковому имени. Например, хотим получить значение
 * из объекта someObject.key1.key2.key3
 * getPropertyByStringName({ stringName: 'key1.key2.key3', objectL: someObject })
 * Функция вернёт значение из последнего
 * вложенного свойства key3 или undefined, если оно не существует.
 */
function getPropertyByStringName({ stringName, object }: GetPropertyByStringName) {
    if (!stringName || !object) return;
    
    if (!stringName.includes('.')) {
        const convertedObject = object as any;
        return convertedObject[stringName];
    }
    
    const splitedName = stringName.split('.');
    let property = cloneDeep(object);

    return splitedName.reduce((prop: any, stringName) => {
        if (prop === 0) return 0;
        if (!prop) return;

        return property = prop[stringName];
    }, property);
}

export default getPropertyByStringName;