import { PropsWithChildren } from "react";
import { AlertIconProps } from "./AlertIcons";
import classes from './AnimatedIcon.module.css';

export function AnimatedIcon({ fill, children }: AlertIconProps & PropsWithChildren) {
    return(
        <div style={{ position: 'relative', transform: 'translateY(-10px)' }}>
            { children }
            <Animation fill={fill}/>
        </div>
    );
}

//Бесконечная анимация вокруг иконки
export function Animation({ fill }: AlertIconProps) {
    return(
        <>
            <div className={[classes.animate, classes.first].join(' ')} style={{ position: 'absolute', top: '-7.5px', left: '-17.5px' }}>
                <svg width="62" height="62" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3.5" y="3.5" width="55" height="55" rx="27.5" fill={fill} opacity={0.2}/>
                </svg>
            </div>
            <div className={[classes.animate, classes.second].join(' ')} style={{ position: 'absolute', top: '-7.5px', left: '-17.5px' }}>
                <svg width="62" height="62" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="3.5" y="3.5" width="55" height="55" rx="27.5" stroke={fill} opacity={0.2} strokeWidth="7"/>
                </svg>
            </div>
        </>
    );
}