import { Box } from "../../../../../components";
import { BoxProps } from "@mui/material";

const FireIcon = ({ ...props }: BoxProps) => (
  <Box {...props}>
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.87373 7.1434C8.87373 7.1434 9.37747 2.6658 6.74687 1.09863C5.90869 3.21193 4.7371 5.17716 3.27672 6.91953C1.20582 9.38222 0.534178 13.6359 4.95582 16.0986C5.50735 15.2895 5.97613 14.427 6.35508 13.524C6.66547 12.3125 6.87144 11.0767 6.97073 9.82998C6.97073 9.82998 9.93718 12.6845 9.37748 16.0986C9.37748 16.0986 16.0939 12.8524 11.2804 6.13595" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </Box>
);

export default FireIcon;
