import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "../../../../components";
import { useEffect, useState } from "react";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { req } from "../../../api";
import ExpandIcon from "../../templateCard/constructor/expandIcon";
import { ItemsStatisticsEstablishmentDTO } from "../../../api/request/statisticsEstablishment";

export type StatisticsViewsType = {};

type keyTypevalue =
  | "visitTotalCount"
  | "groupClickTotalCount"
  | "positionClickTotalCount";

const descriptionValue: Record<keyTypevalue, string> = {
  visitTotalCount: "Количество просмотров\r\n опубликованного шаблона меню",
  groupClickTotalCount: "Кликов по группам",
  positionClickTotalCount: "Кликов по позициям",
};

interface viewDataType {
  visitTotalCount: number;
  groupClickTotalCount: number;
  positionClickTotalCount: number;
}

/**Статистика просмотров меню */
function StatisticsViews({}: StatisticsViewsType) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [viewData, setViewData] = useState<Record<keyTypevalue, number> | null>(
    null,
  );
  const [listData, setListData] = useState<ItemsStatisticsEstablishmentDTO[]>(
    [],
  );

  useEffect(() => {
    req.stasticsEstablishment.getViewData().then((res) => {
      setViewData({
        visitTotalCount: res.data.data.visitTotalCount,
        groupClickTotalCount: res.data.data.groupClickTotalCount,
        positionClickTotalCount: res.data.data.positionClickTotalCount,
      });
      setListData(res.data.data.items);
    });
  }, []);

  if (!viewData) return;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "35px" }}>
      <TopData viewData={viewData} desktop={desktop} />
      {desktop ? (
        <DesktopList listData={listData} />
      ) : (
        <MobileList listData={listData} />
      )}
    </Box>
  );
}

function MobileList({
  listData,
}: {
  listData: ItemsStatisticsEstablishmentDTO[];
}) {
  const elementBox = (name: string, row: ItemsStatisticsEstablishmentDTO) => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "14px",
        padding: "10px",
      }}
    >
      <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <Typography sx={{ lineHeight: "16px" }}>{name}</Typography>
        <Typography sx={{ lineHeight: "16px", fontWeight: "bold" }}>
          {row.name}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Typography sx={{ lineHeight: "16px" }}>Количество кликов</Typography>
        <Typography sx={{ lineHeight: "16px", fontWeight: "bold" }}>
          {row.count}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <Box>
      {listData.map((row, index) => (
        <Accordion
          sx={{
            background: index % 2 ? "#F9F9F9" : "none",
            boxShadow: "unset",
            ".Mui-focusVisible": {
              backgroundColor: "unset !important",
            },
          }}
          key={row.name}
        >
          <AccordionSummary
            sx={{
              color: "#343C44",
              padding: "0",
              ".MuiAccordionSummary-content": {
                margin: "0 !important",
              },
            }}
            expandIcon={<ExpandIcon />}
          >
            {elementBox("Группа", row)}
          </AccordionSummary>
          <AccordionDetails
            sx={{
              color: "#343C44",
              padding: "0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {row.children.map((child, index) => (
              <Box
                key={child.name}
                sx={{ background: index % 2 ? "#E6E6E6" : "#EFEFEF" }}
              >
                {elementBox("Позиция", child)}
              </Box>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
}

function DesktopList({
  listData,
}: {
  listData: ItemsStatisticsEstablishmentDTO[];
}) {
  const elementBox = (row: ItemsStatisticsEstablishmentDTO) => (
    <Accordion
      sx={{
        background: "inherit",
        boxShadow: "unset",
        ".Mui-focusVisible": {
          backgroundColor: "unset !important",
        },
        margin: "0 !important",
      }}
    >
      <AccordionSummary
        sx={{
          color: "#343C44",
          padding: "0 10px",
          height: "52px",
          minHeight: "52px !important",
        }}
        expandIcon={<ExpandIcon />}
      >
        <Typography sx={{ fontSize: "0.875rem" }}>{row.name}</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: "0",
          display: "flex",
          flexDirection: "column",
          color: "#343C44",
        }}
      >
        {row.children.map(({ name, count }, index) => {
          return (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                background: "#EFEFEF",
              }}
              key={name}
            >
              <Typography>{name}</Typography>
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                  minWidth: "50px",
                  textAlign: "center",
                  lineHeight: "32px",
                  padding: "0 10px",
                  background: index % 2 ? "#E6E6E6" : "",
                }}
              >
                {count}
              </Typography>
            </Box>
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "66px 1fr 110px",
          background: "#090909",
          color: "#FFFFFF",
          alignItems: "center",
          height: "64px",
        }}
      >
        <Box></Box>
        <Typography sx={{ fontWeight: "bold", padding: "10px" }}>
          Группа
        </Typography>
        <Typography sx={{ fontWeight: "bold", padding: "10px" }}>
          Количество кликов
        </Typography>
      </Box>
      {listData.map((row, index) => (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "66px 1fr 110px",
            background: index % 2 ? "#F9F9F9" : "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>{index + 1}</Typography>
          </Box>
          {elementBox(row)}
          <Box
            sx={{
              display: "flex",
              padding: "10px",
              alignItems: "center",
              height: "52px",
            }}
          >
            <Typography>{row.count}</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

function TopData({
  viewData,
  desktop,
}: {
  viewData: viewDataType;
  desktop: boolean;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: desktop ? "row" : "column",
        gap: "40px",
        justifyContent: "space-between",
        alignItems: desktop ? "start" : "center",
        marginTop: desktop ? "unset" : "20px",
      }}
    >
      {Object.keys(viewData).map((key) => {
        const keyValue = key as keyTypevalue;
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: desktop ? "start" : "center",
            }}
            key={key}
          >
            <Typography
              fontSize="60px"
              fontWeight={900}
              color="#F82440"
              lineHeight="72px"
            >
              {viewData[keyValue]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, " ")}
            </Typography>
            <Typography
              variant="body1"
              color="#090909"
              fontWeight="700"
              sx={{
                whiteSpace: "pre-line",
                textAlign: desktop ? "start" : "center",
              }}
            >
              {descriptionValue[keyValue]}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}

export default StatisticsViews;
