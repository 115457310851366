import { Box, Typography } from "../../../../components";
import { useMediaQuery } from "@mui/material";
import { FormOptionType, ModalKeyType } from "../type";
import { darkTheme } from "../../../theme/theme";
import WrapperItem from "../shared/wrapperItem";
import WrapperInput from "../../../component/Input/WrappedInput";
import WrappedCheckbox from "../../../component/Checkbox/WrappedCheckbox";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import TariffBlock from "../shared/TariffBlock";
import Carousel from "react-material-ui-carousel";
import { req } from "../../../api";
import { TariffDataDto } from "../../../api/request/billing";
import WrappedRadioButton from "../../../component/RadioButton/WrappedRadioButton";
import WrapperButton from "../../../component/Button/WrappedButton";
import { useAlert } from "../../../store/alert";
import ExtendTariffModal from "../modal/extendTariffModal";
import { ApplyPromoCodeResponse } from "../../../api/request/promocodes";

type TariffType = Omit<FormOptionType, "name"> & {
  isAdmin: boolean;
  setTypeModal: (modalKey: ModalKeyType) => void;
  automaticPayment: boolean;
};

type PromoCodeType = Omit<ApplyPromoCodeResponse, "success" | "message"> &  { code: string | null };

export type TariffsDataType = {
  id: number;
  label: string;
  options: string[];
  prices: TariffDataDto[];
};

const Tariff = ({
  isAdmin,
  control,
  clearErrors,
  setError,
  setTypeModal,
  watch,
  automaticPayment
}: TariffType) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const promoCode = useRef("");
  const { openAlert } = useAlert();
  const [selectedPeriod, setSelectedPeriod] = useState<number>(
    watch("order.period") || 1,
  );
  const [selectedTariffId, setSelectedTariffId] = useState<number>(
    watch("order.tariffId") || 1,
  );
  const isCurrentTariffSelected = selectedTariffId === watch("order.tariffId")
  const [isOpenTariffModel, setIsOpenTariffModel] = useState(false);
  const [conditionAgree, setConditionAgree] = useState(false);
  const [promo, setPromo] = useState<PromoCodeType>({ type: 0, value: 0, code: null });

  const handlerRequest = () => {
    if (selectedPeriod && selectedTariffId) {
      req.billing
        .connectTariff({
          tariff: selectedTariffId,
          period: selectedPeriod,
          autoProlong: automaticPayment,
          isCheckedOffer: conditionAgree,
          promoCode: promo.code,
        })
        .then((res) => {
          if (!res.success) {
            openAlert({
              text: res.errors.join(";\n"),
              isVisibleCloseIcon: true,
              autoHide: false,
              type: "error",
            });
            return;
          }

          if (res.data.errorMessage) {
            openAlert({
              text: res.data.errorMessage,
              isVisibleCloseIcon: true,
              autoHide: false,
              type: "error",
            });
            return;
          }
            openAlert({
              text: "Заказ успешно создан",
              isVisibleCloseIcon: true,
              autoHide: true,
              type: "success",
            });
          
            if (res.data.redirectUrl) {
              window.location.href = res.data.redirectUrl;
            }
        })
        .catch(() => {
          openAlert({
            text: "Ошибка создания заказа",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "error",
          });
        });
    }
  };

  const codeApply = (code: string) => {
    req.promoCode
      .apply(code)
      .then(response => {
        if (!response.data.data.success) {
          openAlert({
            text: response.data.data.message,
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });

          return;
        }

        openAlert({
          text: response.data.data.message,
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "success",
        });
        setPromo({...response.data.data, code});
      });
  };

  return (
    <>
      <TariffSelect
        {...{
          watch,
          selectedPeriod,
          setSelectedPeriod,
          selectedTariffId,
          setSelectedTariffId,
          promoCode: promo
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <WrappedCheckbox
          checked={automaticPayment}
          onCheckChange={() => setTypeModal("automaticPayment")}
          label={
            <Typography variant="body1" color="#616161">
              Автоматическая оплата
            </Typography>
          }
          disabled={!watch("isEmailConfirmed")}
          sx={{
            alignItems: "center",
          }}
        />
        {!watch("isEmailConfirmed") && (
          <Typography variant="body1" color="#616161">
            (необходимо{" "}
            <Typography
              variant="body1"
              component={"a"}
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setTypeModal("confirmEmail")}
            >
              подтвердить почту
            </Typography>
            )
          </Typography>
        )}
      </Box>
      <WrapperItem
        label="Срок действия подписки"
        control={control}
        name="order.finishDate"
        additionalPropertiesButton={{
          hidden: true,
        }}
        render={() => {
          return (
            <WrapperInput
              backgroundColor="#FFFFFF"
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                  color: "#0D0C22 !important",
                },
                "& .Mui-disabled": {
                  WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                },
              }}
              disabled
              value={`${moment(watch("order.created")).format("DD.MM.YYYY")}-${moment(watch("order.finishDate")).format("DD.MM.YYYY")}`}
            />
          );
        }}
      />
      <WrapperItem
        label="Промокод"
        control={control}
        name="order.promo"
        additionalPropertiesButton={{
          label: "Применить",
          onClick: () => codeApply(promoCode.current),
        }}
        onChange={(e) => {
          promoCode.current = e.target.value;
        }}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <WrappedCheckbox
          checked={conditionAgree}
          onCheckChange={() => setConditionAgree(!conditionAgree)}
          label="Принимаю условия"
          sx={{
            alignItems: "center",
          }}
        />
        <Typography variant="body1" color="#616161">
          <Typography
            variant="body1"
            component={"a"}
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setTypeModal("publicOffer")}
          >
            публичной оферты
          </Typography>
          , соглашение{" "}
          <Typography
            variant="body1"
            component={"a"}
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setTypeModal("paymentsAgreement")}
          >
            о применении автоматических платежей
          </Typography>
        </Typography>
      </Box>
      <WrapperButton
        label={isCurrentTariffSelected ? "Продлить" : "Подключить"}
        disabled={!conditionAgree}
        variant="contained"
        size={"normal"}
        sx={{
          width: desktop ? "210px" : "auto",
          height: "36px",
        }}
        onClick={() => setIsOpenTariffModel(true)}
        type="button"
      />
      {isOpenTariffModel && (
        <ExtendTariffModal
          onClose={() => {
            setIsOpenTariffModel(false);
          }}
          onConfirm={handlerRequest}
        />
      )}
    </>
  );
};

const TariffSelect = ({
  watch,
  selectedPeriod,
  setSelectedPeriod,
  selectedTariffId,
  setSelectedTariffId,
  promoCode
}: any) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [tariffs, setTariffs] = useState<TariffsDataType[]>([]);

  const selectedTariff = tariffs.find(
    (tariffData) => tariffData.id === selectedTariffId,
  );

  useEffect(() => {
    //TODO будет запрос за тарифами на бэк
    req.billing.getTariff().then((res) => {
      if (res.success) {
        setTariffs([
          {
            id: res.data.tariffs.base[0].tariffId,
            label: "Базовый",
            options: ["Создание и публикация меню", "Генерация QR-кода"],
            prices: res.data.tariffs.base,
          },
          {
            id: res.data.tariffs.advanced[0].tariffId,
            label: "Продвинутый",
            options: [
              "Создание и публикация меню",
              "Генерация QR-кода",
              "Добавление фото блюд",
              "Информация о блюде и КБЖУ",
              "Иконки «New» «Спайси» и «Вегетарианское»",
              "Тёмная тема",
              "Ссылки",
            ],
            prices: res.data.tariffs.advanced,
          },
          {
            id: res.data.tariffs.premium[0].tariffId,
            label: "👑 Премиум",
            options: [
              "Создание и публикация меню",
              "Генерация QR-кода",
              "Добавление фото блюд",
              "Информация о блюде и КБЖУ",
              "Иконки «New» «Спайси» и «Вегетарианское»",
              "Тёмная тема",
              "Ссылки",
              "Расширенная статистика о гостях",
              "Перевод на любой язык мира",
            ],
            prices: res.data.tariffs.premium,
          },
        ]);
      }
    });
  }, []);

  const tariffBlocks = tariffs.map((tariffData) => {
    return (
      <TariffBlock
        isSelected={selectedTariffId === tariffData.id}
        onSelect={() => setSelectedTariffId(tariffData.id)}
        {...tariffData}
      />
    );
  });

  const calculateDiscount = (price: number, pc: PromoCodeType) => {
    switch(pc.type) {
      case 2:
        return price - (price * (pc.value/100));
      case 4: 
        return price - pc.value;
    }
  }

  return (
    <>
      {desktop ? (
        <Box
          sx={{
            display: "grid",
            gap: "15px",
            gridTemplateColumns: "28% 28% 28%",
          }}
        >
          {tariffBlocks}
        </Box>
      ) : (
        <Carousel interval={undefined} navButtonsAlwaysInvisible={true}>
          {tariffBlocks}
        </Carousel>
      )}
      {selectedTariff && (
        <Box sx={{ 
            display: "flex", 
            flexDirection: "column", 
            gap: "15px",
            "& .strike-diagonal-price": {
              position: "relative",
              "&:before": {
                position: "absolute",
                content: '""',
                left: "0",
                top: "37%",
                right: "0",
                borderTop: "2px solid",
                borderColor: "#F72642",
                "-webkit-transform": "rotate(-15deg)",
                "-moz-transform": "rotate(-15deg)",
                "-ms-transform": "rotate(-15deg)",
                "-o-transform": "rotate(-15deg)",
                transform: "rotate(-15deg)",
              }}}}>
          {selectedTariff.prices.map(
            ({ period, tariffId, periodName, price }) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <WrappedRadioButton
                    key={`${tariffId} ${period}`}
                    value={period}
                    checked={period === selectedPeriod}
                    onRadioChange={(value) => setSelectedPeriod(+value)}
                    label={`${periodName}:`}
                  />
                  <Typography variant="caption" marginTop="2px" className={promoCode?.value > 0 ? "strike-diagonal-price" : ""} color="#0D0C22">
                    {`${price}₽`}
                  </Typography>
                  {promoCode?.value > 0 ? <Typography variant="caption" marginLeft="10px" marginTop="2px" color="#0D0C22">
                    {`${calculateDiscount(price, promoCode)}₽`}
                  </Typography> : <></>}
                </Box>);
            },
          )}
        </Box>
      )}
    </>
  );
};

export default Tariff;
