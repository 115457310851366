import { Box, InputAdornment, Typography, IconButton } from "../../../../components";
import { useMediaQuery } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";
import { FormOptionType, ModalKeyType } from "../type";
import { darkTheme } from "../../../theme/theme";
import WrapperItem from "../shared/wrapperItem";
import WrapperInput from "../../../component/Input/WrappedInput";
import { req } from "../../../api";
import { useAlert } from "../../../store/alert";
import { useDispatch } from "../../../store/model/extensions";
import { useState } from "react";
import VisibilityIcon from "../../../shared/assets/img/icons/visibilityIcon";
import VisibilityOff from "../../../shared/assets/img/icons/visibilityOffIcon";
import fileService from "../../../services/fileService";
import WrapperLabel from "../../constructorMenu/wrapperLabel";
import WrapperButton from "../../../component/Button/WrappedButton";

type BasicDataType = Omit<FormOptionType, "name"> & {
  isAdmin: boolean;
  setTypeModal: (modalKey: ModalKeyType) => void;
};

const BasicData = ({
  isAdmin,
  control,
  clearErrors,
  setError,
  setTypeModal,
  watch,
}: BasicDataType) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation((show) => !show);

  const [sendChangePassword, setSendChangePassword] = useState(false);

  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const { openAlert } = useAlert();
  const dispatch = useDispatch();

  const changePassword = () => {
    req.user
      .changePassword({
        userId: watch("id"),
        newPassword: watch("newPassword"),
        newPasswordRepeat: watch("confirmNewPassword"),
      })
      .then((res) => {
        if (!res.success) {
          openAlert({
            text: res.errors.join(";\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
        } else {
          openAlert({
            text: "Пароль успешно изменен",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при попытке изменить пароль",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  return (
    <>
      <WrapperItem
        label="Фамилия"
        control={control}
        name="surname"
        required
        render={({ ...props }) => {
          return (
            <WrapperInput
              backgroundColor="#FFFFFF"
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                  color: "#0D0C22 !important",
                },
              }}
              onInput={(e) => {
                return (
                  //@ts-ignore
                  (e.target.value = e.target.value.replace(
                    /[^а-яА-ЯЁё-\s]/g,
                    "",
                  ))
                );
              }}
              {...props}
            />
          );
        }}
      />
      <WrapperItem
        label="Имя"
        required
        control={control}
        name="name"
        render={({ ...props }) => {
          return (
            <WrapperInput
              backgroundColor="#FFFFFF"
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                  color: "#0D0C22 !important",
                },
              }}
              onInput={(e) => {
                return (
                  //@ts-ignore
                  (e.target.value = e.target.value.replace(
                    /[^а-яА-ЯЁё-\s]/g,
                    "",
                  ))
                );
              }}
              {...props}
            />
          );
        }}
      />
      <WrapperItem
        label="Отчество (при наличии)"
        control={control}
        name="patronymic"
        render={({ ...props }) => {
          return (
            <WrapperInput
              backgroundColor="#FFFFFF"
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                  color: "#0D0C22 !important",
                },
              }}
              onInput={(e) => {
                return (
                  //@ts-ignore
                  (e.target.value = e.target.value.replace(
                    /[^а-яА-ЯЁё-\s]/g,
                    "",
                  ))
                );
              }}
              {...props}
            />
          );
        }}
      />
      <WrapperItem
        label="Телефон для связи"
        required
        control={control}
        name="phoneNumber"
        additionalPropertiesButton={{
          label: "Изменить номер",
          onClick: () => setTypeModal("phone"),
        }}
        render={({ ...props }) => {
          return (
            <WrapperInput
              backgroundColor="#FFFFFF"
              fullWidth
              disabled
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                },
                "& .Mui-disabled": {
                  WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginLeft: "0px" }}>
                    {watch("isPhoneActive") && (
                      <CheckCircleOutline color="success" />
                    )}
                  </InputAdornment>
                ),
              }}
              {...props}
            />
          );
        }}
      />
      <WrapperItem
        label="E-mail"
        required
        hintLabel={
          <Typography sx={{ width: "100%", display: "flex" }}>
            {watch("isEmailConfirmed")
              ? "E-mail будет использован для получения уведомлений"
              : "Подтвердите почту для получения уведомлений об оплате и оформления автоплатежа"}
          </Typography>
        }
        control={control}
        name="email"
        additionalPropertiesButton={{
          label: "Изменить e-mail",
          onClick: () => setTypeModal("email"),
        }}
        render={({ ...props }) => {
          return (
            <WrapperInput
              backgroundColor="#FFFFFF"
              fullWidth
              disabled
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                },
                "& .Mui-disabled": {
                  WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" sx={{ marginLeft: "0px" }}>
                    {watch("isEmailConfirmed") && (
                      <CheckCircleOutline color="success" />
                    )}
                  </InputAdornment>
                ),
              }}
              {...props}
            />
          );
        }}
      />
      <WrapperItem
        label="Новый пароль"
        hintLabel={
          <>
            <Typography sx={{ width: "100%", display: "flex" }}>
              Пароль должен соответствовать требованиям:
            </Typography>
            <Box
              component="ul"
              sx={(theme) => ({
                fontStyle: theme.typography.body1,
              })}
            >
              <Box component="li">длина пароля от 8 до 128 символов;</Box>
              <Box component="li">
                как минимум одна заглавная и одна строчная буква;
              </Box>
              <Box component="li">
                только латинские или кириллические буквы;
              </Box>
              <Box component="li">как минимум одна цифра;</Box>
              <Box component="li">только арабские цифры;</Box>
              <Box component="li">без пробелов;</Box>
              <Box component="li">{`другие допустимые символы: ~ ! ? @ # $ % ^ & * _ - + ( ) [ ] { } > < / \ | " ' . , :`}</Box>
            </Box>
          </>
        }
        control={control}
        name="newPassword"
        additionalPropertiesButton={{
          hidden: true,
        }}
        render={({ onChange, ...props }) => {
          return (
            <WrapperInput
              backgroundColor="#FFFFFF"
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                  color: "#0D0C22 !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleClickShowPassword}
                      edge="end"
                    >
                      {props.value ? (
                        showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <VisibilityIcon />
                        )
                      ) : (
                        <></>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPassword ? "text" : "password"}
              onChange={(e) => {
                onChange(e);
                if (sendChangePassword) {
                  if (watch("confirmNewPassword") === e.target.value) {
                    clearErrors(["newPassword", "confirmNewPassword"]);
                  } else {
                    setError("confirmNewPassword", {
                      message: "Пароли не совпадают",
                    });
                    setError("newPassword", {
                      message: "Пароли не совпадают",
                    });
                  }
                }
              }}
              {...props}
            />
          );
        }}
      />
      <WrapperItem
        label="Подтверждение пароля"
        hintLabel={
          <Typography sx={{ width: "100%", display: "flex" }}>
            Вводимое значение должно полностью совпадать со значением, указанным
            в поле «Новый пароль»
          </Typography>
        }
        control={control}
        name="confirmNewPassword"
        additionalPropertiesButton={{
          label: "Изменить пароль",
          onClick: () => {
            setSendChangePassword(true);
            if (watch("newPassword") !== watch("confirmNewPassword")) {
              setError("confirmNewPassword", {
                message: "Пароли не совпадают",
              });
              setError("newPassword", {
                message: "Пароли не совпадают",
              });
            }
            if (!watch("newPassword")) {
              setError("newPassword", {
                message: "Поле обязательно для заполнения",
              });
            }
            if (!watch("confirmNewPassword")) {
              setError("confirmNewPassword", {
                message: "Поле обязательно для заполнения",
              });
            }
            if (
              watch("newPassword") &&
              watch("confirmNewPassword") &&
              watch("newPassword") === watch("confirmNewPassword")
            ) {
              clearErrors(["newPassword", "confirmNewPassword"]);
              changePassword();
            }
          },
        }}
        render={({ onChange, ...props }) => {
          return (
            <WrapperInput
              backgroundColor="#FFFFFF"
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                  color: "#0D0C22 !important",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPasswordConfirmation}
                      onMouseDown={handleClickShowPasswordConfirmation}
                      edge="end"
                    >
                      {props.value ? (
                        showPasswordConfirmation ? (
                          <VisibilityOff />
                        ) : (
                          <VisibilityIcon />
                        )
                      ) : (
                        <></>
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              type={showPasswordConfirmation ? "text" : "password"}
              onChange={(e) => {
                onChange(e);
                if (sendChangePassword) {
                  if (watch("newPassword") === e.target.value) {
                    clearErrors(["newPassword", "confirmNewPassword"]);
                  } else {
                    setError("confirmNewPassword", {
                      message: "Пароли не совпадают",
                    });
                    setError("newPassword", {
                      message: "Пароли не совпадают",
                    });
                  }
                }
              }}
              {...props}
            />
          );
        }}
      />
      <WrapperLabel title="QR-код">
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flex: 1,
            gap: "10px",
          }}
        >
          {watch("organization.qrCodeId") && (
            <WrapperButton
              label="Скачать png"
              variant="contained"
              sx={{
                width: "150px",
              }}
              onClick={() => {
                req.file
                  .getGuidFile(watch("organization.qrCodeId") as string)
                  .then((file: any) => {
                    const name = fileService.getFileNameFromHeaders(
                      file.headers,
                    );
                    if (file) {
                      fileService.downloadBlobFile(file.data, name);
                    }
                  });
              }}
            />
          )}
          {watch("organization.qrSvgId") && (
            <WrapperButton
              label="Скачать svg"
              variant="contained"
              sx={{
                width: "150px",
              }}
              onClick={() => {
                req.file
                  .getGuidFile(watch("organization.qrSvgId") as string)
                  .then((file: any) => {
                    const name = fileService.getFileNameFromHeaders(
                      file.headers,
                    );
                    if (file) {
                      fileService.downloadBlobFile(file.data, name);
                    }
                  });
              }}
            />
          )}
        </Box>
      </WrapperLabel>
      {/* <Controller
        name="isActive"
        control={control}
        render={({
          field: { onChange, value },
          fieldState: { error, isDirty },
          formState,
        }) => (
          <WrappedCheckbox
            checked={value}
            onCheckChange={onChange}
            label="Активность"
          />
        )}
      /> */}
    </>
  );
};

export default BasicData;
