import { useState } from "react";
import { TColumnId } from "../types";

export type TUseSortTable = { initDirection: number, sort: string };

function useSortTable({ initDirection, sort = "" }: TUseSortTable) {
    const [sortBy, setSortBy] = useState<string>(sort);
    const [direction, setDirection] = useState(initDirection);
  
    function updateSort(columnId: TColumnId) {
        if (!columnId) return;
    
        if (columnId === sortBy) {
            setDirection(direction ? 0 : 1);
        } else {
            setSortBy(columnId);
            setDirection(0);
        }
    }

    return { sortBy, direction, updateSort };
}

export default useSortTable;