import { api } from "../api";
import { TSearchData } from "../../component/DataTable/types";
import { Response } from "../type";
import { AgentResponseItem } from "../../pages/adminPanel/agents/types";

export namespace Agent {
    //Возвращает постраничный список агентов
    export async function search(data: TSearchData) {
        return api.sendRequest({
            domain: "on-menu",
            method: "post",
            url: `/sales/agents/search`,
            data
        });
    }

    //Создание агента
    export async function createAgent(
        request: {
            firstName: string;
            lastName: string;
            middleName: string;
            phoneNumber: string;
            promocode: string;
            isActive: boolean;
        },
    ) {
        return api
            .sendRequest<Response>({
                domain: "on-menu",
                method: "post",
                url: `/sales/agents`,
                data: request,
            })
            .then((response) => response?.data)
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    //Редактирование агента
    export async function updateAgent(
        request: {
            id: number;
            firstName: string;
            lastName: string;
            middleName: string;
            phoneNumber: string;
            promocode: string;
            isActive: boolean;
        },
    ) {
        return api
            .sendRequest<Response>({
                domain: "on-menu",
                method: "put",
                url: `/sales/agents/${request.id}`,
                data: request,
            })
            .then((response) => response?.data)
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    //Загрузка требуемого агента по ID
    export async function getAgent(id: number) {
        return api
            .sendRequest<Response>({
                domain: "on-menu",
                method: "get",
                url: `/sales/agents/${id}`,
            });
    }

    //Загрузка пользователей агента
    export async function getUsersByAgent(data: TSearchData) {
        const agentId = data.filterItems.find(x => x.fieldName === "agentId")?.value;
        return api.sendRequest({
            domain: "on-menu",
            method: "post",
            url: `/sales/agents/${agentId}/users`,
            data
        });
    }

    //Загрузка всех активных агентов
    export async function getAll() {
        const  data = {
            filterItems: [{ fieldName: "isActive", value: true, condition: 0, valueTo: "" }],
            sortItem: {
                fieldName: "",
                direction: 0,
            }
        };
        return api.sendRequest({
            domain: "on-menu",
            method: "post",
            url: `/sales/agents/search`,
            data
        });
    }

    export async function getAgentPromocodesUse(id: number) {
        return api.sendRequest({
            domain: "on-menu",
            method: "get",
            url: `/sales/agents/${id}/promocodes-use`,
        });
    }
}