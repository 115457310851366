import { Box, IconButton, InputAdornment, Typography } from "../../../components";
import { useEffect, useState } from "react";
import { useAlert } from "../../store/alert";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "../../store/model/extensions";
import { darkTheme } from "../../theme/theme";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useMediaQuery } from "@mui/material";
import HelpIcon from "../../shared/assets/img/icons/helpIcon";
import VisibilityIcon from "../../shared/assets/img/icons/visibilityIcon";
import { logIn } from "../../store/user";
import { req } from "../../api";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import WrappedInput from "../../component/Input/WrappedInput";
import WrapperButton from "../../component/Button/WrappedButton";
import ConfirmationByCode from "./confirmationByCode";
import { formatPhoneNumber } from "../../utils";
import { PasswordHintPopover } from "../../component/PasswordHintPopover";

enum StatusType {
  LOGIN = "login",
  RECOVERY = "recovery",
  CONFIRM = "confirm",
  NEW_PASSWORD = "newPassword",
}

interface FormValues {
  userName: string;
  password: string;
  confirmPassword: string;
}

const AutorizationFields = () => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  //const [userName, setUserName] = useState<string | "">("");
  //const [password, setPassword] = useState<string | "">("");
  //const [error, setError] = useState<string[] | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //const [rememberMe, setRememberMe] = useState(false);
  const [status, setStatus] = useState<StatusType>(StatusType.LOGIN);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [code, setCode] = useState<[string, string, string, string]>([
    "",
    "",
    "",
    "",
  ]);
  const [passwordChangeData, setPasswordChangeData] = useState<{
    userRequestId: number;
    token: string;
  } | null>(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    clearErrors,
    watch,
  } = useForm<FormValues>();

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const { openAlert } = useAlert();

  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handlerLogin = () => {
    dispatch(
      logIn({
        userName: watch("userName"),
        password: watch("password"),
        rememberMe: true,
      }),
    ).then((result) => {
      if (result.meta.requestStatus === "fulfilled") {
        //@ts-ignore
        if (result?.payload?.success) {
          if (!location.state || location.state === "/") {
            if(result?.payload?.data?.currentUser?.role?.id === 1) {
              navigate("/adminpanel/transactions");
            } else {
              navigate("/personalArea");
            }            
          } else {
            navigate(location.state);
          }
        } else {
          setError("userName", {
            //@ts-ignore
            message: result?.payload?.errors?.join(";\n"),
          });
          setError("password", {
            //@ts-ignore
            message: result?.payload?.errors?.join(";\n"),
          });
        }
      } else if (result.meta.requestStatus === "rejected") {
        setError("userName", {
          message: "Ошибка авторизации",
        });
        setError("password", {
          message: "Ошибка авторизации",
        });
      }
    });
  };

  const forgotPassword = () => {
    req.user.forgotPassword(watch("userName")).then((res) => {
      if (!res.success) {
        openAlert({
          text: res.errors.join(";\n"),
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      } else {
        setStatus(StatusType.CONFIRM);
      }
    });
  };

  const checkResetPasswordCode = () => {
    req.user
      .checkResetPasswordCode(watch("userName"), code.join(""))
      .then((res) => {
        if (!res.success) {
          openAlert({
            text: res.errors.join(";\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
        } else {
          setPasswordChangeData(res.data);
          setStatus(StatusType.NEW_PASSWORD);
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при проверке кода",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const resetPassword = () => {
    if (passwordChangeData) {
      req.user
        .resetPassword({
          ...passwordChangeData,
          newPassword: watch("password"),
        })
        .then((res) => {
          if (!res.success) {
            openAlert({
              text: res.errors.join(";\n"),
              isVisibleCloseIcon: true,
              autoHide: false,
              type: "error",
            });
          } else {
            setStatus(StatusType.LOGIN);
          }
        })
        .catch(() => {
          openAlert({
            text: "Произошла ошибка при смене пароля",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "error",
          });
        });
    }
  };

  const userNameInput = () => {
    return (
      <Controller
        name="userName"
        control={control}
        defaultValue=""
        rules={{ required: "Введите номер телефона" }}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <WrappedInput
            label="Номер телефона"
            variant="outlined"
            fullWidth={true}
            value={value}
            onChange={onChange}
            InputProps={{ autoComplete: "userName" }}
            autoFocus={true}
            shadowHeight={30}
            inputPadding={16.5}
            colorType="black"
            error={error?.message}
            inputProps={{ maxLength: 16 }}
            onInput={(e: any) => {
              e.target.value = formatPhoneNumber(
                e.target.value.replace(/[^0-9+\-()\s]/g, ""),
              );
            }}
          />
        )}
      />
    );
  };

  const passwordInput = (label: string = "Пароль") => {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Controller
          name="password"
          control={control}
          defaultValue=""
          rules={{ required: "Введите пароль" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <WrappedInput
              label={label}
              variant="outlined"
              fullWidth={true}
              value={value}
              onChange={onChange}
              type={showPassword ? "text" : "password"}
              error={error?.message}
              shadowHeight={30}
              inputPadding={16.5}
              colorType="black"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOff />
                      ) : (
                        <VisibilityIcon
                          sx={{ display: "flex", alignItems: "center" }}
                        />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <>
          <IconButton
            aria-owns={open ? "mouse-over-popover" : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={[
              (theme) => ({
                padding: 0,
                fontSize: 0,
                marginRight: 0,
                position: desktop ? "absolute" : "static",
                right: desktop ? "-36px" : "0",
                marginLeft: desktop ? "0" : "12px",
              }),
            ]}
          >
            <HelpIcon />
          </IconButton>
          <PasswordHintPopover {...{ open, anchorEl, handlePopoverClose }} />
        </>
      </Box>
    );
  };

  const getAutorizationComponents = () => {
    switch (status) {
      case StatusType.LOGIN:
        return (
          <>
            {userNameInput()}
            {passwordInput()}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                flexWrap: "wrap",
              }}
            >
              <Typography
                sx={{
                  textDecoration: "underline",
                  textDecorationStyle: "dashed",
                  textUnderlineOffset: "2px",
                  cursor: "pointer",
                  ":hover": {
                    opacity: 0.8,
                  },
                  marginBottom: `${desktop ? "0px" : "14px"}`,
                }}
                onClick={() => {
                  clearErrors(["password", "userName"]);
                  setStatus(StatusType.RECOVERY);
                }}
              >
                Забыли пароль?
              </Typography>
              <WrapperButton
                label="Войти"
                fullWidth
                variant="contained"
                type="submit"
                size="normal"
                sx={() => ({
                  maxWidth: `${desktop ? "252px" : "100%"}`,
                  marginBottom: desktop ? "0px" : "40px",
                })}
              />
            </Box>
          </>
        );
      case StatusType.RECOVERY:
        return (
          <>
            <Typography sx={{ width: "100%" }}>
              Восстановление пароля
            </Typography>
            {userNameInput()}
            <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
              <WrapperButton
                label="Восстановить пароль"
                fullWidth
                variant="contained"
                size="normal"
                onClick={() => {
                  forgotPassword();
                }}
                sx={{
                  maxWidth: `${desktop ? "252px" : "100%"}`,
                  marginBottom: desktop ? "0px" : "40px",
                }}
              />
            </Box>
          </>
        );
      case StatusType.CONFIRM:
        return (
          <ConfirmationByCode
            {...{
              code,
              setCode,
              requestCode: forgotPassword,
              checkCode: checkResetPasswordCode,
              buttonLabel: "Восстановить пароль",
            }}
          />
        );
      case StatusType.NEW_PASSWORD:
        return (
          <>
            <Typography sx={{ width: "100%" }}>Задайте новый пароль</Typography>
            {passwordInput("Новый пароль")}
            <Controller
              name="confirmPassword"
              control={control}
              defaultValue=""
              rules={{
                required: "Подтвердите пароль",
                validate: (value) =>
                  value === watch("password") || "Пароли не совпадают",
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <WrappedInput
                  label="Повторите пароль"
                  variant="outlined"
                  fullWidth={true}
                  value={value}
                  onChange={onChange}
                  type={showConfirmPassword ? "text" : "password"}
                  error={error?.message}
                  InputProps={{
                    autoComplete: "new-password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <VisibilityIcon
                              sx={{ display: "flex", alignItems: "center" }}
                            />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Box sx={{ display: "flex", justifyContent: "end", width: "100%" }}>
              <WrapperButton
                label="Изменить пароль"
                fullWidth
                variant="contained"
                size="normal"
                sx={{
                  maxWidth: `${desktop ? "252px" : "100%"}`,
                  marginBottom: desktop ? "0px" : "40px",
                }}
                type="submit"
              />
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  useEffect(() => {
    const KeyEnterHandlerLogin = (event: any) => {
      if (event.code == "Enter") {
        handlerLogin();
      }
    };

    document.addEventListener("keyup", KeyEnterHandlerLogin);
    return () => {
      document.removeEventListener("keyup", KeyEnterHandlerLogin);
    };
  }, [watch("userName"), watch("password")]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    status !== StatusType.NEW_PASSWORD ? handlerLogin() : resetPassword();
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: "100%" }}
      autoComplete="new-password"
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          gap: "30px",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: "10px",
          boxShadow: "0px 0px 15px 0px #00000040",
          width: "100%",
        }}
      >
        {getAutorizationComponents()}
      </Box>
    </form>
  );
};

export default AutorizationFields;
