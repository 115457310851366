import DataTable from "../../../component/DataTable";
import { Box } from "../../../../components";
import { useRef, useState } from "react";
import { req } from "../../../api";
import { OrderDataDto } from "../../../api/request/billing";
import { TColumn, TRefreshTable } from "../../../component/DataTable/types";
import moment from "moment";
import { useAlert } from "../../../store/alert";
import ConfirmModal from "../../../component/Modal/ConfirmModal";

type HistoryOrdersType = {};

const HistoryOrders = ({}: HistoryOrdersType) => {
  const { openAlert } = useAlert();
  const [changeTariff, setChangeTariff] = useState<{
    tariff: number;
    period: number;
    autoProlong: boolean;
  } | null>(null);

  const refreshTableRef = useRef<TRefreshTable | null>(null);

  const handleConnectTariff = () => {
    if (changeTariff) {
      req.billing
        .connectTariff({
          tariff: changeTariff.tariff,
          period: changeTariff.period,
          autoProlong: changeTariff.autoProlong,
        })
        .then((res) => {
          if (!res.success) {
            openAlert({
              text: res.errors.join(";\n"),
              isVisibleCloseIcon: true,
              autoHide: false,
              type: "error",
            });
          } else {
            openAlert({
              text: "Заказ успешно создан",
              isVisibleCloseIcon: true,
              autoHide: true,
              type: "success",
            });
          }
        })
        .catch(() => {
          openAlert({
            text: "Ошибка создания заказа",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "error",
          });
        })
        .finally(() => {
          setChangeTariff(null);
          refreshTableRef.current && refreshTableRef.current();
        });
    }
  };

  const columns: TColumn<OrderDataDto>[] = [
    { name: "Тариф", id: "tariffName", sortId: "tariffId" },
    {
      name: "Дата оформления",
      id: "created",
      renderComponent: ({ value }) => {
        return <>{moment(value).format("DD.MM.YYYY")}</>;
      },
    },
    {
      name: "Срок действия заказа",
      id: "periodName",
      sortId: "period",
      renderComponent: ({ row }) => {
        return (
          <>{`c ${moment(row.created).format("DD.MM.YYYY")} ${row.finishDate ? "по " + moment(row.finishDate).format("DD.MM.YYYY") : ""}`}</>
        );
      },
    },
    {
      name: "Автоматическая оплата",
      id: "autoProlongation",
      renderComponent: ({ value, row, refreshTable }) => {
        return (
          <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
            {value ? "Да" : "Нет"}
            {/* <IconButton
              size="small"
              sx={{ color: "#0000008A" }}
              onClick={() => {
                setChangeTariff({
                  tariff: row.tariffId,
                  period: row.period,
                  autoProlong: row.autoProlongation,
                });
                refreshTable();
                refreshTableRef.current = refreshTable;
              }}
            >
              <Cached fontSize="medium" />
            </IconButton> */}
          </Box>
        );
      },
    },
  ];

  return (
    <>
      <DataTable<OrderDataDto>
        requestData={req.billing.getOrder}
        columns={columns}
        noData="Здесь пока ничего нет, но со временем появится история ваших заказов"
      />
      {changeTariff && (
        <ConfirmModal
          onClose={() => setChangeTariff(null)}
          onAccept={handleConnectTariff}
          buttonAccept="Создать заказ"
          headerModal="Создать заказ"
          bodyModal={`Вы действительно хотите создать заказ? Предыдущий заказ приостановит свое действие.`}
        />
      )}
    </>
  );
};

export default HistoryOrders;
