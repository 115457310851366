import axios, {
  AxiosError,
  AxiosRequestHeaders,
  AxiosResponse,
  ResponseType,
} from "axios";
import { AuthenticationError } from "./errors";
import type { AxiosInstance, Method } from "axios";

type Domain = "on-menu";

type GetToken = () => Promise<{
  access_token: string | null;
  refresh_token?: string | null;
}>;

type DomainConfig = { baseURL: string };

export type RequestConfig<T, P> = {
  domain: Domain;
  method: Method;
  url: string;
  data?: T;
  params?: P;
  headers?: AxiosRequestHeaders;
  responseType?: ResponseType;
};

type RequestResponse<T> = {
  data: T;
};
let axiosInstances: Record<Domain, AxiosInstance> | null = null;

function setupAxiosInstance(params: { baseURL: string; getToken: GetToken, impersonate: () => number | undefined }) {
  const axiosInstance = axios.create({
    baseURL: params.baseURL,
  });

  axiosInstance.interceptors.request.use(async (config) => {
    const token = await params.getToken();

    if (token.access_token && config.headers) {
      config.headers.Authorization = `Bearer ${token.access_token}`;
    }

    if (params.impersonate) {
      config.headers["X-Impersonate"] = params.impersonate();
    }

    return config;
  });

  // axiosInstance.interceptors.response.use(
  //    (response) => {
  //      return response;
  //    },
  //    (error) => {
  //       if (error?.response?.status === 404) {
  //         navigate("/notfound");
  //         return;
  //       }

  //      throw error;
  //    },
  //  );

  return axiosInstance;
}

export const api = {
  async sendRequest<TResponse = any, TData = any, TParams = any>(
    config: RequestConfig<TData, TParams>,
  ): Promise<AxiosResponse<TResponse>> {
    if (!axiosInstances) {
      throw new Error("Axios instances were not initialized");
    }
    try {
      return await axiosInstances[config.domain].request({
        method: config.method,
        url: config.url,
        params: config.params,
        data: config.data,
        headers: config.headers,
        responseType: config.responseType,
      });
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 401) {
          throw new AuthenticationError(e.message);
        } else if (e.response?.status === 400) {
          throw new AuthenticationError(e.message);
        }
      }
      throw e;
    }
  },

  /**Ручная обработка ошибок*/
  async requestWithoutCatch<T = any, D = any, P = any>(
    config: RequestConfig<D, P>,
  ): Promise<AxiosResponse<T>> {
    if (!axiosInstances) {
      throw new Error("Axios instances were not initialized");
    }
    return await axiosInstances[config.domain].request({
      method: config.method,
      url: config.url,
      params: config.params,
      data: config.data,
      headers: config.headers,
      responseType: config.responseType,
    });
  },

  async request<T = any, D = any, P = any>(
    config: RequestConfig<D, P>,
  ): Promise<RequestResponse<T>> {
    const { data } = await this.sendRequest(config);
    return { data };
  },

  config(apiConfig: {
    getToken: () => Promise<{
      access_token: string | null;
      refresh_token?: string | null;
    }>;
    domainConfigs: Record<Domain, DomainConfig>;
    impersonate: () => number | undefined;
  }) {
    const arrayObjectEntries = Object.entries(apiConfig.domainConfigs) as [
      Domain,
      DomainConfig,
    ][];
    axiosInstances = arrayObjectEntries.reduce<Record<Domain, AxiosInstance>>(
      (acc, [domain, config]) => {
        acc[domain] = setupAxiosInstance({
          baseURL: config.baseURL,
          getToken: apiConfig.getToken,
          impersonate: apiConfig.impersonate,
        });
        return acc;
      },
      {} as any,
    );
  },

  getBaseUrl(domain: Domain = "on-menu") {
    return axiosInstances && axiosInstances[domain].getUri();
  },
};

// // TODO: remove
// // @ts-ignore
// window.__api__ = api;
