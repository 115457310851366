import { Box, Button } from "../../../../components";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import checkIcon from './images/check.svg';

export type CardType = {
    id: string,
    theme?: 'light'|'dark',
    title: string,
    price: number,
    discounts: ReactNode[],
    options: string[],
    comment: string,
    onClick: () => void
}

const Title = styled('div')({
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.8125rem'
});

const Price = styled('div')({
    marginTop: '8px',
    marginBottom: '8px',
    fontSize: '3rem',
    fontWeight: 700,
    lineHeight: '3.5rem'
});

const Discount = styled('ul')(() => ({
    marginTop: '12px',
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    fontWeight: 400,
    textAlign: 'center',
    '& span': {
        fontWeight: 700
    }
}));

const Option = styled('ul')(() => ({
    marginTop: '13px',
    marginBottom: '16px',
    fontSize: '0.875rem',
    fontWeight: 400,
    width: '224px',
    '& li': {
        listStyleImage: `url(${checkIcon})`,
        transform: 'translateX(8px)'
    }
}));


const StyledButton = styled(Button)({
    color: '#FFFFFF',
    background: 'linear-gradient(-45deg, #FF4D65, #F71735)',
    borderRadius: '60px',
    height: '40px',
    width: '120px',
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.375rem',
    '&:hover': {
        background: 'linear-gradient(-45deg, #39106a, #39106a)'
    }
});

function Card({ theme = 'light', title, price, discounts, options, comment,
    onClick, space}: CardType & { space?: boolean }) {

    return(
        <Box
            sx={{
                backgroundColor: theme === 'light' ? '#FFFFFF' : '#1E2024',
                color: theme === 'light' ? '#0D0C22' : '#FFFFFF',
                marginLeft: space ? '32px' : 0,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '12px',
                padding: '32px 34px 24px 34px',
                width: '328px',
                '& ul': {
                    listStyleType: 'none'
                }
            }}
        >
            <Title>
                { title }
            </Title>
            <Price>
                { price }₽
            </Price>
            <Box
                sx={{
                    color: theme === 'light' ? '#3D3D4E' : '#FFFFFF',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    lineHeight: '1rem',
                }}
            >
                за один месяц
            </Box>
            <Discount>
                { discounts.map(item => <li key={JSON.stringify(item)}>{ item }</li>) }
            </Discount>
            <Option>
                { options.map((item, index) =>
                    <li style={{ marginTop: index > 0 ? '10px' : 'unset'}} key={item.toString()}>
                        { item }
                    </li>) }
            </Option>
            <Box
                sx={{
                    color: theme === 'light' ? '#F71735' : '#FE4A63',
                    marginBottom: '16px',
                    fontSize: '0.75rem',
                    fontWeight: 400,
                    lineHeight: '1rem'
                }}
            >
                { comment }
            </Box>
            <StyledButton
                label="Подключить"
                variant="contained"
                size="large"
                onClick={onClick}
            />
        </Box>
    );
}

export default Card;