import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateAutorizationRouter from "./privateAuthorizationRouter";
import "./App.scss";
import LoginPage from "../pages/login";
import Main from "../pages/main";
import PrivateUnautorizationRouter from "./privateUnauthorizationRouter";
import AdminPanel from "../pages/adminPanel";
import Users from "../pages/adminPanel/users";
import PersonalArea from "../pages/personalArea";
import Constructor from "../pages/constructor";
import Snackbar from "./snackbar";
import UserCard from "../pages/adminPanel/users/userCard";
import ConfirmEmail from "../pages/ConfirmEmail";
import TemplateCard from "../pages/templateCard";
import ConstructorMenuPage from "../pages/constructorMenu";
import ConstructorMenu from "../pages/constructorMenu/constructor";
import PositionEdit from "../pages/constructorMenu/position";
import OrganizationEditPage from "../pages/constructorMenu/organization";
import AdminCreation from "../pages/adminPanel/users/createUser";
import Settings from "../pages/templateCard/settings";
import ChangeHistory from "../pages/templateCard/changeHistory";
import OperationJournal from "../pages/adminPanel/operationjournal";
import PaymentTransactions from "../pages/adminPanel/paymentTransactions";
import UsersTariff from "../pages/adminPanel/statistics/usersTariff";
import NewUsers from "../pages/adminPanel/statistics/newUsers";
import Payment from "../pages/adminPanel/statistics/payment";
import Promocodes from "../pages/adminPanel/promocodes";
import Agents from "../pages/adminPanel/agents";
import StatisticsEstablishment from "../pages/statisticsEstablishment";
import PublicMenu from "../pages/publicMenu";
import StatisticsViews from "../pages/statisticsEstablishment/views";
import StatisticsRating from "../pages/statisticsEstablishment/rating";
import StatisticsDinamics from "../pages/statisticsEstablishment/dinamics";
import AgentCard from "../pages/adminPanel/agents/agentCard";
import CreateAgent from "../pages/adminPanel/agents/createAgent";
import { PromoCodeCard } from "../pages/adminPanel/promocodes/promoCodeCard";
import GroupsEdit from "../pages/constructorMenu/groups";
import Unsubscribe from "../pages/subscriptions";
import NotFound from "../pages/notFound";
import Paid from "../pages/paid";

function App() {
  return (
    <div
      className={"app"}
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateAutorizationRouter />}>
            <Route path="/adminpanel" element={<AdminPanel />}>
              <Route path="/adminpanel/users" element={<Users />} />
              <Route
                path="/adminpanel/operationjournal"
                element={<OperationJournal />}
              />
              <Route path="/adminpanel/statistics" element={<>Статистика</>} />
              <Route
                path="/adminpanel/statistics/tariff"
                element={<UsersTariff />}
              />
              <Route
                path="/adminpanel/statistics/users"
                element={<NewUsers />}
              />
              <Route
                path="/adminpanel/statistics/payment"
                element={<Payment />}
              />
              <Route
                path="/adminpanel/transactions"
                element={<PaymentTransactions />}
              />
              <Route path="/adminpanel/sales" element={<>Продажи</>} />
              <Route
                path="/adminpanel/sales/agents"
                element={<Agents />}
              />
              <Route
                path="/adminpanel/sales/promocodes"
                element={<Promocodes />}
              />
              <Route path="/adminpanel/sales/agents/:id" element={<AgentCard />} />
              <Route path="/adminpanel/sales/promocodes/:id" element={<PromoCodeCard />} />
            </Route>
            <Route path="/templateCard/:id" element={<TemplateCard />}>
              <Route path="/templateCard/:id/settings" element={<Settings />} />
              <Route
                path="/templateCard/:id/constructor"
                element={<ConstructorMenu />}
              />
              <Route
                path="/templateCard/:id/changeHistory"
                element={<ChangeHistory />}
              />
            </Route>
            <Route
              path="/statistics-establishment"
              element={<StatisticsEstablishment />}
            >
              <Route
                path="/statistics-establishment/views"
                element={<StatisticsViews />}
              />
              <Route
                path="/statistics-establishment/rating"
                element={<StatisticsRating />}
              />
              <Route
                path="/statistics-establishment/dinamics"
                element={<StatisticsDinamics />}
              />
            </Route>
            <Route
              path="/adminpanel/userCreation"
              element={<AdminCreation />}
            />
            <Route path="/adminpanel/users/:id" element={<UserCard />} />
            <Route path="/personalArea" element={<PersonalArea />} />
            <Route path="/personalArea/paid" element={<Paid />} />
            <Route path="/constructor" element={<Constructor />} />
            <Route path="/constructor/:id" element={<ConstructorMenuPage />}>
              <Route path="/constructor/:id/organization" element={<OrganizationEditPage />} />
              <Route
                  path="/constructor/:id/constructor"
                  element={<ConstructorMenu />}
              />
              <Route
                  path="/constructor/:id/groups"
                  element={<GroupsEdit />}
              />
              <Route
                  path="/constructor/:id/position/:positionId?"
                  element={<PositionEdit />}
              />
              <Route
                  path="/constructor/:id/changeHistory"
                  element={<ChangeHistory />}
              />
            </Route>
            <Route path="/adminpanel/sales/agents/:id" element={<AgentCard />} />
            <Route path="/adminpanel/sales/agent/create" element={<CreateAgent />} />
          </Route>
          <Route element={<PrivateUnautorizationRouter />}>
            <Route path="/" element={<Main />} />
            <Route path="/subscriptions/unsubscribe/:id" element={<Unsubscribe />} />
            <Route path="/notfound" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Route>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/user-menu" element={<PublicMenu />} />
          <Route path="/menu/:code" element={<PublicMenu />} />
          <Route path="/confirm-email" element={<ConfirmEmail />} />
         
        </Routes>
      </BrowserRouter>
      <Snackbar />
    </div>
  );
}

export default App;
