export type TMenuItem = {
  title: string;
  link: string;
  id: string;
  defaultSelect?: boolean;
  children?: TMenuItem[];
};

export const menuItems: TMenuItem[] = [
  {
    title: "Расчётные операции",
    link: "transactions",
    id: "transactions",
  },
  {
    title: "Управление пользователями",
    link: "users",
    id: "users",
  },
  {
    title: "Журнал операций",
    link: "operationjournal",
    id: "operationjournal",
  },
  {
    title: "Статистика",
    link: "statistics",
    id: "statistics",
    children: [
      {
        title: "Тарифы пользователей",
        link: "tariff",
        id: "statistics-tariff",
      },
      {
        title: "Новые пользователи",
        link: "users",
        id: "statistics-users",
      },
      {
        title: "Оплаты",
        link: "payment",
        id: "statistics-payment",
      },
    ],
  },
  {
    title: "Продажи",
    link: "sales",
    id: "sales",
    children: [
      {
        title: "Агенты по продажам",
        link: "agents",
        id: "sales-agents",
      },
      {
        title: "Промокоды",
        link: "promocodes",
        id: "sales-promocodes",
      },
    ]
  },
];
