import { TMenuItem } from "../../../pages/adminPanel/menuItems";
import { Box } from "../../../../components";
import CarouselBase from "../../CarouselBase";
import "react-alice-carousel/lib/alice-carousel.css";
import Link, { TMobileLinkStyle } from "./link";
import { Fragment } from "react";
import { useContext } from "react";
import { NavMenuContext } from "../";

export type MobileNavMenuProps = {
  menuItems: TMenuItem[];
  mobileLinkStyle?: TMobileLinkStyle;
};

/**Мобильное меню навигации в карусели */
function MobileNavMenu({ mobileLinkStyle, menuItems }: MobileNavMenuProps) {
  //const location = useLocation();

  const { selectedId } = useContext(NavMenuContext);

  const getCarousel = (
    currentMenuItems: TMenuItem[] = menuItems,
    parentLink: string = "",
    display: boolean = true
  ) => {
    let arrayCarousel: JSX.Element[] = [];
    const arrayItem: JSX.Element[] = [];
    currentMenuItems.forEach((item, index) => {
      const isActive = selectedId === item.id || (item.children && item.children.some(x => x.id === selectedId));

      if (item.children) {
        arrayItem.push(
          <Box key={`${parentLink}${item.link}`} sx={{ display: "inline" }}>
            <Link
              to={`${parentLink}${item.link}`}
              mobileLinkStyle={mobileLinkStyle}
              id={item.id}
              state={{ childrenLink: item.children[0].link }}
            >
              {item.title}
            </Link>
          </Box>,
        );
        arrayCarousel = [
          ...arrayCarousel,
          ...getCarousel(item.children, `${item.link}/`, isActive),
        ];
      } else {
        arrayItem.push(
          <Box key={`${parentLink}${item.link}`} sx={{ display: display ? "inline": "none" }}>
            <Link
              id={item.id}
              to={`${parentLink}${item.link}`}
              mobileLinkStyle={mobileLinkStyle}
            >
              {item.title}
            </Link>
          </Box>,
        );
      }
    });
    arrayCarousel.push(<CarouselBase items={arrayItem} isActive={display} />);
    return arrayCarousel.reverse();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px", marginBottom: "10px" }}>
      {getCarousel().map(i => <Fragment key={i.key}>{ i }</Fragment>)}
    </Box>
  );
}

export default MobileNavMenu;
