import { Controller } from "react-hook-form";
import WrapperInput from "../../../component/Input/WrappedInput";
import { numberWithSpaces } from "../../../utils";

export type FieldProps = {
    value?: string;
    symbol?: string
    name: string;
    control: any;
}

function Field({ name, value, symbol = "", control }: FieldProps) {
    function format(value: string) {
        return numberWithSpaces(parseInt(value)) + symbol;
    }

    return(
        <Controller
            name={name}
            control={control}
            defaultValue={format(value || "")}
            render={({ field: { onChange, value, onBlur } }) =>
                <WrapperInput
                    backgroundColor="#FFFFFF"
                    fullWidth
                    sx={{
                        "& .MuiInputBase-root": {
                            height: "36px",
                            color: "#0D0C22 !important",
                            fontSize: "0.875rem",
                            fontWeight: 500,
                            lineHeight: "1.625rem",
                            paddingLeft: "6px",
                        },
                    }}
                    onFocus={(e: any) => {
                        /**На фокусе убирается symbol и пробелы, разделяющие разряды числа */
                        setTimeout(() => {
                            const target = e.target;
                            const selectionStart = target.selectionStart; //начальная позиция курсора
                            const slice = target.value.slice(0, selectionStart);  //символы до курсора
                            const spaces = slice.match(/\s/g)?.length || 0;   //количество пробелов перед курсором
                            target.value = (target.value.replace(/\D/gi, "") || "");  //оставить только цифры
                            const newPos = selectionStart - spaces; //новая позиция курсора с учётом форматирования
                            target.setSelectionRange(newPos, newPos);
                        })
                    }}
                    onInput={(e: any) => {  //форматирование(только цифры)
                        const target = e.target;
                        target.value = target.value.replace(/\D/gi, "").trim();
                        if (target.value === "") return;
                    }}
                    onBlur={(e: any) => {
                        const target = e.target;
                        setTimeout(() => {  //форматирование(разделить разряды и добавить символ в конце)
                            if (target.value.trim() === "") return;
                            target.value = format(target.value.replace(/\D/gi, ""));
                        });
                        onBlur();
                    }}
                    { ...{ onChange, value } }
                />
            }
        />
    );
}

export default Field;