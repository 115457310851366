import {
  Box,
  Typography,
  IconButton,
} from "../../../components";
import { NavLink, useNavigate } from "react-router-dom";
//import { logOut, useUserData } from "../../../store/user";
import logo from "shared/assets/img/on-menu.svg";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import LogoutIcon from "@mui/icons-material/Logout";
import { SxProps } from "@mui/material";
import { Theme } from "@emotion/react";
import { useEffect, useState } from "react";
import WrapperButton from "../Button/WrappedButton";
import { logOut, useUserData } from "../../store/user";
import { useDispatch } from "../../store/model/extensions";
import { styled } from "@mui/material/styles";

const AuthorizationHeader = () => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState<Boolean | null>(null);
  const dispatch = useDispatch();
  const { userData } = useUserData();
  const [menuList, setMenuList] = useState<
    { title: string; path: string; isVisible?: boolean }[]
  >([]);

  useEffect(() => {
    if (!desktop) {
      setOpenMenu(null);
    }
  }, [desktop]);

  useEffect(() => {
    if (userData && userData?.role?.id !== 1) {
      setMenuList([
        {
          title: "Личный кабинет",
          path: "/personalArea",
        },
        {
          title: "Конструктор меню",
          path: "/constructor",
        },
        {
          title: "Статистика заведения",
          path: "/statistics-establishment/views",
          isVisible:
            userData?.order?.tariffName === "Премиум" && userData.role.id !== 1,
        },
        {
          title: "Панель администратора",
          path: "/adminpanel",
          isVisible: userData?.role?.id === 1,
        },
      ]);
    }
  }, [userData]);

  const StyledLink = styled(NavLink)({
    "&:visited": {
      textDecorationColor: "#FFFFFF !important",
    },
  });

  const getStyleBurgerMenu: SxProps<Theme> = (theme: any) => {
    return !desktop
      ? {
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          width: "100%",
          flexDirection: "column",
          alignItems: "start",
          zIndex: openMenu ? 2 : 1,
          top: "100%",
          left: "0px",
          padding: "23px",
          opacity: !openMenu ? 0 : 1,
          transition: openMenu !== null ? "opacity 500ms linear" : "none",
        }
      : {};
  };

  return (
    <Box
      component={"header"}
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          paddingTop: desktop ? "59px" : "0px",
          position: "relative",
        }),
      ]}
    >
      <Box
        sx={[
          (theme) => ({
            display: "flex",
            justifyContent: "center",
            padding: desktop ? "41px 123px" : "12px 23px",
          }),
        ]}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: 1280,
            flexGrow: 1,
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {!desktop && (
              <IconButton onClick={() => setOpenMenu((prev) => !prev)}>
                {!openMenu ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 15H21V13H3V15ZM3 19H21V17H3V19ZM3 11H21V9H3V11ZM3 5V7H21V5H3Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.92893 6.41421L6.34314 5L19.0711 17.7279L17.6568 19.1421L4.92893 6.41421Z"
                      fill="white"
                    />
                    <path
                      d="M19.0711 6.4142L6.34317 19.1421L4.92896 17.7279L17.6569 4.99998L19.0711 6.4142Z"
                      fill="white"
                    />
                  </svg>
                )}
              </IconButton>
            )}
            <img
              style={{ width: desktop ? "165px" : "100px" }}
              src={logo}
              alt="Логотип"
            />
          </Box>
          <Box
            sx={[
              (theme) => ({
                display: "flex",
                gap: "35px",
                ...getStyleBurgerMenu(theme),
              }),
            ]}
          >
            {menuList
              .filter(({ isVisible }) => isVisible ?? true)
              .map(({ path, title }) => {
                return (
                  <StyledLink
                    to={path}
                    key={title}
                    style={({ isActive, isPending }) => {
                      return {
                        textDecoration: isActive ? "none" : "underline",
                        textDecorationColor: "#FFFFFF",
                      };
                    }}
                    onClick={() => {
                      !desktop && setOpenMenu(false);
                    }}
                  >
                    <Typography variant="subtitle2" color="#FFFFFF">
                      {title}
                    </Typography>
                  </StyledLink>
                );
              })}
          </Box>
          <Box
            sx={[
              (theme) => ({
                display: "flex",
                gap: "20px",
                alignItems: "center",
              }),
            ]}
          >
            {desktop && (
              <Box
                sx={[
                  (theme) => ({
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }),
                ]}
              >
                {userData?.organization?.logoId && (
                  <img
                    style={{ width: "40px" }}
                    src={`${process.env.REACT_APP_BASE_URL}/file/downloadGuid?guid=${userData?.organization?.logoId}`}
                    alt="Логотип компании"
                  />
                )}
                <Typography variant="body1" color="#FFFFFF">
                  {userData?.name}
                </Typography>
              </Box>
            )}
            <WrapperButton
              label={<Typography sx={{
                lineHeight: "1",
                maxWidth: desktop ? "100%" : "116px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                textWrap: "nowrap",
              }} >{desktop ? "Выйти" : userData?.name}</Typography>}
              variant="contained"
              size={desktop ? "large" : "small"}
              icon={!desktop && <LogoutIcon />}
              iconPosition="right"
              onClick={() => {
                dispatch(logOut()).then((res) => {
                  if (res.meta.requestStatus === "fulfilled") {
                    navigate("/login");
                  }
                });
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AuthorizationHeader;
