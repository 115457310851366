import { createSlice } from "@reduxjs/toolkit";
import { AlertColor } from "@mui/material";
import { getSliceDataSelectorFactory } from "../../utils";
import { useDispatch } from "../model/extensions";
import { useSelector } from "react-redux";

export type AlertDataType = {
  type?: AlertColor;
  autoHide?: boolean;
  isVisibleCloseIcon?: boolean;
  text: string;
};
export type AlertProps = {
  isOpen?: boolean;
  data: AlertDataType;
};

type initialStateType = {
  isOpen: boolean;
  data: AlertDataType;
};

const initialState: initialStateType = {
  isOpen: false,
  data: {
    text: "",
  },
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    openAlert(state, action) {
      state.data = { ...action.payload };
      state.isOpen = true;
    },
    closeAlert(state) {
      state.isOpen = false;
      state.data = { ...state.data, text: "" };
    },
  },
});

export default alertSlice.reducer;

const selectorFactory = getSliceDataSelectorFactory("alert");
const selectData = selectorFactory((state) => state.data);
const selectIsOpen = selectorFactory((state) => state.isOpen);

export function useAlert() {
  const dispatch = useDispatch();
  const currentData = useSelector(selectData);
  const isOpen = useSelector(selectIsOpen);

  const openAlert = (props: AlertDataType) => {
    dispatch(alertSlice.actions.openAlert(props));
  };

  const closeAlert = () => {
    dispatch(alertSlice.actions.closeAlert());
  };

  return {
    isOpen,
    currentData,
    openAlert,
    closeAlert,
  };
}
