import { Box } from "../../../../components";

export type TableSortIconprops = {
    reverse?: boolean;
};

function TableSortIcon({ reverse = false }: TableSortIconprops) {
    return(
        <Box
            sx={{
                display: 'inline-flex',
                cursor: 'pointer',
                transform: `rotate(${reverse ? 180 : 0}deg)`,
            }}
        >
            <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.706633 1.9141L3.29663 4.5041C3.68663 4.8941 4.31663 4.8941 4.70663 4.5041L7.29663 1.9141C7.92663 1.2841 7.47663 0.204102 6.58663 0.204102H1.40663C0.516633 0.204102 0.0766335 1.2841 0.706633 1.9141Z" fill="white"/>
            </svg>
        </Box>
    );
}

export default TableSortIcon;