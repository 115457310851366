import {
    Box,
    Typography,
  } from "../../../../../components";
import { darkTheme } from "../../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { Controller, useForm, SubmitHandler, RegisterOptions } from "react-hook-form";
import { PromoCodeCardForm, PROMOCODE_DEFAULT } from "./types";
import { req } from "../../../../api";
import { useAlert } from "../../../../store/alert";
import { Link, useNavigate, useParams } from "react-router-dom";
import WrappedButton from "../../../../component/Button/WrappedButton";
import WrapperInput from "../../../../component/Input/WrappedInput";
import { useEffect, useState } from "react";
import WrappedSelect from "../../../../component/Select";
import { AgentResponseItem } from "../../agents/types";

const promoCodeTypes = [{ value: "0", label: "Без скидки"},{value: "2", label: "Скидка в %"},{value: "4", label: "Скидка в рублях"},{value: "", label: "Неважно"}];
const booleanSelect = [{ value: "true", label: "Да"},{value: "false", label: "Нет"}];

type AgentSelectItem = {
    label: string,
    value: number | string
};

const discountValueRules: any = {
  required: "Поле обязательно для заполнения"
};

const discountValuePercentRules: any = {
  required: "Поле обязательно для заполнения",
  max: { value: 100, message: "Значение не может быть больше 100%"}
};

export function PromoCodeCard() {
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    const { openAlert } = useAlert();
    const navigate = useNavigate();

    const [promocode, setPromocode] = useState<PromoCodeCardForm | null>(PROMOCODE_DEFAULT);
    const [agents, setAgents] = useState<AgentSelectItem[]>([{ label: "Не задано", value: 0 }]);
    const { id } = useParams();
    const [discountFieldRules, setDiscountFieldRules] = useState(discountValuePercentRules);

    const edit = !!id && id !== "create";
    const required = edit ? <></> : <Typography variant="caption" color="#F00" fontWeight="700"> * </Typography>;

    const {
        handleSubmit,
        control,
        reset,
        resetField,
        getValues
    } = useForm<PromoCodeCardForm>({ defaultValues: PROMOCODE_DEFAULT });

    const onSubmit: SubmitHandler<PromoCodeCardForm> = (data) => {
        if (!edit) {
            req.promoCode
            .create(data)
            .then((res) => {
                if (!res.success) {
                openAlert({
                    text: res.errors
                    .concat(
                        res.data && Object.entries(res.data.errors).map((e) => e[1]),
                    )
                    .join("\n"),
                    isVisibleCloseIcon: true,
                    autoHide: false,
                    type: "error",
                });
                } else {
                openAlert({
                    text: "Сохранено",
                    isVisibleCloseIcon: true,
                    autoHide: true,
                    type: "success",
                });
                navigate("/adminpanel/sales/promocodes");
                }
            })
            .catch((w) => {
                openAlert({
                text: "Произошла ошибка",
                isVisibleCloseIcon: true,
                autoHide: true,
                type: "error",
                });
            });

            return;
        }

        req.promoCode
          .updatePromoCode({
            id: promocode.id!,
            isActive: data.isActive,
            agentId: data.agentId,
            code: promocode?.code!
          })
          .then((res) => {
            if (!res.success) {
              openAlert({
                text: res.errors.join(";\n"),
                isVisibleCloseIcon: true,
                autoHide: false,
                type: "error",
              });
            } else {
              openAlert({
                text: "Данные промокода успешно обновлены",
                isVisibleCloseIcon: true,
                autoHide: true,
                type: "success",
              });
              setPromocode(res.data);
              navigate("/adminpanel/sales/promocodes");
            }
          })
          .catch(() => {
            openAlert({
              text: "Произошла ошибка при обновлении промокода",
              isVisibleCloseIcon: true,
              autoHide: true,
              type: "error",
            });
          });

    };
    
    useEffect(() => {
        req.agent.getAll()
        .then((response) => {
            const list: AgentSelectItem[] = response.data.data.items.map((x: AgentResponseItem) => ({ value: x.id, label: x.fullName }));
            list.unshift({ label: "Не задано", value: 0 })
            setAgents(list);
        });
    },[]);

    if (edit) {
        useEffect(() => {
            req.promoCode.getPromoCode(Number(id))
            .then((response) => {
              setPromocode(response.data.data);
              reset(response.data.data);
            });
          }, [id]);
    } else {
      const nevemind = promoCodeTypes.find(x => x.value === "");
      if (nevemind) promoCodeTypes.pop();
    }

    let discountValueDisabled = false;

    const type = Number(getValues("type"));

    if (type === 0) {
      discountValueDisabled = true;
    }

    return (
        <Box
        component={"main"}
        sx={[
          () => ({
            display: "flex",
            flexDirection: "column",
          
            position: "relative",
            margin: "auto",
            zIndex: 1,
            paddingBottom: "60px",
          }),
        ]}
      >
        <form onSubmit={handleSubmit(onSubmit)} style={{ flexGrow: 1 }}>        
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "25px",
                "& .MuiInputBase-input.Mui-disabled": {
                    color: "#000",
                    "-webkit-text-fill-color": "#000"
                }
              }}
            >
              
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: desktop ? "row" : "column",
                }}
              >
                <Typography variant="caption" color="#0D0C22">
                  Промокод
                  {required}
                </Typography>
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                  <Controller
                    name="code"
                    control={control}
                    rules={{required: "Поле обязательно для заполнения" }}
                    render={({ 
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <WrapperInput
                        backgroundColor="#FFFFFF"
                        fullWidth
                        disabled={edit}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "34px",
                            color: "#0D0C22 !important",
                            width: "100%",
                          },
                        }}
                        {...{ onChange, value }}
                        error={error?.message}
                      />
                    )}
                  />
                </Box>
              </Box>
  
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: desktop ? "row" : "column",
                }}
              >
                <Typography variant="caption" color="#0D0C22">
                  Тип промокода
                </Typography>
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                  <Controller
                    name="type"
                    control={control}
                    rules={{required: "Поле обязательно для заполнения", onChange: ({ target: { value }}) => {
                      resetField('discountValue');

                      const type = Number(value);

                      switch(type) {
                        case 0:
                          setDiscountFieldRules(undefined);
                          break;
                        case 2: 
                          setDiscountFieldRules(discountValuePercentRules);
                          break;
                        case 4: 
                          setDiscountFieldRules(discountValueRules);
                          break;
                      }
                    }}}
                    render={({ field: { onChange, value } }) => (
                        <WrappedSelect
                            multiple={false}
                            options={promoCodeTypes}
                            value={value}
                            disabled={edit}
                            onChange={onChange}
                            sx={{ height: "36px" }}
                        />
                    )}
                  />
                </Box>
              </Box>
  
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: desktop ? "row" : "column",
                }}
              >
                <Typography variant="caption" color="#0D0C22">
                  Агент по продажам
                </Typography>
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                  <Controller
                    name="agentId"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <WrappedSelect
                            multiple={false}
                            options={agents}
                            value={value ?? 0}
                            disabled={!!promocode?.agentId}
                            onChange={onChange}
                            sx={{ height: "36px" }}
                        />
                    )}
                  />
                </Box>
              </Box>
  
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: desktop ? "row" : "column",
                }}
              >
                <Typography variant="caption" color="#0D0C22">
                  Размер скидки
                  {discountValueDisabled ? <></> : required}
                </Typography>
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <Controller
                        name="discountValue"
                        control={control}
                        rules={{
                          validate: () => {
                            if (edit) return true;
                            const rawDiscount = getValues("discountValue");
                            const discount = Number(rawDiscount);
                            if (discountFieldRules.required && (rawDiscount === "" || rawDiscount === undefined || Number.isNaN(discount))) {
                              return discountFieldRules.required;
                            }

                            if (discountFieldRules.max && discount > discountFieldRules.max.value) {
                              return discountFieldRules.max.message;
                            }

                            return true;
                          }
                        }}
                        disabled={discountValueDisabled}
                        render={({
                            field: { onChange, value },
                            fieldState: { error }
                        }) => (
                            <WrapperInput
                            inputProps={{ maxLength: 16 }}
                            backgroundColor="#FFFFFF"
                            fullWidth
                            disabled={edit || discountValueDisabled}
                            sx={{
                                "& .MuiInputBase-root": {
                                height: "34px",
                                color: "#0D0C22 !important",
                                width: "100%",
                                },
                            }}
                            onInput={(e: { target: { value: string } }) => {
                              return (
                                //@ts-ignore
                                (e.target.value = e.target.value.replace(/[^0-9\s]/g, ""))
                              );
                            }}
                            {...{ onChange, value }}
                            error={error?.message}
                        />
                        )}
                    />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: desktop ? "row" : "column",
                }}
              >
                <Typography variant="caption" color="#0D0C22">
                  Единоразовый промокод
                </Typography>
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <Controller
                        name="isOneTime"
                        control={control}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <WrappedSelect
                                multiple={false}
                                options={booleanSelect}
                                disabled={edit}
                                value={value}
                                onChange={onChange}
                                sx={{ height: "36px", width: "80px" }}
                            />
                        )}
                    />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: desktop ? "row" : "column",
                }}
              >
                <Typography variant="caption" color="#0D0C22">
                  Активность
                  {required}
                </Typography>
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <Controller
                        name="isActive"
                        control={control}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <WrappedSelect
                                multiple={false}
                                options={booleanSelect}
                                value={value}
                                onChange={onChange}
                                sx={{ height: "36px", width: "80px" }}
                            />
                        )}
                    />
                </Box>
              </Box>
                
            </Box>
            <Box
            sx={{
              display: "flex",
              flexDirection: desktop ? "row" : "column",
              justifyContent: "space-between",
              padding: desktop ? "50px 0px 40px 0px" : "30px 23px 10px 23px",
            }}
          >
            <Box
                sx={{
                display: "flex",
                gap: "10px",
                marginTop: "20px",
                maxWidth: "536px",
                }}
            >
                <WrappedButton
                    label="Сохранить промокод"
                    variant="contained"
                    sx={{
                        width: "100%",
                        minWidth: 207,
                        color: "#FFFFFF",
                        background:
                        "linear-gradient(94deg, #FF6F83 0.78%, #F61735 105.69%)",
                    }}
                    type="submit"
                />
                <Link to={"/adminpanel/sales/promocodes"}>
                <WrappedButton
                    label="Отмена"
                    variant="contained"
                    typeBorder="RoundGradient"
                    sx={{
                    width: "100%",
                    minWidth: 156,
                    color: "#F72642",
                    backgroundColor: "#FFFFFF",
                    whiteSpace: "nowrap",
                    }}
                />
                </Link>
            </Box>
          </Box>
        </form>
      </Box>);
}