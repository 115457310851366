import { api } from "../api";
import { Response } from "../type";
import { UserInfo } from "./auth";
import { TSearchData } from "../../component/DataTable/types";

export type RigisterDataInfo = {
  email: string;
  phoneNumber: string;
  name: string;
  password: string;
  organization: {
    name: string;
    address: string;
    serviceName: string;
  };
};

export namespace User {
  //Генерирует код для сброса пароля и совершает дозвон
  export async function forgotPassword(phone: string) {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "post",
        url: `/admin/user/forgotPassword`,
        data: { phone },
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  //Повторный запрос кода регистрации
  export async function resendRegistrationCode(phone: string) {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "post",
        url: `/admin/user/resendRegistrationCode`,
        data: { phone },
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Генерирует код для смены номера и совершает дозвон
  export async function requestChangePhoneNumber(phone: string) {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "post",
        url: `/admin/user/requestChangePhoneNumber`,
        data: { phone },
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Смена номера
  export async function changePhoneNumber(phone: string, code: string) {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "post",
        url: `/admin/user/changePhoneNumber`,
        data: { phone, code },
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Проверяет код восстановления пароля
  export async function checkResetPasswordCode(phone: string, code: string) {
    return api
      .sendRequest<Response<{ userRequestId: number; token: string }>>({
        domain: "on-menu",
        method: "post",
        url: `/admin/user/checkResetPasswordCode`,
        data: { phone, code },
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Меняем пароль пользователя
  export async function resetPassword(request: {
    userRequestId: number;
    token: string;
    newPassword: string;
  }) {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "put",
        url: `/admin/user/resetPassword`,
        data: request,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Регистрация нового пользователя
  export async function register(request: RigisterDataInfo) {
    return api
      .sendRequest<Response<{ errors: Record<string, string[]> }>>({
        domain: "on-menu",
        method: "post",
        url: `/admin/user/register`,
        data: request,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Проверяет код восстановления пароля
  export async function confirmRegistrationCode(phone: string, code: string) {
    return api
      .sendRequest<Response<{ userRequestId: number; token: string }>>({
        domain: "on-menu",
        method: "post",
        url: `/admin/user/confirmRegistrationCode`,
        data: { phone, code },
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Создание пользователя
  export async function createUser(
    request: {
      surname: string,
      patronymic: string,
      password: string,
      phoneNumber: string,
      name: string
    },
  ) {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "post",
        url: `/admin/user`,
        data: request,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Обновление пользователя
  export async function updateUser(
    request: UserInfo & { changedField: string },
  ) {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "put",
        url: `/admin/user`,
        data: request,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Обновление пользователя админом
  export async function updateUserAdmin(
    request: {
      id: number,
      name: string,
      surname: string,
      patronymic: string,
      isActive: boolean,
    },
  ) {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "put",
        url: `/admin/user/updateadmin`,
        data: request,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  //Смена пароля
  export async function changePassword(request: {
    userId: number;
    newPassword: string;
    newPasswordRepeat: string;
  }) {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "put",
        url: `/admin/user/changePassword`,
        data: request,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  //Изменение логотипа организации текущего пользователя
  export async function changeLogo(formData: FormData) {
    return api.sendRequest<Response<string>>({
      domain: "on-menu",
      method: "put",
      url: `/admin/user/changeLogo`,
      data: formData,
    });
  }
  //Подтверждение email пользователя после регистрации
  export async function confirmEmail(userId: string, token: string) {
    return api.sendRequest({
      domain: "on-menu",
      method: "post",
      url: `/admin/user/confirmEmail?userId=${userId}&token=${token}`,
    });
  }
  //Возвращает постраничный список пользователей
  export async function search(data: TSearchData) {
    return api.sendRequest({
      domain: "on-menu",
      method: "post",
      url: `/admin/user/search`,
      data
    });
  }
  //Возвращает пользователя по ID
  export async function getUser(id: number) {
    return api.sendRequest({
      domain: "on-menu",
      method: "get",
      url: `/admin/user/${id}`,
    });
  }
}