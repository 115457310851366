import { useMediaQuery, BoxProps } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import { Box } from "../../../components";

/**Обычный контейнер для основного контента */
function ContentContainer({ maxWidth = 1280, sx, children }: BoxProps & { maxWidth?: number }) {
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

    return(
        <Box
            sx={[
                () => ({
                    display: "flex",
                    justifyContent: "center",
                    padding: desktop ? "0 123px" : "0 10px"
                }),
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
        >
            <Box
                style={{
                    flexGrow: 1,
                    maxWidth,
                }}
            >
                { children }
            </Box>
        </Box>
    );
}

export default ContentContainer;