import { PropsWithChildren } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { theme as themeRNS } from "../../theme/theme";
import { Theme } from "@emotion/react";

function RNSThemeProvider({
  theme = themeRNS,
  children,
}: { theme: Theme } & PropsWithChildren) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

export default RNSThemeProvider;
