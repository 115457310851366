import { Box } from "../../../../../components";
import { BoxProps } from "@mui/material";

const AttachedFileIcon = ({ ...props }: BoxProps) => (
  <Box {...props}>
    <svg
      width="12"
      height="23"
      viewBox="0 0 12 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 17.2097L2 6.62971C2 4.53971 3.53 2.67971 5.61 2.47971C7.99 2.24971 10 4.11971 10 6.45971L10 18.8197C10 20.1297 9.06 21.3197 7.76 21.4497C6.26 21.5997 5 20.4297 5 18.9597L5 8.45971C5 7.90971 5.45 7.45971 6 7.45971C6.55 7.45971 7 7.90971 7 8.45971L7 17.2097C7 17.6197 7.34 17.9597 7.75 17.9597C8.16 17.9597 8.5 17.6197 8.5 17.2097L8.5 8.59971C8.5 7.28971 7.56 6.09971 6.26 5.96971C4.76 5.81971 3.5 6.98971 3.5 8.45971L3.5 18.7897C3.5 20.8797 5.03 22.7397 7.11 22.9397C9.5 23.1697 11.5 21.2997 11.5 18.9597L11.5 6.68971C11.5 3.81971 9.4 1.24971 6.54 0.979709C3.25 0.679708 0.5 3.23971 0.5 6.45971L0.499999 17.2097C0.499999 17.6197 0.839999 17.9597 1.25 17.9597C1.66 17.9597 2 17.6197 2 17.2097Z"
        fill="url(#paint0_linear_3007_54)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3007_54"
          x1="11.5"
          y1="0.95578"
          x2="4.77124"
          y2="24.1956"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED6985" />
          <stop offset="1" stopColor="#674099" />
        </linearGradient>
      </defs>
    </svg>
  </Box>
);

export default AttachedFileIcon;
