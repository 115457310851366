export type TMenuItem = {
  title: string;
  link: string;
  defaultSelect?: boolean;
};

export const menuItems: TMenuItem[] = [
  {
    title: "Общие настройки",
    link: "settings",
  },
  {
    title: "Конструктор",
    link: "constructor",
  },
  {
    title: "История изменений",
    link: "changeHistory",
  },
];
