import { Box } from "../../../../components";
import { styled } from "@mui/material";
import Category from "./Category";
import AddInfo from "./AddInfo";
import { TMenuPosition } from "../../../api/request/menuTemplate";
import { notEmptyString } from "../../../utils";
import useImage from "../../../utils/useImage";
import { EFeatures } from "./Category";

export type DetailContentProps = Pick<TMenuPosition, 'id'|'description'|'ingredients'|
    'photo'|'carbohydrates'|'fats'|'proteins'|'foodValue'|'spicy'|'vegan'|'popular'> & {
    lightTheme: boolean;
    amount: string | null;
    openModal: (img: string) => void;
    isNew: boolean;
};

const Thumb = styled('img')({
    width: '145px',
    height: '150px',
    borderRadius: '8px',
    cursor: 'pointer'
});

/**Подробное описание блюда */
function DetailContent({ id, description, ingredients, photo, carbohydrates,
    fats, proteins, foodValue, lightTheme, openModal, amount, isNew, popular, spicy, vegan }: DetailContentProps) {

    const addInfo = { carbohydrates, fats, proteins, amount, energy: foodValue };
    const hasInfo = Object.values(addInfo).find(info => !!info);

    const { photoFile } = useImage({ id: photo?.guid });

    const features = [
        {key: EFeatures.NEW, value: isNew},
        {key: EFeatures.POPULAR, value: popular},
        {key: EFeatures.SPICY, value: spicy},
        {key: EFeatures.VEGAN, value: vegan}].filter(x => x.value).map(x => x.key);

    return(
        <>
            { !!features?.length &&
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        height: '20px',
                        padding: '5px 0 2px 2px',
                        gap: '14px',
                    }}
                >
                    { features.map(feature =>
                        <Category key={feature} lightTheme={lightTheme} type={feature} />) }
                </Box> }
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    fontSize: "0.75rem",
                    fontWeight: 400,
                    lineHeight: "0.875rem",
                    letterSpacing: "-0.02em",
                    marginTop: '16px'
                }}
            >
                <Box sx={{ paddingRight: '16px', letterSpacing: '0.4px', }}>
                    { notEmptyString(description) &&
                        <>
                            { description }
                        </> }
                    { hasInfo && <AddInfo lightTheme={lightTheme} { ...addInfo } /> }
                </Box>
                { photoFile && <Thumb onClick={() => openModal(photoFile)} src={photoFile.toString()} alt={"image" + id}/> }
            </Box>
        </>        
    );
}

export default DetailContent;