import React from "react";
import { Box, InputProps, InputText, Typography } from "../../../components";

interface WrapperInputType extends Omit<InputProps, "label" | "error"> {
  label?: string;
  colorType?: "black" | "white";
  height?: string;
  borderColor?: string;
  borderRadius?: string;
  backgroundColor?: string;
  error?: string;
  shadowHeight?: number;
  inputPadding?: number;
}

const WrapperInput: React.FC<WrapperInputType> = ({
  label,
  borderColor = "linear-gradient(-45deg, #674099,#ED6985)",
  colorType = "white",
  borderRadius = "60px",
  backgroundColor = "#272727",
  sx = [],
  error,
  ...props
}) => {
  if (colorType === "white") {
    backgroundColor = "#FFFFFF";
  }
  const color = colorType === "white" ? "#0D0C22" : "#FFFFFF";
  const shadowHeight = props.shadowHeight ?? 22;
  const inputPadding = props.inputPadding ?? 7;

  return (
    <Box sx={{ flexDirection: "column", width: "100%" }}>
      <InputText
        label={<span>{label}</span>}
        sx={[
          (theme) => ({
            position: "relative",
            fieldset: {
              border: `none !important`,
            },
            "&:hover fieldset": {
              border: `none !important`,
            },
            label: {
              background:
                props.value && `${theme.palette.primary.main} !important`,
              lineHeight: "normal",
            },
            "& span": {
              backgroundImage: !error && borderColor,
              WebkitBackgroundClip: !error && "text",
              WebkitTextFillColor: !error && "transparent",
              color: (error && `${theme.palette.error.main}`),
            },
            "& label.Mui-focused": {
              background: `${theme.palette.primary.main} !important`,
              lineHeight: "normal",
            },
            ".MuiInputBase-root": {
              height: props.height,
              borderRadius: borderRadius,
              backgroundColor: backgroundColor,
              color: color,
              ":hover": {
                border: "none",
              },
            },
            "&:after": {
              position: "absolute",
              top: -2,
              left: -2,
              right: -2,
              bottom: -2,
              background: error ? `${theme.palette.error.main}` : borderColor,
              opacity: props.disabled ? 0.4 : 1,
              content: '""',
              zIndex: -1,
              borderRadius: borderRadius,
            },
            "&:focus-within:after": {
              filter: "blur(2px)",
            },
            "input:-webkit-autofill": {
              WebkitBoxShadow: `0 0 0 ${shadowHeight}px ${backgroundColor} inset !important`,
              caretColor: `${color} !important`,
              WebkitTextFillColor: color,
              "padding": `${inputPadding}px 14px !important`,
              // WebkitBoxShadow: `0 0 0 30px #272727 inset !important`,
               borderRadius: props?.InputProps?.endAdornment
                 ? `${borderRadius} 0 0 ${borderRadius}`
                 : "inherit",
            },
            "button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd div":
              {
                fontSize: 0,
              },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
      />
      {!!error && (
        <Typography
          variant="body1"
          color="#FF5252"
          sx={{
            whiteSpace: "pre-wrap",
            marginRight: "auto",
            paddingLeft: "10px",
            position: "absolute",
          }}
        >
          {error}
        </Typography>
      )}
    </Box>
  );
};

export default WrapperInput;
