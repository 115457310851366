import { Box } from "../../../../../components";
import { BoxProps } from "@mui/material";

const StarIcon = ({ ...props }: BoxProps) => (
  <Box {...props}>
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0704 6.3428L9.68205 1.77157C9.40816 0.874321 8.14257 0.874321 7.87811 1.77157L6.4803 6.3428H2.2774C1.36127 6.3428 0.983479 7.52339 1.72961 8.0523L5.16748 10.5079L3.81689 14.8619C3.54299 15.7403 4.56302 16.4486 5.29026 15.8914L8.77536 13.2469L12.2605 15.9008C12.9877 16.4581 14.0077 15.7497 13.7338 14.8714L12.3832 10.5174L15.8211 8.06174C16.5672 7.52339 16.1895 6.35225 15.2733 6.35225H11.0704V6.3428Z" stroke="currentColor" strokeWidth="1.3" strokeLinejoin="round"/>
    </svg>
  </Box>
);

export default StarIcon;
