import { daysWeek } from "../../pages/personalArea/type";
import { api } from "../api";
import { Response } from "../type";

export type WorkHoursType = Record<
  keyof typeof daysWeek,
  { start: string; end: string, dayOfWeek: 1|2|3|4|5|6|7 } | null
>;

export type OrganizationType = {
    id: number;
    workhours: WorkHoursType;
    workhoursString: string;
    serviceName: string;
    logoId: string | null;
    qrCodeId: string | null;
    qrSvgId: string | null;
    phoneNumber: string;
    website: string;
    telegram: string;
    whatsApp: string;
    name: string;
    address: string;
}

export type UserInfo = {
  organization: OrganizationType | null;
  order: {
    created: Date;
    finishDate: Date;
    period: number;
    periodName: string;
    tariffId: number;
    tariffName: string;
    autoProlongation: boolean;
    isPaid: boolean;
    isTrial: boolean;
    promo: string;
  };
  id: number;
  role: {
    name: string;
    description: string;
    id: number;
    title: string;
  };
  isActive: boolean;
  isPhoneActive: boolean;
  isEmailConfirmed: boolean;
  surname: string;
  patronymic: string;
  email: string;
  phoneNumber: string;
  name: string;
  created: string;
  modified: string;
};

export type LoginParams = {
  userName: string;
  password: string;
};

export namespace Auth {
  export async function getCurrent(): Promise<Response<UserInfo>> {
    const { data } = await api.requestWithoutCatch({
      domain: "on-menu",
      method: "get",
      url: "/auth/current",
    });
    return data;
  }
  export async function login(
    params: LoginParams,
  ): Promise<Response<{ token: string; currentUser: UserInfo }>> {
    const { data } = await api.requestWithoutCatch({
      domain: "on-menu",
      method: "post",
      url: "/auth/login",
      data: params,
    });
    return data;
  }
  export async function logout() {
    return api
      .sendRequest<Response>({
        domain: "on-menu",
        method: "post",
        url: `/auth/logout`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
}
