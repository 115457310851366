import { Accordion as StyledAccordion } from "../../../../components";
import { AccordionProps } from "@mui/material";

function Accordion({ lightTheme, sx, ...props }: { lightTheme: boolean } & AccordionProps) {
    return(
        <StyledAccordion
            { ...props }
            sx={{
                borderRadius: "8px !important",
                background: lightTheme ? "#FFFFFF" : "#040404",
                boxShadow: 'unset',
                ...(Array.isArray(sx) ? sx : [sx])
            }}
            disableGutters
        />
    );
}

export default Accordion;