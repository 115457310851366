export type PromoCodeCardForm = {
    id?: number,
    code: string,
    type: number,
    isActive: boolean,
    isOneTime: boolean,
    discountValue: string,
    agentId?: number,
}

export const PROMOCODE_DEFAULT: PromoCodeCardForm = {
    code: "",
    type: 2,
    isActive: true,
    isOneTime: false,
    discountValue: "",
    agentId: 0
};