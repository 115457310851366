import React from "react";
import { DatePicker } from "../../../components";
import { DatePickerProps as DatePickerPropsMui } from "@mui/x-date-pickers/DatePicker";

const WrapperDatePicker: React.FC<DatePickerPropsMui<moment.Moment>> = ({
  sx = [],
  ...props
}) => {
  return (
    <DatePicker
      variant="outlined"
      label={""}
      format="DD.MM.YYYY"
      sx={[
        (theme) => ({
          position: "relative",
          borderRadius: "60px",
          backgroundColor: "#FFFFFF",
          "& .MuiInputBase-root": {
            height: "36px",
            color: "#0D0C22!important",
          },
          "& button": {
            color: "#0D0C22!important",
          },
          fieldset: {
            border: `none !important`,
          },
          "&:hover fieldset": {
            border: `none !important`,
          },
          "&:after": {
            position: "absolute",
            top: -2,
            left: -2,
            right: -2,
            bottom: -2,
            background: "linear-gradient(-45deg, #674099,#ED6985)",
            opacity: props.disabled ? 0.4 : 1,
            content: '""',
            zIndex: -1,
            borderRadius: "60px",
          },
          "&:focus-within:after": {
            filter: "blur(2px)",
          },
          "input:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 30px #272727 inset !important`,
          },
          "button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd div":
            {
              fontSize: 0,
            },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
};

export default WrapperDatePicker;
