import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Box } from "../../components";
import { useEffect } from "react";
import { fetchUserData, useUserData } from "../store/user";
import { useDispatch } from "../store/model/extensions";
import { AuthorizationHeader } from "../component/Header";
import { AuthorizationFooter } from "../component/Footer";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../theme/theme";

const PrivateAuthorizationRouter = () => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const { loading } = useUserData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (loading !== "successed") {
      dispatch(fetchUserData());
    }
  }, []);

  if (loading === "pending") return <div>Loading...</div>;
  if (loading === "failed") {
    navigate("/login", { state: location.pathname });
  }

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <AuthorizationHeader />
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
            display: "flex",
            height: "100%",
            zIndex: 1,
            position: "relative",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Outlet />
          </Box>
          <Box
            sx={[
              (theme) => ({
                padding: desktop ? "29px 123px" : "12px 29px",
                backgroundColor: theme.palette.primary.main,
              }),
            ]}
          >
            <AuthorizationFooter />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateAuthorizationRouter;
