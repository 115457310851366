import { styled } from "../../../../components";

export type BlockNameProps = {
    label: string;
};

function BlockName({ label }: BlockNameProps) {
    const TDStyled = styled('td')({
        fontSize: "1rem",
        fontWeight: 500,
        lineHeight: "1.625rem",
        color: "#0D0C22",
        marginBottom: "9px"
    });

    return(
        <tr>
            <TDStyled colSpan={2}>
                { label }
            </TDStyled>
        </tr>
    );
}

export default BlockName;