import { Checkbox, FormControlLabel } from "../../../components";
import React from "react";
import checkedIcon from "shared/assets/img/checked.svg";
import { FormControlLabelProps } from "@mui/material";

interface WrappedCheckboxProps extends Omit<FormControlLabelProps, "control"> {
  checked: boolean;
  onCheckChange: (value: boolean) => void;
}

const WrappedCheckbox: React.FC<WrappedCheckboxProps> = ({
  checked,
  onCheckChange,
  sx = [],
  ...props
}) => {
  return (
    <FormControlLabel
      sx={[
        (theme) => ({
          paddingRight: "5px",
          alignItems: "start",
          marginLeft: 0,
          marginRight: 0,
          "& input": {
            width: "24px",
          },
          "& span:first-child": {
            padding: "0 8px 0 0",
          },
          "& span:first-child::before": {
            content: checked ? `url("${checkedIcon}")` : "none",
            padding: checked ? "3px" : "0",
            width: "18px",
            height: "18px",
            fontSize: "0",
          },
          "& span:first-child:hover": {
            backgroundColor: 'inherit'
          },
          "& span:first-child svg": {
            display: checked ? "none" : "block",
          },
          ".MuiCheckbox-root": {
            color: "rgba(13,12,34,0.6)",
          },
          "& span:last-child": {
            color: "#0D0C22",
            fontWeight: 300,
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      control={
        <Checkbox
          size="normal"
          sx={{
            padding: "0px 10px",
          }}
          checked={checked}
          onChange={(event) => onCheckChange(event.target.checked)}
        />
      }
      {...props}
    />
  );
};

export default WrappedCheckbox;
