import { Box, Typography } from "../../../../components";
import { useMediaQuery } from "@mui/material";
import { Check, RadioButtonUnchecked } from "@mui/icons-material";
import { darkTheme } from "../../../theme/theme";
import { TariffsDataType } from "../blocks/tariffSelect";
import { CheckCircleOutline } from "@mui/icons-material";

type WrapperTariffInterface = TariffsDataType & {
  isSelected: boolean;
  onSelect: () => void;
};

const TariffBlock = ({
  label,
  options,
  isSelected,
  onSelect
}: WrapperTariffInterface) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  const getOptions = () => {
    return (
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {options.map((value, index) => {
          return (
            <Box key={index} sx={{ display: "flex", gap: "8px" }}>
              <Check
                sx={{ color: "#4BB7BF" }}
                fontSize={desktop ? "inherit" : "small"}
              />
              <Typography
                sx={{ color: "#FFFFFF" }}
                variant={desktop ? "body1" : "body2"}
              >
                {value}
              </Typography>
            </Box>
          );
        })}
      </Box>
    );
  };

  return (
    <Box
      onClick={onSelect}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: desktop ? "15px" : "5px",
        justifyContent: "space-between",
        padding: "10px",
        background: isSelected ? "#2E2E2E" : "#161616",
        borderRadius: "5px",
        width: "100%",
        height: !desktop ? "310px" : "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: desktop ? "15px" : "5px",
        }}
      >
        <Typography
          sx={{ color: "#FFFFFF", fontWeight: "500" }}
          variant="subtitle2"
        >
          {label}
        </Typography>
        {getOptions()}
      </Box>
      {isSelected ? (
        <CheckCircleOutline sx={{ color: "#FFF" }} />
      ) : (
        <RadioButtonUnchecked sx={{ color: "#FFF" }} />
      )}
    </Box>
  );
};

export default TariffBlock;
