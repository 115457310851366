import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import { Box } from "../../../components";
import LeftNavMenu from "./LeftNavMenu";
import MobileNavMenu from "./MobileNavMenu";
import { TMenuItem } from "../../pages/adminPanel/menuItems";
import useScrollToAnchor from "./useScrollToAnchor";
import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { TMobileLinkStyle } from "./MobileNavMenu/link";

export type NavMenuProps = {
    menuItems: TMenuItem[];
    mobileLinkStyle?: TMobileLinkStyle;
};

export type TNavMenuSelected = string|null|undefined

export type TNavMenuContext = {
    selected: TNavMenuSelected;
    selectedId: TNavMenuSelected;
    setSelected: (value: string) => void;
    setSelectedId: (value: string) => void;
};

export const NavMenuContext = createContext<TNavMenuContext>({
    selected: null,
    selectedId: null,
    setSelected: () => {},
    setSelectedId: () => {},
});

function NavMenu({ menuItems, mobileLinkStyle }: NavMenuProps) {
    const location = useLocation();
    const defaultSelect = menuItems.find(item => item.defaultSelect)?.link;
    const [ selected, setSelected ] = useState<TNavMenuSelected>(null);
    const [ selectedId, setSelectedId ] = useState<TNavMenuSelected>(null);

    /**Элемент с defaultSelect: true будет выделен, даже если путь не совпадает ни с одной из ссылок */
    useEffect(() => {
        //Для надёжности в selected хранится полный путь
        if (defaultSelect && !selected) setSelected(location.pathname + defaultSelect);
    }, [ selected, defaultSelect ]);
    
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    useScrollToAnchor();

    return(
        <NavMenuContext.Provider value={{ selected, selectedId, setSelected, setSelectedId }}>
            { desktop ?
                <Box sx={{ width: '312px' }}>
                    <LeftNavMenu menuItems={menuItems}/>
                </Box> :
                <MobileNavMenu
                    menuItems={menuItems}
                    mobileLinkStyle={mobileLinkStyle}
                /> }
        </NavMenuContext.Provider>
    );
}

export default NavMenu;