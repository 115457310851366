import normalizeCount from "../../utils/normalizeCount";
import { styled } from '@mui/material/styles';
import InputTypography from "./InputTypography";

const Container = styled('div')({
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4px'
});

export type ChipsCounterProps = {
    filesCount: number;
};

function ChipsCounter({ filesCount }: ChipsCounterProps) {
    return(
        <Container>
            <InputTypography>
                (+{ normalizeCount(filesCount - 1, ['файл', 'файла' , 'файлов'] ) })
            </InputTypography>
        </Container>
    );
}

export default ChipsCounter;