import { useEffect, useState } from "react";
import { file } from "../api/request";

export type TUseImage = {
    id?: string
}

function useImage({ id }: TUseImage) {
    const [ photoFile, setPhotoFile ] = useState<string|null>(null);

    useEffect(() => {
        if (!id) return;
        file.getGuidFile(id)
            .then(result => setPhotoFile(URL.createObjectURL(result.data)));
            //.then(result => setPhotoFile(result as string));
    }, [ id ]);

    return { photoFile };
}

//Считывание изображения из ответа сервера
export function readImage(loadedPhoto: Blob): Promise<ArrayBuffer|string|null>{
    return new Promise((resolve, reject) => {
        const reader = new FileReader();  
        reader.onload = () => resolve(reader.result);
        reader.onerror = () => reject();
        reader.readAsDataURL(loadedPhoto);
    });
  }

export default useImage;