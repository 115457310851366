import { Logging } from "../../../api/request/logging";
import DataTable from "../../../component/DataTable";
import { TColumn } from "../../../component/DataTable/types";
import { LoggingResponseItem } from "./types";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import ActionSuccess from "./ActionSuccess";

const getColumns = (desktop: boolean): TColumn<LoggingResponseItem>[] => ([
    { name: 'ID', id: 'userId' },
    { name: 'IP-адрес', id: 'ipAddress' },
    { name: 'Дата', id: 'dateTime', renderComponent: ({ value }) =>
        <>{ new Date(value).toLocaleDateString('ru') }</> },
    { name: 'Пользователь', id: 'userName' },
    { name: 'Раздел', id: 'moduleName' },
    { name: 'Действие', id: 'actionName', sortId: 'action' },
    { name: 'Успешность действия', id: 'isSuccess', renderComponent: ({ row }) =>
        <ActionSuccess row={row} desktop={desktop}/> }
]);

function OperationJournal() {
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    const columns = getColumns(desktop);

    return(
        <>
            <DataTable<LoggingResponseItem>
                requestData={Logging.search}
                columns={columns}
                desktopTableProps={{
                    bodyCell: () => ({
                        sx: { cursor: 'pointer' }
                    }),
                    headCell: ({ column }) => ({
                        sx: (column?.id === 'modified') ?
                            { minWidth: '155px' } : {}
                    })
                }}
                searchOptions={{sortItem: { fieldName: "name", direction: 0}}}
            />
        </>
    );
}

export default OperationJournal;