import { Box } from "../../../../../components";
import { BoxProps } from "@mui/material";

const HelpIcon = ({ ...props }: BoxProps) => (
  <Box {...props}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 19H11V17H13V19ZM15.07 11.25L14.17 12.17C13.67 12.68 13.31 13.14 13.13 13.86C13.05 14.18 13 14.54 13 15H11V14.5C11 14.04 11.08 13.6 11.22 13.19C11.42 12.61 11.75 12.09 12.17 11.67L13.41 10.41C13.87 9.97 14.09 9.31 13.96 8.61C13.83 7.89 13.27 7.28 12.57 7.08C11.46 6.77 10.43 7.4 10.1 8.35C9.98 8.72 9.67 9 9.28 9H8.98C8.4 9 8 8.44 8.16 7.88C8.59 6.41 9.84 5.29 11.39 5.05C12.91 4.81 14.36 5.6 15.26 6.85C16.44 8.48 16.09 10.23 15.07 11.25Z"
        fill="url(#paint0_linear_666_6383)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_666_6383"
          x1="2"
          y1="2"
          x2="24.4251"
          y2="5.24598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED6985" />
          <stop offset="1" stopColor="#674099" />
        </linearGradient>
      </defs>
    </svg>
  </Box>
);

export default HelpIcon;
