import { Modal, Box, Typography, IconButton } from "../../../../components";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import WrapperButton from "../../../component/Button/WrappedButton";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";

type AutomaticPaymentModalInterface = {
  onClose: () => void;
  onChange: () => void;
  isActive: boolean;
};

const AutomaticPaymentModal = ({
  onClose,
  onChange,
  isActive,
}: AutomaticPaymentModalInterface) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  const handlerRequest = () => {
    onClose();
  };

  return (
    <Modal
      open
      onClose={onClose}
      sx={[
        (theme) => ({
          left: desktop ? "" : "0",
          right: desktop ? "" : "unset",
          bottom: desktop ? "" : 0,
          top: desktop ? "" : "unset",
          position: desktop ? "relative" : "fixed",
          transform: desktop ? "" : "unset",
          backgroundColor: theme.palette.primary.main,
          padding: "25px !important",
          width: desktop ? "450px" : "100%",
          borderRadius: "10px",
          borderColor: theme.palette.primary.main,
          gap: "25px",
          borderBottomLeftRadius: desktop ? "" : "unset",
          borderBottomRightRadius: desktop ? "" : "unset",
        }),
      ]}
      header={
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 500, color: "#FFFFFF" }}
          >
            {"Автоматическая оплата текущего тарифа"}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", top: "2px", right: "2px" }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Box>
      }
      body={<></>}
      footer={
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            margin: "auto",
          }}
        >
          <WrapperButton
            label={"Отмена"}
            sx={{ height: "36px" }}
            variant="contained"
            size={"normal"}
            onClick={onClose}
          />
          <WrapperButton
            label={isActive ? "Отключить автоплатеж" : "Подключить автоплатеж"}
            sx={{ height: "36px" }}
            variant="contained"
            size={"normal"}
            onClick={onChange}
          />
        </Box>
      }
    >
      <span></span>
    </Modal>
  );
};

export default AutomaticPaymentModal;
