import { useState } from "react";

export type UseModalProps<T> = {
    initOpen?: boolean;
    initData?: T|null;
};

/**Состояние модального окна:
 * открыто/закрыто и какие данные получает
 */
function useModal<T>(props?: UseModalProps<T>) {
    const initOpen = props?.initOpen || false;
    const initData = props?.initData || null;
    const [ isOpen, setIsOpen ] = useState(initOpen);
    const [ data, setData ] = useState<T|null|undefined>(initData);

    //Методы для простого открытия/закрытия модального окна
    function openModal(dataToOpen: T|null) {
        setData(dataToOpen);
        setIsOpen(true);
    }

    function closeModal() {
        setData(null);
        setIsOpen(false);
    }

    return { isOpen, data, setIsOpen, setData, openModal, closeModal };
}

export default useModal;