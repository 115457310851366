import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import alertSlice from "./alert";
import breadCrumbsSlice from "./breadCrumbs";
import userSlice from "./user";
import templateCardSlice from "./templateCard";

const rootReducer = combineReducers({
  alert: alertSlice,
  breadCrumbs: breadCrumbsSlice,
  user: userSlice,
  templateCard: templateCardSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: {},
    }),
});

export type Store = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};
