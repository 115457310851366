import { createSlice } from "@reduxjs/toolkit";
import { getSliceDataSelectorFactory } from "../../utils";
import { useDispatch } from "../model/extensions";
import { useSelector } from "react-redux";

export type arrayUrlType = { url?: string; name: string };

type initialStateType = {
  data: arrayUrlType[]
};

const initialState: initialStateType = {
  data: [{ name: "" }],
};

export const breadCrumbsSlice = createSlice({
  name: "breadCrumbs",
  initialState,
  reducers: {
    setBreadCrumbs(state, action) {
      state.data = action.payload
    },

  },
});

export default breadCrumbsSlice.reducer;

const selectorFactory = getSliceDataSelectorFactory("breadCrumbs");
const selectData = selectorFactory((state) => state.data);

export function useBreadCrumbs() {
  const dispatch = useDispatch();
  const breadCrumbs = useSelector(selectData);

  const setBreadCrumbs = (props: arrayUrlType[]) => {
    dispatch(breadCrumbsSlice.actions.setBreadCrumbs(props));
  };


  return {
    breadCrumbs,
    setBreadCrumbs,
  };
}
