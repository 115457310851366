import Head from "./head";
import RestaurantMenu from "./restaurantMenu";
import Container from "./container";
import { Box } from "../../../components";
import useModal from "./useModal";
import PictureModal from "./PictureModal";
import { useEffect, useState } from "react";
import Groups, { TGroupItem } from "./Groups";
import useMenuData from "./useMenuData";
import { TMenuGroup } from "../../api/request/menuTemplate";
import PageContainer from "./PageContainer";
import ScreenLoader from "../../component/Loader/ScreenLoader";
import useImage from "../../utils/useImage";
import { useParams } from "react-router-dom";
import ExpiredSubscription from "./ExpiredSubscription";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";

/**Опубликованное меню */
function PublicMenu() {
    const [ language, setLanguage ] = useState<number>(1);
    const { code } = useParams();
    const { menuData } = useMenuData({ language, code });
    const { photoFile: logo } = useImage({ id: menuData?.organizationLogoId });
    const { isOpen, data, closeModal, openModal } = useModal<string>();
    const lightTheme = menuData?.theme === 1;
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

    const [ group, setGroup ] = useState(-1);

    useEffect(() => {
        document.documentElement.style.backgroundColor = lightTheme ? '#FFFFFF' : '#040404'
    }, [ lightTheme ]);

    const groups = createGroups(menuData?.groups || []);
    const groupId = group;

    const renderMenu = group === -1 ?
        menuData?.positions :
        menuData?.positions.filter(p => p.groups.some(x => x === groupId));

    return(
        <>
            <PageContainer lightTheme={lightTheme}>
                <Container sx={{
                    padding: "16px 10px"
                }} >
                    { menuData &&
                        <Head
                            workhoursString={menuData?.status == 2 ? menuData.workhoursString : ""}
                            organizationAddress={menuData.organizationAddress}
                            organizationName={menuData.organizationName}
                            phoneNumber={menuData.phoneNumber}
                            telegram={menuData.telegram}
                            whatsApp={menuData.whatsApp}
                            website={menuData.website}
                            lightTheme={lightTheme}
                            logo={logo}
                            link={menuData.link}
                            languages={menuData.languages}
                            language={language}
                            desktop={desktop}
                            setLanguage={setLanguage}
                        /> }
                </Container>

                {menuData?.status == 2 && <Groups
                    items={groups}
                    group={group}
                    setGroup={setGroup}
                    lightTheme={lightTheme}
                    templateId={menuData.id}
                />}
                
                <Box sx={{
                    margin: "0",
                    marginTop: "16px",
                    fontFamily: 'Inter',
                }}>
                    { renderMenu && menuData?.status == 2 &&
                        <RestaurantMenu
                            openModal={openModal}
                            menu={renderMenu}
                            lightTheme={lightTheme}
                            groupId={group}
                        /> }
                    { menuData?.status === 3 && <ExpiredSubscription lightTheme={lightTheme} />}
                </Box>

                { data &&
                    <PictureModal
                        image={data}
                        open={isOpen}
                        onClose={closeModal}
                    /> }
            </PageContainer>
            { !menuData && <ScreenLoader/> }
        </>
    );
}

//Привести данные групп к нужному виду и добавить "Все"
function createGroups(items: TMenuGroup[]) {
    const groups: TGroupItem[] = items.map(g => ({
        title: g.name,
        id: g.id,
    })) || [];
    
    const menuItems: TGroupItem[] = [
        {
            title: 'Все',
            defaultSelect: true,
            id: -1
        }
    ];

    return [ ...menuItems, ...groups ];
}

export default PublicMenu;