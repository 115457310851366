export interface AdminCreationForm {
  name: string;
  surname: string;
  patronymic: string;
  isActive: boolean;
  roleId: number;
  password: string;
  email: string;
  phoneNumber: string;
}

export const ROLE_OPTIONS: { value: string | number, label: string }[] =
  [{ value: 1, label: "Администратор" },
  { value: 2, label: "Работник заведения" }];