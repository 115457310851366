import {
  Box,
  Breadcrumbs,
  Button,
  Typography,
  IconButton,
  Grid2 as Grid,
} from "../../../components";
import { HeadsetMicOutlined, MarkunreadOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
//import { logOut, useUserData } from "../../../store/user";
import logo from "shared/assets/img/on-menu.svg";
import company from "shared/assets/img/company.svg";
//import Grid from "@mui/material/Unstable_Grid2";
import LinkRouter from "../LinkRouter";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import LogoutIcon from "@mui/icons-material/Logout";
import { SxProps } from "@mui/material";
import { Theme } from "@emotion/react";
import { useEffect, useState } from "react";
import { keyframes } from "@mui/system";
import moment from "moment";
import WrapperButton from "../Button/WrappedButton";

const AuthorizationFooter = () => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  // const { userData } = useUserData();

  return (
    <Box
      component={"footer"}
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "center",
        }),
      ]}
    >
      <Grid
        container
        justifyContent={desktop ? "space-between" : "center"}
        alignItems={"center"}
        maxWidth={1280}
        gap={"20px 0px"}
        width={"100%"}
        //margin="auto"
      >
        <Grid mobile={12} desktop={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <img
              style={{
                width: desktop ? "165px" : "100px",
                margin: !desktop ? "auto" : "",
              }}
              src={logo}
              alt="Логотип"
            />
            {desktop && (
              <Typography variant="caption" color="#FFFFFF">
                {`© OnMenu ${moment().year()}, все права защищены`}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid
          container
          mobile={12}
          desktop={6}
          rowSpacing={2}
          columnSpacing={7}
          maxWidth={460}
          gap={"10px 0px"}
        >
          <Grid mobile={12} desktop={12}>
            <Typography
              variant="subtitle2"
              color="#FFFFFF"
              sx={(theme) => ({
                textAlign: "center",
              })}
            >
              Есть <strong>вопрос</strong> по работе сервиса? Свяжитесь с нами!
            </Typography>
          </Grid>
          <Grid mobile={12} desktop={6} textAlign={desktop ? "end" : "center"}>
            <WrapperButton
              label="+7 965 224-25-36"
              size={"large"}
              icon={<HeadsetMicOutlined />}
              fullWidth
              iconPosition="left"
              sx={(theme) => ({
                color: "#FFFFFF",
                background: "#090909",
              })}
              typeBorder="RoundGradient"
              gradientBorderColor="linear-gradient(-45deg, #674099,#ED6985)"
              positionBias={-2}
            />
          </Grid>
          <Grid
            mobile={12}
            desktop={6}
            textAlign={desktop ? "start" : "center"}
          >
            <WrapperButton
              label="onmenu@mail.ru"
              size={"large"}
              fullWidth
              icon={<MarkunreadOutlined />}
              iconPosition="left"
              sx={(theme) => ({
                color: "#FFFFFF",
                background: "#090909",
              })}
              typeBorder="RoundGradient"
              gradientBorderColor="linear-gradient(-45deg, #674099,#ED6985)"
              positionBias={-2}
            />
          </Grid>
        </Grid>
        <Grid container mobile={12} desktop={3} rowSpacing={2}>
          <Grid mobile={12} desktop={12}>
            <Typography
              variant="body1"
              color="#808080"
              sx={(theme) => ({
                textAlign: !desktop ? "center" : "end",
                textDecoration: "underline",
                cursor: "pointer",
              })}
            >
              {`Пользовательское соглашение`}
            </Typography>
          </Grid>
          <Grid mobile={12} desktop={12}>
            <Typography
              variant="body1"
              color="#808080"
              sx={(theme) => ({
                textAlign: !desktop ? "center" : "end",
                textDecoration: "underline",
                cursor: "pointer",
              })}
            >
              {`Политика конфиденциальности`}
            </Typography>
          </Grid>
          {!desktop && (
            <Grid mobile={12} desktop={12} textAlign="center">
              <Typography variant="caption" color="#FFFFFF">
                {`© OnMenu ${moment().year()}, все права защищены`}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthorizationFooter;
