import { Box } from "../../../../../components";
import { BoxProps } from "@mui/material";

const VisibilityIcon = ({ ...props }: BoxProps) => (
  <Box {...props}>
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 6.64263C14.76 6.64263 17 8.88263 17 11.6426C17 12.1526 16.9 12.6426 16.76 13.1026L19.82 16.1626C21.21 14.9326 22.31 13.3926 23 11.6326C21.27 7.25263 17 4.14263 12 4.14263C10.73 4.14263 9.51 4.34263 8.36 4.71263L10.53 6.88263C11 6.74263 11.49 6.64263 12 6.64263ZM2.71 3.30263C2.32 3.69263 2.32 4.32263 2.71 4.71263L4.68 6.68263C3.06 7.97263 1.77 9.67263 1 11.6426C2.73 16.0326 7 19.1426 12 19.1426C13.52 19.1426 14.97 18.8426 16.31 18.3226L19.03 21.0426C19.42 21.4326 20.05 21.4326 20.44 21.0426C20.83 20.6526 20.83 20.0226 20.44 19.6326L4.13 3.30263C3.74 2.91263 3.1 2.91263 2.71 3.30263ZM12 16.6426C9.24 16.6426 7 14.4026 7 11.6426C7 10.8726 7.18 10.1426 7.49 9.50263L9.06 11.0726C9.03 11.2526 9 11.4426 9 11.6426C9 13.3026 10.34 14.6426 12 14.6426C12.2 14.6426 12.38 14.6126 12.57 14.5726L14.14 16.1426C13.49 16.4626 12.77 16.6426 12 16.6426ZM14.97 11.3126C14.82 9.91263 13.72 8.82263 12.33 8.67263L14.97 11.3126Z"
        fill="url(#paint0_linear_845_13084)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_845_13084"
          x1="1"
          y1="3.01013"
          x2="25.4462"
          y2="7.2583"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED6985" />
          <stop offset="1" stopColor="#674099" />
        </linearGradient>
      </defs>
    </svg>
  </Box>
);

export default VisibilityIcon;
