import { createContext, useContext, useMemo } from "react";
import type { CSSProperties, PropsWithChildren } from "react";
import type {
  DraggableSyntheticListeners
} from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { DragIndicator } from "@mui/icons-material";
import { Button, ListItem } from "@mui/material";

interface Props {
  id: number | null;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
  attributes: {},
  listeners: undefined,
  ref() {}
});

export function SortableItem({ children, id }: PropsWithChildren<Props>) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id });
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef
    }),
    [attributes, listeners, setActivatorNodeRef]
  );
  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <SortableItemContext.Provider value={context}>
      <ListItem sx={{
        // display: "flex",
        // justifyContent: "space-between",
        // flexGrow: "1",
        // alignItems: "center",
        // //padding: "18px 20px",
        // backgroundColor: "#fff",
        // boxShadow: "0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15)",
        // borderRadius: "calc(4px / var(--scale-x, 1))",
        // boxSizing: "border-box",
        // listStyle: "none",
        // color: "#333",
        // fontWeight: "400",
        // fontSize: "1rem",
        // fontFmily:"sans-serif",
        padding: '0'
      }} ref={setNodeRef} style={style}>
        {children}
      </ListItem>
    </SortableItemContext.Provider>
  );
}

export function DragHandle() {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <Button sx={{
        display: "flex",
        //display: 'inline-block',
        width: "12px",
        padding: "0",
        margin: "0",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        flex: "0 0 auto",
        minWidth:'26px',
        touchAction: "none",
        cursor: "var(--cursor, pointer)",
        borderRadius: "5px",
        border: "none",
        outline: "none",
        appearance: "none",
        backgroundColor: "transparent",
        WebkitTapHighlightColor: "transparent",
        "&:hover" : {
            backgroundColor: "rgba(0, 0, 0, 0.05)",
        },
        "&:focus-visible": {
            boxShadow: "0 0px 0px 2px #4c9ffe",
        },
        "& svg": {
            flex: "0 0 auto",
            margin: "0",
            height: "100%",
            overflow: "visible",
            fill: "#919eab",
        },
    }} {...attributes} {...listeners} ref={ref}>
      <DragIndicator />
    </Button>
  );
}
