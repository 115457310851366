import React, { ReactNode, useState } from "react";
import { Popover, useMediaQuery } from "@mui/material";
import { Box, IconButton } from "../../../components";
import { darkTheme } from "../../theme/theme";
import HelpIcon from "../../shared/assets/img/icons/helpIcon";

interface PasswordHintPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
  children: ReactNode;
}

const HintPopover: React.FC<PasswordHintPopoverProps> = ({
  open,
  anchorEl,
  handlePopoverClose,
  children,
}) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Popover
      id="mouse-over-popover"
      sx={(theme) => ({
        pointerEvents: "none",
        ".MuiPaper-root": {
          "&.MuiPopover-paper": {
            padding: desktop ? "24px 32px" : "12px 16px",
            fontStyle: theme.typography.body1,
            borderRadius: desktop ? "30px" : "10px",
          },
        },
      })}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      {...{ open, anchorEl }}
    >
      <Box>{children}</Box>
    </Popover>
  );
};

export function HintPopoverIcon({ children }: { children: ReactNode }) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          padding: 0,
          fontSize: 0,
          marginRight: 0,
          position: desktop ? "absolute" : "static",
          right: desktop ? "-36px" : "0",
          marginLeft: desktop ? "0" : "12px",
        }}
      >
        <HelpIcon />
      </IconButton>
      <HintPopover {...{ children, open, anchorEl, handlePopoverClose }} />
    </>
  );
}
