import { Box, Typography } from "../../../../components";
import ContentContainer from "../../../component/ContentContainer";
import look from "./images/look.png";
import scan from "./images/scan.png";
import WrapperButton from "../../../component/Button/WrappedButton";
import { useNavigate } from "react-router-dom";
import { Tab } from "../../../pages/login/autorization";

function ForGuest() {
  const navigate = useNavigate();

  return (
    <Box
      sx={[
        (theme) => ({
          backgroundColor: "#FFFFFF",
          width: "100%",
          zIndex: "1",
          position: "relative",
          overflow: "hidden",
          padding: "30px 0px",
        }),
      ]}
    >
      <ContentContainer>
        <Box
          sx={{
            display: "flex",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <img height={256} src={scan} alt="Сканировать QR-код" />
          <img height={256} src={look} alt="Смотреть меню" />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              flexGrow: 0,
            }}
          >
            <Typography>
              <span style={{ color: "#68429B", fontSize: "32px" }}>
                {"Просто "}
              </span>
              <span style={{ color: "#0D0C22", fontSize: "32px" }}>гостю!</span>
            </Typography>
            <WrapperButton
              label={"ПОДКЛЮЧИТЬ"}
              sx={{ height: "50px", width: "170px" }}
              variant="contained"
              size={"normal"}
              onClick={() => navigate("/login?tab=" + Tab.REGISTRATION)}
              fullWidth={false}
            />
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  );
}

export default ForGuest;
