import { Box, Grid } from "../../../../components";
import ContentContainer from "../../../component/ContentContainer";
import gradient from './images/gradient.png';
import { styled } from "@mui/material/styles";
import logo from './images/logo.svg';
import logo1 from './images/logo-1.svg';
import logo2 from './images/logo-2.svg';
import logo3 from './images/logo-3.svg';
import logo4 from './images/logo-4.svg';
import logo5 from './images/logo-5.svg';
import logo6 from './images/logo-6.svg';
import logo7 from './images/logo-7.svg';
import logo8 from './images/logo-8.svg';
import logo9 from './images/logo-9.svg';
import logo10 from './images/logo-10.svg';
import logo11 from './images/logo-11.svg';
import logo12 from './images/logo-12.svg';
import logo13 from './images/logo-13.svg';
import logo14 from './images/logo-14.svg';
import logo15 from './images/logo-15.svg';

const logoArray = [
    {
        id: 'logo',
        img: logo
    },
    {
        id: 'logo1',
        img: logo1
    },
    {
        id: 'logo2',
        img: logo2
    },
    {
        id: 'logo3',
        img: logo3
    },
    {
        id: 'logo4',
        img: logo4
    },
    {
        id: 'logo5',
        img: logo5
    },
    {
        id: 'logo6',
        img: logo6
    },
    {
        id: 'logo7',
        img: logo7
    },
    {
        id: 'logo8',
        img: logo8
    },
    {
        id: 'logo9',
        img: logo9
    },
    {
        id: 'logo10',
        img: logo10
    },
    {
        id: 'logo11',
        img: logo11
    },
    {
        id: 'logo12',
        img: logo12
    },
    {
        id: 'logo13',
        img: logo13
    },
    {
        id: 'logo14',
        img: logo14
    },
    {
        id: 'logo15',
        img: logo15
    },
];

const Title = styled('div')({
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: '97px',
    marginBottom: '50px',
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: '3rem'
});

const GradientWrap = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative'
});

const Gradient = styled('img')({
    position: 'absolute',
    bottom: 0,
    left: 0,
    Zindex: -1,
    transform: 'translateX(-169px)'
});

function Clients() {
    return(
        <Box
            sx={[
                (theme) => ({
                    backgroundColor: theme.palette.primary.main,
                    width: "100%",
                    zIndex: "1",
                    position: "relative",
                    overflow: 'hidden',
                }),
            ]}
        >
            <ContentContainer maxWidth={1312}>
                <Title>
                    Нашим сервисом пользуются
                </Title>
                <Grid
                    container
                    spacing={8}
                    sx={{
                        width: '100%',
                        marginBottom: '97px',
                        //transform: 'translateX(16px)'
                    }}
                    justifyContent="center"
                >
                    { logoArray.map(item =>
                        <Grid item key={item.id}><Logo src={item.img}/></Grid>) }
                </Grid>
                <GradientWrap>
                    <Gradient
                        src={gradient}
                    />
                </GradientWrap>
            </ContentContainer>
        </Box>
    );
}

function Logo({ src }: { src: string }) {
    return(
        <Box
            sx={{
                width: '132px',
                height: '80px'
            }}
        >
            <img src={src} alt={src}/>
        </Box>
    );
}

export default Clients;