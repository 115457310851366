export interface AgentCardForm {
    firstName: string;
    lastName: string;
    middleName: string;
    phoneNumber: string;
    promocode: string;
    isActive: boolean;
  };
  
  export const AGENT_DEFAULT: AgentCardForm = {
    firstName: "",
    lastName: "",
    middleName: "",
    phoneNumber: "",
    promocode: "",
    isActive: true
};