import { Box } from "../../../components";
import ContentContainer from "../ContentContainer";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import { PropsWithChildren, ReactNode } from "react";
import { TMenuItem } from "../../pages/adminPanel/menuItems";
import NavMenu from "../NavMenu";
import CarouselBase from "../CarouselBase";

export type CommonContainerProps = {
  title: ReactNode;
  menuItems: TMenuItem[];
  arrayControlButtons?: JSX.Element[][];
};

/**Общий контейнер содержит:
 * 1. Наверху заголовок (title)
 * 2. Слева меню (menuItems). Для мобильных устройств меню на всю ширину страницы.
 * 3. Справа основной контент (children)
 */
function CommonContainer({
  title,
  menuItems,
  arrayControlButtons,
  children,
}: PropsWithChildren<CommonContainerProps>) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Box
      sx={{
        fontFamily: "Inter",
        paddingTop: desktop ? "50px" : "30px",
        paddingBottom: "60px",
        zIndex: 1,
      }}
    >
      <ContentContainer>
        <Box
          sx={{
            fontSize: desktop ? "2.5rem" : "1.25rem",
            fontWeight: 600,
            lineHeight: desktop ? "3rem" : "1.5rem",
            marginBottom: desktop ? "40px" : "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {title}
          {desktop && arrayControlButtons && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {arrayControlButtons.map((arrayJSXElement, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      gap: "15px",
                      justifyContent: "flex-end",
                    }}
                  >
                    {arrayJSXElement.map((JSXElement) => {
                      return JSXElement;
                    })}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </ContentContainer>

      <ContentContainer sx={{ padding: desktop ? "0 123px" : "0" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            position: "relative",
          }}
        >
          <NavMenu menuItems={menuItems} />
          {!desktop && arrayControlButtons && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "30px",
                marginBottom: "10px",
                gap: "10px",
              }}
            >
              {arrayControlButtons.map((arrayJSXElement, index) => {
                return <CarouselBase key={index} items={arrayJSXElement} />;
              })}
            </Box>
          )}
          <ContentContainer
            sx={{
              padding: desktop ? "0 0 0 50px" : "0 23px",
              flexGrow: 1,
            }}
          >
            {children}
          </ContentContainer>
        </Box>
      </ContentContainer>
    </Box>
  );
}

export default CommonContainer;
