import { Box, Typography, IconButton } from "../../../../components";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import React, { useState } from "react";
import WrapperInput from "../../../component/Input/WrappedInput";
import WrapperButton from "../../../component/Button/WrappedButton";
import { Popover } from "@mui/material";
import { Controller } from "react-hook-form";
import { FormOptionType } from "../type";
import { SxProps } from "@mui/material";
import { Edit } from "@mui/icons-material";
import HelpIcon from "../../../shared/assets/img/icons/helpIcon";

type WrapperItemType = Omit<
  FormOptionType,
  "setError" | "clearErrors" | "watch"
> & {
  label: string;
  hintLabel?: string | React.ReactElement;
  additionalPropertiesButton?: {
    onClick?: (e: any) => void;
    label?: string;
    hidden?: boolean;
    SxProps?: SxProps;
  };
  render?: ({
    onChange,
    value,
    error,
  }: {
    onChange: (e: any) => void;
    value: any;
    error: any;
  }) => React.ReactElement;
  hiddenChildren?: boolean;
  required?: boolean;
  sx?: SxProps;
  onChange?: (...event: any[]) => void;
};

const WrapperItem = ({
  label,
  name,
  control,
  additionalPropertiesButton,
  sx,
  hiddenChildren,
  hintLabel,
  required,
  render,
  onChange = () => {},
}: WrapperItemType) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        gap: "20px",
        position: "relative",
        paddingTop: !desktop ? "20px" : "0",
        ...sx,
      }}
    >
      <Box
        sx={{
          flex: "none",
          display: "flex",
          flexBasis: "220px",
          position: desktop ? "relative" : "absolute",
          top: !desktop && hintLabel ? "-5px" : 0,
          alignItems: "center",
          gap: "2px",
        }}
      >
        <Typography variant="caption" color="#0D0C22">
          {label}
          {required && (
            <Typography variant="caption" color="#F00" fontWeight="700">
              *
            </Typography>
          )}
        </Typography>
        {hintLabel && (
          <IconButton
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            sx={[
              (theme) => ({
                padding: 0,
                fontSize: 0,
                marginRight: 0,
              }),
            ]}
          >
            <HelpIcon />
          </IconButton>
        )}
      </Box>
      <Controller
        name={name}
        control={control}
        rules={required ? { required: "Поле обязательно для заполнения" } : {}}
        render={({
          field: { onChange: onChangeRender, value },
          fieldState: { error, isDirty },
          formState,
        }) => (
          <>
            {hiddenChildren ? (
              <></>
            ) : render ? (
              <Box sx={{ flexGrow: 1 }}>
                {render({
                  onChange: onChangeRender,
                  value: value,
                  error: error?.message,
                })}
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1 }}>
                <WrapperInput
                  label=""
                  backgroundColor="#FFFFFF"
                  fullWidth
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "34px",
                      color: "#0D0C22 !important",
                    },
                  }}
                  value={value}
                  error={error?.message}
                  onChange={(...args: any[]) => {
                    onChangeRender(...args);
                    onChange(...args);
                  }}
                />
              </Box>
            )}
            <WrapperButton
              label={
                additionalPropertiesButton
                  ? additionalPropertiesButton.label || ""
                  : "Сохранить"
              }
              variant="contained"
              size={"normal"}
              iconPosition={
                desktop || additionalPropertiesButton?.SxProps ? "left" : "only"
              }
              icon={
                !desktop && !additionalPropertiesButton?.SxProps && <Edit />
              }
              sx={{
                width: desktop ? "165px !important" : "auto",
                height: "36px",
                visibility: additionalPropertiesButton
                  ? additionalPropertiesButton.hidden
                    ? "hidden"
                    : "visible"
                  : isDirty
                    ? "visible"
                    : "hidden",
                ...additionalPropertiesButton?.SxProps,
              }}
              onClick={
                additionalPropertiesButton &&
                additionalPropertiesButton?.onClick
              }
              name={name}
              type={additionalPropertiesButton ? "button" : "submit"}
            />
          </>
        )}
      />
      <Popover
        id="mouse-over-popover"
        sx={(theme) => ({
          pointerEvents: "none",
          borderRadius: "30px",
          ".MuiPaper-root": {
            "&.MuiPopover-paper": {
              padding: "10px 30px",
              fontStyle: theme.typography.body1,
              borderRadius: "30px",
            },
          },
        })}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {hintLabel}
      </Popover>
    </Box>
  );
};

export default WrapperItem;
