import { Box, Typography } from "../../../components";
import { darkTheme } from "../../theme/theme";
import { TRefreshTable } from "../../component/DataTable/types";
import { useMediaQuery } from "@mui/material";
import WrappedButton from "../../component/Button/WrappedButton";
import "./styles.scss";
import { StatusEnum } from "./types";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TemplateDataDto } from "../../api/request/template";
import { req } from "../../api";
import ConfirmModal from "../../component/Modal/ConfirmModal";
import WrapperInput from "../../component/Input/WrappedInput";
import WrappedControlButton from "../../component/WrappedControlButton.tsx";
import TemplateList from "./templateList";
import { useAlert } from "../../store/alert";

export type typeModal = "delete" | "copy" | "import";

const Constructor = () => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [typeModal, setTypeModal] = useState<typeModal | null>(null);
  const [guidTemplate, setGuidTemplate] = useState("");
  const navigate = useNavigate();
  const refreshTableRef = useRef<TRefreshTable | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [templateData, setTemplateData] = useState<TemplateDataDto[]>([]);
  const { openAlert } = useAlert();

  const loadTemplates = () => {
    setIsLoading(true);
    req.template
      .getTemplates({
        page: 1,
        count: 50,
        sortItem: {
          fieldName: "id",
          direction: 0,
        },
      })
      .then((data) => {
        setTemplateData(
          data.data.data.items.sort((item1, item2) =>
            item1?.statusName !== StatusEnum.PUBLISHED ? 1 : -1,
          ),
        );
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    loadTemplates();
  }, []);

  const importTemplate = () => {
    req.template
      .importTemplate(guidTemplate)
      .then(res =>{
        if (res.success) {
          navigate(`/constructor/${res.data.id}/constructor`);
        }
      })
      .catch(() => {
        openAlert({
            text: "Произошла ошибка при импорте шаблона",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "error",
        });
      });

    
  };

  const controlButtons = [
    [
      <WrappedButton
        label="Импортировать шаблон"
        variant="contained"
        sx={{
          height: "36px",
          color: "#FFFFFF",
          minWidth: "210px",
        }}
        onClick={() => setTypeModal("import")}
      />,
    ],
  ];

  return (
    <Box
      component={"main"}
      sx={[
        (theme) => ({
          display: "flex",
          flexDirection: "column",
          maxWidth: "1280px",
          position: "relative",
          margin: "auto",
          zIndex: 1,
          padding: "0 23px 30px 23px",
        }),
      ]}
    >
      <Box
        sx={{
          padding: desktop ? "50px 0px 40px 0px" : "30px 0px  0px 0px",
          width: "100%",
          position: "relative",
        }}
      >
        <Typography
          variant="subtitle2"
          color="#0D0C22"
          sx={{
            fontSize: desktop ? "2.5rem" : "1.25rem",
            fontWeight: "600",
            overflow: "auto",
            flexGrow: 1,
          }}
        >
          {"Конструктор меню"}
        </Typography>
        <Box
          sx={{
            marginTop: !desktop ? "10px" : "0px",
            marginBottom: !desktop ? "10px" : "0px",
          }}
        >
          <WrappedControlButton controlButtons={controlButtons} />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TemplateList 
            templateData={templateData} 
            desktop={desktop} 
            loadTemplates={loadTemplates}
            refreshTableRef={refreshTableRef}/>
      </Box>
      <Box
        sx={{
          padding: "20px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to={"/constructor/0/constructor"}>
          <WrappedButton
            label="+"
            variant="caption"
            sx={{
              height: "36px",
              color: "#FFFFFF",
            }}
          />
        </Link>
      </Box>
      {typeModal === "import" && (
        <ConfirmModal
          width={470}
          onClose={() => setTypeModal(null)}
          onAccept={() => {
            importTemplate();
          }}
          buttonAccept="Импортировать шаблон"
          headerModal="Импортировать шаблон"
          bodyModal={
            <WrapperInput
              backgroundColor="#0D0C22"
              fullWidth
              //@ts-ignore
              size="small"
              label="Вставьте ссылку на импортируемый шаблон"
              value={guidTemplate}
              onChange={(e) => setGuidTemplate(e.target.value)}
            />
          }
        />
      )}
    </Box>
  );
};

export default Constructor;
