import {
  Box,
  Typography,
  Grid,
  InputAdornment,
  IconButton,
} from "../../../../../components";
import { useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import WrappedButton from "../../../../component/Button/WrappedButton";
import WrappedCheckbox from "../../../../component/Checkbox/WrappedCheckbox";
import WrapperInput from "../../../../component/Input/WrappedInput";
import { darkTheme } from "../../../../theme/theme";
import ImpersonalizeModal from "../modals/impersonalizeModal";
import { useMediaQuery, Select, MenuItem } from "@mui/material";
import VisibilityIcon from "../../../../shared/assets/img/icons/visibilityIcon";
import HelpIcon from "../../../../shared/assets/img/icons/helpIcon";
import { AdminCreationForm, ROLE_OPTIONS } from "./types";
import { PasswordHintPopover } from "../../../../component/PasswordHintPopover";
import WrappedSelect from "../../../../component/Select";
import VisibilityOff from "../../../../shared/assets/img/icons/visibilityOffIcon";
import { formatPhoneNumber } from "../../../../utils";
import { req } from "../../../../api";
import { useAlert } from "../../../../store/alert";

export const enum ModalEnum {
  IMPERSONALIZE,
}

const user: AdminCreationForm = {
  name: "",
  surname: "",
  patronymic: "",
  isActive: true,
  roleId: 1,
  password: "",
  email: "",
  phoneNumber: "",
};

function AdminCreation() {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [shownModal, setShownModal] = useState<ModalEnum | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const { openAlert } = useAlert();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<AdminCreationForm>({ defaultValues: user });

  const onSubmit: SubmitHandler<AdminCreationForm> = (data) => {
    req.user
      .createUser(data)
      .then((res) => {
        if (!res.success) {
          openAlert({
            text: res.errors
              .concat(
                res.data && Object.entries(res.data.errors).map((e) => e[1]),
              )
              .join("\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
        } else {
          openAlert({
            text: "Сохранено",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
          navigate("/adminpanel/users");
        }
      })
      .catch((w) => {
        openAlert({
          text: "Произошла ошибка",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  return (
    <Box
      component={"main"}
      sx={[
        (theme) => ({
          display: "flex",
          flexDirection: "column",
          maxWidth: "1280px",
          position: "relative",
          margin: "auto",
          zIndex: 1,
          paddingBottom: "60px",
        }),
      ]}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ flexGrow: 1 }}
        autoComplete="new-password"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            justifyContent: "space-between",
            padding: desktop ? "50px 0px 40px 0px" : "30px 23px 10px 23px",
          }}
        >
          <Box
            sx={{
              fontWeight: "600",
              overflow: "auto",
              flexGrow: 1,
              display: "flex",
              gap: "14px",
            }}
          >
            <Box sx={{ a: { textDecoration: "none" } }}>
              <Link to={"/adminPanel/users"}>
                <Typography
                  variant="subtitle2"
                  color="#0D0C22"
                  sx={{
                    textUnderlineOffset: "4px",
                    textDecoration: "underline",
                    textDecorationStyle: "dashed",
                    textDecorationThickness: "1px",
                    fontSize: desktop ? "2.5rem" : "1.25rem",
                    fontWeight: "600",
                  }}
                >
                  {"←"}
                </Typography>
              </Link>
            </Box>
            <Typography
              variant="subtitle2"
              color="#0D0C22"
              sx={{
                fontSize: desktop ? "2.5rem" : "1.25rem",
                fontWeight: "600",
              }}
            >
              {" Создание пользователя"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              marginTop: "20px",
              maxWidth: "536px",
            }}
          >
            <WrappedButton
              label="Сохранить"
              variant="contained"
              sx={{
                width: "100%",
                minWidth: 156,
                color: "#FFFFFF",
                background:
                  "linear-gradient(94deg, #FF6F83 0.78%, #F61735 105.69%)",
              }}
              type="submit"
            />
            <Link to={"/adminPanel/users"}>
              <WrappedButton
                label="Отмена"
                variant="contained"
                typeBorder="RoundGradient"
                sx={{
                  width: "100%",
                  minWidth: 156,
                  color: "#F72642",
                  backgroundColor: "#FFFFFF",
                  whiteSpace: "nowrap",
                }}
              />
            </Link>
          </Box>
        </Box>
        {shownModal === ModalEnum.IMPERSONALIZE && (
          <ImpersonalizeModal
            {...{ user }}
            onImpersonalize={() => {
              setShownModal(null);
            }}
            onClose={() => {
              setShownModal(null);
            }}
          />
        )}

        <Grid
          desktop={7}
          mobile={12}
          container
          sx={{ padding: desktop ? "0" : "0px 23px 0px 23px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "25px",
            }}
          >
            <Controller
              name="isActive"
              control={control}
              defaultValue={true}
              render={({ field: { onChange, value } }) => (
                <WrappedCheckbox
                  checked={value as boolean}
                  onCheckChange={onChange}
                  label={
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: darkTheme.palette.outline.default,
                      }}
                    >
                      Активность
                    </Typography>
                  }
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Фамилия
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="surname"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                          width: "100%",
                        },
                      }}
                      onInput={(e: { target: { value: string } }) => {
                        return (
                          //@ts-ignore
                          (e.target.value = e.target.value.replace(
                            /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                            "",
                          ))
                        );
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Имя
                <Typography variant="caption" color="#F00" fontWeight="700">
                  *
                </Typography>
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Поле обязательно для заполнения" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                          width: "100%",
                        },
                      }}
                      onInput={(e: { target: { value: string } }) => {
                        return (
                          //@ts-ignore
                          (e.target.value = e.target.value.replace(
                            /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                            "",
                          ))
                        );
                      }}
                      {...{ onChange, value }}
                      error={error?.message}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Отчество (при наличии)
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="patronymic"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                        },
                      }}
                      onInput={(e: { target: { value: string } }) => {
                        return (
                          //@ts-ignore
                          (e.target.value = e.target.value.replace(
                            /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                            "",
                          ))
                        );
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Номер телефона
                <Typography variant="caption" color="#F00" fontWeight="700">
                  *
                </Typography>
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{ required: "Поле обязательно для заполнения" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <WrapperInput
                      inputProps={{ maxLength: 16 }}
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                          width: "100%",
                        },
                      }}
                      onInput={(e: any) => {
                        e.target.value = formatPhoneNumber(
                          e.target.value.replace(/[^0-9+\-()\s]/g, ""),
                        );
                      }}
                      {...{ onChange, value }}
                      error={error?.message}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="caption" color="#0D0C22">
                  Пароль
                  <Typography variant="caption" color="#F00" fontWeight="700">
                    *
                  </Typography>
                </Typography>
                <IconButton
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  sx={[
                    (theme) => ({
                      padding: "2px",
                      fontSize: 0,
                      marginRight: 0,
                    }),
                  ]}
                >
                  <HelpIcon />
                </IconButton>
              </Box>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="password"
                  control={control}
                  rules={{ required: "Поле обязательно для заполнения" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                        },
                      }}
                      InputProps={{
                        autoComplete: "new-password",
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              edge="end"
                            >
                              {value ? (
                                showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <VisibilityIcon />
                                )
                              ) : (
                                <></>
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      type={showPassword ? "text" : "password"}
                      {...{ onChange, value }}
                      error={error?.message}
                    />
                  )}
                />
                <PasswordHintPopover
                  {...{ open, anchorEl, handlePopoverClose }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Роль
                <Typography variant="caption" color="#F00" fontWeight="700">
                  *
                </Typography>
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="roleId"
                  control={control}
                  rules={{ required: "Поле обязательно для заполнения" }}
                  render={({ field: { onChange, value } }) => (
                    <>
                      <WrappedSelect
                        disabled
                        options={ROLE_OPTIONS}
                        {...{ onChange, value }}
                      />
                    </>
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </form>
    </Box>
  );
}

export default AdminCreation;
