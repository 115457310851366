import { Modal, Box, Typography, IconButton } from "../../../../components";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { useState } from "react";
import WrapperInput from "../../../component/Input/WrappedInput";
import WrapperButton from "../../../component/Button/WrappedButton";
import { FormOptionType, ModalKeyType } from "../type";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import { formatPhoneNumber } from "../../../utils";
import { req } from "../../../api";
import { useAlert } from "../../../store/alert";
import { useDispatch } from "../../../store/model/extensions";
import { getUserData } from "../../../store/user";
import { Path } from "react-hook-form";
import { UserInfo } from "../../../api/request/auth";

type ChangeTelephoneOrEmailModalInterface = Pick<
  FormOptionType,
  "resetField"
> & {
  currentValue: string;
  onClose: () => void;
  typeModal: ModalKeyType;
  updateUserWithModal: (
    value: any,
    changedField: Path<UserInfo>,
    textMessageSuccess: string,
  ) => void;
};

const ChangeTelephoneOrEmailModal = ({
  currentValue,
  onClose,
  typeModal,
  updateUserWithModal,
  resetField,
}: ChangeTelephoneOrEmailModalInterface) => {
  const [value, setValue] = useState<string>(currentValue);
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [currentAction, setCurrentAction] = useState<
    "value-input" | "cod-input"
  >("value-input");
  const [code, setCode] = useState(["", "", "", ""]);

  const { openAlert } = useAlert();
  const dispatch = useDispatch();

  const requestChangePhoneNumber = () => {
    req.user
      .requestChangePhoneNumber(value)
      .then((res) => {
        if (!res.success) {
          openAlert({
            text: res.errors.join(";\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
        } else {
          setCurrentAction("cod-input");
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при попытке изменить номер",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const changePhoneNumber = () => {
    req.user
      .changePhoneNumber(value, code.join(""))
      .then((res) => {
        if (!res.success) {
          openAlert({
            text: res.errors.join(";\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
        } else {
          openAlert({
            text: "Номер телефона успешно изменен",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
          resetField &&
            resetField("phoneNumber", {
              keepDirty: false,
              defaultValue: value,
            });
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при попытке изменить номер",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      })
      .finally(() => {
        dispatch(getUserData());
        onClose();
      });
    onClose();
  };

  const sendEmail = () => {
    updateUserWithModal(
      value,
      "email",
      "На указанный Вами email отправлено письмо. Для подтверждения нового адреса, Вам нужно открыть письмо и пройти по указанной в нем ссылке.",
    );
    onClose();
  };

  return (
    <Modal
      open
      onClose={onClose}
      sx={[
        (theme) => ({
          left: desktop ? "" : "0",
          right: desktop ? "" : "unset",
          bottom: desktop ? "" : 0,
          top: desktop ? "" : "unset",
          position: desktop ? "relative" : "fixed",
          transform: desktop ? "" : "unset",
          backgroundColor: theme.palette.primary.main,
          padding: "25px !important",
          borderRadius: "10px",
          borderColor: theme.palette.primary.main,
          gap: "15px",
          width: desktop ? "450px" : "100%",
          borderBottomLeftRadius: desktop ? "" : "unset",
          borderBottomRightRadius: desktop ? "" : "unset",
        }),
      ]}
      header={
        <Box>
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: 500, color: "#FFFFFF" }}
          >
            {typeModal === "phone"
              ? "Изменить номер телефона"
              : "Изменить E-mail"}
          </Typography>
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", top: "2px", right: "2px" }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Box>
      }
      body={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            gap: "15px",
          }}
        >
          {currentAction === "value-input" ? (
            <WrapperInput
              label=""
              backgroundColor="#272727"
              fullWidth
              sx={{
                "& .MuiInputBase-root": {
                  height: "34px",
                  color: "#FFFFFF !important",
                },
              }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              inputProps={typeModal !== "email" ? { maxLength: 16 } : {}}
              onInput={(e: any) => {
                if (typeModal !== "email") {
                  e.target.value = formatPhoneNumber(
                    e.target.value.replace(/[^0-9\()\s]/g, ""),
                  );
                }
              }}
              type={typeModal === "email" ? "email" : "text"}
            />
          ) : (
            <>
              <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
                Код подтверждения
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                {code.map((value, index) => {
                  return (
                    <WrapperInput
                      label=""
                      backgroundColor="#272727"
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "36px",
                          color: "#FFFFFF !important",
                          textAlign: "center",
                        },
                        "& .MuiInputBase-input": {
                          textAlign: "center !important",
                        },
                      }}
                      inputProps={{ maxLength: 1 }}
                      onInput={(e) => {
                        //@ts-ignore
                        return (e.target.value = e.target.value.replace(
                          /[^0-9]/g,
                          "",
                        ));
                      }}
                      value={value}
                      onChange={(e) => {
                        setCode((prev) => {
                          const newArray = [...prev];
                          newArray.splice(index, 1, e.target.value);
                          return newArray;
                        });
                      }}
                    />
                  );
                })}
              </Box>
              <Typography
                variant="body1"
                sx={{
                  textDecoration: "underline dashed",
                  color: "#FFFFFF",
                  cursor: "pointer",
                  ":hover": {
                    opacity: 0.8,
                  },
                }}
                onClick={requestChangePhoneNumber}
              >
                Запросить код повторно
              </Typography>
            </>
          )}
        </Box>
      }
      footer={
        <WrapperButton
          label={
            typeModal === "email"
              ? "Отправить письмо"
              : currentAction === "value-input"
                ? "Запросить код"
                : "Подтвердить"
          }
          variant="contained"
          size={"normal"}
          sx={{
            height: "36px",
            display: "flex",
            marginLeft: "auto",
            justifyContent: "flex-end",
          }}
          name="email"
          onClick={
            typeModal === "email"
              ? sendEmail
              : currentAction === "value-input"
                ? requestChangePhoneNumber
                : changePhoneNumber
          }
        />
      }
    >
      <span></span>
    </Modal>
  );
};

export default ChangeTelephoneOrEmailModal;
