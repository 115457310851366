import { Box } from "../../../components";

export type LoaderProps = {
    width?: number|string;
    height?: number|string;
}

function Loader({ width = 140, height = 174 }: LoaderProps) {
    return(
        <Box
            sx={[
                {
                    "& svg": {
                        overflow: 'visible',
                    },
                    "& #rotation": {
                        animationName: 'rotation, color',
                        animationDuration: '1.2s',
                        animationIterationCount: 'infinite',
                        animationTimingFunction: 'linear',
                        transformOrigin: '50% 40%'
                    },
                    "& #bottom": {
                        animationName: 'color',
                        animationDuration: '1.2s',
                        animationIterationCount: 'infinite',
                        animationTimingFunction: 'linear',
                    },
                    '@keyframes rotation': {
                        from: {
                        transform: 'rotate(359deg)'
                        },
                        to: {
                        transform: 'rotate(0deg)'
                        }
                    },
                    '@keyframes color': {
                        '0%': {
                            fill: '#F71835'
                        },
                        '25%': {
                            fill: '#674099'
                        },
                        '50%': {
                            fill: '#F71835'
                        },
                        '75%': {
                            fill: '#674099'
                        },
                        '100%': {
                            fill: '#F71835'
                        },
                    }
                },
            ]}
        >
            <svg width={width} height={height} viewBox="0 0 140 154" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path id="rotation" d="M16.9658 105.333C5.65528 94.7134 0 79.7891 0 60.5594C0 41.5385 5.6297 26.692 16.8891 16.0204C28.1484 5.34887 45.8308 0 69.9104 0C94.1181 0 111.826 5.34887 123.111 16.0204C134.37 26.692 140 41.5645 140 60.5594C140 79.8935 134.396 94.844 123.188 105.411C111.98 115.978 94.2204 121.275 69.9104 121.275C45.9331 121.301 28.2764 115.978 16.9658 105.333ZM97.2656 83.6507C102.844 78.7193 105.633 71.0223 105.633 60.5594C105.633 50.3314 102.844 42.7387 97.2656 37.729C91.6871 32.7194 82.5772 30.2407 69.9104 30.2407C57.3716 30.2407 48.3129 32.7455 42.7344 37.729C37.1559 42.7387 34.3667 50.3314 34.3667 60.5594C34.3667 70.8918 37.1559 78.5627 42.7344 83.5723C48.3129 88.5819 57.3716 91.0606 69.9104 91.0606C82.5516 91.0606 91.6615 88.582 97.2656 83.6507Z" fill="#F71835"/>
                <path id="bottom" d="M140 130.9H0V154H140V130.9Z" fill="#F71835"/>
            </svg>
        </Box>
    );
}

export default Loader;