import { Box } from "../../../components";
import Accordion from "./Accordion/accordion";
import AccordionDetails from "./Accordion/accordionDetails";
import AccordionSummary from "./Accordion/accordionSummary";
import SummaryContent from "./AccordionContent/summaryContent";
import DetailContent from "./AccordionContent/detailContent";
import { EElementType, MenuTemplate, TMenuPosition } from "../../api/request/menuTemplate";
import { useState } from "react";

export type RestaurantMenuProps = {
    menu: TMenuPosition[];
    openModal: (img: string) => void;
    lightTheme: boolean;
    groupId: number;
};

function MenuItem({ item, index, lightTheme, groupId, openModal}: {item: TMenuPosition, index: number, lightTheme: boolean, groupId: number, openModal: (img: string) => void}) {
    const [opened, setOpened] = useState(false);

    return(
        <Box sx={{ marginTop: index > 0 ? "10px" : "unset" }}>
            <Accordion lightTheme={lightTheme}>
                <AccordionSummary
                    id={String(item.id)}
                    lightTheme={lightTheme}
                    onClick={() => {
                        MenuTemplate.Click(EElementType.POSITION, item.id, groupId);
                        setOpened(!opened);
                    }}
                >
                    <SummaryContent prices={item.prices} isOpened={opened} sx={{ 
                        lineHeight: "17px", 
                        letterSpacing: '0.4px', }} >
                        { item.name }
                    </SummaryContent>
                </AccordionSummary>
                <AccordionDetails lightTheme={lightTheme} sx={{
                    backgroundColor: "#F3F3F4"
                }}>
                    <DetailContent
                        lightTheme={lightTheme}
                        id={item.id}
                        description={item.description}
                        ingredients={item.ingredients}
                        carbohydrates={item.carbohydrates}
                        fats={item.fats}
                        proteins={item.proteins}
                        foodValue={item.foodValue}
                        photo={item.photo}
                        isNew={item.new}
                        vegan={item.vegan}
                        spicy={item.spicy}
                        popular={item.popular}
                        amount={item.prices.length === 1 ? item.prices[0].amount : null}
                        openModal={openModal}
                    />
                </AccordionDetails>
            </Accordion>
        </Box>);
}


/**Меню заведения */
function RestaurantMenu({ menu, openModal, lightTheme, groupId }: RestaurantMenuProps) {
    menu.sort((a,b)=>a.order-b.order);    
    return(
        <>
            { menu.map((item, index) => <MenuItem key={item.id} item={item} index={index} lightTheme={lightTheme} openModal={openModal} groupId={groupId}/>) }
        </>
    );
}

export default RestaurantMenu;