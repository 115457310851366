import { Box } from "../../../../../components";
import { BoxProps } from "@mui/material";

const ClearIcon = ({ ...props }: BoxProps) => (
  <Box {...props}>
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7987 0.709727C13.4087 0.319727 12.7787 0.319727 12.3887 0.709727L7.49875 5.58973L2.60875 0.699727C2.21875 0.309727 1.58875 0.309727 1.19875 0.699727C0.80875 1.08973 0.80875 1.71973 1.19875 2.10973L6.08875 6.99973L1.19875 11.8897C0.80875 12.2797 0.80875 12.9097 1.19875 13.2997C1.58875 13.6897 2.21875 13.6897 2.60875 13.2997L7.49875 8.40973L12.3887 13.2997C12.7787 13.6897 13.4087 13.6897 13.7987 13.2997C14.1887 12.9097 14.1887 12.2797 13.7987 11.8897L8.90875 6.99973L13.7987 2.10973C14.1787 1.72973 14.1787 1.08973 13.7987 0.709727Z"
        fill="url(#paint0_linear_1291_40545)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1291_40545"
          x1="0.90625"
          y1="0.407227"
          x2="15.69"
          y2="2.54714"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#ED6985" />
          <stop offset="1" stopColor="#674099" />
        </linearGradient>
      </defs>
    </svg>
  </Box>
);

export default ClearIcon;
