import { Box, Grid, InputAdornment, Typography } from "../../../../../components";
import { darkTheme } from "../../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import ImpersonalizeModal from "../modals/impersonalizeModal";
import { useEffect, useState } from "react";
import WrappedButton from "../../../../component/Button/WrappedButton";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import WrappedCheckbox from "../../../../component/Checkbox/WrappedCheckbox";
import { UserCardForm } from "../types";
import WrapperInput from "../../../../component/Input/WrappedInput";
import { CheckCircleOutline } from "@mui/icons-material";
import { req } from "../../../../api";
import { setImpersonalization } from "../../../../app/api";
import { useAlert } from "../../../../store/alert";
import { UserInfo } from "../../../../api/request/auth";
import { useDispatch } from "../../../../store/model/extensions";
import { fetchUserData } from "../../../../store/user";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const enum ModalEnum {
  IMPERSONALIZE,
}

const USER_DEFAULT: UserCardForm = {
  name: "",
  surname: "",
  patronymic: "",
  isActive: true,
  role: "Администратор",
  organizationName: "",
  phoneNumber: "",
  email: "",
  isEmailConfirmed: true,
  isPhoneActive: true,
};

function UserCard() {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [shownModal, setShownModal] = useState<ModalEnum | null>(null);
  const [user, setUser] = useState<UserInfo | null>(null);
  const { openAlert } = useAlert();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<UserCardForm>({ defaultValues: USER_DEFAULT });

  useEffect(() => {
    req.user.getUser(Number(id)).then((response) => {
      setUser(response.data.data);
      reset({
        ...response.data.data,
        organizationName: response.data.data.organization.name,
        role: response.data.data.role.title,
      });
    });
  }, [id]);

  const onSubmit: SubmitHandler<UserCardForm> = (data) => {
    if (!user) {
      return;
    }

    req.user
      .updateUserAdmin({
        id: user.id,
        surname: data.surname,
        name: data.name,
        patronymic: data.patronymic,
        isActive: data.isActive,
      })
      .then((res) => {
        if (!res.success) {
          openAlert({
            text: res.errors.join(";\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
        } else {
          openAlert({
            text: "Данные пользователя успешно обновлены",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
          setUser({
            ...user,
            modified: (new Date()).toISOString(),
          })
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при обновлении пользователя",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const onImpersonalize = () => {
    setImpersonalization(Number(id));
    dispatch(fetchUserData())
      .then(() => navigate("/personalArea"));
  }

  return (
    <Box
      component={"main"}
      sx={[
        (theme) => ({
          display: "flex",
          flexDirection: "column",
          maxWidth: "1280px",
          position: "relative",
          margin: "auto",
          zIndex: 1,
          paddingBottom: "60px",
        }),
      ]}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ flexGrow: 1 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            justifyContent: "space-between",
            padding: desktop ? "50px 0px 40px 0px" : "30px 23px 10px 23px",
          }}
        >
          <Box
            sx={{
              fontWeight: "600",
              overflow: "auto",
              flexGrow: 1,
              display: "flex",
              gap: "14px",
            }}
          >
            <Box sx={{ a: { textDecoration: "none" } }}>
              <Link to={"/adminPanel/users"}>
                <Typography
                  variant="subtitle2"
                  color="#0D0C22"
                  sx={{
                    textUnderlineOffset: "4px",
                    textDecoration: "underline",
                    textDecorationStyle: "dashed",
                    textDecorationThickness: "1px",
                    fontSize: desktop ? "2.5rem" : "1.25rem",
                    fontWeight: "600",
                  }}
                >
                  {"←"}
                </Typography>
              </Link>
            </Box>
            <Typography
              variant="subtitle2"
              color="#0D0C22"
              sx={{
                fontSize: desktop ? "2.5rem" : "1.25rem",
                fontWeight: "600",
              }}
            >
              {" Карточка пользователя"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              maxWidth: "100%",
              overflowX: "scroll",
              MsOverflowStyle: "none",
              scrollbarWidth: "none",
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Grid mobile={8} desktop={2}>
              <WrappedButton
                label="Сохранить изменения"
                variant="contained"
                sx={{
                  color: "#FFFFFF",
                  background:
                    "linear-gradient(94deg, #FF6F83 0.78%, #F61735 105.69%)",
                  borderRadius: "60px",
                  marginTop: "20px",
                  width: "max-content",
                }}
                type="submit"
              />
            </Grid>

            <Grid mobile={8} desktop={2}>
              <WrappedButton
                label="Войти под пользователем"
                variant="contained"
                sx={{
                  color: "#FFFFFF",
                  marginTop: "20px",
                  width: "max-content",
                  textWrap: "balance",
                }}
                onClick={() => setShownModal(ModalEnum.IMPERSONALIZE)}
              />
            </Grid>
          </Box>
        </Box>
        {shownModal === ModalEnum.IMPERSONALIZE && (
          <ImpersonalizeModal
            {...{ user }}
            onImpersonalize={onImpersonalize}
            onClose={() => {
              setShownModal(null);
            }}
          />
        )}

        <Grid
          desktop={7}
          mobile={12}
          container
          sx={{ padding: desktop ? "0" : "0px 23px 0px 23px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: desktop ? "row" : "column",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: "9px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "0.875rem",
                fontStyle: "italic",
                fontWeight: 300,
                lineHeight: "1.125rem",
                color: "#848484",
              }}
            >
              {`Тариф: `}
              <strong>{user?.order.tariffName}</strong>
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "0.875rem",
                fontStyle: "italic",
                fontWeight: 300,
                lineHeight: "1.125rem",
                color: "#848484",
              }}
            >
              {`Дата создания: `}
              <strong>{moment(user?.created).format("DD.MM.YYYY")}</strong>
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontSize: "0.875rem",
                fontStyle: "italic",
                fontWeight: 300,
                lineHeight: "1.125rem",
                color: "#848484",
              }}
            >
              {`Дата изменения: `}
              <strong>{moment(user?.modified).format("DD.MM.YYYY")}</strong>
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "25px",
            }}
          >
            <Controller
              name="isActive"
              control={control}
              defaultValue={true}
              render={({ field: { onChange, value } }) => (
                <WrappedCheckbox
                  checked={value as boolean}
                  onCheckChange={onChange}
                  label={
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: darkTheme.palette.outline.default,
                      }}
                    >
                      Активность
                    </Typography>
                  }
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Фамилия
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="surname"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                          width: "100%",
                        },
                      }}
                      onInput={(e: { target: { value: string } }) => {
                        return (
                          //@ts-ignore
                          (e.target.value = e.target.value.replace(
                            /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                            "",
                          ))
                        );
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Имя
                <Typography variant="caption" color="#F00" fontWeight="700">
                  *
                </Typography>
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                          width: "100%",
                        },
                      }}
                      onInput={(e: { target: { value: string } }) => {
                        return (
                          //@ts-ignore
                          (e.target.value = e.target.value.replace(
                            /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                            "",
                          ))
                        );
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Отчество (при наличии)
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="patronymic"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                        },
                      }}
                      onInput={(e: { target: { value: string } }) => {
                        return (
                          //@ts-ignore
                          (e.target.value = e.target.value.replace(
                            /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                            "",
                          ))
                        );
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Роль
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="role"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                        },
                        "& .Mui-disabled": {
                          WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                        },
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Телефон для связи
                <Typography variant="caption" color="#F00" fontWeight="700">
                  *
                </Typography>
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                        },
                        "& .Mui-disabled": {
                          WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {user?.isPhoneActive && (
                              <CheckCircleOutline color="success" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                E-mail
                <Typography variant="caption" color="#F00" fontWeight="700">
                  *
                </Typography>
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                        },
                        "& .Mui-disabled": {
                          WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                        },
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {user?.isEmailConfirmed && (
                              <CheckCircleOutline color="success" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Название заведения
                <Typography variant="caption" color="#F00" fontWeight="700">
                  *
                </Typography>
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="organizationName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      disabled
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                        },
                        "& .Mui-disabled": {
                          WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                        },
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </form>
    </Box>
  );
}

export default UserCard;
