import { Box } from "../../../../../components";
import { BoxProps } from "@mui/material";

const LeafIcon = ({ ...props }: BoxProps) => (
  <Box {...props}>
    <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.58611 14.5791C8.66583 15.5347 7.41589 16.0986 6.02447 16.0986C4.63304 16.0986 3.38243 15.5107 2.46283 14.5791C1.54255 13.6234 1 12.3241 1 10.878C1 8.81919 3.66539 4.72635 5.43453 2.00557C5.64675 1.68724 5.83561 1.36824 6.02447 1.09863C6.21333 1.36824 6.40219 1.68657 6.61441 2.00557C8.38355 4.75037 11.0489 8.81919 11.0489 10.878C11.0489 12.3241 10.483 13.6228 9.58611 14.5791Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.03308 6.28027V15.2898" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.89372 9.33398L6.01843 13.2099" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.14258 9.33398L6.01854 13.2099" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </Box>
);

export default LeafIcon;
