import { Box, Typography } from "../../../../components";
import ContentContainer from "../../../component/ContentContainer";
import restoran from "./images/restoran.png";
import bakery from "./images/bakery.png";
import bar from "./images/bar.png";
import bar2 from "./images/bar2.png";
//import bufet from "./images/bufet.png";
import cafe from "./images/cafe.png";
import cafeteria from "./images/cafeteria2.png";
import coffeHouse from "./images/coffeHouse.png";
import company from "./images/company2.png";
import dining from "./images/dining.png";
import shawarma from "./images/shawarma.png";
//import shop from "./images/shop.png";
import snackBar from "./images/snackBar.png";
import dacha from "./images/dacha.png";
import kalian from "./images/kalian.png";

const blockData = [
  {
    title: "Ресторан",
    img: restoran,
  },
  {
    title: "Бар",
    img: bar,
  },
  {
    title: "Кафе",
    img: cafe,
  },
  {
    title: "Столовая",
    img: dining,
  },
  {
    title: "Закусочная",
    img: snackBar,
  },
  {
    title: "Ночные клубы",
    img: bar2,
    //description: "быстрого обслуживания",
  },
  {
    title: "Буфет",
    img: cafeteria,
  },
  {
    title: "Сетевые проекты",
    img: company,
  },
  {
    title: "Места загородного отдыха",
    img: dacha,
  },
  {
    title: "Кальянная",
    img: kalian,
  },
  {
    title: "Стрит-фуд",
    img: shawarma,
  },
  {
    title: "Пекарня",
    img: bakery,
  },
];

function Healthy() {
  return (
    <Box
      sx={[
        (theme) => ({
          backgroundColor: "#FFFFFF",
          width: "100%",
          zIndex: "1",
          position: "relative",
          overflow: "hidden",
          padding: "55px 0px 95px 0px",
        }),
      ]}
    >
      <ContentContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "60px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography sx={{ fontSize: "40px", color: "#0D0C22", fontWeight: 600 }}>
            Кому это полезно?
          </Typography>
          <Box
            sx={{
              display: "flex",
              paddingTop: "19px",
              gap: "31px",
              flexWrap: "wrap",
              justifyContent: 'center'
            }}
          >
            {blockData.map(({ title, description, img }) => {
              return (
                <Box
                  key={title}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "187px",
                    height: "180px",
                    background: "#FFFFFF",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "12px",
                    gap: "8px",
                    boxShadow: "0px 12px 110px 0px rgba(35, 40, 105, 0.13)",
                  }}
                >
                  <img src={img} alt={title} />
                  <Typography
                    variant="h4"
                    sx={{
                      color: "#0D0C22",
                      fontWeight: 700,
                      lineHeight: "18px",
                      fontSize: '1.125rem',
                      textAlign: 'center'
                    }}
                  >
                    {title}
                  </Typography>
                  {description && (
                    <Typography
                      variant="body2"
                      sx={{ color: "#71717A" }}
                      textAlign="center"
                    >
                      {description}
                    </Typography>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  );
}

export default Healthy;
