import { getTemplateData } from "../../../store/templateCard";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { Box, Typography } from "../../../../components";
import { useEffect, useState } from "react";
import { req } from "../../../api";
import { PositionType } from "../../../api/request/template";
import {
  Block,
  Delete,
  Edit,
  MoreVert,
  OpenWith,
  Add,
} from "@mui/icons-material";
import ConfirmModal from "../../../component/Modal/ConfirmModal";
import { useNavigate, useParams } from "react-router-dom";
import { useAlert } from "../../../store/alert";
import { useDispatch } from "../../../store/model/extensions";
import { MultiAmount, SingleAmount } from "../../publicMenu/AccordionContent/summaryContent";
import WrapperButton from "../../../component/Button/WrappedButton";
import { SortableList } from "../../../component/SortableList/SortableList";

function Product ({product, desktop, sorting, setCurrentProduct}:
    { product:PositionType, desktop: boolean, sorting: boolean, setCurrentProduct: (item:PositionType)=>void }) {

    const optionsButton = <Box
            sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "24px",
            height: "24px",
            cursor: "pointer",
            }}
            onClick={() => setCurrentProduct(product)}
        >
            <MoreVert />
        </Box>;

    return (<Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "#F3F3F4",
        padding: desktop ? "16px 10px" : "10px 3px",
        color: "#61646C",
        width:'100%',
        paddingLeft: sorting ? '3px' : '10px'
      }}
    >
       <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'stretch',
       }}>
            {sorting ? <SortableList.DragHandle /> : <></>}
            {product.stop ? <Block sx={{
                    color: "#F72642",
                    marginLeft: '-6px',
                    marginRight: '4px',
                    width: '36px'
                }}/> : <></> }
            <Typography variant="body1" fontSize={desktop ? "16px" : "14px"} sx={{
                opacity: product.stop ? '0.5' : '1'
            }}>
                {product.name}
            </Typography>
      </Box>
      <Box
        sx={{
          gap: desktop ? "20px" : "4px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {product.prices.length === 1 ? <SingleAmount price={product.prices[0].value} sx={{
            opacity: product.stop ? '0.5' : '1',
            paddingLeft: '10px',
          }} /> : <MultiAmount prices={product.prices} sx={{
            opacity: product.stop ? '0.5' : '1'
          }} /> }
        </Box>
        {optionsButton}
      </Box>
    </Box>);
}
  
function ProductModal({
    product,
    sorting,
    setCurrentProduct,
    onDeletePosition,
    onUpdate,
    onStartSorting,
  }: {
    product: PositionType;
    sorting: boolean;
    setCurrentProduct: (val: PositionType | null) => void;
    onDeletePosition: (positionId: number) => void;
    onUpdate: (position: PositionType) => void;
    onStartSorting: () => void;
  }) {
    const { id } = useParams();
    const navigate = useNavigate();

    const buttons = [
      {
        name: "Редактировать позицию",
        icon: <Edit />,
        onClick: () => navigate(`/constructor/${id}/position/${product.id}`),
      },
      {
        name: product.stop ? "Снять со стопа" : "Поставить на стоп",
        icon: <Block sx={{ 
            color: product.stop ? "#5F5F5F" : "#F72642",
        }} />,
        onClick: () => {
          const p = JSON.parse(JSON.stringify(product));
          p.stop = !p.stop;
          onUpdate(p);
        },
      },
      {
        name: "Удалить позицию",
        icon: <Delete />,
        onClick: () => onDeletePosition(product.id as number),
      },
      {
        name: sorting ? "Сохранить в новом порядке" : "Поменять порядок",
        icon: <OpenWith />,
        onClick: () => {
            onStartSorting();
            setCurrentProduct(null);
        },
      },
    ];
    const body = buttons.map((button) => (
      <Box sx={{ display: "flex", gap: "10px", color: "#FFFFFF" }}>
        {button.icon}
        <Typography
          variant="body2"
          component={"a"}
          sx={{
            textDecoration: "underline",
            textUnderlineOffset: "2px",
            cursor: "pointer",
          }}
          onClick={button.onClick}
        >
          {button.name}
        </Typography>
      </Box>
    ));
  
    return (
      product && (
        <ConfirmModal
          onClose={() => setCurrentProduct(null)}
          onAccept={() => {}}
          buttonAccept={null}
          buttonCancel={null}
          headerModal={product.name}
          bodyModal={body}
        />
      )
    );
}

export default function ProductsList({ positions }: { positions: PositionType[]}) {
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    const [currentProduct, setCurrentProduct] = useState<PositionType | null>(
      null,
    );
    const { openAlert } = useAlert();
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [products, setProducts] = useState(positions.sort((a,b) => a.order-b.order));

    useEffect(() => {
        setProducts(positions);
    }, [positions]);

    const onUpdatePosition = (position: PositionType) => {
      setCurrentProduct(null);
      req.template.savePositionData(position, Number(id))
        .then(res => {
          if (res.success) {
            openAlert({
              text: `Позиция успешно ${position.stop ? "поставлена на стоп" : "снята со стопа"}`,
              isVisibleCloseIcon: true,
              autoHide: true,
              type: "success",
            });
            dispatch(getTemplateData({ id: Number(id) }));
          }
        }).catch(() => {
          openAlert({
            text: "Произошла ошибка при обновлении позиции",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "error",
          });
        });
    }
  
    const onDeletePosition = (positionId: number) => {
      setCurrentProduct(null);
      req.template
        .deletePosition(positionId, Number(id))
        .then((res) => {
          if (res.success) {
            openAlert({
              text: "Позиция успешно удалена",
              isVisibleCloseIcon: true,
              autoHide: true,
              type: "success",
            });
            dispatch(getTemplateData({ id: Number(id) }))
          }
        })
        .catch(() => {
          openAlert({
            text: "Произошла ошибка при удалении позиции",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "error",
          });
        });
    };
  
    const [sorting, setSorting] = useState(false);

    const onOrderChanged = (positions: PositionType[]) => {
        const changed: { id: number, order: number }[] = [];

        for (let i = 0; i < positions.length; i++) {
            let item = positions[i];
            if (item.order !== i) {
                changed.push({id: item.id!, order: i});
            }
        }

        if (positions.length) {
            req.template
            .updatePositionsOrder(changed, Number(id))
            .then(res => {
                if (res.success) {
                    openAlert({
                      text: "Новый порядок позиций успешно сохранен",
                      isVisibleCloseIcon: true,
                      autoHide: true,
                      type: "success",
                    });
                    dispatch(getTemplateData({ id: Number(id) }))
                  }
            })
            .catch(() => {
                openAlert({
                  text: "Произошла ошибка при обновлении позиции",
                  isVisibleCloseIcon: true,
                  autoHide: true,
                  type: "error",
                });
              });
        }
    }

    return (
        <Box sx={{
            gap: '16px',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box
                sx={{
                display: "flex",
                flexDirection: "column",
                gap: desktop ? "20px" : "10px",
                }}
            >
                {currentProduct && (
                <ProductModal
                    setCurrentProduct={setCurrentProduct}
                    product={currentProduct}
                    sorting={sorting}
                    onDeletePosition={onDeletePosition}
                    onUpdate={onUpdatePosition}
                    onStartSorting={() => {
                        if (sorting) {
                            onOrderChanged(products);
                        }
                        setSorting(!sorting);
                    }}
                />
                )}
                {products.length ? (
                    <SortableList items={products} onChange={items => setProducts(items)} renderItem={item => (
                        <SortableList.Item id={item.id} >
                            <Product key={item.id} product={item} desktop={desktop} sorting={sorting} setCurrentProduct={setCurrentProduct} />
                        </SortableList.Item>)
                    }>

                    </SortableList>
                    // products.map((product) => <Product key={product.id} product={product} desktop={desktop} sorting={sorting} setCurrentProduct={setCurrentProduct} />)
                
                ) : (
                <Typography variant="body1" fontSize="16px" color="#989CA4">
                    Нет позиций
                </Typography>
                )}
            </Box>
            <WrapperButton
                label="Добавить блюдо"
                variant="contained"
                sx={{
                    width: desktop ? "210px" : "100%",
                    alignSelf: "end",
                }}
                icon={<Add />}
                onClick={() => navigate(`/constructor/${id}/position`)}
            />
            {/* { sorting ? <WrapperButton
                label="Сохранить"
                variant="contained"
                sx={{
                    width: desktop ? "210px" : "100%",
                    alignSelf: "end",
                }}
                icon={<Save />}
                onClick={() => setSorting(false)}
            /> : <></> } */}
        </Box>
    );
}