import { TSearchData } from "../../component/DataTable/types";
import { api } from "../api";
import { Response } from "../type";

export type TariffDataDto = {
  period: number;
  periodName: string;
  price: number;
  priceWithDiscount: number;
  tariffId: number;
};

type TariffDto = {
  trialPeriod: number;
  tariffs: {
    base: TariffDataDto[];
    advanced: TariffDataDto[];
    premium: TariffDataDto[];
  };
};

export type OrderDataDto = {
  paymentId: string;
  created: Date;
  finishDate: Date;
  isTrial: boolean;
  isPaid: boolean;
  autoProlongation: boolean;
  tariffId: number;
  tariffName: string;
  period: number;
  periodName: string;
};

export type CreateOrderResponseDto = {
  errorMessage: string | null,
  redirectUrl: string | null,
  statusCode: number
}

export type CheckPaymentResponseDto = {
  errorMessage: string | null,
  status: number,
  orderId: number,
}

export namespace Billing {
  export async function getTariff() {
    return api
      .sendRequest<Response<TariffDto>>({
        domain: "on-menu",
        method: "get",
        url: `/billing/tariff`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

  export async function connectTariff(reguest: {
    tariff: number;
    period: number;
    autoProlong: boolean;
    isCheckedOffer?: boolean;
    promoCode: string | null;
  }) {
    const data: { isCheckedOffer?: boolean, promoCode?: string } = {};
    if (reguest.isCheckedOffer) {
      data.isCheckedOffer = reguest.isCheckedOffer;
    }
    if(reguest.promoCode) {
      data.promoCode = reguest.promoCode;
    }

    return api
      .sendRequest<Response<CreateOrderResponseDto>>({
        domain: "on-menu",
        method: "post",
        url: `/billing/order/${reguest.tariff}/${reguest.period}/${reguest.autoProlong}`,
        data
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  //Возвращает постраничный список заказов
  export async function getOrder(data: TSearchData) {
    return api.sendRequest({
      domain: "on-menu",
      method: "post",
      url: `/billing/order/search`,
      data: data,
    });
  }

  //
  export async function verifyPayment(orderId: string) {
    return api
      .sendRequest<Response<CheckPaymentResponseDto>>({
        domain: "on-menu",
        method: "get",
        url: `/billing/order/payment-confirmation/${orderId}`
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
}
