import { Box } from "../../../components";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { menuItems } from "./menuItems";
import CommonContainer from "../../component/CommonContainer";
import {
  ReactNode,
  createContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useUserData } from "../../store/user";

export type AdminPanelContextProps = {
  setHeaderSlot: null | ((node: ReactNode) => void);
};

export const AdminPanelContext = createContext<AdminPanelContextProps>({
  setHeaderSlot: null,
});

function AdminPanel() {
  const [headerSlot, setHeaderSlot] = useState<ReactNode>(null);
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const mobile = !desktop;
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useUserData();

  /**Вообще headerSlot предназначен  для размещения кнопки рядом с заголовком.
   * Но заголовок относится к AdminPanel, а кнопка к конкретному выбранному
   * разделу. Сеттер слота setHeaderSlot передаётся разделу через контекст. Это
   * усложняет код, но позволяет избежать отрицательных отступов или
   * position: absolute в вёрстке (потенциально могут привести к
   * "наезду" кнопки на заголовок, при небольшой ширине окна).
   * Здесь важно использовать useLayoutEffect для сброса слота после
   * перехода в другой раздел. А в самом разделе вызывать setHeaderSlot внутри
   * useEffect. Таким образом, обновление слота произойдёт в правильном порядке:
   * 1. AdminPanel -> Сброс слота в useLayoutEffect
   * 2. Выбранный раздел -> Установка нового компонента в слот в useEffect
   */
  useLayoutEffect(() => {
    setHeaderSlot(null);
  }, [location.pathname]);

  useEffect(() => {
    if (userData) {
      if (userData.role.id !== 1) {
        navigate("/personalArea");
      }
    }
  }, [userData]);

  return (
    <CommonContainer
      menuItems={menuItems}
      title={
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexGrow: 1,
          }}
        >
          Панель администратора
          {desktop && headerSlot}
        </Box>
      }
    >
      {mobile && (
        <Box
          sx={{
            textAlign: "left",
          }}
        >
          {headerSlot}
        </Box>
      )}
      <AdminPanelContext.Provider value={{ setHeaderSlot }}>
        <Outlet />
      </AdminPanelContext.Provider>
    </CommonContainer>
  );
}

export default AdminPanel;
