import { Box, Typography } from "../../../../components";
import cat from "./cat.svg";

type ExpiredSubscriptionProps = {
    lightTheme: boolean
}

export default function ExpiredSubscription ({ lightTheme }: ExpiredSubscriptionProps) {
    return (<Box sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center"
    }}>
        <Box sx={{
            display: "flex",
            flexFlow: "column"
        }}>
            <Typography sx={{
                color: lightTheme ? '#61646C' : '#FFFFFF',
                fontSize: "1rem",
                lineHeight: "1.2rem",
                fontWeight: "600",
                paddingBottom: "90px",
                textAlign: "center"
            }}>Владелец скоро продлит подписку, и сервис снова станет доступен</Typography>
            <Box sx={{
                textAlign: "center"
            }}>
                <img src={cat} alt=""/>
            </Box>
        </Box>
    </Box>);
}