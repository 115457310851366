import { Link as MuiLink, LinkProps, SxProps, Theme } from "@mui/material";

interface LinkInterface
  extends Pick<
    LinkProps,
    | "sx"
    | "children"
    | "color"
    | "component"
    | "variant"
    | "TypographyClasses"
    | "ref"
    | "underline"
    | "classes"
  > {
  sx?: SxProps<Theme>;
}

const Link = ({ sx, ...props }: LinkInterface) => {
  return (
    <MuiLink
      {...props}
      sx={[
        (theme) => ({
          cursor: "pointer",
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default Link;
