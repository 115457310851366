import { Box, Typography } from "../../../components";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { menuItems } from "./menuItems";
import CommonContainer from "../../component/CommonContainer";
import { useEffect } from "react";
import { useDispatch } from "../../store/model/extensions";
import { getHistoryData, getTemplateData } from "../../store/templateCard";

function TemplateCard() {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const mobile = !desktop;
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (id) {
      dispatch(getTemplateData({ id, guid: location?.state?.guid }));
      dispatch(getHistoryData(id));
    }
  }, [id]);

  return (
    <CommonContainer
      menuItems={menuItems}
      title={
        <Box
          sx={{
            fontWeight: "600",
            overflow: "auto",
            flexGrow: 1,
            display: "flex",
            gap: "14px",
          }}
        >
          <Box sx={{ a: { textDecoration: "none" } }}>
            <Link to={"/constructor"}>
              <Typography
                variant="subtitle2"
                color="#0D0C22"
                sx={{
                  textUnderlineOffset: "4px",
                  textDecoration: "underline",
                  textDecorationStyle: "dashed",
                  textDecorationThickness: "1px",
                  fontSize: desktop ? "2.5rem" : "1.25rem",
                  fontWeight: "600",
                }}
              >
                {"🡠"}
              </Typography>
            </Link>
          </Box>
          <Typography
            variant="subtitle2"
            color="#0D0C22"
            sx={{
              fontSize: desktop ? "2.5rem" : "1.25rem",
              fontWeight: "600",
            }}
          >
            {" Карточка шаблона"}
          </Typography>
        </Box>
      }
    >
      <Outlet />
    </CommonContainer>
  );
}

export default TemplateCard;
