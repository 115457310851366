import WrapperButton from "../../../component/Button/WrappedButton";
import { useTemlateCardData } from "../../../store/templateCard";
import { typeModal } from ".";

export const useControlButtons = ({
  setTypeModal,
  getGuid,
  isDirty,
}: {
  setTypeModal: (typeModal: typeModal) => void;
  getGuid: () => void;
  isDirty: boolean;
}) => {
  const { tempateId } = useTemlateCardData();

  const getControlButtons = () => {
    return [
      [
        <WrapperButton
          label="Экспортировать шаблон"
          variant="contained"
          disabled={!tempateId || isDirty}
          sx={{
            height: "36px",
            whiteSpace: "nowrap",
            minWidth: "156px",
          }}
          onClick={getGuid}
        />,
        <WrapperButton
          label="Скопировать шаблон"
          variant="contained"
          disabled={!tempateId || isDirty}
          sx={{
            height: "36px",
            whiteSpace: "nowrap",
            minWidth: "156px",
          }}
          onClick={() => setTypeModal("copy")}
        />,
        <WrapperButton
          label="Отмена"
          variant="contained"
          typeBorder="RoundGradient"
          disabled={!isDirty}
          sx={{
            color: "#F72642",
            backgroundColor: "#FFFFFF",
            height: "36px",
            whiteSpace: "nowrap",
            minWidth: "156px",
          }}
          type="submit"
          name="cancel"
        />,
      ],
      [
        <WrapperButton
          label="Опубликовать шаблон"
          variant="contained"
          sx={{
            height: "36px",
            whiteSpace: "nowrap",
            minWidth: "156px",
          }}
          onClick={() => setTypeModal("publish")}
          disabled={!tempateId || isDirty}
        />,
        <WrapperButton
          label="Сохранить"
          variant="contained"
          disabled={!isDirty}
          sx={{
            height: "36px",
            whiteSpace: "nowrap",
            minWidth: "156px",
          }}
          type="submit"
        />,
        <WrapperButton
          label="Удалить"
          variant="contained"
          sx={{
            height: "36px",
            whiteSpace: "nowrap",
            minWidth: "156px",
          }}
          disabled={!tempateId}
          onClick={() => setTypeModal("delete")}
        />,
      ],
    ];
  };

  return { controlButtons: getControlButtons() };
};
