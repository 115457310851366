import { Path } from "react-hook-form";
import { UserInfo } from "../../../api/request/auth";
import ConfirmModal from "../../../component/Modal/ConfirmModal";

type ChangeTelephoneOrEmailModalInterface = {
  currentValue: string;
  onClose: () => void;
  updateUserWithModal: (
    value: any,
    changedField: Path<UserInfo>,
    textMessageSuccess: string,
  ) => void;
};

const ConfirmEmailModal = ({
  currentValue,
  onClose,
  updateUserWithModal,
}: ChangeTelephoneOrEmailModalInterface) => {

  const sendEmail = () => {
    updateUserWithModal(
      currentValue,
      "email",
      "На указанный Вами email отправлено письмо. Для подтверждения адреса, Вам нужно открыть письмо и пройти по указанной в нем ссылке.",
    );
    onClose();
  };

  return (
      <ConfirmModal
          onClose={onClose}
          onAccept={sendEmail}
          buttonAccept='Отправить письмо'
          buttonCancel={null}
          headerModal="Подтвердить E-mail"
          bodyModal=''
      />
  );
};

export default ConfirmEmailModal;
