import logo from "../../../shared/assets/img/on-menu.svg";
import userIcon from "../../../shared/assets/img/user-icon.svg";
import { ForwardedRef, forwardRef } from "react";
import { Box } from "../../../../components";

/**хэдер для опубликованного меню */
function Header({ lightTheme }: any, ref: ForwardedRef<HTMLHeadElement>) {
    return(
        <header
            ref={ref}
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
            }}
        >
            <Box
                component={'div'}
                sx={theme => ({
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '16px',
                    boxShadow: lightTheme ?
                        '0px 0px 5.423600196838379px 0px #343C4433' : 'unset',
                    userSelect: 'none',
                    '& > img': {
                        cursor: 'pointer'
                    },
                    backgroundColor: lightTheme ? '#FFFFFF' : '#121212'
                })}
            >
                <img src={logo} alt="logo" height="24px" width="97.71px"/>
                <img src={userIcon} alt="userIcon"/>
            </Box>
        </header>
    );
}

export default forwardRef<HTMLHeadElement, any>(Header);