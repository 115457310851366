import ContentContainer from "../../../component/ContentContainer";
import { Box } from "../../../../components";
import Card, { CardType } from "./card";
import { useNavigate } from "react-router-dom";
import { Tab } from "../../../pages/login/autorization";

const cards: CardType[] = [
    {
        id: 'base',
        title: 'Базовый',
        price: 399,
        discounts: [
            <>6 месяцев — 1999 ₽ <span>скидка 15%</span></>,
            <>12 месяцев — 3499 ₽ <span>скидка 25%</span></>
        ],
        options: [
            "Создание и публикация меню",
            "Генерация QR-кода"
        ],
        comment: "Чуть дороже капучино ☕",
        onClick: () => {}
    },
    {
        id: 'advanced',
        title: 'Продвинутый',
        price: 599,
        discounts: [
            <>6 месяцев — 2999 ₽ <span>скидка 15%</span></>,
            <>12 месяцев — 5299 ₽ <span>скидка 25%</span></>,
        ],
        options: [
            "Создание и публикация меню",
            "Генерация QR-кода",
            "Добавление фото блюд",
            "Информация о блюде и КБЖУ",
            "Иконки «New» «Спайси» и «Вегетарианское»",
            "Тёмная тема",
            "Ссылки",
        ],
        comment: "Чуть дороже салата Цезарь 🥗",
        onClick: () => {}
    },
    {
        id: 'premium',
        theme: 'dark',
        title: 'Премиум',
        price: 999,
        discounts:  [
            <>6 месяцев — 4999 ₽ <span>скидка 15%</span></>,
            <>12 месяцев — 8999 ₽ <span>скидка 25%</span></>,
        ],
        options: [
            "Создание и публикация меню",
            "Генерация QR-кода",
            "Добавление фото блюд",
            "Информация о блюде и КБЖУ",
            "Иконки «New» «Спайси» и «Вегетарианское»",
            "Тёмная тема",
            "Ссылки",
            "Расширенная статистика о гостях",
            "Перевод на любой язык мира",
        ],
        comment: "Чуть дороже двух билетов в кино 🎬",
        onClick: () => {}
    }
];

function Price() {
    const navigate = useNavigate();
    return(
        <Box
            sx={{
                backgroundColor: '#F0F1F4',
                width: '100%',
                paddingBottom: '64px',
            }}
        >
            <ContentContainer>
                <Box
                    sx={{
                        fontSize: '2.5rem',
                        fontWeight: 600,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end',
                        alignItems: 'center',
                        color: '#0D0C22',
                        lineHeight: '3.75rem',
                        height: '104px',
                        letterSpacing: '-2%',
                        marginBottom: '39px',
                    }}
                >
                    Сколько стоит?
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}
                >
                    { cards.map((card, index) =>
                        <Card
                            {...card}
                            space={index > 0}
                            onClick={() => navigate("/login?tab=" + Tab.REGISTRATION)}
                            key={card.id}
                        />) }
                </Box>
            </ContentContainer>
        </Box>
    );
}



export default Price;