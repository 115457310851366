import { Box } from "../../../../components";

function ExpandIcon({ color = "white" }: { color?: "white" | "black" }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "24px",
        height: "24px",
        borderRadius: "50px",
        background: color === "white" ? "#FFFFFF" : "#0D0C22",
      }}
    >
      <svg
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.29337 3.5859L4.70337 0.995898C4.31337 0.605898 3.68337 0.605898 3.29337 0.995898L0.703366 3.5859C0.0733663 4.2159 0.523366 5.2959 1.41337 5.2959L6.59337 5.2959C7.48337 5.2959 7.92337 4.2159 7.29337 3.5859Z"
          fill="url(#paint0_linear_3337_898)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_3337_898"
            x1="7.58984"
            y1="5.2959"
            x2="-0.229503"
            y2="3.52625"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#ED6985" />
            <stop offset="1" stopColor="#674099" />
          </linearGradient>
        </defs>
      </svg>
    </Box>
  );
}

export default ExpandIcon;
