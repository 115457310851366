import { Box, Typography, Grid2 as Grid } from "../../../components";
import {
  HeadsetMicOutlined,
  MarkunreadOutlined,
  YouTube,
} from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import logo from "shared/assets/img/on-menu-white.svg";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import WrapperButton from "../Button/WrappedButton";
import tg from "../../shared/assets/img/tg.svg";
import vk from "../../shared/assets/img/vk.svg";
import yt from "../../shared/assets/img/yt.svg";
import LinkRouter from "../LinkRouter";

const descriptionList = [
  {
    title: "Как работает",
    path: "/#top",
  },
  {
    title: "Плюсы",
    path: "/#plus_and_minus",
  },
  {
    title: "Сколько стоит",
    path: "/#prices",
  },
  {
    title: "Кому это полезно",
    path: "/#healthy",
  },
];


const UnauthorizationFooter = () => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const location = useLocation();
  // const dispatch = useDispatch();

  // const { userData } = useUserData();
  return (
    <Box
      component={"footer"}
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "center",
        }),
      ]}
    >
      <Grid
        container
        justifyContent={desktop ? "space-between" : "center"}
        alignItems={"center"}
        maxWidth={1280}
        gap={"44px 0px"}
      >
        <Grid
          container
          mobile={12}
          desktop={6}
          columnSpacing={8}
          spacing={0}
          maxWidth={640}
        >
          <Grid
            mobile={12}
            desktop={12}
            marginBottom={desktop ? "70px" : "20px"}
          >
            <Typography
              variant={desktop ? "h3" : "h4"}
              color="#FFFFFF"
              sx={(theme) => ({
                textAlign: "start",
                lineHeight: desktop ? "60px" : "30px",
              })}
            >
              Есть <strong>вопрос</strong> по работе сервиса?
              <br /> Свяжитесь с нами!
            </Typography>
          </Grid>
          <Grid
            mobile={12}
            desktop={6}
            textAlign={desktop ? "end" : "center"}
            marginBottom={"10px"}
          >
            <WrapperButton
              label="+7 965 224-25-36"
              size={"large"}
              icon={<HeadsetMicOutlined />}
              fullWidth
              iconPosition="left"
              sx={(theme) => ({
                color: "#FFFFFF",
                background: "#090909",
              })}
              typeBorder="RoundGradient"
              gradientBorderColor="linear-gradient(-45deg, #674099,#ED6985)"
              positionBias={-2}
            />
          </Grid>
          <Grid
            mobile={12}
            desktop={6}
            textAlign={desktop ? "start" : "center"}
          >
            <WrapperButton
              label="onmenu@mail.ru"
              size={"large"}
              fullWidth
              icon={<MarkunreadOutlined />}
              iconPosition="left"
              sx={(theme) => ({
                color: "#FFFFFF",
                background: "#090909",
              })}
              typeBorder="RoundGradient"
              gradientBorderColor="linear-gradient(-45deg, #674099,#ED6985)"
              positionBias={-2}
            />
          </Grid>
        </Grid>

        <Grid
          container
          mobile={12}
          desktop={6}
          bgcolor={"#181819"}
          borderRadius="10px"
          padding={desktop ? "40px 90px" : "20px 23px"}
          gap={"40px 0px"}
          maxWidth={640}
        >
          <Grid mobile={12} desktop={12} justifyContent="center">
            <img
              style={{
                width: desktop ? "165px" : "100px",
                display: "block",
                margin: "0 auto",
              }}
              src={logo}
              alt="Логотип"
            />
          </Grid>
          <Grid mobile={12} desktop={6}>
            <Box
              sx={[
                (theme) => ({
                  display: "flex",
                  gap: desktop ? "32px" : "20px",
                  flexDirection: "column",
                  flexWrap: desktop ? "nowrap" : "wrap",
                  justifyContent: desktop ? "start" : "center",
                  textAlign: !desktop ? "center" : "unset",
                }),
              ]}
            >
              {descriptionList.map(({ title, path }) => {
                return (
                  <LinkRouter onClick={(e) => {
                    if (location.pathname === "/") {
                      const element = document.querySelector(path.replace("/",""));
                      if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                      }
                    } 
                   }} to={path} key={title}>
                    <Typography
                      variant="subtitle2"
                      color="#FFFFFF"
                      sx={{ cursor: "pointer" }}
                      key={title}
                    >
                      {title}
                    </Typography>
                  </LinkRouter>
                );
              })}
            </Box>
          </Grid>

          <Grid
            container
            mobile={12}
            desktop={6}
            gap={"40px 0px"}
            flexDirection="column"
            justifyContent="space-between"
          >
            <Grid mobile={12} desktop={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <img style={{ cursor: "pointer" }} src={tg} alt="Телеграм" />
                <img style={{ cursor: "pointer" }} src={vk} alt="Вконтакте" />
                <img style={{ cursor: "pointer" }} src={yt} alt="YouTube" />
              </Box>
            </Grid>
            <Grid mobile={12} desktop={12}>
              <Box
                sx={{ display: "flex", gap: "10px", flexDirection: "column" }}
              >
                <Typography
                  variant="body1"
                  color="#808080"
                  sx={(theme) => ({
                    textAlign: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  })}
                >
                  {`Пользовательское соглашение`}
                </Typography>
                <Typography
                  variant="body1"
                  color="#808080"
                  sx={(theme) => ({
                    textAlign: "center",
                    textDecoration: "underline",
                    cursor: "pointer",
                  })}
                >
                  {`Политика конфиденциальности`}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UnauthorizationFooter;
