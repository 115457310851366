import {
    Box,
    Typography,
  } from "../../../../components";
import WrapperInput from "../../../component/Input/WrappedInput";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery, SxProps, Theme } from "@mui/material";
import DataTable from "../../../component/DataTable";
import { Link, useNavigate } from "react-router-dom";
import { user } from "../../../api/request";
import { UserResponseItem } from "./types";
import { ReactNode, useContext, useEffect, useState } from "react";
import { TColumn, TSearchData, TSearchFilterItem } from "../../../component/DataTable/types";
import { AdminPanelContext } from "..";
import WrapperButton from "../../../component/Button/WrappedButton";
import { formatPhoneNumber } from "../../../utils";

function stringIsNotEmpty(string: string) {
    if (!string) return false;
    if (string.trim().length > 0) return true;
}

type FilterData = Omit<TSearchData, "page" | "count">;
type FilterItems = Omit<FilterData, "sortItem">;

const dateColumn: TColumn<UserResponseItem> = { name: 'Дата изменения', id: 'modified',
    renderComponent: ({ row }) => {
        const date = stringIsNotEmpty(row.modified) ? row.modified : row.created;
        const formattedDate = new Date(date).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
        return(<>{ formattedDate }</>);
    }
};

const desktopColumns: TColumn<UserResponseItem>[] = [
    { name: 'ID', id: 'id' },
    { name: 'Логин', id: 'phoneNumber', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ value }</Box> },
    { name: 'ФИО', id: 'name' },
    { name: 'Роль', id: 'role.title' },
    { name: 'Название заведения', id: 'organizationName' },
    dateColumn,
];

const mobileColumns: TColumn<UserResponseItem>[] = [
    { name: 'ФИО', id: 'fullName' },
    { name: 'ID', id: 'id' },
    { name: 'Логин', id: 'phoneNumber' },
    { name: 'Роль', id: 'role.title' },
    { name: 'Название заведения', id: 'organizationName' },
    dateColumn,
];

//TODO: Этот компонент используется в других местах, нужно вынести его в общее место и переиспользовать везде
function LabelWrapper({
    label,
    children,
    desktop,
    sx
  }: {
    label: string;
    children: React.ReactElement;
    desktop: boolean;
    sx: SxProps<Theme>;
  }) {

    const defaultSx: SxProps<Theme> = {
        display: "flex",
        alignItems: desktop ? "center" : "start",
        gap: desktop ? "10px" : "2px",
        flexDirection: desktop ? "row" : "column",
        width: desktop ? "unset" : "100%",
    };
    const p = {...defaultSx,...sx}; 

    return (
      <Box sx={p}>
        <Typography variant="caption" color="#0D0C22">{label}</Typography>
        {children}
      </Box>
    );
  }

function Filter({ applyFilter, value, desktop }: { applyFilter: (filter: FilterItems) => void, value: FilterData, desktop: boolean}) : ReactNode {
    const getFilterValue = (fieldName: string) : string => {
        const field = value.filterItems.find(x => x.fieldName === fieldName);

        if (!field) return "";

        return field.value;
    }
   const [id, setId ] = useState(getFilterValue("Id"));
   const [login, setLogin ] = useState(getFilterValue("phoneNumber"));
   const [name, setName ] = useState(getFilterValue("name"));
   const [organizationName, setOrganizationName ] = useState(getFilterValue("Organization.Name"));

    const clearFilter = () => {
       setId("");
       setLogin("");
       setName("");
       setOrganizationName("");
       applyFilter({filterItems: []});
    }

    const addFilter = (fieldName: string, val: string, condition: 0|5, items: TSearchFilterItem[]) => {
        if (!val) return;
        const field = items.find(x => x.fieldName === fieldName);
        if (field) {
            field.value = val!;
        } else {
            items.push({
                condition: condition!,
                fieldName: fieldName!,
                value: val!,
                valueTo: ""
            });
        }
    }

    const saveFilter = () => {
        const items: TSearchFilterItem[] = [];

        addFilter("Id", id, 0, items);
        addFilter("phoneNumber", login, 5, items);
        addFilter("name", name, 5, items);
        addFilter("Organization.Name", organizationName, 5, items);

        applyFilter({filterItems: items});
    }

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "start",
                width: "100%"
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: desktop ? "row" : "column",
                    gap: "20px",
                    alignItems: "start",
                    width: "100%"
                }}
            >
                <LabelWrapper
                    desktop={desktop}
                    label="ID"
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ maxWidth: 536, width: "100%" }}>
                        <WrapperInput
                            backgroundColor="#FFFFFF"
                            fullWidth
                            sx={{
                                "& .MuiInputBase-root": {
                                height: "34px",
                                color: "#0D0C22 !important",
                                width: desktop ? "87px" : "100%",
                                },
                            }}
                            onInput={(e: { target: { value: string } }) => {
                                return (
                                //@ts-ignore
                                (e.target.value = e.target.value.replace(
                                    /[^0-9\s]/g,
                                    "",
                                ))
                                );
                            }}
                            onChange={(e: any) => { setId(e.target.value); }}
                            value={id}
                        />
                    </Box>
                </LabelWrapper>
                <LabelWrapper
                    desktop={desktop}
                    label="Логин"
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ maxWidth: 536, width: "100%" }}>
                        <WrapperInput
                            backgroundColor="#FFFFFF"
                            fullWidth
                            sx={{
                                "& .MuiInputBase-root": {
                                height: "34px",
                                color: "#0D0C22 !important",
                                width: desktop ? "227px" : "100%",
                                },
                            }}
                            onInput={(e: any) => {
                                e.target.value = formatPhoneNumber(
                                  e.target.value.replace(/[^0-9+\-()\s]/g, ""),
                                );
                              }}
                            onChange={(e: any) => { setLogin(e.target.value); }}
                            value={login}
                        />
                    </Box>
                </LabelWrapper>
                <LabelWrapper
                    desktop={desktop}
                    label="ФИО"
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ maxWidth: 536, width: "100%" }}>
                        <WrapperInput
                            backgroundColor="#FFFFFF"
                            fullWidth
                            colorType="white"
                            sx={{
                                "& .MuiInputBase-root": {
                                height: "34px",
                                color: "#0D0C22 !important",
                                width: desktop ? "425px": "100%",
                                },
                            }}
                            onChange={(e: any) => { setName(e.target.value); }}
                            value={name}
                        />
                    </Box>
                </LabelWrapper>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: desktop ? "row" : "column",
                    gap: "20px",
                    alignItems: desktop ? "center" : "start",
                    width: "100%"
                }}
            >
                 <LabelWrapper
                    desktop={desktop}
                    label="Название заведения"
                    sx={{
                        justifyContent: "space-between",
                    }}
                >
                    <Box sx={{ maxWidth: 536, width:  desktop ? "261px" : "100%" }}>
                        <WrapperInput
                            backgroundColor="#FFFFFF"
                            fullWidth
                            sx={{
                                "& .MuiInputBase-root": {
                                height: "34px",
                                color: "#0D0C22 !important",
                                width: "100%",
                                },
                            }}
                            onChange={(e: any) => { setOrganizationName(e.target.value); }}
                            value={organizationName}
                        />
                    </Box>
                </LabelWrapper>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: desktop ? "row" : "column",
                        alignItems: "end",
                        width: desktop ? "50%" : "100%",
                        gap: "15px"
                    }}
                >
                    <WrapperButton
                        label="Применить"
                        variant="contained"
                        sx={{
                            marginLeft: desktop ? "auto" : "unset",
                            height: "36px",
                            width: desktop ? "156px" : "100%",
                            whiteSpace: "nowrap",
                        }}
                        onClick={saveFilter}
                    />

                    <WrapperButton
                        label="Сбросить фильтр"
                        variant="contained"
                        typeBorder="RoundGradient"
                        sx={{
                            minWidth: 156,
                            color: "#F72642",
                            backgroundColor: "#FFFFFF",
                            width: desktop ? "156px" : "100%",
                            whiteSpace: "nowrap",
                        }}
                        onClick={clearFilter}
                    />
                </Box>
            </Box>
        </Box>
    );
}

function Users() {
    const { setHeaderSlot } = useContext(AdminPanelContext);
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    const [ total, setTotal ] = useState(0);
    const [ filterDataString, setFilter ] = useState(JSON.stringify({
        sortItem: {
            fieldName: "name", 
            direction: 0
        },
        filterItems: []
    }));
    const navigate = useNavigate();

    const CreateAdmin = () => (
        <Link to="/adminpanel/userCreation">
            <WrapperButton
                //@ts-ignore
                label="Создать&nbsp;администратора"
                variant="contained"
                size={desktop ? "large" : "normal"}
                iconPosition="left"
                sx={{
                    width: '228px',
                    height: '36px',
                    margin: desktop ? 0 : '10px 0',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: '1.857rem'
                }}
            />
        </Link>
    );

    useEffect(() => {
        setHeaderSlot?.(<CreateAdmin/>);
    }, [ setHeaderSlot, desktop ]);

    const filterData: FilterData = JSON.parse(filterDataString);
    const applyFilter = (filter: FilterItems) => {
        filterData.filterItems = filter.filterItems;
        setFilter(JSON.stringify(filterData));
    }

    return(
        <Box
            sx={{
                width: '100%',
                display: desktop ? 'flex' : 'block',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '30px'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: desktop ? 'flex' : 'block',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px'
                }}
            >
                <Box
                    sx={{
                        textAlign: desktop ? 'end' : 'start',
                        width: '100%',
                        fontStyle: 'italic',
                        fontSize: '0.875rem',
                        fontWeight: 300,
                        color: '#848484',
                    }}
                >
                    Всего пользователей: <span style={{ fontWeight: 700 }}>{ total }</span>
                </Box>
                <Filter applyFilter={applyFilter} desktop={desktop} value={filterData} />
            </Box>
            <DataTable<UserResponseItem>
                requestData={user.search}
                onResponse={res => { setTotal(res.data.data.itemsTotal); }}
                columns={desktop ? desktopColumns : mobileColumns}
                desktopTableProps={{
                    bodyCell: () => ({
                        sx: { cursor: 'pointer' }
                    }),
                    row: ({row}) => ({
                        onClick: () => {
                            if (row) navigate(`${row['id']}`)   //вместо старого пропа onRowClicked
                        }
                    }),
                    headCell: ({ column }) => ({
                        sx: (column?.id === 'modified') ?
                            { minWidth: '155px' } : {}
                    })
                }}
                mobileTableProps={{
                    row: ({row}) => ({
                        onClick: () => {
                            if (row) navigate(`${row['id']}`)
                        }
                    }),
                }}
                searchOptions={filterData}
            />
        </Box>
    );
}

export default Users;