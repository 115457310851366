import { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAlert } from "../../store/alert";
import { req } from "../../api";

function ConfirmEmail() {
  const [queryParameters] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { openAlert } = useAlert();

  useEffect(() => {
    const id = queryParameters.get("id");
    const code = queryParameters.get("code");
    if (id && code) {
      req.user
        .confirmEmail(id, code)
        .then(() => {
          if (!location.state || location.state === "/") {
            navigate("/personalArea");
          } else {
            navigate(location.state);
          }
        })
        .finally(() => {
          openAlert({
            text: "Электронная почта успешно подтверждена",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
        });
    }
  }, []);

  return null;
}

export default ConfirmEmail;
