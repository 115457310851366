import { Box, Typography } from "../../../../../components";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../../theme/theme";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import { useEffect, useState } from "react";
import { req } from "../../../../api";
import DataTable from "../../../../component/DataTable";
import { TariffUserTableDto } from "../../../../api/request/analitics";
import { TColumn } from "../../../../component/DataTable/types";
import moment from "moment";
import WrappedSelect from "../../../../component/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import WrapperButton from "../../../../component/Button/WrappedButton";

type OptionSelect = { value: number; label: string }[];

function UsersTariff() {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const mobile = !desktop;
  const [data, setData] = useState<{ name: string; value: number }[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [selectValue, setSelectValue] = useState<number[]>([]);
  const [optionsSelect, setOptionSelect] = useState<OptionSelect | null>(null);
  const [changeSelectTariffs, setChangeSelectTariffs] = useState<number[]>([]);

  const columns: TColumn<TariffUserTableDto>[] = [
    { name: "Тариф", id: "tariffName", sortId: "tariffId" },
    {
      name: "ФИО",
      id: "userFullName",
    },
    {
      name: "Телефон",
      id: "phoneNumber",
    },
    {
      name: "Название заведения",
      id: "organizationName",
    },
    {
      name: "Срок действия подписки",
      id: "subscriptionStart",
      renderComponent: ({ row }) => {
        return (
          <>{`${row.subscriptionEnd ? moment(row.subscriptionStart).format("DD.MM.YYYY") : "-"} ${row.subscriptionEnd ? "- " + moment(row.subscriptionEnd).format("DD.MM.YYYY") : ""}`}</>
        );
      },
    },
    {
      name: "Период подписки",
      id: "periodName",
    },
  ];

  useEffect(() => {
    req.analitics.getTariffUserDiagram().then((res) => {
      if (res.success && res.data) {
        const data = Object.keys(res.data.diagramData).map((key, index) => {
          return { name: key, value: res.data.diagramData[key] };
        });
        setData(data);
        setTotalCount(res.data.totalCount);
      }
    });
    req.dictionary.getDictionary("TariffEnum").then((res) => {
      if (res.data) {
        setOptionSelect(() => {
          return res.data?.reduce((acc, el) => {
            acc.push({ value: el.id, label: el.title });
            return acc;
          }, [] as OptionSelect);
        });
      }
    });
  }, []);

  const COLORS = ["#8B3CBB", "#3C6FBB", "#3CACBB", "#BB3C3C"];

  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }: {
    cx: number;
    cy: number;
    midAngle: number;
    innerRadius: number;
    outerRadius: number;
    percent: number;
    index: number;
  }) => {
    if (!percent) return null;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#FFFFFF"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
        fontSize="14px"
        fontFamily="Inter"
        fontWeight={700}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  function LabelWrapper({
    label,
    children,
    fullwidth,
  }: {
    label: string;
    children: React.ReactElement;
    fullwidth?: boolean;
  }) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: desktop ? "center" : "start",
          gap: desktop ? "10px" : "2px",
          flexDirection: desktop ? "row" : "column",
          width: fullwidth ? "100%" : "unset",
        }}
      >
        <Typography variant="caption">{label}</Typography>
        {children}
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "30px" }}>
      {data.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px 0px",
            position: "relative",
            gap: desktop ? "0px" : "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
              "& ul": {
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: "25px",
              },
            }}
          >
            <Typography fontSize="24px" fontWeight={700}>
              Тарифы пользователей:
            </Typography>
            <Legend
              iconSize={24}
              wrapperStyle={{
                position: "relative",
                fontSize: "14px",
              }}
              payload={data.map((item, index) => ({
                id: item.name,
                value: `${item.name} - ${item.value} (${((item.value * 100) / totalCount).toFixed(0)}%)`,
                legendIcon: (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      y="0.5"
                      width="24"
                      height="24"
                      rx="4"
                      fill={COLORS[index % COLORS.length]}
                    />
                  </svg>
                ),
              }))}
            />
          </Box>
          <PieChart width={260} height={260}>
            <Pie
              data={data}
              cx={"50%"}
              cy={"50%"}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={"100%"}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
          <Box
            sx={{
              display: "flex",
              flexDirection: desktop ? "column" : "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Typography fontSize="60px" fontWeight={900} color="#F82440">
              {totalCount}
            </Typography>
            <Typography
              fontSize="14px"
              fontWeight={700}
              color="#090909"
              textAlign={desktop ? "center" : "start"}
              whiteSpace="pre-line"
            >
              {desktop
                ? "Количество всех подписок"
                : "Количество\r\n всех подписок"}
            </Typography>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: desktop ? "row" : "column",
          gap: "20px",
          alignItems: desktop ? "center" : "start",
        }}
      >
        {optionsSelect && (
          <LabelWrapper
            label="Тариф"
            children={
              <FormControl sx={{ width: desktop ? "250px" : "100%" }}>
                <InputLabel
                  sx={{
                    top: "-9px",
                    display: selectValue.length > 0 ? "none" : "unset",
                    "&.Mui-focused": { display: "none" },
                  }}
                >
                  Выбрать
                </InputLabel>
                <WrappedSelect
                  multiple={true}
                  options={optionsSelect}
                  value={selectValue}
                  onChange={(e) => {
                    const changeValue = e.target.value as number[];
                    setSelectValue(changeValue);
                  }}
                  sx={{ height: "36px" }}
                />
              </FormControl>
            }
            fullwidth={!desktop ? true : false}
          />
        )}
        <WrapperButton
          label="Применить"
          variant="contained"
          sx={{
            marginLeft: desktop ? "auto" : "unset",
            height: "36px",
            whiteSpace: "nowrap",
          }}
          onClick={() => setChangeSelectTariffs(selectValue)}
        />
      </Box>
      <DataTable<TariffUserTableDto>
        requestData={req.analitics.getTariffUserTable}
        columns={columns}
        searchOptions={{
          sortItem: { fieldName: "tariffId", direction: 0 },
          filterItems: [
            {
              fieldName: "tariffs",
              condition: 0,
              value: JSON.stringify(changeSelectTariffs),
              valueTo: "",
            },
          ],
        }}
      />
    </Box>
  );
}

export default UsersTariff;
