import { createSlice } from "@reduxjs/toolkit";
import { modelExtensions } from "../model";
import { req } from "../../api";
import { getSliceDataSelectorFactory } from "../../utils";
import { useDispatch, useSelector } from "../model/extensions";
import { LoginParams, UserInfo } from "../../api/request/auth";
import {
  GroupType,
  HistoryDataDto,
  PositionType,
  RequestTableType,
  TemplateDataDto,
} from "../../api/request/template";
import moment from "moment";
import { alertSlice } from "../alert";
import { NotFoundError } from "../../utils/errors";

export const templateDefaultValue: TemplateDataDto = {
  organizationLogoId: "",
  workhoursString: "",
  id: 0,
  status: 1,
  statusName: "",
  organizationName: "",
  organizationAddress: "",
  created: new Date(),
  modified: null,
  published: null,
  canPublish: false,
  name: "",
  description: "",
  phoneNumber: "",
  website: "",
  whatsApp: "",
  telegram: "",
  link: "",
  theme: 1,
  languages: [1],
  groups: [],
  positions: [],
};

export const defaultPositionValue: PositionType = {
  prices: [{ amount: null, value: 0 }],
  checked: false,
  name: '',
  ingredients: '',
  description: '',
  foodValue: 0,
  proteins: 0,
  fats: 0,
  carbohydrates: 0,
  stop: false,
  order: 0,
  features: [],
  groups: [],
  id: null
};

type initialStateType = {
  data: TemplateDataDto | null;
  historyData: HistoryDataDto[];
  requestHistoryData: {
    page: number;
    pagesTotal: number;
  };
};

const initialState: initialStateType = {
  data: templateDefaultValue,
  historyData: [],
  requestHistoryData: {
    page: 1,
    pagesTotal: 0,
  },
};

//Функция получения данных шаблона
export const getTemplateData = modelExtensions.createAsyncThunk(
  "getTemplateData",
  async ({ id, guid }: { id: number; guid?: string }, thunkApi) => {
    const userData = thunkApi.getState().user.user;
    if (Number(id) === 0) {
      if (guid) {
        return { ...(await req.template.getImportGuid(guid)).data, id: 0 };
      }
      return {
        ...templateDefaultValue,
        name: `Меню от ${moment().format("DD.MM.YYYY")}`,
        organizationLogoId: userData?.organization?.logoId,
        organizationName: userData?.organization?.name,
        organizationAddress: userData?.organization?.address,
        workhoursString: userData?.organization?.workhoursString,
        phoneNumber: userData?.organization?.phoneNumber,
        website: userData?.organization?.website,
        telegram: userData?.organization?.telegram,
        whatsApp: userData?.organization?.whatsApp,
      } as TemplateDataDto;
    }
    const response = await req.template.getTemplateData(id);
    return response.data;
  },
);

//Функция получения истории изменений
export const getHistoryData = modelExtensions.createAsyncThunk(
  "getHistoryData",
  async (id: string, thunkApi) => {
    const tempaleData = thunkApi.getState().templateCard;
    const request: RequestTableType = {
      count: 10,
      page: tempaleData.requestHistoryData.page,
      filterItems: [],
      sortItem: {
        fieldName: "modificationDate",
        direction: 0,
      },
    };
    const response =
      Number(id) > 0 ? await req.template.getHistoryData(request, id) : null;
    return response;
  },
);

//Функция получения следующих истории изменений
export const getHistoryNextData = modelExtensions.createAsyncThunk(
  "getHistoryNextData",
  async (id: string, thunkApi) => {
    const tempaleData = thunkApi.getState().templateCard;
    const request: RequestTableType = {
      count: 10,
      page: tempaleData.requestHistoryData.page,
      filterItems: [],
      sortItem: {
        fieldName: "modificationDate",
        direction: 0,
      },
    };
    const response =
      Number(id) > 0 ? await req.template.getHistoryData(request, id) : null;
    return response;
  },
);

//Функция сохранения данных шаблона
export const saveTemplateData = modelExtensions.createAsyncThunk(
  "saveTemplateData",
  async (_, thunkApi) => {
    const templateCardData = thunkApi.getState().templateCard;
    if (templateCardData.data) {
      const response = await req.template.saveTemplateData(
        templateCardData.data,
        templateCardData.data.id,
      );
      thunkApi.dispatch(
        alertSlice.actions.openAlert({
          type: "success",
          autoHide: true,
          isVisibleCloseIcon: true,
          text: "Данные шаблона успешно сохранены",
        }),
      );
    }
  },
);

//Функция получения данных конструктора
export const getTemplateConstructorData = modelExtensions.createAsyncThunk(
  "getTemplateConstructorData",
  async (id: number, thunkApi) => {
    //TODO будет запрос за получением данных конструктора шаблона по его id
    //const response = await req.auth.getCurrent();
    //return response;
  },
);

export const templateCardSlice = createSlice({
  name: "templateCard",
  initialState,
  extraReducers: (builder) => {
    //getTemplateData
    builder.addCase(getTemplateData.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(getTemplateData.rejected, (state, action) => {
      console.error(action.error);

      if (action.error.message?.indexOf('404') ?? -1 > -1 ) {
        throw new NotFoundError(action.error.message!);
      }

      throw action.error;
    });
    //getHistoryData
    builder.addCase(getHistoryData.fulfilled, (state, action) => {
      if (!action.payload) {
        state.historyData = [];
        state.requestHistoryData.page = 1;
        state.requestHistoryData.pagesTotal = 0;
      } else {
        state.historyData = action.payload.data?.items;
        state.requestHistoryData.pagesTotal = action.payload.data?.pagesTotal;
      }
    });
    builder.addCase(getHistoryData.rejected, (state, action) => {
      console.error(action.error);
    });
    //getHistoryNextData
    builder.addCase(getHistoryNextData.fulfilled, (state, action) => {
      if (!action.payload) {
        state.historyData = [];
        state.requestHistoryData.page = 1;
        state.requestHistoryData.pagesTotal = 0;
      } else {
        state.historyData = [
          ...state.historyData,
          ...action.payload.data.items,
        ];
        state.requestHistoryData.pagesTotal = action.payload.data.pagesTotal;
      }
    });
    builder.addCase(getHistoryNextData.rejected, (state, action) => {
      console.error(action.error);
    });
  },
  reducers: {
    addGroup(state, action: { payload: { name: string } }) {
      const { name } = action.payload;
      state.data?.groups.push({
        id: 0,
        checked: false,
        name: name,
        order: state.data?.groups.length + 1,
        positions: [],
      });
    },
    addPositions(
      state,
      action: {
        payload: {
          groupIndex: number;
          position: Omit<PositionType, "order" | "id">;
        };
      },
    ) {
      const { groupIndex, position } = action.payload;
      state.data?.groups[groupIndex].positions.push({
        ...position,
        order: state.data?.groups[groupIndex].positions.length + 1,
        id: 0,
      });
    },
    deleteGroup(state, action: { payload: number[] }) {
      const filterConstructorData = state.data?.groups.filter((el, index) => {
        return !action.payload.includes(index);
      });
      if (state.data && state.data.groups && filterConstructorData) {
        state.data.groups = filterConstructorData;
      }
    },
    updateGroups(state, action: { payload: GroupType }) {
      if (state.data && state.data.groups) {
        state.data.groups = action.payload;
      }
    },
    updateTemplateData(
      state,
      action: { payload: Omit<TemplateDataDto, "groups"> },
    ) {
      if (state.data && state.data.groups) {
        state.data = { ...action.payload, groups: state.data.groups };
      }
    },
    setPage(state) {
      state.requestHistoryData.page += 1;
    },
    // deletePosition(state, action: {payload: number[]}) {
    //   const filterConstructorData = state.constructorData.filter((el, index) => {
    //     return !action.payload.includes(index)
    //   })
    //   state.constructorData=filterConstructorData;
    // },
  },
});

export default templateCardSlice.reducer;

const selectorFactory = getSliceDataSelectorFactory("templateCard");
const selectGroupsData = selectorFactory((state) => {
  return { groups: state.data?.groups };
});
const selectHistoryData = selectorFactory((state) => {
  return state.historyData;
});
const selectRequestHistoryData = selectorFactory((state) => {
  return state.requestHistoryData;
});
const selectTemplateData = selectorFactory((state) => {
  if (state.data) {
    //const { groups, ...data } = state.data;
    return state.data;
  }
  //const { groups, ...data } = defaultValue;
  return templateDefaultValue;
});
const selectTemplateId = selectorFactory((state) => {
  if (state.data) {
    return state.data.id;
  }
  return 0;
});

//selection

export function useTemlateCardData() {
  const dispatch = useDispatch();
  const groups = useSelector(selectGroupsData);
  const tempateData = useSelector(selectTemplateData);
  const tempateId = useSelector(selectTemplateId);
  const historyData = useSelector(selectHistoryData);
  const requestHistoryData = useSelector(selectRequestHistoryData);

  const updateGroups = (groups: GroupType) => {
    dispatch(templateCardSlice.actions.updateGroups(groups));
  };

  const updateTempateData = (templateData: Omit<TemplateDataDto, "groups">) => {
    dispatch(templateCardSlice.actions.updateTemplateData(templateData));
  };

  const nextHistoryData = async () => {
    await dispatch(templateCardSlice.actions.setPage());
    dispatch(getHistoryData(tempateId.toString()));
  };

  const addGroup = (payload: { name: string }) => {
    dispatch(templateCardSlice.actions.addGroup(payload));
  };

  return {
    tempateData,
    groups,
    tempateId,
    historyData,
    requestHistoryData,
    addGroup,
    updateGroups,
    updateTempateData,
    nextHistoryData,
  };
}
