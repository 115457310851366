import { Box } from "../../../components";
import { RefObject, useEffect, useRef, useState } from "react";
import AliceCarousel, { Props } from "react-alice-carousel";

export type CarouselBaseProps = Props & {
  height?: string;
  width?: string;
  paddingLeft?: number;
  paddingRight?: number;
  isActive?: boolean;
};

/**Общий стилизованный компонент на всю ширину страницы */
function CarouselBase({ width = "100%", paddingLeft = 23,
  paddingRight = 23, isActive = true, ...props }: CarouselBaseProps) {
  //position: 'absolute', чтобы убрать горизонтальный скроллбар
  const ref = useRef(null) as RefObject<HTMLDivElement>;
  const [ height, setHeight ] = useState<string|undefined>(undefined);

  useEffect(() => {
      if (!ref.current) return;
      setHeight(ref.current.offsetHeight + 'px');
  }, [ ref.current, isActive ]);

  return(
      <Box
          sx={{
              position: 'relative',
              width,
              height: "37px",
              overflow: 'hidden',
              display: isActive ? "block" : "none",
              "& ul": {
                display: "flex",
                gap: "5px",
              },
          }}
      >
          <div
              ref={ref}
              className={isActive ? "nav-active": ""}
              style={{
                  position: 'absolute',
                  width: '100%',
                  top: 0,
                  left: 0,
              }}
          >
              <AliceCarousel
                  mouseTracking
                  disableDotsControls
                  autoWidth
                  disableButtonsControls
                  paddingLeft={paddingLeft}
                  paddingRight={paddingRight}
                  { ...props }
              />
          </div>
      </Box>
  );
}

export default CarouselBase;
