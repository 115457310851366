import { NavLink, NavLinkProps, useLocation } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import useLinkSelect from "../useLinkSelect";

function StyledNavLink(props: NavLinkProps & { childrenLink: string }) {
  const { id } = props;
  const { isActive, linkRef } = useLinkSelect(id!);
  const location = useLocation();

  return (
    <ListItemButton
      sx={{
        display: "flex",
        flexDirection: "row",
        position: "relative",
        color: "#000",
        padding: 0,
        backgroundColor: "none",
        width: "100%",
        "&:hover": {
          backgroundColor: "#FFF",
        },
        "& a:hover": {
          textDecoration: "none",
        },
      }}
    >
      <NavLink
        ref={linkRef}
        style={{
          fontWeight: isActive ? "bold" : "unset",
          textDecoration: isActive ? "none" : "underline",
          textDecorationStyle: isActive ? "unset" : "dashed",
          textDecorationThickness: isActive ? 0 : "1px",
          color: isActive ? "#000" : "#0D0C22",
          borderRadius: "4px 0 0 4px",
          padding: "1px 10px 4px 8px",
          width: "100%",
          display: "block",
        }}
        state={props.childrenLink ? { childrenLink: props.childrenLink } : {}}
        {...props}
      />
    </ListItemButton>
  );
}

export default StyledNavLink;
