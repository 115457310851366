import ConfirmModal from "../../../component/Modal/ConfirmModal";

const PublicOfferModal = ({
  onClose,
}: { onClose: () => void }) => {
  return (
      <ConfirmModal
          onClose={onClose}
          onAccept={onClose}
          buttonAccept='Закрыть'
          buttonCancel={null}
          headerModal="Публичная оферта"
          bodyModal='Тут будет текст'
      />
  );
};

export default PublicOfferModal;
