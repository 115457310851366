import React from "react";
import {
  TextField,
  TextFieldProps as MiuTextFieldProps,
  InputAdornment,
  IconButton,
} from "@mui/material";
import Clear from "@mui/icons-material/Clear";

export interface InputProps extends Omit<MiuTextFieldProps, "size"> {
  type?: React.HTMLInputTypeAttribute;
  clearable?: boolean;
  onClear?: () => void;
}

const InputText = ({
  clearable = false,
  type = "text",
  sx = [],
  onClear,
  ...props
}: InputProps) => {
  return (
    <TextField
      sx={[
        (theme) => ({
          "& .MuiInputBase-input": {
            width: clearable ? "80% !important" : "100%  !important",
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      type={type}
      InputProps={{
        endAdornment: (
          <InputAdornment
            sx={{ position: "absolute", right: `7px` }}
            position="end"
          >
            <IconButton
              sx={{
                color: "currentColor",
                visibility:
                  //@ts-ignore
                  clearable && props.value.length && !props.disabled
                    ? "visible"
                    : "hidden",
              }}
              onClick={onClear}
            >
              <Clear fontSize="small" />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default InputText;
