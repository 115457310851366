import { Box, Grid, Typography } from "../../../components";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import Autorization from "./autorization";
import gradientsAndPhones from "shared/assets/img/gradients-and-phones.svg";
import { UnauthorizationFooter } from "../../component/Footer";

function LoginPage() {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  const Item = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    paddingLeft: "0px !important",
  }));

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.main,
        overflow: "unset",
        width: "100%",
        position: "relative",
        zIndex: 1,
        color: "white",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        overflowX: "clip",
      })}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          padding: `${desktop ? "104px 0 75px 0" : "20px"}`,
          margin: 0,
          width: "100%",
          maxWidth: 1280,
          gap: desktop ? "75px" : "20px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "start", gap: "15px" }}>
          <Item
            item
            mobile={12}
            desktop={6}
            sx={{ height: "fit-content", paddingTop: 0 }}
          >
            <Autorization />
          </Item>
          <Item
            item
            mobile={12}
            desktop={6}
            sx={{
              display: `${!desktop ? "none" : ""}`,
              position: "relative",
              paddingTop: 0,
              alignSelf: "center",
            }}
          >
            <img
              style={{
                height: 800,
                marginRight: -134,
                marginTop: -104,
              }}
              src={gradientsAndPhones}
              alt=""
            />
          </Item>
        </Box>
        <UnauthorizationFooter />
      </Box>
    </Box>
  );
}

export default LoginPage;
