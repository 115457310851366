import { api } from "../api";
import { TSearchData } from "../../component/DataTable/types";
import { Response } from "../type";

export type ApplyPromoCodeResponse = { 
    message: string,
    success: boolean,
    type: number,
    value: number
}

export namespace Promocode { 
    //Возвращает постраничный список промокодов
    export async function search(data: TSearchData) {
        return api.sendRequest({
            domain: "on-menu",
            method: "post",
            url: `/sales/promocodes/search`,
            data
        });
    }

    //Добавление нового промокода
    export async function create(request: {
        code: string,
        type: number,
        isActive: boolean,
        isOneTime: boolean,
        discountValue: string,
        agentId?: number | null,
    }) {
        if (request.agentId === 0) request.agentId = null;

        return api
        .sendRequest<Response>({
            domain: "on-menu",
            method: "post",
            url: `/sales/promocodes`,
            data: request,
        })
        .then((response) => response?.data)
        .catch((err) => {
            console.log(err);
            throw err;
        });
    }

    //Редактирование промокода
    export async function updatePromoCode(
        request: {
            id: number;
            isActive: boolean,
            agentId?: number,
            code: string
        },
    ) {
        return api
            .sendRequest<Response>({
                domain: "on-menu",
                method: "put",
                url: `/sales/promocodes/${request.id}`,
                data: request,
            })
            .then((response) => response?.data)
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }

    //Загрузка требуемого промокода по ID
    export async function getPromoCode(id: number) {
        return api
            .sendRequest<Response>({
                domain: "on-menu",
                method: "get",
                url: `/sales/promocodes/${id}`,
            });
    }

    export async function apply(code: string) {
        return api
            .sendRequest<Response<ApplyPromoCodeResponse>>({
                domain: "on-menu",
                method: "post",
                url: "/sales/promocodes/apply",
                data: { code }
            });
    }
}