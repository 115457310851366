import { RefObject, useContext, useEffect, useRef, useState } from "react";
import { NavMenuContext } from ".";
import { useLocation, useNavigate } from "react-router-dom";

/**Логика подсветки элементов меню */
function useLinkSelect(itemId: string) {
  //Свой isActive, так как NavLink не умеет работать с #якорями
  const [isActive, setIsActive] = useState(false);
  const { selected, setSelected, setSelectedId } = useContext(NavMenuContext);
  const linkRef = useRef(null) as RefObject<HTMLAnchorElement>;
  const location = useLocation();
  const navigate = useNavigate();

  //Для надёжности везде используются полные пути

  useEffect(() => {
    if (!linkRef.current) return;

    const isEqual =
      location.pathname + location.hash ===
      linkRef.current.pathname + linkRef.current.hash;

    if (isEqual) {
      setSelected(linkRef.current.pathname + linkRef.current.hash);
      setSelectedId(itemId);
    }
  }, [linkRef.current, location]);

  useEffect(() => {
    if (!linkRef.current || !selected) return;
    setIsActive(
      selected.includes(linkRef.current.pathname + linkRef.current.hash),
    );
    if (location?.state?.childrenLink) {
      navigate(
        `${location.pathname + location.hash}/${location?.state?.childrenLink}`,
      );
    }
  }, [selected, linkRef.current]);

  return { linkRef, isActive };
}

export default useLinkSelect;
