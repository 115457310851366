import { Box, Typography } from "../../../../components";
import { RenderDataTableProps } from "../types";
import getPropertyByStringName from "./getPropertyByStringName";

/**В column.id должен соответствовать имени поля из rows.*/
function MobileTable<ResponseItem extends object>({
  columns,
  rows,
  mobileTableProps,
  noData,
  refreshTable
}: RenderDataTableProps<ResponseItem>) {
  const containerSx = mobileTableProps?.tableContainer?.sx;
  const cardSx = mobileTableProps?.card?.({}).sx;
  const rowSx = mobileTableProps?.row?.({}).sx;
  const rowNameSx = mobileTableProps?.rowName?.({}).sx;
  const rowValueSx = mobileTableProps?.rowValue?.({}).sx;

  return (
    <>
      {!rows.length ? noData :
      <Box                                      //-----------mobileTableProps.tableContainer
        { ...mobileTableProps?.tableContainer }
        sx={[
          {
          "&>div:nth-of-type(even)": { backgroundColor: "#F9F9F9" },
          fontFamily: "Inter"
          },
          ...(Array.isArray(containerSx) ? containerSx : [containerSx])
        ]}
      >
        {rows.map((row, rowIndex) => {
          const key = columns.map((column) => getPropertyByStringName({ stringName: column.id, object: row }));

          return(
            <Box                                  //-----------mobileTableProps.card
              { ...mobileTableProps?.card?.({row, rowIndex, refreshTable}) }
              key={key.toString()}
              sx={[
                {
                  fontSize: "0.875rem",
                  lineHeight: "1.05875rem",
                  color: "#343C44",
                  padding: "10px"
                },
                ...(Array.isArray(cardSx) ? cardSx : [cardSx])
              ]}
            >
              {mobileTableProps?.renderCard?.({ row, rowIndex, refreshTable }) || columns.map((column, columnIndex) => {
                const renderValue = function() {
                  const value = getPropertyByStringName({ stringName: column.id, object: row });
                  if (column.renderComponent) {
                    if (typeof column.renderComponent === 'function') {
                      return column.renderComponent({value, row, column, rowIndex, columnIndex, refreshTable});
                    } else {
                      return value;
                    }
                  } else {
                    return mobileTableProps?.renderValue?.({value, row, column, rowIndex, columnIndex, refreshTable}) ||
                    value ;
                  }
                }();

                return(   //-----------mobileTableProps.row
                  <Box
                    { ...mobileTableProps?.row?.({row, column, rowIndex, columnIndex, refreshTable}) }
                    key={column.id}
                    sx={[
                      {
                        display: "flex",
                        flexDirection: "row",
                        marginTop: columnIndex > 0 ? "10px" : 0,
                        alignItems: 'center'
                      },
                      ...(Array.isArray(rowSx) ? rowSx : [rowSx])
                    ]}
                  >
                    <Box                          //-----------mobileTableProps.rowName
                      { ...mobileTableProps?.rowName?.({row, column, rowIndex, columnIndex, refreshTable}) }
                      sx={[
                        {
                          whiteSpace: 'nowrap',
                          marginRight: "10px"
                        },
                        ...(Array.isArray(rowNameSx) ? rowNameSx : [rowNameSx])
                      ]}
                    >
                      {column.name}
                    </Box>
                    <Box                          //-----------mobileTableProps.rowValue
                      { ...mobileTableProps?.rowValue?.({row, column, rowIndex, columnIndex, refreshTable}) }
                      sx={[
                        {
                          fontWeight: 700,
                          
                        },
                        ...(Array.isArray(rowValueSx) ? rowValueSx : [rowValueSx])
                      ]}
                    >
                      {renderValue}
                    </Box>
                  </Box>
                )})}
            </Box>
          )})}
        </Box>}
    </>
  );
}

export default MobileTable;
