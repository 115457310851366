import { TSearchData } from "../../component/DataTable/types";
import { NotFoundError, UnauthorizedError } from "../../utils/errors";
import { api } from "../api";
import { Response } from "../type";

export type RequestTableType = {
  page: number;
  count: number;
  sortItem: {
    fieldName: string;
    direction: number;
  };
  filterItems: {
    fieldName: string;
    condition: number;
    value: string;
    valueTo: string;
  }[];
};

export type ResponseTableType<ItemsDTO> = {
  pageNumber: number;
  pageSize: number;
  itemCount: number;
  firstItemNumber: number;
  itemsTotal: number;
  pagesTotal: number;
  items: ItemsDTO[];
  additionalInfo: "string";
};

export type HistoryDataDto = {
  id: number;
  menuTemplateId: number;
  userId: number;
  userName: string;
  modificationDate: Date;
  historyItems: {
    id: number;
    action: number;
    actionName: string;
    itemName: string;
    changeLogs: {
      attributeName: string;
      oldValue: string;
      newValue: string;
    }[];
  }[];
};

export type PositionPriceItem = {
  amount: string | null,
  value: number
}

export type PositionType = {
  prices: PositionPriceItem[];
  checked: boolean;
  name: string;
  ingredients: string;
  description: string;
  foodValue: number;
  proteins: number;
  fats: number;
  carbohydrates: number;
  photoId?: string;
  stop: boolean;
  order: number;
  features: number[];
  groups: number[];
  id: number | null;
};

export type GroupType = {
  id: number;
  checked: boolean;
  name: string;
  order: number;
};

export type TemplateDataDto = {
  organizationLogoId: string;
  workhoursString: string;
  id: number;
  status: number;
  statusName: string;
  organizationName: string;
  organizationAddress: string;
  created: Date;
  modified: Date | null;
  published: Date | null;
  canPublish: boolean;
  name: string;
  description: string;
  phoneNumber: string;
  website: string;
  telegram: string;
  whatsApp: string;
  link: string;
  theme: number;
  languages: number[];
  groups: GroupType[];
  positions: PositionType[];
};

export namespace Template {
  //Возвращает постраничный список шаблонов
  export async function getTemplates(data: TSearchData) {
    return api.sendRequest<Response<{items: TemplateDataDto[]}>>({
      domain: "on-menu",
      method: "post",
      url: `/menu/menuTemplate/search`,
      data: data,
    });
  }
  export async function getTemplateData(id: number) {
    return api
      .sendRequest<Response<TemplateDataDto>>({
        domain: "on-menu",
        method: "get",
        url: `/menu/menuTemplate/${id}`,
      })
      .then((response) => {
        if (response.status === 401) {
          throw new UnauthorizedError("Unauthorized");
        }

        return response?.data;
      })
      .catch((err) => {
        if (err?.response?.status === 404) {
          throw new NotFoundError(err.message);
        }
        throw err;
      });
  }
  export async function getHistoryData(request: RequestTableType, id: string) {
    return api
      .sendRequest<Response<ResponseTableType<HistoryDataDto>>>({
        domain: "on-menu",
        method: "post",
        url: `/menu/menuTemplate/${id}/history`,
        data: request,
      })
      .then((response) => response?.data)
      .catch((err) => {
        throw err;
      });
  }
  export async function saveTemplateData(request: TemplateDataDto, id: number) {
    return api
      .sendRequest<Response<number>>({
        domain: "on-menu",
        method: id === 0 ? "post" : "put",
        url: `/menu/menuTemplate`,
        data: request,
      })
      .then((response) => response.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function deleteTemplate(id: number) {
    return api
      .sendRequest<Response<number>>({
        domain: "on-menu",
        method: "delete",
        url: `/menu/menuTemplate/${id}`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function copyTemplate(id: number) {
    return api
      .sendRequest<Response<number>>({
        domain: "on-menu",
        method: "post",
        url: `/menu/menuTemplate/${id}/copy`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function getGuid(id: number) {
    return api
      .sendRequest<Response<string>>({
        domain: "on-menu",
        method: "get",
        url: `/menu/menuTemplate/${id}/guid`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function getImportGuid(guid: string) {
    return api
      .sendRequest<Response<TemplateDataDto>>({
        domain: "on-menu",
        method: "get",
        url: `/menu/menuTemplate/${guid}/Import`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function publishTemplate(id: number) {
    return api
      .sendRequest<Response<number>>({
        domain: "on-menu",
        method: "put",
        url: `/menu/menuTemplate/${id}/publish`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function savePositionData(position: PositionType, templateId: number) {
    const positionId = position.id
    let url = `/menu/menuTemplate/${templateId}/position`
    if (positionId) {
      url += `/${positionId}`
    }
    return api
      .sendRequest<Response<number>>({
        domain: "on-menu",
        method: !positionId ? "post" : "put",
        url,
        data: position,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function updatePositionsOrder(positions: { id: number, order: number }[], templateId: number) {
    let url = `/menu/menuTemplate/${templateId}/position/orders`
    return api
      .sendRequest<Response<number>>({
        domain: "on-menu",
        method: "post",
        url,
        data: positions,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function deletePosition(positionId: number, templateId: number) {
    return api
      .sendRequest<Response<number>>({
        domain: "on-menu",
        method: "delete",
        url: `/menu/menuTemplate/${templateId}/position/${positionId}`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function updateGroups(groups: GroupType[], templateId: number) {
    return api
      .sendRequest<Response<number>>({
        domain: "on-menu",
        method: "post",
        url: `/menu/menuTemplate/${templateId}/group`,
        data: groups,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function exportTemplate(id: number) {
    return api
      .sendRequest<Response<{link:string}>>({
        domain: "on-menu",
        method: "post",
        url: `/menu/menuTemplate/${id}/export`,
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }
  export async function importTemplate(link: string) {
    return api
      .sendRequest<Response<{id:number}>>({
        domain: "on-menu",
        method: "post",
        url: `/menu/menuTemplate/import`,
        data: { link }
      })
      .then((response) => response?.data)
      .catch((err) => {
        console.log(err);
        throw err;
      });
  }

}
