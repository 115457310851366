import * as React from "react";
import {
  DatePicker as DatePickerMui,
  DatePickerProps as DatePickerPropsMui,
} from "@mui/x-date-pickers/DatePicker";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Clear from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import moment from "moment";

const mergeAdornments = (...adornments: React.ReactNode[]) => {
  const nonNullAdornments = adornments.filter((el) => el != null);
  if (nonNullAdornments.length === 0) {
    return null;
  }

  if (nonNullAdornments.length === 1) {
    return nonNullAdornments[0];
  }

  return (
    <Stack direction="row">
      {nonNullAdornments.map((adornment, index) => (
        <React.Fragment key={index}>{adornment}</React.Fragment>
      ))}
    </Stack>
  );
};

const PickerTextField = ({
  onClearClick,
  clearable,
  ...rest
}: TextFieldProps & { clearable: boolean; onClearClick: () => void }) => (
  <TextField
    {...rest}
    InputProps={{
      ...rest.InputProps,
      endAdornment: mergeAdornments(
        <InputAdornment
          sx={{ position: "absolute", right: `35px` }}
          position="end"
        >
          <IconButton
            sx={{
              color: "currentColor",
              visibility:
                //@ts-ignore
                clearable && rest.value.length && !rest.disabled
                  ? "visible"
                  : "hidden",
            }}
            onClick={onClearClick}
          >
            <Clear fontSize="small" />
          </IconButton>
        </InputAdornment>,
        rest.InputProps?.endAdornment ?? null
      ),
    }}
  />
);

export interface DatePickerProps extends DatePickerPropsMui<moment.Moment> {
  color?: "error" | "primary" | "secondary" | "info" | "success" | "warning";
  error?: boolean;
  clearable?: boolean;
  helperText?: string;
  variant?: "filled" | "outlined" | "standard";
}

const DatePicker = ({
  helperText,
  error = false,
  clearable = false,
  variant = "filled",
  color = "primary",
  ...props
}: DatePickerProps) => {
  return (
    <DatePickerMui
      {...props}
      slotProps={{
        actionBar: {
          actions: ["clear"],
        },
        textField: {
          helperText,
          clearable,
          variant,
          error,
          color,
          //@ts-ignore
          onClearClick: () => props.onChange(null),
        } as any,
      }}
      slots={{
        textField: PickerTextField as React.ElementType<TextFieldProps>,
      }}
    />
  );
};

export default DatePicker;
