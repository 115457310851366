import React from "react";
import { Popover, useMediaQuery } from "@mui/material";
import { Typography, Box } from "../../../components";
import { darkTheme } from "../../theme/theme";

interface PasswordHintPopoverProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  handlePopoverClose: () => void;
}
export const PasswordHintPopover: React.FC<PasswordHintPopoverProps> = ({
  open,
  anchorEl,
  handlePopoverClose,
}) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Popover
      id="mouse-over-popover"
      sx={(theme) => ({
        pointerEvents: "none",

        ".MuiPaper-root": {
          "&.MuiPopover-paper": {
            padding: desktop ? "29px 31px 32px 52px" : "13px 18px 15px 39px",
            fontStyle: theme.typography.body1,
            borderRadius: desktop ? "30px" : "10px",
          },
        },
      })}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      {...{ open, anchorEl }}
    >
      <Typography sx={{ width: "100%", display: "flex", marginLeft: "-21px" }}>
        Пароль должен соответствовать требованиям:
      </Typography>
      <Box
        component="ul"
        sx={(theme) => ({
          fontStyle: theme.typography.body1,
        })}
      >
        <Box component="li">длина пароля от 8 до 128 символов;</Box>
        <Box component="li">
          как минимум одна заглавная и одна строчная буква;
        </Box>
        <Box component="li">только латинские или кириллические буквы;</Box>
        <Box component="li">как минимум одна цифра;</Box>
        <Box component="li">только арабские цифры;</Box>
        <Box component="li">без пробелов;</Box>
        <Box component="li">{`другие допустимые символы: ~ ! ? @ # $ % ^ & * _ - + ( ) [ ] { } > < / \ | " ' . , :`}</Box>
      </Box>
    </Popover>
  );
};

export const NickHintPopover: React.FC<PasswordHintPopoverProps> = ({
  open,
  anchorEl,
  handlePopoverClose,
}) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Popover
      id="mouse-over-popover"
      sx={(theme) => ({
        pointerEvents: "none",

        ".MuiPaper-root": {
          "&.MuiPopover-paper": {
            padding: desktop ? "29px 31px 32px 52px" : "13px 18px 15px 39px",
            fontStyle: theme.typography.body1,
            borderRadius: desktop ? "30px" : "10px",
          },
        },
      })}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
      {...{ open, anchorEl }}
    >
      <Typography sx={{ width: "100%", display: "flex", marginLeft: "-21px" }}>
        Сетевое имя будет отображаться в адресной строке на странице с опубликованным меню. Введите название ресторана латинскими буквами.
      </Typography>
    </Popover>
  );
};