import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../../../components";
import { darkTheme } from "../../../theme/theme";
import { RenderDataTableProps } from "../types";
import { StyledBodyCell, StyledHeadCell } from "./Cells";
import getPropertyByStringName from "./getPropertyByStringName";
import { nanoid } from "nanoid";

/**В column.id должен соответствовать имени поля из rows.*/
function DesktopTable<ResponseItem extends object>({
  columns,
  rows,
  desktopTableProps,
  showIndexColumn = true,
  noData,
  refreshTable
}: RenderDataTableProps<ResponseItem>) {
  const headCellSx = desktopTableProps?.headCell?.({}).sx;
  const bodyCellSx = desktopTableProps?.bodyCell?.({}).sx;
  const tableSx = desktopTableProps?.table?.sx;

  return (
    <>
      {!rows.length ? noData :
      <TableContainer { ...desktopTableProps?.tableContainer }>
        <Table
          { ...desktopTableProps?.table }
          sx={[
            {
              border: 0
            },
            ...(Array.isArray(tableSx) ? tableSx : [tableSx])
          ]}
        >
          <TableHead { ...desktopTableProps?.head }>
            <TableRow { ...desktopTableProps?.row }>
              {showIndexColumn && (
                <TableCell
                  { ...desktopTableProps?.headCell }
                  sx={[
                    {
                      backgroundColor: darkTheme.palette.primary.main,
                      border: 0,
                      width: "35px"
                    },
                    ...(Array.isArray(headCellSx) ? headCellSx : [headCellSx])
                  ]}
                />
              )}
              {columns.map((column, columnIndex) => (
                <StyledHeadCell
                  key={column.id}
                  columnId={column.sortId || column.id}
                  { ...desktopTableProps?.headCell?.({column, columnIndex, refreshTable}) }
                >
                  {column.name}
                </StyledHeadCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            { ...desktopTableProps?.body }
            sx={[
              {
                "&>tr:nth-of-type(even)": {
                  backgroundColor: "#F9F9F9",
                }
              },
              ...(Array.isArray(bodyCellSx) ? bodyCellSx : [bodyCellSx])
            ]}
          >
            {rows.map((row, rowIndex) => {
              //const key = columns.map((column) => getPropertyByStringName({ stringName: column.id, object: row }));
              return(
                <TableRow
                  //key={key.toString()}
                  key={nanoid()}
                  { ...desktopTableProps?.row?.({row, rowIndex, refreshTable}) }
                >
                  {showIndexColumn && (
                    <StyledBodyCell
                      { ...desktopTableProps?.bodyCell }
                      sx={[
                        {
                          textAlign: "center",
                          padding: "17.5px 10px"              
                        },
                        ...(Array.isArray(bodyCellSx) ? bodyCellSx : [bodyCellSx])
                      ]}
                    >
                      {rowIndex + 1}
                    </StyledBodyCell>
                  )}
                  {columns.map((column, columnIndex) => {
                    const value = getPropertyByStringName({ stringName: column.id, object: row });

                    return(
                      <StyledBodyCell
                        key={nanoid()}
                        {...desktopTableProps?.bodyCell?.({row, column, rowIndex, columnIndex, refreshTable})}
                      >
                        {typeof column.renderComponent === 'function' ?
                          column.renderComponent({ value, row, column, rowIndex, columnIndex, refreshTable }) : value}
                      </StyledBodyCell>);
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>}
    </>
  );
}

export default DesktopTable;
