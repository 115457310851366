import {
    Box, Button, Typography
} from "../../../../components";
import background from './images/background.png';
import phone1 from './images/phone1.png';
import phone2 from './images/phone2.png';
import add from "./images/add.svg";
import ContentContainer from "../../../component/ContentContainer";
import { styled } from "@mui/material/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import { darkTheme } from "../../../theme/theme";
import { useNavigate } from "react-router-dom";
import { Tab } from "../../../pages/login/autorization";

const Phone1 = styled('img')({
    width: '287px',
    objectFit: 'contain',
    zIndex: 1
});

const Phone2 = styled('img')({
    transform: 'translateY(21px) translateX(59px)',
    width: '278px',
    objectFit: 'contain'
});

const Background = styled('img')({
    position: 'absolute',
    top: 0,
    left: '-50%',
    zIndex: -1
});

const Text1 = styled('div')({
    fontSize: '2.5rem',
    fontWeight: 600,
    lineHeight: '3rem',
    color: '#FFFFFF',
    marginTop: '94px'
});

const Text2 = styled('div')({
    color:' #A1A1AA',
    fontSize: '1.125rem',
    fontWeight: 400,
    lineHeight: '1.35rem',
    marginTop: '32px',
    marginBottom: '32px',
});

const StyledButton = styled(Button)({
    minWidth: '170px',
    width: '170px',
    height: '50px',
    borderRadius: '60px',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '34px',
    color: "#18181B",
    '&:hover': {
        backgroundColor: '#f81d3a',
        color: '#FFFFFF'
    }
});

const RightBlock = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    position: 'relative'
});

const Trial = styled('div')({
    minWidth: '473px',
    position: 'relative',
    borderRadius: '60px',
    display:'inline-flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontWeight: 400,
    color: '#A1A1AA',
    background: darkTheme.palette.primary.main,
    padding: '5px',
    '&::after': {
        position: 'absolute',
        top: '-2px',
        bottom: '-2px',
        left: '-2px',
        right: '-2px',
        content: '""',
        borderRadius: '60px',
        zIndex: -1,
        background: "linear-gradient(-45deg, #674099,#ED6985)",
    }
});

function Subscribe() {
    const showBr = useMediaQuery('(min-width: 1400px)');
    const navigate = useNavigate();

    return(
        <Box
            sx={[
                (theme) => ({
                    backgroundColor: theme.palette.primary.main,
                    width: "100%",
                    zIndex: "1",
                    position: "relative",
                    overflow: 'hidden'
                }),
            ]}
        >
            <ContentContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <Box>
                        <Text1>
                            Онлайн меню в один клик, {showBr ? <br/> : <></>}
                            для любого вида общепита
                        </Text1>
                        <Text2>
                            <span style={{ fontWeight: 700 }}>Сервис On-menu</span> позволяет ресторану создавать и размещать {showBr ? <br/> : <></>}
                            электронное меню, а гостю легко просматривать и выбирать блюда
                        </Text2>
                        <Trial>
                            <img src={add} style={{ marginLeft: "23px", marginRight: '13px' }} alt="+"/>
                            Пробный тариф на 7 дней
                            <StyledButton
                                onClick={() => navigate("/login?tab=" + Tab.REGISTRATION)}
                                label={<>
                                    <Typography
                                            sx={() => ({ fontWeight: 600 })}
                                        >
                                            ПОДКЛЮЧИТЬ
                                    </Typography>
                                </>}
                            />
                        </Trial>
                    </Box>
                    <RightBlock>
                        <Phone2 src={phone2}
                            alt="phone2"
                        />
                        <Phone1
                            src={phone1}
                            alt="phone1"
                        />
                        <Background
                            src={background}
                        />
                    </RightBlock>
                </Box>
            </ContentContainer>
        </Box>
    );
}

export default Subscribe;