import { Box } from "../../../components";
import Clients from "./clients";
import Price from "./price";
import Subscribe from "./subscribe";
import ForAdmin from "./forAdmin";
import ForGuest from "./forGuest";
import PlusAndMinus from "./plusAndMinus";
import ForFree from "./forFree";
import Healthy from "./healthy";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Main() {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
          setTimeout(() => {
            element.scrollIntoView({ behavior: "smooth" });
          }, 300);
      }
    }
  }, []);

  return (
    <Box sx={{ fontFamily: "Inter" }}>
      <div id="top">
        <Subscribe />
      </div>
      <ForAdmin />
      <ForGuest />
      <div id="plus_and_minus">
        <PlusAndMinus />
      </div>
      <div id="prices">
        <Price />
      </div>
      <Clients />
      <ForFree />
      <div id="healthy">
        <Healthy />
      </div>
      {/* <Look /> */}
    </Box>
  );
}

export default Main;
