import {
    Box,
    Typography,
  } from "../../../../components";
import { TColumn, TSearchData, TSearchFilterItem } from "../../../component/DataTable/types";
import { PromoCodeResponseItem } from "./types";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery, SxProps, Theme } from "@mui/material";
import { ReactNode, useContext, useEffect, useState } from "react";
import DataTable from "../../../component/DataTable";
import { promoCode } from "../../../api/request";
import { Link, useNavigate } from "react-router-dom";
import WrapperButton from "../../../component/Button/WrappedButton";
import { AdminPanelContext } from "..";
import WrapperInput from "../../../component/Input/WrappedInput";
import FormControl from "@mui/material/FormControl";
import WrappedSelect from "../../../component/Select";

const dateColumn: TColumn<PromoCodeResponseItem> = { name: 'Дата создания', id: 'createdAt',
    renderComponent: ({ row }) => {
        const formattedDate = new Date(row.createdAt).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
        return(<>{ formattedDate }</>);
    }
};

function discountTypeConverter (value: number) : string {
    switch(value) {
        case 0:
            return "Без скидки";
        case 2:
            return "Скидка в %";
        case 4: 
            return "Скидка в рублях";
    }

    return "Неизвестно";
}

function discountValueConverter(value: number, row: PromoCodeResponseItem): string {
    switch(row.type) {
        case 0:
            return "-";
        case 2:
            return `${row.discountValue}%`;
        case 4: 
            return `${row.discountValue}₽`;
    }

    return value.toString();
}

function booleanConverter(value: boolean): string {
    return value ? "Да": "Нет";
}

const columns: TColumn<PromoCodeResponseItem>[] = [
    { name: 'Промокод', id: 'code' },
    dateColumn,
    { name: 'Тип промокода', id: 'type', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{discountTypeConverter(value)}</Box> },
    { name: 'Агент по продажам', id: 'agentName' },
    { name: 'Размер скидки', id: 'discountValue', renderComponent: ({ value, row }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ discountValueConverter(value,row) }</Box> },
    { name: 'Единоразовый промокод', id: 'isOneTime', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ booleanConverter(value) }</Box> },
    { name: 'Активность', id: 'isActive', renderComponent: ({ value }) => <Box component={'p'} sx={{ whiteSpace: 'nowrap'}}>{ booleanConverter(value) }</Box> },
];

function LabelWrapper({
    label,
    children,
    desktop,
    sx
  }: {
    label: string;
    children: React.ReactElement;
    desktop: boolean;
    sx: SxProps<Theme>;
  }) {

    const defaultSx: SxProps<Theme> = {
        display: "flex",
        alignItems: desktop ? "center" : "start",
        gap: desktop ? "10px" : "2px",
        flexDirection: desktop ? "row" : "column",
        width: desktop ? "unset" : "100%",
    };
    const p = {...defaultSx,...sx}; 

    return (
      <Box sx={p}>
        <Typography variant="caption" color="#0D0C22">{label}</Typography>
        {children}
      </Box>
    );
  }

const promoCodeTypes = [{ value: "0", label: "Без скидки"},{value: "2", label: "Скидка в %"},{value: "4", label: "Скидка в рублях"},{value: "", label: "Неважно"}];
const booleanSelect = [{ value: "true", label: "Да"},{value: "false", label: "Нет"},{value:"", label: "Неважно"}];

function Filter({ applyFilter, value, desktop }: { applyFilter: (filter: FilterItems) => void, value: FilterData, desktop: boolean}) : ReactNode {

    const getFilterValue = (fieldName: string) : string => {
        const field = value.filterItems.find(x => x.fieldName === fieldName);

        if (!field) return "";

        return field.value;
    }

    const [code, setCode ] = useState(getFilterValue("code"));
    const [type, setType ] = useState(getFilterValue("type"));
    const [agent, setAgent ] = useState(getFilterValue("agentName"));
    const [isActive, setActive] = useState<string>(getFilterValue("isActive"));
    const [isOneTime, setOneTime] = useState<string>(getFilterValue("isOneTime"));
    
    const addFilter = (fieldName: string, val: string, condition: 0|5, items: TSearchFilterItem[]) => {
        if (!val) return;
        const field = items.find(x => x.fieldName === fieldName);
        if (field) {
            field.value = val!;
        } else {
            items.push({
                condition: condition!,
                fieldName: fieldName!,
                value: val!,
                valueTo: ""
            });
        }
    }

    const saveFilter = () => {
        const items: TSearchFilterItem[] = [];

        addFilter("code", code, 5, items);
        addFilter("type", type, 0, items);
        addFilter("agentName", agent, 5, items);
        addFilter("isActive", isActive, 0, items);
        addFilter("isOneTime", isOneTime, 0, items);

        applyFilter({filterItems: items});
    }

    const clearFilter = () => {
        setCode("");
        setActive("");
        setType("");
        setAgent("");
        setOneTime("");
        applyFilter({filterItems: []});
     }

    return (<Box
        sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            alignItems: "start",
            width: "100%",
            paddingBottom: "25px"
        }}
    >
        <Box
            sx={{
                display: "flex",
                flexDirection: desktop ? "row" : "column",
                gap: "20px",
                alignItems: "start",
                width: "100%"
            }}
        >
            <LabelWrapper
                desktop={desktop}
                label="Промокод"
                sx={{
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <WrapperInput
                        backgroundColor="#FFFFFF"
                        fullWidth
                        sx={{
                            "& .MuiInputBase-root": {
                            height: "34px",
                            color: "#0D0C22 !important",
                            width: desktop ? "272px": "100%",
                            },
                        }}
                        onChange={(e: any) => { setCode(e.target.value); }}
                        value={code}
                    />
                </Box>
            </LabelWrapper>
            <LabelWrapper
                desktop={desktop}
                label="Тип промокода"
                sx={{
                    justifyContent: "space-between",
                    textWrap: "nowrap"
                }}
            >
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <WrappedSelect
                            multiple={false}
                            options={promoCodeTypes}
                            value={type}
                            onChange={(e) => {
                                const changeValue = e.target.value as string;
                                setType(changeValue);
                            }}
                            sx={{ height: "36px", width: "180px" }}
                    />
                </Box>
            </LabelWrapper>
        
            <LabelWrapper
                desktop={desktop}
                label="Единоразовый промокод"
                sx={{
                    justifyContent: "space-between",
                    textWrap: "nowrap"
                }}
            >
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <WrappedSelect
                            multiple={false}
                            options={booleanSelect}
                            value={isOneTime}
                            onChange={(e) => {
                                const changeValue = e.target.value as string;
                                setOneTime(changeValue);
                            }}
                            sx={{ height: "36px", width: "80px" }}
                    />
                </Box>
            </LabelWrapper>
        </Box>

        <Box
            sx={{
                display: "flex",
                flexDirection: desktop ? "row" : "column",
                gap: "20px",
                alignItems: desktop ? "center" : "start",
                width: "100%"
            }}
        >
    
            <LabelWrapper
                desktop={desktop}
                label="Агент по продажам"
                sx={{
                    justifyContent: "space-between",
                    textWrap: "nowrap"
                }}
            >
                <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <WrapperInput
                        backgroundColor="#FFFFFF"
                        fullWidth
                        sx={{
                            "& .MuiInputBase-root": {
                            height: "34px",
                            color: "#0D0C22 !important",
                            width: desktop ? "276px": "100%",
                            },
                        }}
                        onChange={(e: any) => { setAgent(e.target.value); }}
                        value={agent}
                    />
                </Box>
            </LabelWrapper>
            <LabelWrapper
                desktop={desktop}
                label="Активность"
                sx={{
                    justifyContent: "space-between",
                }}
            >
                <FormControl sx={{ width: desktop ? "80px" : "100%" }}>
                    <WrappedSelect
                        multiple={false}
                        options={booleanSelect}
                        value={isActive}
                        onChange={(e) => {
                            const changeValue = e.target.value as string;
                            setActive(changeValue);
                        }}
                        sx={{ height: "36px", width: "80px" }}
                    />
                </FormControl>
            </LabelWrapper>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: desktop ? "row" : "column",
                    alignItems: "end",
                    width: desktop ? "50%" : "100%",
                    gap: "15px"
                }}
            >
                <WrapperButton
                    label="Применить"
                    variant="contained"
                    sx={{
                        marginLeft: desktop ? "auto" : "unset",
                        height: "36px",
                        width: desktop ? "156px" : "100%",
                        whiteSpace: "nowrap",
                    }}
                    onClick={saveFilter}
                />

                <WrapperButton
                    label="Сбросить фильтр"
                    variant="contained"
                    typeBorder="RoundGradient"
                    sx={{
                        minWidth: 156,
                        color: "#F72642",
                        backgroundColor: "#FFFFFF",
                        width: desktop ? "156px" : "100%",
                        whiteSpace: "nowrap",
                    }}
                    onClick={clearFilter}
                />
            </Box>
        </Box>
    </Box>);
}

const CreatePromoCode = ({desktop}: {desktop: boolean }) => (
    <Link to="/adminpanel/sales/promocodes/create">
        <WrapperButton
            //@ts-ignore
            label="Добавить промокод"
            variant="contained"
            size={desktop ? "large" : "normal"}
            iconPosition="left"
            sx={{
                width: '196px',
                height: '36px',
                margin: desktop ? 0 : '10px 0',
                fontSize: '0.875rem',
                fontWeight: 500,
                lineHeight: '1.857rem',
                textWrap: "nowrap"
            }}
        />
    </Link>
);

type FilterData = Omit<TSearchData, "page" | "count">;
type FilterItems = Omit<FilterData, "sortItem">;

function Promocodes() {

    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    const { setHeaderSlot } = useContext(AdminPanelContext);
    const [ filterDataString, setFilter ] = useState(JSON.stringify({
        sortItem: {
            fieldName: "createdAt", 
            direction: 1
        },
        filterItems: []
    }));


    const filterData: FilterData = JSON.parse(filterDataString);
    const applyFilter = (filter: FilterItems) => {
        filterData.filterItems = filter.filterItems;
        setFilter(JSON.stringify(filterData));
    }

    useEffect(() => {
        setHeaderSlot?.(<CreatePromoCode desktop={desktop} />);
    }, [ setHeaderSlot, desktop ]);

    const navigate = useNavigate();

    return(
        <Box
        sx={{
            width: '100%',
            display: 'block',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '30px',
            maxWidth: desktop? '968px' : "unset"
        }}
    >
        <Box
            sx={{
                width: '100%',
                display: desktop ? 'flex' : 'block',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px'
            }}
        >
            <Filter applyFilter={applyFilter} desktop={desktop} value={filterData} />
        </Box>
        <DataTable<PromoCodeResponseItem>
            requestData={promoCode.search}
            columns={columns}
            desktopTableProps={{
                bodyCell: () => ({
                    sx: { cursor: 'pointer' }
                }),
                row: ({row}) => ({
                    onClick: () => {
                        if (row) navigate(`/adminPanel/sales/promocodes/${row['id']}`)   //вместо старого пропа onRowClicked
                    }
                }),
                headCell: ({ column }) => ({
                    sx: (column?.id === 'modified') ?
                        { minWidth: '155px' } : {}
                })
            }}
            mobileTableProps={{
                row: ({row}) => ({
                    onClick: () => {
                        if (row) navigate(`${row['id']}`)
                    }
                }),
            }}
            searchOptions={filterData}
        />
    </Box>
    );
}

export default Promocodes;