import {
  Control,
  Path,
  UseFormClearErrors,
  UseFormResetField,
  UseFormSetError,
  UseFormWatch,
} from "react-hook-form";
import { UserInfo } from "../../api/request/auth";

export type FormOptionType = {
  control: Control<
    UserInfo & { newPassword: string; confirmNewPassword: string },
    any
  >;
  name: Path<UserInfo & { newPassword: string; confirmNewPassword: string }>;
  setError: UseFormSetError<
    UserInfo & { newPassword: string; confirmNewPassword: string }
  >;
  clearErrors: UseFormClearErrors<
    UserInfo & { newPassword: string; confirmNewPassword: string }
  >;
  watch: UseFormWatch<
    UserInfo & { newPassword: string; confirmNewPassword: string }
  >;
  resetField?: UseFormResetField<
    UserInfo & {
      newPassword: string;
      confirmNewPassword: string;
    }
  >;
};

export type ModalKeyType =
  | "phone"
  | "email"
  | "time"
  | "logo"
  | "tarif"
  | "automaticPayment"
  | "extendTariff"
  | "confirmEmail"
  | "publicOffer"
  | "paymentsAgreement"
  | null;

export enum daysWeek {
  "monday" = "Понедельник Пн",
  "tuesday" = "Вторник Вт",
  "wednesday" = "Среда Ср",
  "thursday" = "Четверг Чт",
  "friday" = "Пятница Пт",
  "saturday" = "Суббота Сб",
  "sunday" = "Воскресенье Вс",
}
