import {
  TableContainer as MuiTableContainer,
  TableContainerProps,
} from "@mui/material";

const TableContainer = ({ ...props }: TableContainerProps) => {
  return <MuiTableContainer {...props} />;
};

export default TableContainer;
