import { Box, Typography } from "../../../../components";
import { useEffect, useState } from "react";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { req } from "../../../api";
import moment from "moment";
import WrappedSelect from "../../../component/Select";
import WrapperButton from "../../../component/Button/WrappedButton";
import DiagrammWrapper from "../../../component/Diagramm/diagrammWrapper";

export type StatisticsRatingType = {};

const optionsDaysOfWeek = [
  { value: 1, label: "Понедельник" },
  { value: 2, label: "Вторник" },
  { value: 3, label: "Среда" },
  { value: 4, label: "Четверг" },
  { value: 5, label: "Пятница" },
  { value: 6, label: "Суббота" },
  { value: 7, label: "Воскресенье" },
];

/**Рейтинг групп и позиций */
function StatisticsRating({}: StatisticsRatingType) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [groupsData, setGroupsData] = useState<
    { name: string; Количество: number }[]
  >([]);
  const [positionData, setPositionData] = useState<
    { name: string; Количество: number }[]
  >([]);
  const [dayOfWeek, setDayOfWeek] = useState<number[]>([moment().day()]);

  const getStatisticData = () => {
    Promise.all([
      req.stasticsEstablishment.getRatingTopGroups(dayOfWeek),
      req.stasticsEstablishment.getRatingTopPosition(dayOfWeek),
    ]).then(([GroupsData, Positiondata]) => {
      if (GroupsData.success) {
        setGroupsData(
          GroupsData.data.reduce(
            (acc, el) => {
              acc.push({ name: el.name, Количество: el.count });
              return acc;
            },
            [] as { name: string; Количество: number }[],
          ),
        );
      }
      if (Positiondata.success) {
        setPositionData(
          Positiondata.data.reduce(
            (acc, el) => {
              acc.push({ name: el.name, Количество: el.count });
              return acc;
            },
            [] as { name: string; Количество: number }[],
          ),
        );
      }
    });
  };

  useEffect(() => {
    getStatisticData();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "50px",
        marginTop: !desktop ? "10px" : "0px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: desktop ? "row" : "column",
          gap: "20px",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            alignItems: desktop ? "center" : "start",
            gap: desktop ? "10px" : "2px",
          }}
        >
          <Typography variant="caption">Рейтинг за:</Typography>
          <WrappedSelect
            inputProps={{
              "data-testid": "dayOfWeek",
              "aria-label": "dayOfWeek",
            }}
            multiple={true}
            options={optionsDaysOfWeek}
            value={dayOfWeek}
            onChange={(e) => {
              setDayOfWeek(e.target.value as number[]);
            }}
            sx={{ height: "36px", width: desktop ? "170px" : "calc(100vw - 56px)" }}
          />
        </Box>
        <WrapperButton
          label="Применить"
          variant="contained"
          sx={{
            height: "36px",
            width: "156px",
            whiteSpace: "nowrap",
          }}
          onClick={getStatisticData}
        />
      </Box>
      <DiagrammWrapper
        diagrammsData={[
          { label: "Рейтинг групп", dataDiagramm: groupsData },
          { label: "Топ 10 позиций", dataDiagramm: positionData },
        ]}
      />
    </Box>
  );
}

export default StatisticsRating;
