import { TSearchData } from "../../component/DataTable/types";
import { api } from "../api";

export namespace Logging {
    export async function search(data: TSearchData) {
        return api.sendRequest({
            domain: "on-menu",
            method: "post",
            url: `/Logging/search`,
            data
        });
    }
}