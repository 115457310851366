import { AccordionDetails as StyledAccordionDetails } from "../../../../components";
import { AccordionDetailsProps } from "@mui/material";

function AccordionDetails({ lightTheme, sx, ...props }:
    { lightTheme: boolean } & AccordionDetailsProps) {
        
    return(
        <StyledAccordionDetails
            { ...props }
            sx={{
                color: "#989CA4",
                padding: "2px 11px 14px 16px",
                backgroundColor: lightTheme ? "#F3F3F4" : "#040404",
                ...(Array.isArray(sx) ? sx : [sx])
            }}
        />
    );
}

export default AccordionDetails;