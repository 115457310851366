import { Table as MuiTable, TableProps } from "@mui/material";

const Table = ({ sx = [], ...props }: TableProps) => {
  return (
    <MuiTable
      {...props}
      sx={[
        {
          border: "1px solid #CED4DA",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default Table;
