import { Box, Typography } from "../../../../../components";
import { darkTheme } from "../../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import WrappedButton from "../../../../component/Button/WrappedButton";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import WrappedCheckbox from "../../../../component/Checkbox/WrappedCheckbox";
import WrapperInput from "../../../../component/Input/WrappedInput";
import { req } from "../../../../api";
import { useAlert } from "../../../../store/alert";
import { AGENT_DEFAULT, AgentCardForm } from "../createAgent/types";
import { AgentPromoCodeItem, AgentResponseItem, AgentUserResponseItem } from "../types";
import { formatPhoneNumber } from "../../../../utils";
import DataTable from "../../../../component/DataTable";
import { TSearchData, TColumn } from "../../../../component/DataTable/types";

const dateColumn: TColumn<AgentUserResponseItem> = { name: 'Дата', id: 'date',
    renderComponent: ({ row }) => {
        const formattedDate = new Date(row.date).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
        return(<>{ formattedDate }</>);
    }
};

const datePeriodColumn: TColumn<AgentUserResponseItem> = { name: 'Период', id: 'dateFrom',
    renderComponent: ({ row }) => {
        
        const dateTo = row.dateTo;

        const formattedDateFrom = new Date(row.dateFrom).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });

        const formattedDateTo = new Date(row.dateTo).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        });
        return(<>{ formattedDateFrom } - { formattedDateTo}</>);
    }
};

const desktopColumns: TColumn<AgentUserResponseItem>[] = [
    { name: 'ФИО', id: 'fullName' },
    { name: 'Промокод', id: 'promoCode' },
    { name: 'Тип промокода', id: 'promoCodeTypeName' },
    dateColumn,
    { name: 'Тариф', id: 'fare' },
    datePeriodColumn
];

const mobileColumns: TColumn<AgentUserResponseItem>[] = [
    { name: 'ФИО', id: 'fullName' },
    { name: 'Промокод', id: 'promoCode' },
    { name: 'Тип промокода', id: 'promoCodeTypeName' },
    dateColumn,
    { name: 'Тариф', id: 'fare' },
    datePeriodColumn
];

function PromoCodeItem({ code }: { code: AgentPromoCodeItem }) {
    const { name, isActive, isOneTime} = code;

    return (<Box
        sx={{
          width: "100%",
          textDecoration: isActive ? "none" : "line-through",
          marginTop: "3px",
          color: isOneTime ? "#F72642" : "#000"
        }}
      >
        {name}
      </Box>);
}

export default function AgentCard() {
    const [agent, setAgent] = useState<AgentResponseItem | null>(null);
    const { openAlert } = useAlert();
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    const { id } = useParams();
    const[promocodesUseCount, setPromocodesUse] = useState(0);
    const navigate = useNavigate();

    const {
        handleSubmit,
        control,
        formState: { errors },
        reset,
      } = useForm<AgentCardForm>({ defaultValues: AGENT_DEFAULT });

    const onSubmit: SubmitHandler<AgentCardForm> = (data) => {
        if (!agent) {
          return;
        }
    
        req.agent
          .updateAgent({
            id: agent.id,
            firstName: data.firstName,
            lastName: data.lastName,
            middleName: data.middleName,
            isActive: data.isActive,
            phoneNumber: data.phoneNumber,
            promocode: data.promocode
          })
          .then((res) => {
            if (!res.success) {
              openAlert({
                text: res.errors.join(";\n"),
                isVisibleCloseIcon: true,
                autoHide: false,
                type: "error",
              });
            } else {
              openAlert({
                text: "Данные агента успешно обновлены",
                isVisibleCloseIcon: true,
                autoHide: true,
                type: "success",
              });
              setAgent(res.data);
              navigate("/adminpanel/sales/agents");
            }
          })
          .catch(() => {
            openAlert({
              text: "Произошла ошибка при обновлении агента",
              isVisibleCloseIcon: true,
              autoHide: true,
              type: "error",
            });
          });
      };

    useEffect(() => {
        req.agent.getAgent(Number(id))
        .then((response) => {
          setAgent(response.data.data);
          reset(response.data.data);
        });
      }, [id]);

    useEffect(() => {
        req.agent.getAgentPromocodesUse(Number(id))
        .then((response) => {
          setPromocodesUse(response.data.data);
        });
      }, [id]);

    const usersSearchFilter: Partial<TSearchData> = {
        filterItems: [{
            fieldName: "agentId",
            value: id ?? "",
            valueTo: "",
            condition: 0
        }]
    }

    const PromocodeInput = () => {
        return (<Controller
            name="promocode"
            control={control}
            render={({ field: { onChange, value } }) => (
              <WrapperInput
                backgroundColor="#FFFFFF"
                fullWidth
                disabled={ agent?.promocode ? true : false}
                sx={{
                  "& .MuiInputBase-root": {
                    height: "34px",
                    color: "#0D0C22 !important",
                  },
                  "& .Mui-disabled": {
                    WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                  },
                }}
                {...{ onChange, value }}
              />
            )}
          />);
    }

    const PromocodeList = () => {
        return (<>{agent?.promoCodes?.map(x => <PromoCodeItem code={x} />)}</>);
    }

    const Promocode = agent?.promoCodes ? PromocodeList : PromocodeInput;

    return (
        <Box
          component={"main"}
          sx={[
            () => ({
              display: "flex",
              flexDirection: "column",
            
              position: "relative",
              margin: "auto",
              zIndex: 1,
              paddingBottom: "60px",
            }),
          ]}
        >
          <form onSubmit={handleSubmit(onSubmit)} style={{ flexGrow: 1 }}>        
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: "25px",
                }}
              >
                <Controller
                  name="isActive"
                  control={control}
                  defaultValue={true}
                  render={({ field: { onChange, value } }) => (
                    <WrappedCheckbox
                      checked={value as boolean}
                      onCheckChange={onChange}
                      label={
                        <Typography
                          sx={{
                            width: "100%",
                            fontSize: "14px",
                            lineHeight: "24px",
                            color: darkTheme.palette.outline.default,
                          }}
                        >
                          Активность
                        </Typography>
                      }
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
    
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: desktop ? "row" : "column",
                  }}
                >
                  <Typography variant="caption" color="#0D0C22">
                    Фамилия
                    <Typography variant="caption" color="#F00" fontWeight="700">
                      *
                    </Typography>
                  </Typography>
                  <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <WrapperInput
                          backgroundColor="#FFFFFF"
                          fullWidth
                          sx={{
                            "& .MuiInputBase-root": {
                              height: "34px",
                              color: "#0D0C22 !important",
                              width: "100%",
                            },
                          }}
                          onInput={(e: { target: { value: string } }) => {
                            return (
                              //@ts-ignore
                              (e.target.value = e.target.value.replace(
                                /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                                "",
                              ))
                            );
                          }}
                          {...{ onChange, value }}
                        />
                      )}
                    />
                  </Box>
                </Box>
    
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: desktop ? "row" : "column",
                  }}
                >
                  <Typography variant="caption" color="#0D0C22">
                    Имя
                    <Typography variant="caption" color="#F00" fontWeight="700">
                      *
                    </Typography>
                  </Typography>
                  <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <WrapperInput
                          backgroundColor="#FFFFFF"
                          fullWidth
                          sx={{
                            "& .MuiInputBase-root": {
                              height: "34px",
                              color: "#0D0C22 !important",
                              width: "100%",
                            },
                          }}
                          onInput={(e: { target: { value: string } }) => {
                            return (
                              //@ts-ignore
                              (e.target.value = e.target.value.replace(
                                /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                                "",
                              ))
                            );
                          }}
                          {...{ onChange, value }}
                        />
                      )}
                    />
                  </Box>
                </Box>
    
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: desktop ? "row" : "column",
                  }}
                >
                  <Typography variant="caption" color="#0D0C22">
                    Отчество (при наличии)
                  </Typography>
                  <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <Controller
                      name="middleName"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <WrapperInput
                          backgroundColor="#FFFFFF"
                          fullWidth
                          sx={{
                            "& .MuiInputBase-root": {
                              height: "34px",
                              color: "#0D0C22 !important",
                            },
                          }}
                          onInput={(e: { target: { value: string } }) => {
                            return (
                              //@ts-ignore
                              (e.target.value = e.target.value.replace(
                                /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                                "",
                              ))
                            );
                          }}
                          {...{ onChange, value }}
                        />
                      )}
                    />
                  </Box>
                </Box>
    
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: desktop ? "row" : "column",
                  }}
                >
                  <Typography variant="caption" color="#0D0C22">
                    Телефон для связи
                  </Typography>
                  <Box sx={{ maxWidth: 536, width: "100%" }}>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({
                        field: { onChange, value },
                        fieldState: { error },
                    }) => (
                        <WrapperInput
                        inputProps={{ maxLength: 16 }}
                        backgroundColor="#FFFFFF"
                        fullWidth
                        sx={{
                            "& .MuiInputBase-root": {
                            height: "34px",
                            color: "#0D0C22 !important",
                            width: "100%",
                            },
                        }}
                        onInput={(e: any) => {
                            e.target.value = formatPhoneNumber(
                            e.target.value.replace(/[^0-9+\-()\s]/g, ""),
                            );
                        }}
                        {...{ onChange, value }}
                        error={error?.message}
                    />
                  )}
                />
                  </Box>
                </Box>
    
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: desktop ? "row" : "column",
                  }}
                >
                  <Typography variant="caption" color="#0D0C22">
                    Промокод
                  </Typography>
                  <Box sx={{ maxWidth: 536, width: "100%" }}>
                    <Promocode />
                  </Box>
                </Box>
              </Box>
              <Box
              sx={{
                display: "flex",
                flexDirection: desktop ? "row" : "column",
                justifyContent: "space-between",
                padding: desktop ? "50px 0px 40px 0px" : "30px 23px 10px 23px",
              }}
            >
            <Box
                sx={{
                display: "flex",
                gap: "10px",
                marginTop: "20px",
                maxWidth: "536px",
                }}
            >
                <WrappedButton
                    label="Сохранить агента"
                    variant="contained"
                    sx={{
                        width: "100%",
                        minWidth: 207,
                        color: "#FFFFFF",
                        background:
                        "linear-gradient(94deg, #FF6F83 0.78%, #F61735 105.69%)",
                    }}
                    type="submit"
                />
                <Link to={"/adminpanel/sales/agents"}>
                <WrappedButton
                    label="Отмена"
                    variant="contained"
                    typeBorder="RoundGradient"
                    sx={{
                    width: "100%",
                    minWidth: 156,
                    color: "#F72642",
                    backgroundColor: "#FFFFFF",
                    whiteSpace: "nowrap",
                    }}
                />
                </Link>
            </Box>
            </Box>
          </form>

          <Box
            sx={{
              display: "flex",
              flexDirection: desktop ? "row" : "column",
              width: "100%",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: desktop ? "row" : "column",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "9px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{    
                  fontWeight: 500,
                  color: "#000",
                  paddingBottom: "5px"
                }}
              >
                Пользователи от агента
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: desktop ? "row-reverse" : "column",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "9px",
              }}
            >
              <Typography
                variant="subtitle2"
                sx={{    
                  fontWeight: 500,
                  color: "#000",
                  paddingBottom: "5px"
                }}
              >
                Количество использований промокодов агента: <b>{promocodesUseCount}</b>
              </Typography>
            </Box>
          </Box>
          <DataTable<AgentUserResponseItem>
                requestData={req.agent.getUsersByAgent}
                columns={desktop ? desktopColumns : mobileColumns}
                searchOptions={usersSearchFilter}
            />
        </Box>
      );
}