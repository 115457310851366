import { createTheme } from "@mui/material/styles";
import { ruRU } from "@mui/x-date-pickers/locales";
import "./theme.css";
import {
  spacing,
  breakpoints,
  typography,
  borderRadius,
} from "./constants/const";

type TSurface = Record<"1" | "4" | "8" | "12" | "16" | "20" | "24", string>;

declare module "@mui/material/styles" {
  //@ts-ignore
  type TPalette = {
    accent: {
      main: string;
    };
    backgroundColor: {
      dark: string;
      light: string;
    };
    surface: TSurface;
    outline: {
      default: string;
      onColor: string;
    };
    shades: {
      black: string;
      white: string;
    };
  };

  interface Palette extends TPalette {}

  interface PaletteOptions extends TPalette {}

  export interface Theme {
    borderRadius: {
      none: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      pill: string;
      circle: string;
    };
  }

  export interface ThemeOptions {
    borderRadius?: {
      none: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      pill: string;
      circle: string;
    };
  }
}

export const theme = createTheme(
  {
    spacing: spacing,
    breakpoints: breakpoints,
    borderRadius: borderRadius,
    palette: {
      mode: "light",
      primary: {
        main: "#7C4DFF",
      },
      secondary: {
        main: "#1DE9B6",
      },
      accent: {
        main: "#1DE9B6",
      },
      error: {
        main: "#FF5252",
      },
      warning: {
        main: "#FFC107",
      },
      success: {
        main: "#4CAF50",
      },
      info: {
        main: "#2196F3",
      },
      backgroundColor: {
        light: "#FAFAFA",
        dark: "#1A1A1A",
      },
      surface: {
        //Dark theme option
        "1": "#1F1F1F",
        "4": "#292929",
        "8": "#333333",
        "12": "#3D3D3D",
        "16": "#474747",
        "20": "#525252",
        "24": "#5C5C5C",
      },
      outline: {
        default: "#9E9E9E",
        onColor: "#949494",
      },
      shades: {
        black: "#000000",
        white: "#FFFFFF",
      },
    },
    typography: typography,
  },
  ruRU, // x-date-pickers translations
);

export const darkTheme = createTheme(
  {
    spacing: spacing,
    breakpoints: breakpoints,
    borderRadius: borderRadius,
    palette: {
      mode: "dark",
      primary: {
        main: "#090909",
      },
      secondary: {
        main: "#1DE9B6",
      },
      accent: {
        main: "#1DE9B6",
      },
      error: {
        main: "#FF5252",
      },
      warning: {
        main: "#FFC107",
      },
      success: {
        main: "#4CAF50",
      },
      info: {
        main: "#2196F3",
      },
      backgroundColor: {
        light: "#FAFAFA",
        dark: "#1A1A1A",
      },
      surface: {
        //Dark theme option
        "1": "#1F1F1F",
        "4": "#292929",
        "8": "#333333",
        "12": "#3D3D3D",
        "16": "#474747",
        "20": "#525252",
        "24": "#5C5C5C",
      },
      outline: {
        default: "#9E9E9E",
        onColor: "#949494",
      },
      shades: {
        black: "#000000",
        white: "#FFFFFF",
      },
    },
    typography: typography,
  },
  ruRU, // x-date-pickers translations
);
