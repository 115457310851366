export type TMenuItem = {
  title: string;
  link: string;
  defaultSelect?: boolean;
};

export const menuItems: TMenuItem[] = [
  {
    title: "Статистика просмотров меню",
    link: "views",
  },
  {
    title: "Рейтинг групп и позиций",
    link: "rating",
  },
  {
    title: "Динамика посещений",
    link: "dinamics",
  },
];
