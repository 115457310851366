import { Box, FileInput } from "../../../../components";
import {
  getTemplateData,
  useTemlateCardData,
} from "../../../store/templateCard";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import WrapperInput from "../../../component/Input/WrappedInput";
import { useEffect, useState } from "react";
import { TemplateDataDto } from "../../../api/request/template";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import WrapperLabel from "../wrapperLabel";
import WrappedSelect from "../../../component/Select";
import { req } from "../../../api";
import { useAlert } from "../../../store/alert";
import { useNavigate, useParams } from "react-router-dom";
import { getUserData, useUserData } from "../../../store/user";
import { useDispatch } from "../../../store/model/extensions";
import RestaurantInfo from "../RestaurantInfo";
import ConstructorTitle from "../constructorTitle";
import WrapperButton from "../../../component/Button/WrappedButton";
import { ArrowBack } from "@mui/icons-material";
import { formatPhoneNumber, formatSiteAddress } from "../../../utils";
import WrapperItem from "../../personalArea/shared/wrapperItem";
import ChangeOpeningHoursModal from "../../personalArea/modal/changeOpeningHoursModal";
import { OrganizationType, UserInfo, WorkHoursType } from "../../../api/request/auth";
import AttachedFileIcon from "../../../shared/assets/img/icons/AttachedFileIcon";
import ClearIcon from "../../../shared/assets/img/icons/ClearIcon";
import WrapperTextarea from "../../../component/Textarea/Textarea";

export type SettingsType = {};

type OptionSelect = { value: number; label: string }[];

/**Общие настройки */
export default function OrganizationEditPage({}: SettingsType) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const { tempateData, updateTempateData, tempateId } = useTemlateCardData();
  const { userData } = useUserData();
  const { currentTariff } = useUserData();
  const [theme, setTheme] = useState<OptionSelect>([]);
  const [language, setLanguage] = useState<OptionSelect>([]);
  const [isOpenTimeWindow, setIsOpenTimeWindow] = useState<boolean>(false);
  const { openAlert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { handleSubmit, reset, watch, control } = useForm<TemplateDataDto>({
    defaultValues: tempateData,
  });
  const {
    watch: watchUser,
    control: userControl,
    resetField: resetUserField,
  } = useForm<UserInfo>({
    defaultValues: userData!,
  });

  useEffect(() => {
    reset(tempateData);
  }, [JSON.stringify(tempateData)]);

  useEffect(() => {
    req.dictionary.getDictionary("MenuThemeEnum").then((res) => {
      if (res.data) {
        setTheme(() => {
          return res.data?.reduce(
            (
              acc: { value: any; label: any }[],
              el: { id: any; title: any },
            ) => {
              acc.push({ value: el.id, label: el.title });
              return acc;
            },
            [] as OptionSelect,
          );
        });
      }
    });
    req.dictionary.getDictionary("MenuLanguageEnum").then((res) => {
      if (res.data) {
        setLanguage(() => {
          return res.data?.reduce(
            (
              acc: { value: any; label: any }[],
              el: { id: any; title: any },
            ) => {
              acc.push({ value: el.id, label: el.title });
              return acc;
            },
            [] as OptionSelect,
          );
        });
      }
    });
  }, []);

  const updateWorkHours = (value: WorkHoursType, textMessageSuccess: string,) => {
      req.organization.updateWorkHours(userData?.organization?.id!, value).then(res => {
        if (!res.success) throw new Error("Не удалось сохранить часы работы");
        openAlert({
          text: textMessageSuccess,
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "success",
        });
        dispatch(getTemplateData({ id: tempateData.id }));
        dispatch(getUserData());
      }) 
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при сохранении времени работы",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  }

  const onSubmit: SubmitHandler<TemplateDataDto> = (data, e: any) => {
    if (e?.nativeEvent?.submitter.name === "cancel") {
      return reset(tempateData);
    }

    const organization: OrganizationType = {
      name: data.organizationName,
      id: userData?.organization?.id!,
      address: data.organizationAddress,
      phoneNumber: data.phoneNumber,
      logoId: data.organizationLogoId,
      serviceName: userData?.organization?.serviceName!,
      qrCodeId: "",
      qrSvgId: "",
      telegram: data.telegram,
      whatsApp: data.whatsApp,
      website: data.website,
      workhours: userData?.organization?.workhours!,
      workhoursString: "",
    };

    req.organization.update(userData?.organization?.id!, organization)
      .then(res => {
        if (!res.success) throw new Error("Не удалось обновить данные об организации");

        return req.template.saveTemplateData(data, tempateData.id)
      })
      .then((res) => {
        if (res.success) {
          openAlert({
            text: "Данные шаблона успешно сохранены",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });
          if (tempateData.id === 0) {
            navigate(`/templateCard/${res.data}/settings`);
          } else {
            dispatch(getTemplateData({ id: tempateData.id }));
          }
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при сохранении данных шаблона",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const returnButtonText = (
    <>
      <ArrowBack /> Вернуться к списку блюд
    </>
  );

  const buttons = (
    <WrapperButton
      label={returnButtonText}
      variant="contained"
      sx={{
        width: desktop ? "auto" : "100%",
        whiteSpace: "nowrap",
      }}
      onClick={() => navigate(`/constructor/${id}/constructor`)}
    />
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: desktop ? "row" : "column-reverse",
        justifyContent: "space-between",
        gap: "100px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "50px",
          flex: 1,
        }}
      >
        <ConstructorTitle buttons={buttons} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
            }}
          >
            <LogoWrapper userControl={userControl} resetUserField={resetUserField} />
            {currentTariff === "Премиум" && (
              <WrapperLabel title="Язык">
                <Controller
                  name="languages"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrappedSelect
                      options={language}
                      value={value}
                      onChange={onChange}
                      multiple
                      sx={{
                        height: "32px",
                        maxWidth: desktop ? "none" : "320px",
                      }}
                      disabled={currentTariff !== "Премиум"}
                    />
                  )}
                />
              </WrapperLabel>
            )}
            {currentTariff &&
              ["Премиум", "Продвинутый"].includes(currentTariff) && (
                <WrapperLabel title="Тема">
                  <Controller
                    name="theme"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <WrappedSelect
                        options={theme}
                        value={value}
                        onChange={onChange}
                        sx={{
                          height: "32px",
                        }}
                      />
                    )}
                  />
                </WrapperLabel>
              )}
            <WrapperLabel title="Название заведения" required>
              <Controller
                name="organizationName"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error, isDirty },
                  formState,
                }) => (
                  <WrapperInput
                    label=""
                    fullWidth
                    height={"34px"}
                    colorType={"white"}
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
            </WrapperLabel>
            <WrapperLabel title="Сетевое имя">
              <Controller
                name="organization.serviceName"
                control={userControl}
                render={({
                  field: { onChange, value },
                  fieldState: { error, isDirty },
                  formState,
                }) => (
                  <WrapperInput
                    label=""
                    fullWidth
                    disabled
                    height={"34px"}
                    colorType={"white"}
                    sx={{
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                      },
                    }}
                    value={value}
                    error={error?.message}
                    onChange={onChange}
                  />
                )}
              />
            </WrapperLabel>
            <WrapperLabel title="Адрес" required>
              <Controller
                name="organizationAddress"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error, isDirty },
                  formState,
                }) => (
                  <WrapperTextarea
                    onChange={onChange}
                    value={value}
                    error={error?.message}
                  />
                  // <WrapperInput
                  //   label=""
                  //   fullWidth
                  //   height={"34px"}
                  //   colorType={"white"}
                  //   value={value}
                  //   error={error?.message}
                  //   onChange={onChange}
                  // />
                )}
              />
            </WrapperLabel>
            <WrapperItem
              label="Часы работы"
              control={control}
              name="workhoursString"
              sx={{ justifyContent: "flex-start" }}
              additionalPropertiesButton={{
                label: watch("workhoursString")
                  ? "Изменить часы работы"
                  : "Указать часы работы",
                onClick: () => {
                  setIsOpenTimeWindow(true);
                },
                SxProps: desktop
                  ? {
                      width: "205px",
                    }
                  : undefined,
              }}
              hiddenChildren={!watch("workhoursString")}
              render={() => {
                return (
                  <WrapperInput
                    value={watch("workhoursString")}
                    fullWidth
                    disabled
                    height={"34px"}
                    colorType={"white"}
                    sx={{
                      "& .Mui-disabled": {
                        WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                      },
                    }}
                  />
                );
              }}
            />
            {currentTariff === "Премиум" && (
              <>
                <WrapperLabel title="Телефон">
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error, isDirty },
                      formState,
                    }) => (
                      <WrapperInput
                        label=""
                        backgroundColor="#FFFFFF"
                        fullWidth
                        disabled={currentTariff !== "Премиум"}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "34px",
                            color: "#0D0C22 !important",
                          },
                          "& .Mui-disabled": {
                            WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                          },
                        }}
                        inputProps={{ maxLength: 16 }}
                        onInput={(e: any) => {
                          e.target.value = formatPhoneNumber(
                            e.target.value.replace(/[^0-9+\-()\s]/g, ""),
                          );
                        }}
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </WrapperLabel>
                <WrapperLabel title="Сайт" >
                  <Controller
                    name="website"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error, isDirty },
                      formState,
                    }) => (
                      <WrapperInput
                        label=""
                        fullWidth
                        disabled={currentTariff !== "Премиум"}
                        colorType={"white"}
                        height={"34px"}
                        onInput={(e: any) => {
                          e.target.value = formatSiteAddress(e.target.value);
                        }}
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </WrapperLabel>
                <WrapperLabel title="Telegram">
                  <Controller
                    name="telegram"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error, isDirty },
                      formState,
                    }) => (
                      <WrapperInput
                        label=""
                        colorType={"white"}
                        height={"34px"}
                        fullWidth
                        disabled={currentTariff !== "Премиум"}
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </WrapperLabel>
                <WrapperLabel title="Whatsapp">
                  <Controller
                    name="whatsApp"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error, isDirty },
                      formState,
                    }) => (
                      <WrapperInput
                        label=""
                        backgroundColor="#FFFFFF"
                        fullWidth
                        disabled={currentTariff !== "Премиум"}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "34px",
                            color: "#0D0C22 !important",
                          },
                          "& .Mui-disabled": {
                            WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                          },
                        }}
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </WrapperLabel>
                <WrapperLabel title="Ссылка">
                  <Controller
                    name="link"
                    control={control}
                    render={({
                      field: { onChange, value },
                      fieldState: { error, isDirty },
                      formState,
                    }) => (
                      <WrapperInput
                        label=""
                        backgroundColor="#FFFFFF"
                        fullWidth
                        disabled={currentTariff !== "Премиум"}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: "34px",
                            color: "#0D0C22 !important",
                          },
                          "& .Mui-disabled": {
                            WebkitTextFillColor: "rgba(0,0,0,0.4) !important",
                          },
                        }}
                        value={value}
                        error={error?.message}
                        onChange={onChange}
                      />
                    )}
                  />
                </WrapperLabel>
              </>
            )}
          </Box>
          <Box
            sx={{
              marginTop: "16px",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <WrapperButton
              label="Отменить"
              variant="contained"
              secondary={true}
              sx={{
                flex: "1",
              }}
              onClick={() => navigate(`/constructor/${id}/constructor`)}
            />
            <WrapperButton
              label="Сохранить"
              variant="contained"
              sx={{
                flex: "1",
              }}
              type="submit"
            />
          </Box>
        </form>
      </Box>
      {desktop && <RestaurantInfo isEditable={false} />}
      {isOpenTimeWindow && (
        <ChangeOpeningHoursModal
          onClose={() => setIsOpenTimeWindow(false)}
          workhours={watchUser("organization.workhours")}
          updateUserWithModal={updateWorkHours}
        />
      )}
    </Box>
  );
}

function LogoWrapper({ userControl, resetUserField }) {
  const [isChangeLogo, setIsChangeLogo] = useState<boolean>(false);
  const [files, setFiles] = useState<any[]>([]);
  const dispatch = useDispatch();

  return (
    <WrapperItem
      label="Логотип"
      control={userControl}
      name="organization.logoId"
      additionalPropertiesButton={
        isChangeLogo
          ? files.length
            ? {
                label: "Сохранить",
                onClick: () => {
                  const formData = new FormData();
                  files.map((file: File) => formData.append("file", file));
                  req.user.changeLogo(formData).then((res) => {
                    dispatch(getUserData()).then(() => {
                      resetUserField &&
                        resetUserField("organization.logoId", {
                          keepDirty: false,
                          defaultValue: res.data.data,
                        });
                    });
                  });
                  setIsChangeLogo(false);
                },
              }
            : undefined
          : {
              label: "Изменить логотип",
              onClick: (e: any) => {
                e.preventDefault();
                setIsChangeLogo(true);
              },
              SxProps: {
                width: "170px",
              },
            }
      }
      render={({ onChange, value }) => {
        return (
          <Box sx={{ display: "flex", gap: "5px" }}>
            {value && !isChangeLogo ? (
              <img
                style={{
                  width: "66px",
                  marginRight: "auto",
                }}
                src={`${process.env.REACT_APP_BASE_URL}/file/downloadGuid?guid=${value}`}
                alt="Логотип компании"
              />
            ) : (
              <FileInput
                accept="image/*"
                AttachIcon={AttachedFileIcon}
                ClearIcon={ClearIcon}
                files={files}
                onChange={(files) => {
                  setFiles(files);
                }}
                multiple={false}
                sx={{
                  position: "relative",
                  borderRadius: "60px !important",
                  background: "#FFFFFF",
                  height: "34px",
                  border: "none",
                  fontSize: "14px",
                  fontFamily: "Inter",
                  color: "#0D0C22!important",
                  "& label": {
                    fontWeight: "400",
                    fontSize: "14px",
                    fontFamily: "Inter",
                    "& span": {
                      color: "#0D0C22 !important",
                      lineHeight: 1.5,
                    },
                  },
                  "&:after": {
                    position: "absolute",
                    top: -2,
                    left: -2,
                    right: -2,
                    bottom: -2,
                    background: "linear-gradient(-45deg, #674099,#ED6985)",
                    content: '""',
                    zIndex: -1,
                    borderRadius: "60px",
                  },
                }}
                containerProps={{ width: "100%" }}
                label="Загрузить файл"
              />
            )}
          </Box>
        );
      }}
    />
  );
}
