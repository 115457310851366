import { Radio as MuiRadio, RadioProps, SxProps, Theme } from "@mui/material";

type Sizes = "small" | "normal";

interface CheckboxInterface extends Omit<RadioProps, "size" | "sx"> {
  size?: Sizes;
  sx?: SxProps<Theme>;
}

const getLayout = (size: Sizes): SxProps<Theme> => {
  switch (size) {
    case "small": {
      return {
        transform: "scale(0.6667)",
        padding: "0px",
      };
    }
    case "normal": {
      return { padding: "0px" };
    }

    default:
      return null;
  }
};

const Radio = ({ size = "small", sx, ...props }: CheckboxInterface) => {
  return (
    <MuiRadio
      {...props}
      sx={[
        (theme) => ({
          ...getLayout(size),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default Radio;
