import { useEffect, useRef, useState } from "react";

export type TLoadingStatus = "pending" | "successed" | "failed";

function useLoadingStatus() {
    const [ loadingStatus, setLoadingStatus ] = useState<TLoadingStatus>('successed');
    const loadingStatusRef = useRef<TLoadingStatus>('successed');

    useEffect(() => {
        loadingStatusRef.current = loadingStatus;
    }, [ loadingStatus ]);

    //Нужно внутри setTimeout
    function getLoadingStatus() {
        return loadingStatusRef.current;
    }

    return { loadingStatus, setLoadingStatus, getLoadingStatus };
}

export default useLoadingStatus;