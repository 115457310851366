import { Box, Typography } from "../../../../components";
import ContentContainer from "../../../component/ContentContainer";
import comeIn from "./images/comeIn.png";
import edit from "./images/edit.png";
import publicMenu from "./images/public.png";
import WrapperButton from "../../../component/Button/WrappedButton";
import { useNavigate } from "react-router-dom";
import { Tab } from "../../../pages/login/autorization";

function ForAdmin() {
  const navigate = useNavigate();

  return (
    <Box
      sx={[
        (theme) => ({
          backgroundColor: "#F0F1F4",
          width: "100%",
          zIndex: "1",
          position: "relative",
          overflow: "hidden",
          padding: "30px 0px",
        }),
      ]}
    >
      <ContentContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "32px",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              flexGrow: 0,
            }}
          >
            <Typography>
              <span style={{ color: "#F71835", fontSize: "32px" }}>
                {"Легко\r\n"}
              </span>
              <span style={{ color: "#0D0C22", fontSize: "32px" }}>
                администратору!
              </span>
            </Typography>
            <WrapperButton
              label={"ПОДКЛЮЧИТЬ"}
              sx={{ height: "50px", width: "170px" }}
              variant="contained"
              size={"normal"}
              fullWidth={false}
              onClick={() => navigate("/login?tab=" + Tab.REGISTRATION)}
            />
          </Box>
          <Box sx={{ display: "flex", gap: "32px" }}>
            <img height={256} src={comeIn} alt="Заходить в личный кабинет" />
            <img height={256} src={edit} alt="Редактировать меню" />
            <img height={256} src={publicMenu} alt="Публиковать меню" />
          </Box>
        </Box>
      </ContentContainer>
    </Box>
  );
}

export default ForAdmin;
