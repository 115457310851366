import { Box } from "../../../components";
import { styled, Link } from "@mui/material";
import telegramIcon from "../../shared/assets/img/telegram.svg";
import earthIcon from "../../shared/assets/img/earth.svg";
import whatsAppIcon from "../../shared/assets/img/WhatsApp.svg"
import linkIcon from "../../shared/assets/img/link.svg";
import { ImgHTMLAttributes, CSSProperties } from "react";
import { notEmptyString } from "../../utils";
import createLangItems from "./LanguagesMenu/createLangItems";
import LanguagesMenu from "./LanguagesMenu";

const Logo = styled('img')({
    borderRadius: '1000px'
});

export type HeadProps = {
    workhoursString: string;
    organizationAddress: string;
    organizationName: string;
    phoneNumber: string;
    telegram: string;
    website: string;
    whatsApp: string;
    link: string;
    lightTheme: boolean;
    logo: string | null;
    language: number;
    languages: number[];
    desktop: boolean;
    setLanguage: (lng:number) => void;
};

/**Название заведения, адрес, контакты */
function Head({ workhoursString, organizationAddress, organizationName, phoneNumber, desktop,
    telegram, website, whatsApp, link, lightTheme, logo, language, languages, setLanguage }: HeadProps) {


    const buttonStyle: CSSProperties | undefined = desktop ? {display: 'flex', gap: '10px', textDecoration: 'none', color:'#989CA4'} : undefined;

    return(
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
        }}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: desktop ? 'column' :'row',
                    alignItems: desktop ? 'center' : 'start',
                    gap: desktop ? '40px' : '0'
                }}
            >
                <Box sx={{
                    minWidth: "118px",
                    width: "118px",
                    minHeight: "100px"
                }}>
                    {logo && <Logo src={logo} alt="logo" width="118px" height="100px"/>}
                </Box>
                <Box
                    sx={{
                        padding: '0 0 0 10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <Box
                        sx={theme => ({
                            fontSize: '1rem',
                            lineHeight: '1.2rem',
                            fontWeight: 600,
                            color: lightTheme ? '#61646C' : '#FFFFFF'
                        })}
                    >
                        { organizationName }
                    </Box>
                    
                    <Box
                        sx={{
                            fontSize: '0.75rem',
                            fontWeight: 400,
                            lineHeight: '0.875rem',
                            color: '#989CA4',
                        }}
                    >
                        <Box sx={{ padding: '8px 0 6px 0' }}>
                            { workhoursString }
                        </Box>                    
                        { organizationAddress }
                    </Box>
                    <Box sx={{
                        paddingTop: "8px"
                    }}>
                        <Link sx={{
                            fontSize: "12px",
                            fontWeight: "700",
                            color: "#989CA4",
                            textDecoration: "none"
                        }} href={`tel:${phoneNumber}`}>{ phoneNumber }</Link>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
            }}>
                {language && languages?.length > 1 && <Box sx={{ 
                    height: "32px",
                    minWidth: "118px",
                    width: "118px",
                }}>
                    <LanguagesMenu
                        items={createLangItems(languages)}
                        language={language}
                        setLanguage={setLanguage}
                        lightTheme={lightTheme}
                    /> 
                </Box>}
                {(!language || languages?.length === 0) && !desktop && <Box sx={{
                     height: "32px",
                     width: "128px",
                     minWidth: "128px",
                }} />}
                { [phoneNumber, telegram, whatsApp, website].filter(x => x).length > 0 && <Box
                    sx={{
                        display: 'flex',
                        flexDirection: desktop ? 'column' : 'row',
                        gap: '7px',
                        width: '100%',
                    }}
                >
                    <ContactButton value={link} desktop={desktop}>
                        <a href={link} style={buttonStyle}>
                            <Img src={linkIcon} alt="link"/>
                            {desktop ? <>{link}</> : <></>}
                        </a>
                    </ContactButton>
                    <ContactButton value={telegram} desktop={desktop}>
                        <a target="_blank" href={`https://t.me/${telegram.replace(/ |-/g,"")}`} style={buttonStyle}>
                            <Img src={telegramIcon} alt="telegram"/>
                            {desktop ? <>{telegram}</> : <></>}
                        </a>
                    </ContactButton>
                    <ContactButton value={whatsApp} desktop={desktop}>
                        <a target="_blank" href={`https://wa.me/${whatsApp.replace(/ |-|\+/g,"")}`} style={buttonStyle}>
                            <Img src={whatsAppIcon} alt="whatsapp"/>
                            {desktop ? <>{whatsApp}</> : <></>}
                        </a>
                    </ContactButton>
                    <ContactButton value={website} desktop={desktop} >
                        <a target="_blank" href={website} style={buttonStyle}>
                            <Img src={earthIcon} alt="earth"/>
                            {desktop ? <>{website}</> : <></>}
                        </a>
                    </ContactButton>
                </Box> }
            </Box>
        </Box>
    );
}

function ContactButton(props: React.PropsWithChildren<{ value: string, desktop?: boolean }>) {

    const { value, children, desktop } = props;

    const flexStyle = {
        width: 'fit-content',
        backgroundColor: "#F3F3F4",
        padding: "8px 12px",
        borderRadius: "500px",
        paddingRight: "16px"
    };

    return (notEmptyString(value) && <Box sx={desktop ? flexStyle : {
        backgroundColor: "#F3F3F4",
        padding: "5px 12px",
        borderRadius: "500px",
    }}>
        { children }
    </Box>)
}

function Img(props: ImgHTMLAttributes<HTMLImageElement>) {
    return(
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '22px',
                height: '22px',
                cursor: 'pointer'
            }}
        >
            <img { ...props }/>
        </Box>
    );
}

export default Head;