import {
  FormControlLabel as MuiFormControlLabel,
  FormControlLabelProps,
} from "@mui/material";

const FormControlLabel = ({ ...rest }: FormControlLabelProps) => {
  return <MuiFormControlLabel {...rest} />;
};

export default FormControlLabel;
