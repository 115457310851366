import {
  BoxProps,
  Collapse,
  IconButton,
  List,
  ListItem,
  SxProps,
} from "@mui/material";
import Box from "../Box";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { ChangeEvent, RefObject, useRef, useState } from "react";
import normalizeCount from "../../utils/normalizeCount";
import Button from "../Button";
import { ColorType } from "../../types/colorType";

export interface FileInput {
  sx?: SxProps;
  containerProps?: SxProps;
  multiple?: boolean;
  listProps?: SxProps;
  color?: ColorType;
  files: File[];
  label?: string;
  error?: boolean;
  AttachIcon?: ({ ...props }: BoxProps) => JSX.Element;
  ClearIcon?: ({ ...props }: BoxProps) => JSX.Element;
  onChange: (value: File[]) => void;
  accept?: string;
}

const FileInput = ({
  sx,
  containerProps,
  multiple = true,
  listProps,
  color,
  files,
  label = "Прикрепить файлы",
  error,
  AttachIcon,
  ClearIcon,
  onChange,
  accept,
}: FileInput) => {
  const [isHovered, setHovered] = useState<boolean>(false);
  const inputRef = useRef(null) as RefObject<HTMLInputElement>;

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target.files;
    if (target) {
      const filesArr = Object.keys(target).map((file) => target[Number(file)]);
      onChange([...files, ...filesArr]);
    }
  };

  return (
    <Box
      sx={[
        (theme) => ({
          width: "260px",
          position: "relative",
          cursor: "pointer",
        }),
        ...(Array.isArray(containerProps) ? containerProps : [containerProps]),
      ]}
      onMouseEnter={() => files.length > 1 && setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Box
        component="div"
        sx={[
          (theme) => ({
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            border: `1px solid ${color ? color : theme.palette.primary.main}`,
            borderRadius: "4px",
            borderBottom: `${isHovered && files.length ? "none" : ""}`,
            borderBottomLeftRadius: `${isHovered && files.length ? "0" : ""}`,
            borderBottomRightRadius: `${isHovered && files.length ? "0" : ""}`,
            borderColor: error ? theme.palette.error.main : " ",
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <Button
          label={
            <>
              {AttachIcon ? (
                <AttachIcon />
              ) : (
                <AttachFileIcon
                  sx={[
                    (theme) => ({
                      color: error
                        ? theme.palette.error.main
                        : color
                        ? color
                        : theme.palette.primary.main,
                    }),
                  ]}
                />
              )}
              <Box
                component="span"
                sx={[
                  (theme) => ({
                    marginLeft: "6px",
                    color: error
                      ? theme.palette.error.main
                      : color
                      ? color
                      : theme.palette.primary.main,
                  }),
                ]}
              >
                {!files.length
                  ? label
                  : files.length === 1
                  ? files[0].name
                  : `${normalizeCount(files.length, [
                      "файл",
                      "файла",
                      "файлов",
                    ])}`}
              </Box>
              <input
                ref={inputRef}
                id="input-files-id"
                type="file"
                hidden
                multiple={multiple}
                onChange={handleFileChange}
                accept={accept}
              />
            </>
          }
          aria-multiselectable
          variant="outlined"
          component="label"
          sx={{
            width: "100%",
            border: "none",
            padding: "8px 10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            lineHeight: "unset",
            ":hover": {
              border: "none",
              background: "none",
            },
          }}
        />
        {files.length ? (
          <IconButton
            onClick={(e) => {
              if (inputRef.current) {
                inputRef.current.value = "";
              }
              onChange([]);
            }}
          >
            {ClearIcon ? <ClearIcon /> : <ClearOutlinedIcon />}
          </IconButton>
        ) : (
          <></>
        )}
      </Box>
      {files.length > 1 && (
        <Box
          sx={[
            (theme) => ({
              position: "absolute",
              width: "100%",
            }),
          ]}
        >
          <Collapse in={isHovered}>
            <List
              sx={[
                (theme) => ({
                  padding: "0px",
                  overflowY: "auto",
                  transition: "height 0.5s 0.5s ease-out",
                  maxHeight: "100px",
                  border: `${
                    isHovered && files.length > 0
                      ? `1px solid ${
                          color ? color : theme.palette.primary.main
                        }`
                      : ""
                  }`,
                  borderTop: "none",
                  borderBottomLeftRadius: "4px",
                  borderBottomRightRadius: "4px",
                  borderColor: error ? theme.palette.error.main : "",
                }),
                ...(Array.isArray(listProps) ? listProps : [listProps]),
              ]}
            >
              {files.map((file, i) => (
                <ListItem
                  key={`index/${i}`}
                  sx={{
                    padding: "0px 6px 0px 14px",
                    fontSize: "10px",
                    opacity: 0.7,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {file.name}
                  <IconButton
                    onClick={(e) => {
                      if (inputRef.current) {
                        inputRef.current.value = "";
                      }
                      onChange(files.filter((_, index) => i !== index));
                    }}
                  >
                    <ClearOutlinedIcon sx={{ fontSize: "10px" }} />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Box>
      )}
    </Box>
  );
};

export default FileInput;
