import { styled } from "../../../../components";
import { PropsWithChildren } from "react";

const TDStyled = styled('td')({
    width: "180px",
    marginRight: "30px",
    fontSize: "0.875rem",
    fontWeight: 300,
    color: "#0D0C22"
});

function TDLabel({ children }: PropsWithChildren) {
    return(
        <TDStyled>
            { children }
        </TDStyled>
    );
}

export default TDLabel;