import { Box, Typography } from "../../../../components";

export type AddInfoProps = {
    energy: number;
    proteins: number;
    fats: number;
    carbohydrates: number;
    lightTheme: boolean;
    amount: string | null;
};

/**Дополнительная информация о блюде: масса, ккал, жиры, белки, углеводы */
function AddInfo({ energy, proteins, fats, carbohydrates, lightTheme, amount }: AddInfoProps) {
    return(
        <Box
            sx={{
                marginTop: '16px',
                display: 'flex',
                height: '24px',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'left',
                fontSize: '0.75rem',
                fontWeight: 400,
                color: !lightTheme ? '#FFFFFF' : '#61646C'
            }}
        >
            <Box sx={{
                display:'flex',
                flexDirection:'row',
                gap: '20px'
            }}>
                { amount && <Typography sx={{ fontSize: '12px', fontWeight: '700' }}>{ amount }</Typography> }
                { energy && energy !== 0 ? <Typography sx={{ fontSize: '12px', fontWeight: '700' }}>{ energy } ккал</Typography> : <></>}
            </Box>
            <Box
                sx={{
                    marginTop: '8px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '20px',
                    '& b': {
                        marginRight: '4px'
                    }
                }}
            >
                { proteins && proteins !== 0 ? <p><b>б</b><span>{ proteins }г</span></p> : <></> }
                { fats && fats !== 0 ? <p><b>ж</b><span>{ fats }г</span></p> : <></> }
                { carbohydrates && carbohydrates !== 0 ? <p><b>у</b><span>{ carbohydrates }г</span></p> : <></> }
            </Box>
        </Box>
    );
}

export default AddInfo;