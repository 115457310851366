import {
  useDispatch as useDispatchGeneric,
  useSelector as useSelectorGeneric,
} from "react-redux";
import { createAsyncThunk as createAsyncThunkGeneric } from "@reduxjs/toolkit";

import type { TypedUseSelectorHook } from "react-redux";
import { type RootState, type AppDispatch } from "../store";
import type {
  AsyncThunk,
  AsyncThunkOptions,
  AsyncThunkPayloadCreator,
} from "@reduxjs/toolkit";

export const useDispatch = () => useDispatchGeneric<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useSelectorGeneric;

// Options like 'condition' will get properly typed getState.
type ThunkApiConfig = { state: RootState };
export function createAsyncThunk<Returned, ThunkArg>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>,
): AsyncThunk<Returned, ThunkArg, ThunkApiConfig> {
  return createAsyncThunkGeneric<Returned, ThunkArg, ThunkApiConfig>(
    typePrefix,
    payloadCreator,
    options,
  );
}
