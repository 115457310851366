import { getTemplateData, useTemlateCardData } from "../../../store/templateCard";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { Box, Typography } from "../../../../components";
import { useState } from "react";
import WrapperButton from "../../../component/Button/WrappedButton";
import { PositionType } from "../../../api/request/template";
import {
  FileUpload,
} from "@mui/icons-material";
import ConstructorTitle from "../constructorTitle";
import RestaurantInfo from "../RestaurantInfo";
import { useNavigate, useParams } from "react-router-dom";
import GroupsList from "./groupList";
import ProductsList from "./productList";
import { useDispatch } from "../../../store/model/extensions";
import { req } from "../../../api";
import { useAlert } from "../../../store/alert";
import ConfirmModal from "../../../component/Modal/ConfirmModal";
import { ContentCopy } from "@mui/icons-material";
import { SxProps } from "@mui/material";
import { Theme } from "@emotion/react";

type typeModal = "export";

/**Меню заведения */
export default function ConstructorMenu() {
  const { tempateData,  } = useTemlateCardData();
  const [activeGroup, setActiveGroup] = useState<null | number>(null);
  const [linkToTemplate, setLinkToTemplate] = useState<string | null>("https://on-menu.ru/api/file/downloadGuid?guid=cabebb53-5dde-4984-9b80-ee22574fe");
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [typeModal, setTypeModal] = useState<typeModal | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { openAlert } = useAlert();
  const products: PositionType[] = tempateData.positions.filter(
    (position) => !activeGroup || position.groups.includes(activeGroup),
  );

  const exportTemplate = () => {

    req.template
      .exportTemplate(Number(id))
      .then(res => {
        if (res.success) {
          setLinkToTemplate(res.data.link);
          setTypeModal("export");
        }
      })
      .catch(() => {
        openAlert({
            text: "Произошла ошибка при экспорте шаблона",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "error",
        });
    });
  }

  const exportButtonText = (
    <>
      <FileUpload /> Экспортировать шаблон
    </>
  );

  const buttons = (
    <Box
      sx={{
        display: "flex",
        flexDirection: desktop ? "row" : "column",
        width: desktop ? "auto" : "100%",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <WrapperButton
        label="История изменений"
        variant="contained"
        sx={{
          width: desktop ? "auto" : "100%",
          whiteSpace: "nowrap",
        }}
        onClick={() => navigate(`/constructor/${id}/changeHistory`)}
      />
      <WrapperButton
        label={exportButtonText}
        secondary={true}
        variant="contained"
        sx={{
          width: desktop ? "auto" : "100%",
          whiteSpace: "nowrap",
        }}
        onClick={exportTemplate}
      />
    </Box>
  );

  const publish = () => {
    req.template
        .publishTemplate(Number(id))
        .then((res) => {
        if (res.success) {
            openAlert({
                text: "Шаблон успешно опубликован",
                isVisibleCloseIcon: true,
                autoHide: true,
                type: "success",
            });
            dispatch(getTemplateData({ id: Number(id) }));
        } else {
            openAlert({
                text: res.errors.join(";\n"),
                isVisibleCloseIcon: true,
                autoHide: false,
                type: "error",
            });
        }
        })
        .catch(() => {
            openAlert({
                text: "Произошла ошибка при публикации шаблона",
                isVisibleCloseIcon: true,
                autoHide: true,
                type: "error",
            });
        });
  }

  const windowStyle: SxProps<Theme> = !desktop ? {} : {
    display: 'flex',
    flexFlow: 'row-reverse',
    gap: '60px'
  }

  const restInfo = <Box sx={{

  }}>
    <RestaurantInfo showWorkHoursOnDraft={true} />
  </Box>

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: desktop ? "row" : "column",
        justifyContent: "space-between",
        gap: desktop ? "100px" : "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "50px",
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <Box sx={windowStyle}>
            {desktop === true ? restInfo : <></>}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '16px'
            }}>

              <ConstructorTitle buttons={buttons} templateName={tempateData.name} />

              {desktop === false ? restInfo : <></>}
              <Box
                sx={{
                  display: "flex",
                  gap: desktop ? "40px" : "16px",
                  justifyContent: "space-between",
                  flexDirection: desktop ? "row" : "column",
                  alignItems: "center",
                }}
              >
                <Box sx={{
                  margin: "0 9px"
                }}>
                  <GroupsList
                    groups={tempateData.groups}
                    activeGroup={activeGroup}
                    setActiveGroup={setActiveGroup}
                  />
                </Box>
                <WrapperButton
                  label="Редактировать группы"
                  variant="contained"
                  sx={{
                    width: desktop ? "210px" : "100%",
                  }}
                  onClick={() => navigate(`/constructor/${id}/groups`)}
                />
              </Box>
              <ProductsList positions={products} />

              { tempateData.status === 1 && tempateData.canPublish ? <WrapperButton
                  variant="contained"
                  label="Опубликовать" sx={{
                    width: desktop ? "210px" : "100%",
                  }}
                  onClick={publish}
                  /> : <></> }
            </Box>
          </Box>
        </Box>
      </Box>
      
      {typeModal === "export" && (
        <ConfirmModal
          width={470}
          onClose={() => setTypeModal(null)}
          onAccept={() => setTypeModal(null)}
          buttonAccept="Закрыть"
          buttonCancel={null}
          headerModal="Экспорт шаблона"
          bodyModal={
            <Box sx={{
              color: "#fff"
            }}>
              <Box sx={{}}>
                <Typography sx={{marginBottom: '10px'}}>Ссылка на шаблон</Typography>
                <Box sx={{
                  backgroundColor:'#fff',
                  color: '#61646C',
                  borderRadius: '6px',
                  padding: '7px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'stretch',
                  WebkitAlignContent: 'center'
                }}>
                  <Typography sx={{
                    maxWidth: 'calc(100% - 25px)',
                    overflowWrap: 'anywhere'
                  }}>
                    {linkToTemplate}
                  </Typography>
                  <Box sx={{
                      display: 'flex',
                      alignContent: 'center',
                      justifyContent: 'center',
                      flexWrap: 'wrap',
                    }}
                    onClick={e=> {
                      e.preventDefault();
                      navigator.clipboard.writeText(linkToTemplate!);
                      setTypeModal(null);
                    }}
                  >
                    <ContentCopy
                        sx={{
                          color: "#61646C",
                        }}
                      />
                  </Box>
                </Box>
              </Box>
            </Box>
          }
        />)}
    </Box>
  );
}