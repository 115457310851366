import { Box } from "../../../components";
import { BoxProps } from "@mui/material";

/**Общий контейнер для компонентов. Отступы отличаются от других страниц приложения */
function Container({ sx, ...props }: BoxProps) {

    const def = {
        padding: '16px',
        fontFamily: 'Inter',
    };

    return(
        <Box
            { ...props }
            sx={{
                ...def,
                ...sx,
            }}
        />
    );
}

export default Container;