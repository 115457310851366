import { CSSProperties } from "react";

export const redLink = (isActive: boolean): CSSProperties => ({
    userSelect: 'none',
    whiteSpace: 'nowrap',
    borderRadius: '27px',
    background: isActive ? 'linear-gradient(93.51deg, #FF4D65 0.78%, #F71735 105.69%)' : '#F3F3F4',
    textDecoration: 'none',
    color: isActive ? '#FFFFFF' : '#989CA4',
    paddingLeft: '13.5px',
    paddingRight: '13.5px',
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontWeight: 400,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px'
});

export const redLinkDark = (isActive: boolean): CSSProperties => ({
    userSelect: 'none',
    whiteSpace: 'nowrap',
    borderRadius: '27px',
    background: isActive ? 'linear-gradient(93.51deg, #FF4D65 0.78%, #F71735 105.69%)' : '#1C1C1C',
    textDecoration: 'none',
    color: '#FFFFFF',
    paddingLeft: '13.5px',
    paddingRight: '13.5px',
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontWeight: 400,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px'
});

export const blueLink = (isActive: boolean): CSSProperties => ({
    userSelect: 'none',
    whiteSpace: 'nowrap',
    borderRadius: '27px',
    backgroundColor: isActive ? '#0D0C22' : '#C2C2C2',
    textDecoration: 'none',
    color: '#FFFFFF',
    fontFamily: 'Inter',
    fontSize: '0.875rem',
    fontWeight: 300,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '13px',
    paddingRight: '13px',
    height: '36px'
});