import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import WrapperButton from "../../../component/Button/WrappedButton";
import { CSSProperties, RefObject, useContext, useEffect, useRef, useState } from "react";
import { AdminPanelContext } from "..";
import { TTariff, TTariffNames, Tariff } from "../../../api/request/tariff";
import { useForm, SubmitHandler } from "react-hook-form";
import BlockName from "./BlockName";
import PriceBlock from "./PriceBlock";
import { Box } from "../../../../components";
import OverlayLoader from "./OverlayLoader";
import useLoadingStatus from "./useLoadingStatus";
import { numberWithSpaces } from "../../../utils";
import TDLabel from "./TDLabel";
import Field from "./Field";

export type TPaymentTransactionsForm = {
    trialPeriod: string;
    tariffs: Record<TTariffNames, {
        price: string;
        priceWithDiscount: string;
        tariffId: string;
        period: string;
    }[]>
}

function PaymentTransactions() {
    const [ loader, setLoader ] = useState(false);
    const [ data, setData ] = useState<TTariff>();
    const [ init, setInit ] = useState(false);
    const { control, handleSubmit, resetField } = useForm<TPaymentTransactionsForm>();
    const { setHeaderSlot } = useContext(AdminPanelContext);
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    const submitRef = useRef(null) as RefObject<HTMLButtonElement>;
    const { loadingStatus, setLoadingStatus, getLoadingStatus } = useLoadingStatus();

    useEffect(() => {
        setHeaderSlot?.(<SaveButton/>);
    }, [ setHeaderSlot, desktop, loadingStatus ]);

    //
    function resetAllFields(value: any, field: any) {
        if (Array.isArray(value)) {
            value.forEach((v, i) => resetAllFields(v, field + '.' + String(i)));
        } else if (typeof value === 'object') {
            Object.keys(value).forEach(key => resetAllFields(value[key], field + '.' + key));
        } else {
            resetField(field, { defaultValue: format(value) });
        }
    }

    function format(value: string) {
        return numberWithSpaces(parseInt(value)) + " ₽";
    }

    useEffect(() => {
        if (init) return;
        setLoadingStatus('pending');
        Tariff.tariff()
            .then(result => {
                resetAllFields(result.data.data.tariffs, "tariffs");
                setData(result.data.data);
                setLoadingStatus('successed');
            })
            .catch(() => setLoadingStatus('failed'))
            .finally(() => {
                setInit(true);
                setLoader(false);
            });
    }, [ init ]);

    /**Лоадер появляется, если запрос идёт дольше заданного интервала */
    useEffect(() => {
        if (loadingStatus === 'pending') {
            setTimeout(() => {
                if (getLoadingStatus() === 'pending') setLoader(true);
            }, 2000);
        }
    }, [ loadingStatus ]);

    function refresh() {
        setInit(false);
    }

    const onSubmit: SubmitHandler<TPaymentTransactionsForm> = (data) => {
        const converted = JSON.parse(JSON.stringify(data), (key, value) => {
            if (typeof value === "string") return Number(value.replace(/\D/gi, ""));
            return value;
        });
        setLoadingStatus('pending');
        Tariff.update(converted)
            .then(() => refresh())
            .catch(() => setLoadingStatus('failed'))
            .finally(() => setLoader(false));
    };

    const disabled: CSSProperties = {
        opacity: 0.5,
        pointerEvents: 'none'
    };

    function SaveButton() {
        return(
            <WrapperButton
                //@ts-ignore
                label="Сохранить"
                variant="contained"
                size={desktop ? "large" : "normal"}
                iconPosition="left"
                sx={{
                    width: '156px',
                    height: '36px',
                    margin: desktop ? 0 : '10px 0',
                    fontSize: '0.875rem',
                    fontWeight: 500,
                    lineHeight: '1.857rem',
                    ...(loadingStatus === 'pending' ? disabled : {})
                }}
                onClick={() => {
                    if (!submitRef.current) return;
                    submitRef.current.click();
                }}
            />);
    };

    return(
        <>
            { loader ? <OverlayLoader/> :
            <Box sx={{ opacity: loadingStatus === 'pending' ? 0.5 : 1 }}>
                { init &&
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <table style={{ width: '100%' }}>
                            <tbody>
                                <BlockName label="Пробный период"/>
                                <tr>
                                    <TDLabel>Количество дней пробного периода</TDLabel>
                                    <td>
                                        <Field
                                            value={String(data?.trialPeriod)}
                                            name="trialPeriod"
                                            control={control}
                                        />
                                    </td>
                                </tr>
                                { data?.tariffs && Object.entries(data.tariffs)?.sort(([ keyA ], [ keyB ]) => {
                                    //@ts-ignore to keyA, keyB
                                    return data.tariffs[keyA][0].tariffId - data.tariffs[keyB][0].tariffId;
                                })?.map(([ key, value ]) =>
                                    <PriceBlock
                                        key={key}
                                        data={value}
                                        id={key}
                                        control={control}
                                    />) }
                            </tbody>
                        </table>
                        <button ref={submitRef} style={{ display: 'none' }} type="submit">submit</button>
                    </form> }
            </Box> }
        </>
    );
}

export default PaymentTransactions;