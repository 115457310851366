import { Box, Tooltip } from "../../../../components";
import { LoggingResponseItem } from "./types";
import tootip from '../../../shared/assets/img/tooltip.svg';
import { useState } from "react";

export type ActionSuccessProps = {
    row: LoggingResponseItem;
    desktop: boolean;
};

function ActionSuccess({ row, desktop }: ActionSuccessProps) {
    const [ open, setOpen ] = useState(false);

    return(
        <>
            {row.isSuccess ? 'Успешно' :
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'center',
                    whiteSpace: 'nowrap'
                }}>
                    <Box sx={{
                        fontSize: '0.875rem',
                        fontWeight: 700,
                        lineHeight: '1.0625rem',
                        color: '#FF0000',
                        marginRight: desktop ? '12px' : '5px'
                    }}>
                        С ошибкой
                    </Box>
                    <Tooltip
                        title={row.errorMessage}
                        open={open}
                        onMouseOver={() => setOpen(true)}
                        onMouseOut={() => setOpen(false)}
                    >
                        <img
                            onTouchStart={() => setOpen(!open)}
                            src={tootip}
                            alt='tootip'
                        />
                    </Tooltip>
                </Box>}
        </>
    );
}

export default ActionSuccess;