import { Box, Typography } from "../../../components";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import { ReactNode } from "react";
import {
  ArrowBackIosNew
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function ConstructorTitle({
  buttons = null,
  templateName
}: {
  buttons: ReactNode,
  templateName: string
}) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "start",
        gap: "14px",
      }}
    >
      {desktop && (
        <Box
          sx={{
            display: "flex",
            gap: "14px",
          }}
        >
          <Typography
            variant="subtitle2"
            color="#0D0C22"
            sx={{
              fontSize: desktop ? "2.5rem" : "1.25rem",
              fontWeight: "600",
            }}
          >
            Конструктор меню
          </Typography>
        </Box>
      )}
      <Box sx={{
        width: "100%",
        display: "flex",
        flexDirection: desktop ? "row" : "column",
        gap: "16px"
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'stretch'
        }}
          onClick={() => navigate('/constructor')}
        >
          <Box sx={{
            width: '34px',
            display: 'flex',
            alignItems: 'center',
          }}>
            <ArrowBackIosNew sx={{color: "#61646C" }} />
          </Box>
          <Typography sx={{
            fontSize: '16px',
            color: '#61646C',
            verticalAlign: 'middle',
            width: '100%',
            textAlign: 'center'
          }}>
            {templateName}
          </Typography>
        </Box>
        <Box>
          {buttons && buttons}
        </Box>
      </Box>
    </Box>
  );
}
