import { Box, IconButton, Typography } from "../../../components";
import { TRefreshTable } from "../../component/DataTable/types";
import { Tooltip } from "@mui/material";
import WrappedButton from "../../component/Button/WrappedButton";
import "./styles.scss";
import { StatusEnum } from "./types";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TemplateDataDto } from "../../api/request/template";
import moment from "moment";
import { req } from "../../api";
import { useAlert } from "../../store/alert";
import ConfirmModal from "../../component/Modal/ConfirmModal";
import { CancelOutlined, ContentCopy, Edit, Star } from "@mui/icons-material";

type typeModal = "delete" | "copy" | "publish";


function Template({ template, desktop, navigate, setSelectedTemplate, refreshTableRef, setTypeModal }: 
    { template: TemplateDataDto, desktop: boolean, navigate: (link: string) => void, 
        setSelectedTemplate: (item: TemplateDataDto) => void, refreshTableRef: React.MutableRefObject<TRefreshTable | null>, 
        setTypeModal: (type: typeModal) => void, }) {
    const isPublished = template?.statusName === StatusEnum.PUBLISHED;

    return (
      <Box
        key={template.id}
        sx={{
          display: "flex",
          gap: "8px",
          flexDirection: "column",
          padding: "20px 10px",
          background: isPublished ? "inherit" : "#EEEEEE",
          width: desktop ? "600px" : "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "8px",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
            }}
          >
            <Typography fontSize="16px" fontWeight={600} color="#61646C">
              {template.name}
            </Typography>
            <Typography fontSize="12px" color="#989CA4">
              Создан{" "}
              {moment(template.created as Date).format("DD.MM.YYYY")}
            </Typography>
            {template.published && (
              <Typography fontSize="12px" color="#989CA4">
                Опубликован{" "}
                {moment(template.published as Date).format("DD.MM.YYYY")}
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "8px",
            }}
          >
            <Controls row={template} setSelectedTemplate={setSelectedTemplate} refreshTableRef={refreshTableRef} setTypeModal={setTypeModal}/>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "16px",
          }}
        >
          <WrappedButton
            label="Редактировать"
            variant="contained"
            icon={<Edit />}
            sx={{
              height: "32px",
              flex: 1,
              fontSize: '12px'
            }}
            onClick={() => {
              navigate(`/constructor/${template.id}/constructor`);
            }}
          />
          {!isPublished ? (
            <WrappedButton
              label="Опубликовать"
              variant="contained"
              icon={<Star />}
              sx={{
                height: "32px",
                flex: 1,
                fontSize: '12px'
              }}
              onClick={() => {
                setSelectedTemplate(template);
                setTypeModal("publish");
            }}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
                color: "#61646C",
                padding: "0 auto",
                flex: 1,
              }}
            >
              <Star />
              <Typography fontSize="12px">Опубликовано</Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
}

function Controls({row, refreshTableRef, setSelectedTemplate, setTypeModal}: 
    { row: TemplateDataDto, updateTable?: TRefreshTable, setSelectedTemplate: (row: TemplateDataDto) => void, setTypeModal: (type: typeModal) => void, refreshTableRef: React.MutableRefObject<TRefreshTable | null>}) {
    
    return (<>
      <IconButton
        sx={{ padding: 0 }}
        onClick={(e) => {
          e.stopPropagation();
          setSelectedTemplate(row);
          setTypeModal("copy");
        }}
      >
        <ContentCopy
          sx={{
            color: "#61646C",
          }}
        />
      </IconButton>
      {row?.statusName !== StatusEnum.PUBLISHED && (
        <IconButton
          sx={{ padding: 0 }}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedTemplate(row);
            setTypeModal("delete");
          }}
        >
          <Tooltip
            id={"button-delete"}
            title="Удалить"
            style={{ transition: "none !important" }}
            enterDelay={500}
            leaveDelay={0}
            TransitionProps={{ timeout: 0 }}
            placement={"bottom-start"}
          >
            <CancelOutlined sx={{
              color: "red",
            }}/>
          </Tooltip>
        </IconButton>
      )}
    </>);
}


export default function TemplateList({ templateData, desktop, loadTemplates, refreshTableRef }: 
    {templateData: TemplateDataDto[], desktop: boolean, refreshTableRef: React.MutableRefObject<TRefreshTable | null>, loadTemplates: () => void}) {
    const navigate = useNavigate();
    const { openAlert } = useAlert();
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateDataDto | null>(null);
    const [typeModal, setTypeModal] = useState<typeModal | null>(null);

    const handlerDeleteTemplate = () => {
    if (selectedTemplate && selectedTemplate.id) {
        req.template
        .deleteTemplate(selectedTemplate.id)
        .then((res) => {
            if (res.success) {
                openAlert({
                    text: "Шаблон успешно удален",
                    isVisibleCloseIcon: true,
                    autoHide: true,
                    type: "success",
                });
                loadTemplates();
            }
        })
        .catch(() => {
            openAlert({
                text: "Произошла ошибка при удалении шаблона",
                isVisibleCloseIcon: true,
                autoHide: true,
                type: "error",
            });
        });
    }
    };    

    const handlerCopyTemplate = () => {
    if (selectedTemplate && selectedTemplate.id) {
        req.template
        .copyTemplate(selectedTemplate.id)
        .then((res) => {
            if (res.success) {
                openAlert({
                    text: "Шаблон успешно скопирован",
                    isVisibleCloseIcon: true,
                    autoHide: true,
                    type: "success",
                });
                navigate(`/constructor/${res.data}/constructor`);
            }
        })
        .catch(() => {
            openAlert({
                text: "Произошла ошибка при копировании шаблона",
                isVisibleCloseIcon: true,
                autoHide: true,
                type: "error",
            });
        });
    }
    };

    const publishTemplate = () => {
        if (!selectedTemplate) return;

        req.template
            .publishTemplate(selectedTemplate.id)
            .then((res) => {
            if (res.success) {
                openAlert({
                    text: "Шаблон успешно опубликован",
                    isVisibleCloseIcon: true,
                    autoHide: true,
                    type: "success",
                });
                refreshTableRef.current && refreshTableRef.current();
            } else {
                openAlert({
                    text: res.errors.join(";\n"),
                    isVisibleCloseIcon: true,
                    autoHide: false,
                    type: "error",
                });
            }
            })
            .catch(() => {
                openAlert({
                    text: "Произошла ошибка при публикации шаблона",
                    isVisibleCloseIcon: true,
                    autoHide: true,
                    type: "error",
                });
            });
    };

return (<>
    {templateData.map((template) => <Template 
        key={template.id}
        template={template} 
        setSelectedTemplate={setSelectedTemplate}
        setTypeModal={setTypeModal}
        desktop={desktop} 
        navigate={navigate}
        refreshTableRef={refreshTableRef}
    />)}
    {typeModal === "delete" && (
        <ConfirmModal
            onClose={() => setTypeModal(null)}
            onAccept={handlerDeleteTemplate}
            buttonAccept="Удалить шаблон"
            headerModal="Удалить шаблон"
            bodyModal={`Вы действительно хотите удалить шаблон ${selectedTemplate?.name}?`}
        />
    )}
    {typeModal === "copy" && (
        <ConfirmModal
            onClose={() => setTypeModal(null)}
            onAccept={handlerCopyTemplate}
            buttonAccept="Скопировать шаблон"
            headerModal="Скопировать шаблон"
            bodyModal={`Вы действительно хотите скопировать текущий шаблон? При нажатии на кнопку "Спопировать шаблон" будет открыт новый шаблон.`}
        />
    )}
    {typeModal === "publish" && (
        <ConfirmModal
            onClose={() => setTypeModal(null)}
            onAccept={publishTemplate}
            buttonAccept="Опубликовать"
            headerModal="Опубликовать шаблон"
            bodyModal={`Вы действительно хотите опубликовать шаблон "${selectedTemplate?.name}"?`}
        />
    )}
  </>);

}