import { Box } from "../../../components";
import { useEffect, useState } from "react";
import BlockData from "./shared/blockData";
import { Path, SubmitHandler, useForm } from "react-hook-form";
import ChangeTelephoneOrEmailModal from "./modal/changeTelephoneOrEmailModal";
import { ModalKeyType } from "./type";
import BasicData from "./blocks/basicData";
import ChangeOpeningHoursModal from "./modal/changeOpeningHoursModal";
import HistoryOrders from "./blocks/historyOrders";
import AutomaticPaymentModal from "./modal/automaticPaymentModal";
import CommonContainer from "../../component/CommonContainer";
import { UserInfo } from "../../api/request/auth";
import { getUserData, useUserData } from "../../store/user";
import { req } from "../../api";
import { useAlert } from "../../store/alert";
import { useDispatch } from "../../store/model/extensions";
import TariffSelect from "./blocks/tariffSelect";
import ConfirmEmailModal from "./modal/confirmEmailModal";
import PublicOfferModal from "./modal/publicOffer";
import PaymentsAgreementModal from "./modal/paymentsAgreement";

const menuList = [
  {
    title: "Основные данные",
    link: "#basic-data",
    defaultSelect: true,
  },
  /*{
    title: "Моё заведение",
    link: "#my-establishment",
  },*/
  {
    title: "Управление тарифами и подписками",
    link: "#subscription-management",
  },
  {
    title: "История заказов",
    link: "#history-orders",
  },
];

const PersonalArea = () => {
  const [typeModal, setTypeModal] = useState<ModalKeyType>(null);
  const { userData } = useUserData();
  const { openAlert } = useAlert();
  const dispatch = useDispatch();

  const {
    handleSubmit,
    watch,
    control,
    formState: { errors },
    resetField,
    setError,
    clearErrors,
    getValues,
  } = useForm<UserInfo & { newPassword: string; confirmNewPassword: string }>({
    defaultValues: { ...userData, newPassword: "", confirmNewPassword: "" },
  });

  const requestSendUpdateUser = (request: {
    data: UserInfo;
    changedField: Path<UserInfo>;
    value?: any;
    textMessageSuccess?: string;
  }) => {
    return req.user
      .updateUser({ ...request.data, changedField: request.changedField })
      .then((res) => {
        if (!res.success) {
          openAlert({
            text: res.errors.join(";\n"),
            isVisibleCloseIcon: true,
            autoHide: false,
            type: "error",
          });
          return false;
        }

        resetField(request.changedField, {
          keepDirty: false,
          defaultValue: request.value
            ? request.value
            : watch(request.changedField),
        });
        dispatch(getUserData());
        openAlert({
          text: request.textMessageSuccess
            ? request.textMessageSuccess
            : "Данные пользователя успешно обновлены",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "success",
        });
      
        return true;
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при обновлении пользователя",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });

        return false;
      });
  };

  const onSubmit: SubmitHandler<
    UserInfo & { newPassword: string; confirmNewPassword: string }
  > = (data, e: any) => {
    const { confirmNewPassword, newPassword, ...necessaryData } = data;
    const changedField = e?.nativeEvent?.submitter.name as Path<UserInfo>;
    requestSendUpdateUser({ data: necessaryData, changedField });
  };

  const updateUserWithModal = (
    value: any,
    changedField: Path<UserInfo>,
    textMessageSuccess: string,
  ) => {
    const formData = JSON.parse(JSON.stringify(getValues()));
    changedField.split(".").reduce((acc, key, index, array) => {
      if (index === array.length - 1) {
        acc[key] = value;
        return acc;
      }
      return acc[key];
    }, formData);
    return requestSendUpdateUser({
      data: formData,
      changedField,
      value,
      textMessageSuccess,
    });
  };
  //Обновление часов работы в useForm после получения данных с бэка
  useEffect(() => {
    resetField("organization.workhoursString", {
      keepDirty: false,
      defaultValue: userData?.organization?.workhoursString,
    });
  }, [userData?.organization?.workhoursString]);

  //Обновление блока "Управление тарифами и подписками" в useForm после получения данных с бэка
  useEffect(() => {
    if (userData?.order) {
      resetField("order.periodName", {
        keepDirty: false,
        defaultValue: userData?.order.periodName,
      });
      resetField("order.tariffName", {
        keepDirty: false,
        defaultValue: userData?.order.tariffName,
      });
      resetField("order.created", {
        keepDirty: false,
        defaultValue: userData?.order.created,
      });
      resetField("order.finishDate", {
        keepDirty: false,
        defaultValue: userData?.order.finishDate,
      });
    }
  }, [
    userData?.order?.periodName,
    userData?.order?.tariffName,
    userData?.order?.created,
    userData?.order?.finishDate
  ]);

  return (
    <>
      <CommonContainer title="Личный кабинет" menuItems={menuList}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "65px",
            }}
          >
            <BlockData
              id={menuList[0].link.replace("#", "")}
              title={menuList[0].title}
            >
              <BasicData
                clearErrors={clearErrors}
                control={control}
                setError={setError}
                watch={watch}
                setTypeModal={setTypeModal}
                isAdmin={userData?.role.name === "Admin"}
              />
            </BlockData>
            {/*<BlockData
              id={menuList[1].link.replace("#", "")}
              title={menuList[1].title}
            >
              <Establishment
                clearErrors={clearErrors}
                control={control}
                setError={setError}
                watch={watch}
                setTypeModal={setTypeModal}
                isAdmin={userData?.role.name === "Admin"}
                resetField={resetField}
              />
            </BlockData>*/}
            <BlockData
              id={menuList[1].link.replace("#", "")}
              title={menuList[1].title}
            >
              <TariffSelect
                control={control}
                setError={setError}
                clearErrors={clearErrors}
                watch={watch}
                automaticPayment={userData?.order?.autoProlongation ?? false}
                isAdmin={userData?.role.name === "Admin"}
                setTypeModal={setTypeModal}
              />
            </BlockData>
            <BlockData
              id={menuList[2].link.replace("#", "")}
              title={menuList[2].title}
            >
              <HistoryOrders />
            </BlockData>
          </Box>
        </form>
      </CommonContainer>

      {(typeModal === "phone" || typeModal === "email") && (
        <ChangeTelephoneOrEmailModal
          onClose={() => {
            setTypeModal(null);
          }}
          currentValue={
            typeModal === "email" ? watch("email") : watch("phoneNumber")
          }
          typeModal={typeModal}
          updateUserWithModal={updateUserWithModal}
          resetField={resetField}
        />
      )}
      {typeModal === "time" && (
        <ChangeOpeningHoursModal
          onClose={() => {
            setTypeModal(null);
          }}
          workhours={watch("organization.workhours")}
          updateUserWithModal={updateUserWithModal}
        />
      )}
      {typeModal === "automaticPayment" && (
        <AutomaticPaymentModal
          isActive={userData?.order?.autoProlongation ?? false}
          onChange={async () => {
            const currentValue = userData?.order?.autoProlongation;
            await updateUserWithModal(!currentValue, "order.autoProlongation", "");
            setTypeModal(null);
          }}
          onClose={() => {
            setTypeModal(null);
          }}
        />
      )}
      {typeModal === "confirmEmail" && (
        <ConfirmEmailModal
          onClose={() => {
            setTypeModal(null);
          }}
          currentValue={watch("email")}
          updateUserWithModal={updateUserWithModal}
        />
      )}
      {typeModal === "publicOffer" && (
        <PublicOfferModal
          onClose={() => {
            setTypeModal(null);
          }}
        />
      )}
      {typeModal === "paymentsAgreement" && (
        <PaymentsAgreementModal
          onClose={() => {
            setTypeModal(null);
          }}
        />
      )}
    </>
  );
};

export default PersonalArea;
