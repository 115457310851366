import { api } from "../api";
import { Response } from "../type";

export namespace File {
  export async function getGuidFile(id: string) {
    return api.sendRequest<Blob>({
      domain: "on-menu",
      method: "get",
      url: `/file/downloadGuid?guid=${id}`,
      responseType: "blob",
    });
  }
  export async function upload(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return api.sendRequest<Response<{ guid: string }>>({
      domain: "on-menu",
      method: "post",
      url: `/file/upload`,
      data: formData,
    });
  }
}
