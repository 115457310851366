import {
  Box,
  Typography,
} from "../../../../components";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import { ReactNode } from "react";

type BlockDataType = {
  title: string;
  id: string;
  children: ReactNode;
};

const BlockData = ({ title, id, children }: BlockDataType) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Box id={id} component={"section"}>
      <Typography
        variant="subtitle2"
        color="#0D0C22"
        sx={{
          fontWeight: "500",
          paddingBottom: "9px",
        }}
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: desktop ? "25px" : "15px",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BlockData;
