import { NavLink, NavLinkProps } from "react-router-dom";
import usePreventClick from "./usePreventClick";
import useLinkSelect from "../useLinkSelect";
import { CSSProperties } from "react";
import { blueLink } from "./mobileLinkStyles";

export type TMobileLinkStyle = (isActive: boolean) => CSSProperties;

export type LinkProps = {
  mobileLinkStyle?: TMobileLinkStyle;
  id?: string;
} & NavLinkProps;

function Link({ mobileLinkStyle, ...props }: LinkProps) {
  const preventClick = usePreventClick();
  const { id } = props;
  const { isActive, linkRef } = useLinkSelect(id!);
  const style = mobileLinkStyle?.(isActive) || blueLink(isActive);

  return (
    <NavLink
      ref={linkRef}
      draggable="false"
      style={style}
      {...preventClick}
      {...props}
    />
  );
}

export default Link;
