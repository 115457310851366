import { Box } from "../../../../components";
import Loader from "../../../component/Loader";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";

function OverlayLoader() {
    const [ height, setHeight ] = useState(0);
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            const header = document.getElementsByTagName('header')?.[0];
            setHeight(window.innerHeight - header.offsetHeight - 50 - 88);
        });
        observer.observe(document.documentElement);
        return () => observer.disconnect();
    }, []);

    return(
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: height + 'px',
                minHeight: '200px'
            }}
        >
            <Box sx={{ transform: `translateY(-${desktop ? 87 : 0}px)` }}>
                <Loader/>
            </Box>
        </Box>
    );
}

export default OverlayLoader;