import {
  Box,
  Typography,
} from "../../../../components";
import { useTemlateCardData } from "../../../store/templateCard";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";
import moment from "moment";

export type InformationTemplateType = {};

function InformationTemplate({}: InformationTemplateType) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const {
    tempateData: { created, modified, status: statusId, published, statusName },
  } = useTemlateCardData();

  const renderInfoData = (label: string, value: string | null) => {
    return (
      <Typography
        variant="body1"
        color="#848484"
        sx={{
          fontStyle: "italic",
          fontWeight: "300",
        }}
      >
        {label + " "}
        <Typography
          variant="body1"
          color="#848484"
          sx={{
            fontStyle: "italic",
            fontWeight: "600",
            display: "inline",
          }}
        >
          {value ?? "-"}
        </Typography>
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: desktop ? "row" : "column",
        justifyContent: "space-between",
      }}
    >
      {renderInfoData("Статус публикации:", statusName)}
      {renderInfoData("Дата создания:", moment(created).format("DD.MM.YYYY"))}
      {renderInfoData(
        "Дата обновления:",
        modified ? moment(modified).format("DD.MM.YYYY") : null,
      )}
      {renderInfoData(
        "Дата публикации:",
        published ? moment(modified).format("DD.MM.YYYY") : null,
      )}
    </Box>
  );
}

export default InformationTemplate;
