import { AccordionSummary as MuiAccordionSummary, AccordionSummaryProps as MuiAccordionSummaryProps, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function AccordionSummary({ sx, children, ...props }: MuiAccordionSummaryProps) {
    return(
        <MuiAccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={[
                { padding: '0 24px' },
                ...(Array.isArray(sx) ? sx : [sx])
            ]}
            { ...props }
        >
            <Typography variant='subtitle2' component='div'>
                { children }
            </Typography>
        </MuiAccordionSummary>
    );
}

export default AccordionSummary;