import { Radio, FormControlLabel } from "../../../components";
import React from "react";
import radioIcon from "shared/assets/img/radio.svg";
import noRadioIcon from "shared/assets/img/no-radio.svg";
import { FormControlLabelProps } from "@mui/material";

interface WrappedRadioButtonProps
  extends Omit<FormControlLabelProps, "control"> {
  checked: boolean;
  onRadioChange: (value: string) => void;
}

const WrappedRadioButton: React.FC<WrappedRadioButtonProps> = ({
  checked,
  onRadioChange,
  sx = [],
  ...props
}) => {
  return (
    <FormControlLabel
      sx={[
        (theme) => ({
          alignItems: "start",
          marginLeft: 0,
          "& input": {
            width: "24px",
          },
          "& span:first-child": {
            padding: "0 8px 0 0",
          },
          "& span:first-child::before": {
            content: checked ? `url("${radioIcon}")` : `url("${noRadioIcon}")`,
            width: "24px",
            height: "24px",
            fontSize: "0",
          },
          "& span:first-child svg": {
            display: "none",
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      control={
        <Radio
          size="normal"
          checked={checked}
          onChange={(event) => onRadioChange(event.target.value)}
        />
      }
      {...props}
    />
  );
};

export default WrappedRadioButton;
