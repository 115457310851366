import { Box } from "../../../../components";
import 'react-alice-carousel/lib/alice-carousel.css';
import { EElementType, MenuTemplate } from "../../../api/request/menuTemplate";

export type TGroupItem = {
    id: number;
    title: string;
    defaultSelect?: boolean;
};

export type GroupsProps = {
    items: TGroupItem[];
    group: number;
    setGroup: (group: number) => void;
    lightTheme: boolean;
    templateId: number;
};

function Groups({ items, group, setGroup, lightTheme, templateId }: GroupsProps) {
    const langItems = items.map((item, index) =>
        <Box
            key={item.id}
            sx={{
                display: 'inline',
                cursor: 'pointer'
            }}
            onClick={() => {
                MenuTemplate.Click(EElementType.GROUP, templateId, item.id);
                setGroup(item.id);
            }}
        >
            <Box
                sx={{
                    userSelect: 'none',
                    whiteSpace: 'nowrap',
                    //borderRadius: '27px',
                    //background: isActive ? 'linear-gradient(93.51deg, #FF4D65 0.78%, #F71735 105.69%)' : '#F3F3F4',
                    textDecoration: 'none',
                    color: group === item.id ? '#FF4D65' : '#989CA4',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    fontFamily: 'Inter',
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    display: 'inline-flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    letterSpacing: '0.4px',
                }}
            >
                { item.title }
            </Box>
        </Box>
    );

    return(
        <Box sx={{
            padding: "0px 10px",
            gap: "6px",
            display: "flex",
            flexWrap: "wrap",
            lineHeight: "17px",
        }}>
            {langItems}
        </Box>
    );
}

export default Groups;