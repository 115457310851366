import {
  Box,
  Typography,
} from "../../../../components";
import { ReactNode } from "react";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";

export type WrapperLabelType = {
  title: string;
  required?: boolean;
  children: ReactNode;
};

function WrapperLabel({ children, title, required }: WrapperLabelType) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        gap: desktop ? "20px" : "0px",
        position: "relative",
        flexDirection: desktop ? "row" : "column",
      }}
    >
      <Box
        sx={{
          flex: "none",
          flexBasis: desktop ? "220px" : "unset",
        }}
      >
        <Typography variant="caption" color="#0D0C22">
          {title}
          {required && (
            <Typography variant="caption" color="#F00" fontWeight="700">
              *
            </Typography>
          )}
        </Typography>
      </Box>
      {children}
    </Box>
  );
}

export default WrapperLabel;
