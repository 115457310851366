import rus from "../../../shared/assets/img/flag-russia.png";
import en from "../../../shared/assets/img/flag-uk.svg";
import chs from "../../../shared/assets/img/flag-china.png";
import esp from "../../../shared/assets/img/flag-spain.png";

export enum ELanguages {
    RU = 1,
    EN,
    CHS,
    ESP
}

export type TLangItem = {
    id: number;
    icon: string;
    title: string;
};

function createLangItems(items: number[]): TLangItem[] {
    return items.map(i => ({
        id: i,
        icon: getIcon(i),
        title: getTitle(i)
    }));
}

function getIcon(id: number) {
    switch(id) {
        case ELanguages.RU:
            return rus;
        case ELanguages.EN:
            return en;
        case ELanguages.CHS:
            return chs;
        case ELanguages.ESP:
            return esp;
        default:
            return null;
    }
}

function getTitle(id: number) {
    switch(id) {
        case ELanguages.RU:
            return 'РУС';
        case ELanguages.EN:
            return 'EN';
        case ELanguages.CHS:
            return 'CHS';
        case ELanguages.ESP:
            return 'ESP';
        default:
            return '';
    }
}

export default createLangItems;