import {
  Alert as MuiAlert,
  AlertColor,
  AlertProps as MuiAlertProps,
  IconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Text from "../Common/Text";
import { Close, Error, Info, Success, Warning } from "./AlertIcons";
import { ReactNode } from "react";
import { AnimatedIcon } from "./AnimatedIcon";
import Tooltip from "../../Tooltip";

export type SimpleAlertProps = {
  type?: AlertColor | null;
  variant?: "standard" | "outlined" | "filled";
  text: string;
  showBorder?: boolean;
  showIcon?: boolean;
  compact?: boolean;
  animateIcon?: boolean;
} & Omit<MuiAlertProps, "color" | "severity">;

function SimpleAlert({
  type = "success",
  variant = "standard",
  text,
  sx = [],
  children,
  onClose,
  showBorder = false,
  showIcon = true,
  compact = false,
  animateIcon = false,
  ...props
}: SimpleAlertProps) {
  const theme = useTheme();

  const contentColor =
    variant !== "filled" && type
      ? theme.palette[type][theme.palette.mode]
      : "white";

  const typeProps = type
    ? {
        color: type, //цвет
        severity: type, //и тип иконки
      }
    : {};

  const alertBorder =
    showBorder && variant === "standard"
      ? { border: `solid 1px ${contentColor}` }
      : {};

  const containerPaddings = getContainerPaddings({
    compact,
    showIcon,
    animateIcon,
    onClose: !!onClose,
  });

  const commonIconProps = { showIcon, contentColor, animateIcon };

  return (
    <MuiAlert
      variant={variant}
      iconMapping={{
        success: getIcon({
          icon: <Success fill={contentColor} />,
          ...commonIconProps,
        }),
        error: getIcon({
          icon: <Error fill={contentColor} />,
          ...commonIconProps,
        }),
        warning: getIcon({
          icon: <Warning fill={contentColor} />,
          ...commonIconProps,
        }),
        info: getIcon({
          icon: <Info fill={contentColor} />,
          ...commonIconProps,
        }),
      }}
      action={
        onClose && (
          <Tooltip title={props?.closeText}>
            <IconButton>
              <Close fill={contentColor} />
            </IconButton>
          </Tooltip>
        )
      }
      onClose={onClose}
      sx={[
        { paddingLeft: containerPaddings.left + "px" },
        { paddingRight: containerPaddings.right + "px" },
        { paddingTop: containerPaddings.top + "px" },
        { paddingBottom: containerPaddings.bottom + "px" },
        {
          ".MuiAlert-icon": {
            transform: "translateY(1px)",
            width: "20px",
            height: "20px",
            overflow: "visible",
            marginRight: showIcon && animateIcon ? "30px" : "12px",
          },
        },
        { ".MuiAlert-message": { minWidth: "360px", width: "100%" } },
        alertBorder,
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...typeProps}
      {...props}
    >
      <Text color={contentColor}>{text}</Text>

      {children}
    </MuiAlert>
  );
}

type TGetIcon = {
  icon: ReactNode;
  contentColor: string;
  animateIcon: boolean;
  showIcon: boolean;
};

function getIcon({ showIcon, icon, contentColor, animateIcon }: TGetIcon) {
  if (!showIcon) return <></>;

  return animateIcon ? (
    <AnimatedIcon fill={contentColor}>{icon}</AnimatedIcon>
  ) : (
    icon
  );
}

type TGetContainerPaddings = {
  compact: boolean;
  showIcon: boolean;
  animateIcon: boolean;
  onClose: boolean;
};

function getContainerPaddings({
  compact,
  showIcon,
  animateIcon,
  onClose,
}: TGetContainerPaddings) {
  if (!compact && showIcon) {
    return {
      left: animateIcon ? 30 : 16,
      right: onClose ? 21 : 16,
      top: animateIcon ? 20 : 8,
      bottom: animateIcon ? 20 : 8,
    };
  } else if (!compact && !showIcon) {
    return {
      left: 4,
      right: 16,
      top: 8,
      bottom: 8,
    };
  } else if (compact && showIcon) {
    return {
      left: animateIcon ? 30 : 18,
      right: 23,
      top: animateIcon ? 12 : 2,
      bottom: animateIcon ? 12 : 2,
    };
  } else {
    return {
      left: 4,
      right: 13,
      top: 2,
      bottom: 2,
    };
  }
}

export default SimpleAlert;
