import { api } from "../api";
import { Response } from "../type";

export namespace Tariff {
    export async function tariff() {
        return api.sendRequest<Response<TTariff>>({
            domain: "on-menu",
            method: "get",
            url: `/Billing/Tariff`
        });
    }

    export async function update(data: TTariff) {
        return api.sendRequest<Response<TTariff>>({
            domain: "on-menu",
            method: "put",
            url: `/Billing/Tariff`,
            data
        });
    }
}

export type TTariff = {
    trialPeriod: number;
    tariffs: TTariffs;
}

export type TTariffNames = 'premium'|'advanced'|'base'

export type TTariffs = Record<TTariffNames, TTariffPlanData[]>;

export type TTariffPlanData = {
    tariffId: number;
    period: number;
    periodName: string,
    price: number;
    priceWithDiscount: number;
}

export enum TTariffId {
    BASE = 1,
    ADVANCED,
    PREMIUM
}

export enum TPeriod {
    OneMonth = 1,
    SixMonth,
    TwelveMonth
}