import React from 'react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@mui/system';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const filter = {
  '-webkit-filter': 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
  filter: 'drop-shadow( 1px 1px 1px rgba(0, 0, 0, .7))',
}
interface ISpinner {
  size: string;
  delay?: number;
  easing?: string;
  children?: React.ReactNode;
}


const Spinner = ({ size = '50px', delay=1, easing='linear', children }: ISpinner) => {

  const RotatedBox = styled("div")({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: size,
    minHeight: size,
    width: size,
    height: size,
    maxWidth: size,
    maxHeight: size,
    WebkitTransformOrigin: '50% 50%',
    MozTransformOrigin: '50% 50%',
    OTransformOrigin: '50% 50%',
    transformOrigin: '50% 50%',
    animation: `${spin} ${delay}s ${easing} infinite`,
  });

  return (
    <RotatedBox>
      {children
        ? children
        : (<svg viewBox="0 0 172 172" fill="none" xmlns="http://www.w3.org/2000/svg" style={filter}>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M127.737 135.617L125.664 120.914L123.334 106.685L131.948 113.865C139.658 101.914 142.447 85.0191 138.098 70.4641C136.803 66.131 135.157 61.9879 132.881 58.1953C131.81 56.4105 130.603 54.5581 129.359 52.9425C128.682 52.0632 127.826 50.9821 126.9 49.9272C120.899 43.0977 113.544 38.2425 105.811 35.1723L117.348 21.0579L112.298 14.5198C128.217 20.791 140.998 30.8522 150.833 47.0864C165.933 73.2462 164.148 104.134 148.33 127.432L155.875 133.817L142.876 134.725L127.737 135.617Z" fill="white"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6568 95.3544C6.05453 66.0216 21.5467 33.8276 49.0911 19.0498C62.6971 11.5657 77.1704 8.8912 91.67 9.8993L91.6124 0.0790666L100.923 10.1532L110.252 20.9752L101.109 31.4385L91.9132 42.4853L91.8633 31.9606C81.0127 30.8246 69.7126 32.9569 59.5246 38.9044C40.7883 49.8267 31.1068 70.3397 33.1666 90.5385L14.5031 87.0199L10.6568 95.3544Z" fill="#1264B6"></path>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M20.4469 123.637C18.9701 121.045 17.6374 118.776 16.4526 116.071L8.27407 121.134L13.0352 106.681L17.3404 92.9389L30.9565 95.8652L44.5726 98.7915L36.1105 103.943C37.0115 106.186 37.9129 108.396 39.1487 110.64C53.8719 137.383 86.1265 146.367 112.059 132.461C114.044 131.483 118.888 128.162 120.394 126.88L123.037 141.011L135.866 141.7C132.891 144.413 127.818 148.178 124.181 150.25C88.4777 171.43 41.7792 159.847 20.4469 123.637Z" fill="#E01B21"></path>
        </svg>)
      }
    </RotatedBox>
  )
};

export default Spinner;