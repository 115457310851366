import { createSlice } from "@reduxjs/toolkit";
import { modelExtensions } from "../model";
import {
  STORAGE_KEY,
  deleteAuthContext,
  getAuthContext,
  saveAuthContext,
  setImpersonalization,
} from "../../app/api";
import { req } from "../../api";
import { getSliceDataSelectorFactory } from "../../utils";
import { useDispatch, useSelector } from "../model/extensions";
import { LoginParams, UserInfo } from "../../api/request/auth";

type initialStateType = {
  user: UserInfo | null;
  loading: "pending" | "successed" | "failed";
};

const initialState: initialStateType = {
  user: null,
  loading: "pending",
};

//Функция первоначальной проверки данных пользователя
export const fetchUserData = modelExtensions.createAsyncThunk(
  "fetchUserData",
  async (_: void, thunkApi) => {
    const authContext = await getAuthContext(STORAGE_KEY);
    if (authContext.access_token === null) {
      return thunkApi.rejectWithValue("Cannot read user id from auth context");
    }
    try {
      const response = await req.auth.getCurrent();
      if (response.status === 401) {
        return thunkApi.rejectWithValue("Error retrieving user data");
      }
      return response;
    } catch (e: any) {
      if (e.response.status === 401) {
        return thunkApi.rejectWithValue("Error retrieving user data");
      }
      throw e;
    }
  },
);

//Функция получения данных пользователя
export const getUserData = modelExtensions.createAsyncThunk(
  "getUserData",
  async (_: void, thunkApi) => {
    try {
      return await req.auth.getCurrent();
    } catch (e: any) {
      if (e.response.status === 401) {
        return thunkApi.rejectWithValue("Error retrieving user data");
      }
      throw e;
    }
  },
);

export const logIn = modelExtensions.createAsyncThunk(
  "logIn",
  async (params: LoginParams & { rememberMe: boolean }, thunkApi) => {
    try {
      setImpersonalization(null);
      const res = await req.auth.login({
        userName: params.userName,
        password: params.password,
      });
      if (res.success) {
        await saveAuthContext(
          {
            access_token: res.data.token,
          },
          params.rememberMe,
        );
      }
      return res;
    } catch (e: any) {
      if (e.response.data.error) {
        throw new Error(e.response.data.error);
      }
      return thunkApi.rejectWithValue("Error retrieving user data");
    }
  },
);

export const logOut = modelExtensions.createAsyncThunk(
  "logOut",
  async (_: void) => {
    const res = await req.auth.logout();
    if (res.success) {
      await deleteAuthContext();
      setImpersonalization(null);
    }
    return res;
  },
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  extraReducers: (builder) => {
    //fetchUserData
    builder.addCase(fetchUserData.pending, (state, action) => {
      state.loading = "pending";
    });
    builder.addCase(fetchUserData.fulfilled, (state, action) => {
      state.user = action.payload.data;
      state.loading = "successed";
    });
    builder.addCase(fetchUserData.rejected, (state, action) => {
      state.loading = "failed";
      console.error(action.error);
    });
    //getUserData
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.user = action.payload.data;
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      console.error(action.error);
    });
    // login
    builder.addCase(logIn.fulfilled, (state, action) => {
      state.user = action.payload?.data?.currentUser || null;
      if (action.payload.success) {
        state.loading = "successed";
      } else {
        state.loading = "failed";
      }
    });
    builder.addCase(logIn.rejected, (state, action) => {
      state.loading = "failed";
      console.error(action.error);
    });
    // logout
    builder.addCase(logOut.fulfilled, (state) => {
      state.user = null;
      state.loading = "failed";
    });
    builder.addCase(logOut.rejected, (state, action) => {
      console.error(action.error);
    });
  },
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export default userSlice.reducer;

const selectorFactory = getSliceDataSelectorFactory("user");

//selection
const selectLoading = selectorFactory((state) => state.loading);
const selectUserData = selectorFactory((state) => state.user);
const selectCurrentTariff = selectorFactory(
  (state) => state.user?.order?.tariffName || null,
);

export function useUserData() {
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const userData = useSelector(selectUserData);
  const currentTariff = useSelector(selectCurrentTariff);

  const setLoading = async (loading: "pending" | "successed" | "failed") => {
    await dispatch(userSlice.actions.setLoading(loading));
  };

  return {
    loading,
    userData,
    currentTariff,
    setLoading,
  };
}
