import { Box, styled } from "../../../../components";
import { TTariffId, TTariffPlanData } from "../../../api/request/tariff";
import BlockName from "./BlockName";
import Field from "./Field";
import TDLabel from "./TDLabel";
import { Fragment } from "react";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../../theme/theme";

export type PriceBlockProps = {
    data: TTariffPlanData[];
    id: string;
    control: any;
}

function TRSpacer({ height }: { height: string }) {
    const TR = styled('tr')({ height });
    return(<TR><td colSpan={2}></td></TR>);
}

function PriceBlock({ data, id, control }: PriceBlockProps) {
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

    const label = (() => {
        const tariffId = data[0].tariffId;
        switch(tariffId) {
            case TTariffId.BASE:
                return "Базовый тариф";
            case TTariffId.ADVANCED:
                return "Продвинутый тариф";
            case TTariffId.PREMIUM:
                return "Премиум";
            default:
                return "Неизвестный тариф";
        }
    })();

    return(
        <>
            <TRSpacer height={desktop ? "77px" : "30px"}/>
            <BlockName label={label}/>
            { data.map((item, index) =>
                <Fragment key={item.period}>
                    <TRSpacer height={index > 0 ? "25px" : "9px"}/>
                    <tr>
                        <TDLabel>{ 'Стоимость, ' + item.periodName }</TDLabel>
                        <td>
                            {/**Невидимые инпуты для tariffId и period */}
                            <Box sx={{ display: 'none' }}>
                                <Field
                                    value={String(item.tariffId)}
                                    control={control}
                                    name={`tariffs.${id}.${index}.tariffId`}
                                />
                                <Field
                                    value={String(item.period)}
                                    control={control}
                                    name={`tariffs.${id}.${index}.period`}
                                />
                            </Box>
                            <Field
                                value={String(item.price)}
                                symbol=" ₽"
                                name={`tariffs.${id}.${index}.price`}
                                control={control}
                            />
                        </td>
                    </tr>
                </Fragment>) }
        </>
    );
}

export default PriceBlock;