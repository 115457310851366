import { AxiosError } from "axios";

export class AuthenticationError extends Error {}

export class ValidationError extends AxiosError {
  constructor(e: AxiosError) {
    let message = e.message;
    //@ts-ignore errors
    if (e.response?.data?.errors?.length) {
      //@ts-ignore errors
      message += "\n" + e.response.data.errors.join("\n");
    }
    super(message);
  }
}
