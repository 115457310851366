import { IconButton, SimpleAlert } from "../../components";
import { useAlert } from "../store/alert";
import CloseIcon from "@mui/icons-material/Close";
import { Snackbar as SnackbarMui } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../theme/theme";

function Snackbar() {
  const { currentData, isOpen, closeAlert } = useAlert();
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <SnackbarMui
      open={isOpen}
      anchorOrigin={{
        horizontal: desktop ? "right" : "center",
        vertical: "top",
      }}
      sx={{
        whiteSpace: "pre-wrap",
        ".MuiAlert-message": {
          minWidth: "unset",
          width: desktop ? "350px" : "300px",
        },
      }}
      autoHideDuration={currentData.autoHide ? 6000 : null}
      onClose={closeAlert}
    >
      <div>
        <SimpleAlert
          type={currentData.type}
          text={currentData.text}
          compact={true}
          action={
            currentData.isVisibleCloseIcon && (
              <IconButton
                color={currentData.type}
                size="small"
                onClick={closeAlert}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )
          }
        />
      </div>
    </SnackbarMui>
  );
}

export default Snackbar;
