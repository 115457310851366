import { Box, Typography } from "../../../../components";
import newIcon from "../../../shared/assets/img/category-new.svg";
import popular from "../../../shared/assets/img/category-star.svg";
import spicy from "../../../shared/assets/img/category-fire.svg";
import vegan from "../../../shared/assets/img/category-vegan.svg";
import newIcon2 from "../../../shared/assets/img/category-new2.svg";
import popular2 from "../../../shared/assets/img/category-star2.svg";
import spicy2 from "../../../shared/assets/img/category-fire2.svg";
import vegan2 from "../../../shared/assets/img/category-vegan2.svg";

export enum EFeatures {
    SPICY = 1,
    NEW,
    VEGAN,
    POPULAR
}

export type CategoryProps = {
    type: EFeatures;
    lightTheme: boolean;
};

/**Категории: новинка, популярное, острое, веганское*/
function Category({ type, lightTheme }: CategoryProps) {
    const icon = getCategoryData(type);

    function getCategoryData(category: EFeatures) {
        switch (category) {
            case EFeatures.NEW:
                return { icon: !lightTheme ? newIcon : newIcon2, label: 'новинка' };
            case EFeatures.POPULAR:
                return { icon: !lightTheme ? popular : popular2, label: 'популярное' };
            case EFeatures.SPICY:
                return { icon: !lightTheme ? spicy : spicy2, label: 'острое' };
            case EFeatures.VEGAN:
                return { icon: !lightTheme ? vegan : vegan2, label: 'веганское' };
            default:
                return null;
        };
    }

    if (!icon) {
        console.error(`Иконка категории "${type}" не найдена`);
        return <></>;
    }

    return(
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'end',
                fontSize: '0.625rem',
                fontWeight: 600,
                lineHeight: '0.75rem',
                letterSpacing: '-0.02em',
                marginRight: '8px',
                height: '20px',
            }}
        >
            <Box
                sx={{
                    width: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <img height="20px" width="20px" src={icon.icon} alt={icon.label} />
            </Box>
            <Typography sx={{
                height:"100%",
                fontSize: "10px",
                verticalAlign: "middle",
                paddingTop: "3px",
                marginLeft: "3px",
            }}>{ icon.label }</Typography>
        </Box>
    );
}

export default Category;