import {
  Box,
  Typography,
  IconButton,
} from "../../../components";
import { CheckCircleOutline, AccountCircleOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import logo from "shared/assets/img/on-menu.svg";
import LinkRouter from "../LinkRouter";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import { SxProps } from "@mui/material";
import { Theme } from "@emotion/react";
import { useEffect, useState } from "react";
import WrapperButton from "../Button/WrappedButton";
import { Tab } from "../../pages/login/autorization";

const menuList = [
  {
    title: "Как работает",
    path: "#top",
  },
  {
    title: "Плюсы",
    path: "#plus_and_minus",
  },
  {
    title: "Сколько стоит",
    path: "#prices",
  },
  {
    title: "Кому это полезно",
    path: "#healthy",
  },
];


const UnauthorizationHeader = () => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = useState<Boolean | null>(null);
  // const dispatch = useDispatch();

  // const { userData } = useUserData();

  useEffect(() => {
    if (!desktop) {
      setOpenMenu(null);
    }
  }, [desktop]);

  const getStyleBurgerMenu: SxProps<Theme> = (theme: any) => {
    return !desktop
      ? {
          backgroundColor: theme.palette.primary.main,
          position: "absolute",
          width: "100%",
          flexDirection: "column",
          alignItems: "start",
          zIndex: 3,
          top: "100%",
          left: "0px",
          padding: "23px",
          opacity: !openMenu ? 0 : 1,
          transition: openMenu !== null ? "all 700ms linear" : "none",
        }
      : {};
  };

  return (
    <Box
      component={"header"}
      sx={[
        (theme) => ({
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          paddingTop: desktop ? "59px" : "0px",
          position: "relative",
          zIndex: 2,
        }),
      ]}
    >
      <Box
        sx={[
          (theme) => ({
            display: "flex",
            justifyContent: "center",
            padding: desktop ? "41px 123px" : "12px 19px",
          }),
        ]}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            maxWidth: 1280,
            flexGrow: 1,
            gap: "10px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            {!desktop && (
              <IconButton onClick={() => setOpenMenu((prev) => !prev)}>
                {!openMenu ? (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 15H21V13H3V15ZM3 19H21V17H3V19ZM3 11H21V9H3V11ZM3 5V7H21V5H3Z"
                      fill="white"
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.92893 6.41421L6.34314 5L19.0711 17.7279L17.6568 19.1421L4.92893 6.41421Z"
                      fill="white"
                    />
                    <path
                      d="M19.0711 6.4142L6.34317 19.1421L4.92896 17.7279L17.6569 4.99998L19.0711 6.4142Z"
                      fill="white"
                    />
                  </svg>
                )}
              </IconButton>
            )}
            <img
              style={{ width: desktop ? "165px" : "100px" }}
              src={logo}
              alt="Логотип"
            />
          </Box>
          <Box
            sx={[
              (theme) => ({
                display: "flex",
                gap: "35px",
                ...getStyleBurgerMenu(theme),
              }),
            ]}
          >
            {menuList.map(({ path, title }) => {
              return (
                <LinkRouter onClick={(e) => {
                    const element = document.querySelector(path);
                    if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                    }
                }} to={path} key={title}>
                  <Typography variant="subtitle2" color="#FFFFFF">
                    {title}
                  </Typography>
                </LinkRouter>
              );
            })}
          </Box>
          <Box
            sx={[
              (theme) => ({
                display: "flex",
                gap: desktop ? "20px" : "11px",
                alignItems: "center",
              }),
            ]}
          >
            <WrapperButton
              positionBias={-3}
              label="Регистрация"
              size={desktop ? "large" : "small"}
              icon={desktop && <CheckCircleOutline />}
              iconPosition="left"
              sx={(theme) => ({
                color: "#FFFFFF",
                background: "#090909",
              })}
              typeBorder="RoundGradient"
              gradientBorderColor="linear-gradient(-45deg, #674099,#ED6985)"
              onClick={() => {
                navigate("/login?tab=" + Tab.REGISTRATION);
              }}
            />
            <WrapperButton
              label={desktop ? "Войти" : undefined}
              variant="contained"
              size={desktop ? "large" : "small"}
              icon={<AccountCircleOutlined />}
              iconPosition="left"
              onClick={() => {
                navigate("/login?tab=" + Tab.AUTHORIZATION);
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default UnauthorizationHeader;
