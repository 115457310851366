import { ModalProps, Modal } from "@mui/material";
import { Box } from "../../../../components";
import close from "../../../shared/assets/img/close-white.svg";
import { useEffect, useState } from "react";

export type PictureModalProps = {
    image: string;
    onClose: () => void;
} & Omit<ModalProps, 'children'|'onClose'>;

/**Модальное окно. Выводит только изображение и кнопку закрытия */
function PictureModal({ open, onClose, image }: PictureModalProps) {
    const [ sizes, setSizes ] = useState({ width: 0, height: 0});

    useEffect(() => {
        updateSizes();
        const observer = new ResizeObserver(updateSizes);
        observer.observe(document.documentElement);
        return () => observer.disconnect();
    }, []);

    //Всегда квадратная модалка. Длина стороны 83% ширины окна приложения
    function updateSizes() {
        const width = document.documentElement.clientWidth * 0.83;
        setSizes({ width, height: width });
    }

    return(
        <Modal
            open={open}
            onClose={onClose}
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div
                style={{
                    position: 'relative',
                    margin: 0,
                    padding: 0,
                    border: 0,
                    width: sizes.width + 'px',
                    height: sizes.height + 'px',
                    borderRadius: '8px',
                    backgroundImage: `url(${image})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    gap: 0
                }}
            >
                <Box
                    sx={{
                        borderRadius: '5px',
                        backgroundColor: '#77777780',
                        boxShadow: '0px 4px 5px 0px #15161733',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'absolute',
                        right: '24px',
                        top: '24px',
                        width: '32px',
                        height: '32px',
                        cursor: 'pointer'
                    }}
                    onClick={onClose}
                >
                    <img src={close} alt="close" />
                </Box>
            </div>
        </Modal>
    );
}

export default PictureModal;