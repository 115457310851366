import { api } from "../api";

export namespace Subscription {

     //Возвращает постраничный список агентов
     export async function unsubscribe(data: { id: string }) {
        return api.sendRequest({
            domain: "on-menu",
            method: "post", 
            url: "/subscriptions/unsubscribe",
            data
        });
    }
}