import { Box } from "../../../../components";
import { useLocation } from "react-router-dom";
import { TMenuItem } from "../../../pages/adminPanel/menuItems";
import StyledNavLink from "./styledNavLink";
import { SxProps, Theme } from "@mui/material";
import { useContext } from "react";
import { NavMenuContext } from "../";

export const NavMenuId = 'navMenu';

export type LeftNavMenuProps = {
  menuItems: TMenuItem[];
};

function getTheme(index: number, isChildren: boolean): SxProps<Theme> {
  const parentTheme: SxProps<Theme>  = {
    "& a": {
      display: "block",
      textDecorationStyle: "unset",
      outline: "none",
    },
    "& .nav-children": {
      display: "none"
    },
    "&.nav-active .nav-children": {
      display: "block"
    },
    marginTop: index > 0 ? "14px" : "unset",
  };

  const childTheme: SxProps<Theme> = {
    marginTop: "14px",
    marginLeft: "20px",
  }

  return isChildren ? [parentTheme, childTheme] : parentTheme;
}

function LeftNavMenu({ menuItems }: LeftNavMenuProps) {
  const location = useLocation();
  const { selectedId } = useContext(NavMenuContext);

  const renderMenu = (
    currentMenuItems: TMenuItem[] = menuItems,
    parentLink: string = "",
    isChildren: boolean = false,
  ) => {
    return (
      <>
        {currentMenuItems.map((item, index) => {
          const link = `${parentLink}${item.link}`;
          const isActive = selectedId === item.id || (item.children && item.children.some(x => x.id === selectedId));
          const className = isActive ? "nav-active" : undefined;

          return (
            <Box
              className={className}
              id={NavMenuId}
              key={item.title}
              sx={getTheme(index, isChildren)}
            >
              <StyledNavLink
                key={location.pathname}
                id={item.id}
                to={link}
                childrenLink={item.children ? item.children[0].link : ""}
              >
                {item.title}
              </StyledNavLink>
              <Box className="nav-children" >
                {item.children &&
                  renderMenu(item.children, `${item.link}/`, true)}
              </Box>
            </Box>
          );
        })}
      </>
    );
  };

  return (
    <Box
      component={"nav"}
      sx={{
        fontSize: "1.25rem",
        fontWeight: 300,
        lineHeight: "1.5rem",
        width: "312px",
      }}
    >
      {renderMenu()}
      {/* {menuItems.map((item, index) => (
        <Box
          key={item.title}
          sx={{
            "& a": {
              display: "block",
              textDecorationStyle: "unset",
              outline: "none",
            },
            marginTop: index > 0 ? "14px" : "unset",
          }}
        >
          <StyledNavLink key={location.pathname} to={item.link}>
            {item.title}
          </StyledNavLink>
        </Box>
      ))} */}
    </Box>
  );
}

export default LeftNavMenu;
