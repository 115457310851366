import { TableCell as MuiTableCell, TableCellProps } from "@mui/material";

const TableCell = ({ sx = [], ...props }: TableCellProps) => {
  return (
    <MuiTableCell
      {...props}
      sx={[
        {
          border: "1px solid #CED4DA",
          textAlign: "center",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    />
  );
};

export default TableCell;
