import { Modal, Box, Typography, IconButton } from "../../../components";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import { ReactNode } from "react";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import WrapperButton from "../Button/WrappedButton";

type ConfirmModalInterface = {
  width?: number;
  headerModal: string | ReactNode;
  bodyModal: string | ReactNode;
  buttonCancel?: string | null;
  buttonAccept?: string | null;
  onAccept: () => void;
  onClose: () => void;
};

const ConfirmModal = ({
  width = 420,
  headerModal,
  bodyModal,
  onAccept,
  onClose,
  buttonCancel = "Отмена",
  buttonAccept = "Удалить",
}: ConfirmModalInterface) => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Modal
      open
      onClose={onClose}
      sx={[
        (theme) => ({
          left: desktop ? "" : "0",
          right: desktop ? "" : "unset",
          bottom: desktop ? "" : 0,
          top: desktop ? "" : "unset",
          position: desktop ? "relative" : "fixed",
          transform: desktop ? "" : "unset",
          backgroundColor: theme.palette.primary.main,
          padding: "25px !important",
          width: desktop ? `${width}px` : "100%",
          borderRadius: "10px",
          borderColor: theme.palette.primary.main,
          gap: "25px",
          borderBottomLeftRadius: desktop ? "" : "unset",
          borderBottomRightRadius: desktop ? "" : "unset",
        }),
      ]}
      header={
        <Box>
          {typeof headerModal === "string" ? (
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: 500, color: "#FFFFFF" }}
            >
              {headerModal}
            </Typography>
          ) : (
            headerModal
          )}
          <IconButton
            onClick={onClose}
            sx={{ position: "absolute", top: "2px", right: "2px" }}
          >
            <ClearRoundedIcon />
          </IconButton>
        </Box>
      }
      body={
        <>
          {typeof bodyModal === "string" ? (
            <Typography variant="body1" sx={{ color: "#FFFFFF" }}>
              {bodyModal}
            </Typography>
          ) : (
            bodyModal
          )}
        </>
      }
      footer={
        <Box
          sx={{
            display: "flex",
            gap: "5px",
            justifyContent: buttonCancel ? "space-between" : "flex-end",
          }}
        >
          {buttonCancel && <WrapperButton
            label={buttonCancel}
            variant="contained"
            typeBorder="RoundGradient"
            onClick={() => onClose()}
            sx={[
              (theme) => ({
                color: "#F72642",
                backgroundColor: "#090909",
                height: "36px",
                whiteSpace: "nowrap",
                minWidth: "90px",
              }),
            ]}
          />}
          {buttonAccept && <WrapperButton
            label={buttonAccept}
            variant="contained"
            size={"normal"}
            onClick={() => {
              onAccept();
              onClose();
            }}
            sx={{
              height: "36px",
            }}
          />}
        </Box>
      }
    >
      <span></span>
    </Modal>
  );
};

export default ConfirmModal;
