import { Box, Typography } from "../../../../components";
import ContentContainer from "../../../component/ContentContainer";
import rightImg from "./images/rightImg.png";
import leftImg from "./images/leftImg.svg";
import leftImg2 from "./images/leftImg2.png";
import WrapperButton from "../../../component/Button/WrappedButton";
import { useNavigate } from "react-router-dom";
import { Tab } from "../../../pages/login/autorization";

function ForFree() {
  const navigate = useNavigate();

  return (
    <Box
      sx={[
        (theme) => ({
          backgroundColor: "#F0F1F4",
          width: "100%",
          zIndex: "1",
          position: "relative",
          overflow: "hidden",
          padding: "40px 0px",
        }),
      ]}
    >
      <ContentContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: '353px',
              position: 'relative',
              width: '402px'
            }}
          >
            <Box
              sx={{
                position: 'absolute',
                top: '38px',
                left: '117px',
                borderRadius: '7.26px',
                backgroundColor: '#FFFFFF',
                width: '285px',
                height: '315px',
                zIndex: -1,
                padding: '51px 37px 25px 19px',
                boxShadow:` 0px 12px 110px 0px #23286921`
              }}
            >
              <img src={leftImg2} alt="Пример меню" width={261} height={239}/>
            </Box>
            <img src={leftImg} alt="Пример меню" width={250} height={132}/>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              flexGrow: 0,
              alignItems: "center",
            }}
          >
            <Typography textAlign="center" whiteSpace="pre-line">
              <span style={{ color: "#0D0C22", fontSize: "50px", fontWeight: 600 }}>
                {"Бесплатно!\r\n"}
              </span>
              <span style={{ color: "#71717A", fontSize: "18px" }}>
                пробный период на 7 календарных дней!
              </span>
            </Typography>
            <WrapperButton
              label={"ПОДКЛЮЧИТЬ"}
              sx={{ height: "50px", width: "170px" }}
              variant="contained"
              size={"normal"}
              onClick={() => navigate("/login?tab=" + Tab.REGISTRATION)}
              fullWidth={false}
            />
          </Box>
          <img width={338} style={{ alignSelf: "end" }} src={rightImg} alt="Пример меню" />
        </Box>
      </ContentContainer>
    </Box>
  );
}

export default ForFree;
