import { getTemplateData, useTemlateCardData } from "../../../store/templateCard";
import { darkTheme } from "../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { Box } from "../../../../components";
import WrapperButton from "../../../component/Button/WrappedButton";
import ConstructorTitle from "../constructorTitle";
import RestaurantInfo from "../RestaurantInfo";
import { useNavigate, useParams } from "react-router-dom";
import WrapperInput from "../../../component/Input/WrappedInput";
import { useState } from "react";
import { req } from "../../../api";
import WrappedCheckbox from "../../../component/Checkbox/WrappedCheckbox";
import { useAlert } from "../../../store/alert";
import { useDispatch } from "../../../store/model/extensions";
import { GroupType } from "../../../api/request/template";

/**Меню заведения */
function GroupsEdit() {
  const { tempateData } = useTemlateCardData();
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const { id } = useParams();
  const navigate = useNavigate();
  const { openAlert } = useAlert();
  const dispatch = useDispatch();

  const [editableGroups, setEditableGroups] = useState<GroupType[]>([...tempateData.groups]);
  const [checkedIndexes, setCheckedIndexes] = useState<boolean[]>([]);

  const onClose = () => [
    navigate(`/constructor/${id}/constructor`)
  ]

  const onSubmit = () => {
    const p = Number(id) ? Promise.resolve(Number(id)) : req.template.saveTemplateData(tempateData, 0).then(saveRes => saveRes.data);

    p.then(templateId => Promise.all([req.template.updateGroups(editableGroups, templateId), templateId]))
      .then(([res, templateId]) => {
        if (res.success) {
          openAlert({
            text: "Группы успешно сохранены",
            isVisibleCloseIcon: true,
            autoHide: true,
            type: "success",
          });

          if (templateId !== Number(id)) {
            navigate(`/constructor/${templateId}/constructor`);
          } else {
            dispatch(getTemplateData({ id: templateId }));
            onClose()
          }
        }
      })
      .catch(() => {
        openAlert({
          text: "Произошла ошибка при сохранении групп",
          isVisibleCloseIcon: true,
          autoHide: true,
          type: "error",
        });
      });
  };

  const checkedGroups = checkedIndexes.filter(x => x);

  const buttons = (
    <Box
      sx={{
        display: "flex",
        flexDirection: desktop ? "row" : "column",
        width: desktop ? "auto" : "100%",
        gap: "10px",
        alignItems: "center",
      }}
    >
      <WrapperButton
        label="Добавить группу"
        variant="contained"
        sx={{
          width: desktop ? "auto" : "100%",
          whiteSpace: "nowrap",
        }}
        onClick={() => {
          setEditableGroups([...editableGroups, { id: null, name: "" }]);
        }}
      />
      <WrapperButton
        label={checkedGroups.length < 2 ? "Удалить группу" : "Удалить группы"}
        variant="contained"
        secondary={true}
        disabled={checkedGroups.length === 0}
        sx={{
          width: desktop ? "auto" : "100%",
          whiteSpace: "nowrap",
        }}
        onClick={() => {
          setEditableGroups(
            editableGroups.filter((group, index) => !checkedIndexes[index]),
          );
          setCheckedIndexes([]);
        }}
      />
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: desktop ? "row" : "column-reverse",
        justifyContent: "space-between",
        gap: desktop ? "100px" : "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          flex: 1,
        }}
      >

        <ConstructorTitle buttons={buttons} templateName={tempateData.name} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
              }}
            >
              {editableGroups.map((group, index) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    background: "#F3F3F4",
                    padding: "10px",
                  }}
                  key={group.id}
                >
                  <WrappedCheckbox
                    checked={checkedIndexes[index] === true}
                    onCheckChange={() => {
                      checkedIndexes[index] = !checkedIndexes[index];
                      setCheckedIndexes([...checkedIndexes]);
                    }}
                    label=""
                  />
                  <WrapperInput
                    label=""
                    fullWidth
                    height="34px"
                    colorType="white"
                    value={group.name}
                    onChange={(e) => {
                      editableGroups[index] = {
                        ...group,
                        name: e.target.value,
                      };
                      setEditableGroups([...editableGroups]);
                    }}
                    sx={{
                      zIndex: "1",
                    }}
                  />
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <WrapperButton
                label="Отменить"
                variant="contained"
                secondary={true}
                sx={{
                  flex: "1",
                }}
                onClick={() => navigate(`/constructor/${id}/constructor`)}
              />
              <WrapperButton
                label="Сохранить"
                variant="contained"
                sx={{
                  flex: "1",
                }}
                onClick={onSubmit}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      {desktop && <RestaurantInfo />}
    </Box>
  );
}

export default GroupsEdit;
