import React, { ReactNode } from "react";
import { Button, ButtonProps } from "../../../components";
import { styled } from "@mui/material/styles";
import { SxProps, Theme } from "@mui/material";

type RoundGradientProps = {
  gradientBorderColor: string;
  borderRadius: string;
  positionBias: number;
};

type GradientProps = {
  gradientBorderColor: string;
};

const options = {
  shouldForwardProp: (prop: any) =>
    prop !== "gradientBorderColor" && prop !== "borderRadius",
};

const GradientButton = styled(
  Button,
  options,
)<GradientProps>(({ gradientBorderColor }) => ({
  border: "1px solid",
  borderImageSlice: 1,
  borderImageSource: gradientBorderColor,
}));

const RoundGradientButton = styled(
  Button,
  options,
)<RoundGradientProps>(
  ({ gradientBorderColor, borderRadius, positionBias }) => ({
    position: "relative",
    border: "1px solid transparent",
    backgroundClip: "padding-box",
    borderRadius,

    "&:after": {
      position: "absolute",
      top: positionBias,
      left: positionBias,
      right: positionBias,
      bottom: positionBias,
      background: gradientBorderColor,
      content: '""',
      zIndex: -1,
      borderRadius,
    },
  }),
);

interface WrapperButtonType extends ButtonProps {
  gradientBorderColor?: string;
  gradientBackgroundColor?: string;
  typeBorder?: "RoundGradient" | "Gradient";
  borderRadius?: string;
  positionBias?: number;
  label:string | ReactNode;
  variant: any;
  size?: any;
  disabled?: boolean;
  secondary?: boolean;
  onClick?: () => void;
  sx: SxProps<Theme> | undefined;
}

const WrapperButton: React.FC<WrapperButtonType> = ({
  borderRadius = "60px",
  typeBorder,
  gradientBorderColor = "linear-gradient(-45deg, #674099,#ED6985)",
  gradientBackgroundColor = "linear-gradient(94deg, #FF6F83 0.78%, #F61735 105.69%)",
  sx = [],
  positionBias = -1,
  secondary = false,
  ...props
}) => {
  if (secondary)  {
    gradientBackgroundColor="linear-gradient(98deg, #ED6985 0%, #674099 100%)";
  }

  if (typeBorder === "RoundGradient" && gradientBorderColor) {
    return (
      <RoundGradientButton
        sx={[
          () => ({
            color: "#FFFFFF",
            borderRadius: borderRadius,
            fontFamily: "Inter",
            fontSize: props.size === "large" ? "16px" : "14px",
            "&:hover": {
              background: "linear-gradient(-45deg, #39106a, #39106a)",
              "&:after": {
                background: "linear-gradient(-45deg, #39106a, #39106a)",
              },
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        gradientBorderColor={gradientBorderColor}
        borderRadius={borderRadius}
        positionBias={positionBias}
        {...props}
      />
    );
  }
  if (typeBorder === "Gradient" && gradientBorderColor) {
    return (
      <GradientButton
        sx={[
          () => ({
            color: "#FFFFFF",
            fontFamily: "Inter",
            fontSize: props.size === "large" ? "16px" : "14px",
            "&:hover": {
              background: "linear-gradient(-45deg, #39106a, #39106a)",
              "&:after": {
                background: "linear-gradient(-45deg, #39106a, #39106a)",
              },
            },
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        gradientBorderColor={gradientBorderColor}
        {...props}
      />
    );
  }
  return (
    <Button
      sx={[
        () => ({
          color: "#FFFFFF",
          fontFamily: "Inter",
          fontSize: props.size === "large" ? "16px" : "14px",
          borderRadius: borderRadius,
          background: gradientBackgroundColor,
          opacity: props.disabled ? "0.7" : "1",
          minWidth:"50px",
          "&:hover": {
            background: "linear-gradient(-45deg, #39106a, #39106a)",
            "&:after": {
              background: "linear-gradient(-45deg, #39106a, #39106a)",
            },
          },
          "& .MuiButton-startIcon": {
            marginRight: props.label ? "8px" : "0"
          }
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    />
  );
};

export default WrapperButton;
