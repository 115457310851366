import {
    Box,
    Typography,
    Grid,
  } from "../../../../../components";
import { darkTheme } from "../../../../theme/theme";
import { useMediaQuery } from "@mui/material";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { AgentCardForm, AGENT_DEFAULT } from "./types";
import { req } from "../../../../api";
import { useAlert } from "../../../../store/alert";
import { Link, useNavigate } from "react-router-dom";
import WrappedButton from "../../../../component/Button/WrappedButton";
import WrappedCheckbox from "../../../../component/Checkbox/WrappedCheckbox";
import WrapperInput from "../../../../component/Input/WrappedInput";
import { formatPhoneNumber } from "../../../../utils";

export default function CreateAgent() {
    const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
    
    const { openAlert } = useAlert();
    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        formState: { errors },
    } = useForm<AgentCardForm>({ defaultValues: AGENT_DEFAULT });


    const onSubmit: SubmitHandler<AgentCardForm> = (data) => {
        req.agent
          .createAgent(data)
          .then((res) => {
            if (!res.success) {
              openAlert({
                text: res.errors
                  .concat(
                    res.data && Object.entries(res.data.errors).map((e) => e[1]),
                  )
                  .join("\n"),
                isVisibleCloseIcon: true,
                autoHide: false,
                type: "error",
              });
            } else {
              openAlert({
                text: "Сохранено",
                isVisibleCloseIcon: true,
                autoHide: true,
                type: "success",
              });
              navigate("/adminpanel/sales/agents");
            }
          })
          .catch((w) => {
            openAlert({
              text: "Произошла ошибка",
              isVisibleCloseIcon: true,
              autoHide: true,
              type: "error",
            });
          });
    };
    
    return (
        <Box
      component={"main"}
      sx={[
        () => ({
          display: "flex",
          flexDirection: "column",
          maxWidth: "1280px",
          position: "relative",
          margin: "auto",
          zIndex: 1,
          paddingBottom: "60px",
        }),
      ]}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ flexGrow: 1 }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: desktop ? "row" : "column",
            justifyContent: "space-between",
            padding: desktop ? "50px 0px 40px 0px" : "30px 23px 10px 23px",
          }}
        >
          <Box
            sx={{
              fontWeight: "600",
              overflow: "auto",
              flexGrow: 1,
              display: "flex",
              gap: "14px",
            }}
          >
            <Box sx={{ a: { textDecoration: "none" } }}>
              <Link to={"/adminPanel/sales/agents"}>
                <Typography
                  variant="subtitle2"
                  color="#0D0C22"
                  sx={{
                    textUnderlineOffset: "4px",
                    textDecoration: "underline",
                    textDecorationStyle: "dashed",
                    textDecorationThickness: "1px",
                    fontSize: desktop ? "2.5rem" : "1.25rem",
                    fontWeight: "600",
                  }}
                >
                  {"←"}
                </Typography>
              </Link>
            </Box>
            <Typography
              variant="subtitle2"
              color="#0D0C22"
              sx={{
                fontSize: desktop ? "2.5rem" : "1.25rem",
                fontWeight: "600",
              }}
            >
              {" Создание агента"}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              marginTop: "20px",
              maxWidth: "536px",
            }}
          >
            <WrappedButton
              label="Сохранить"
              variant="contained"
              sx={{
                width: "100%",
                minWidth: 156,
                color: "#FFFFFF",
                background:
                  "linear-gradient(94deg, #FF6F83 0.78%, #F61735 105.69%)",
              }}
              type="submit"
            />
            <Link to={"/adminpanel/sales/agents"}>
              <WrappedButton
                label="Отмена"
                variant="contained"
                typeBorder="RoundGradient"
                sx={{
                  width: "100%",
                  minWidth: 156,
                  color: "#F72642",
                  backgroundColor: "#FFFFFF",
                  whiteSpace: "nowrap",
                }}
              />
            </Link>
          </Box>
        </Box>
        <Grid
          desktop={7}
          mobile={12}
          container
          sx={{ padding: desktop ? "0" : "0px 23px 0px 23px" }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: "25px",
            }}
          >
            <Controller
              name="isActive"
              control={control}
              defaultValue={true}
              render={({ field: { onChange, value } }) => (
                <WrappedCheckbox
                  checked={value as boolean}
                  onCheckChange={onChange}
                  label={
                    <Typography
                      sx={{
                        width: "100%",
                        fontSize: "14px",
                        lineHeight: "24px",
                        color: darkTheme.palette.outline.default,
                      }}
                    >
                      Активность
                    </Typography>
                  }
                  value={value}
                  onChange={onChange}
                />
              )}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Фамилия
                <Typography variant="caption" color="#F00" fontWeight="700">
                  *
                </Typography>
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: "Поле обязательно для заполнения" }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                          width: "100%",
                        },
                      }}
                      onInput={(e: { target: { value: string } }) => {
                        return (
                          //@ts-ignore
                          (e.target.value = e.target.value.replace(
                            /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                            "",
                          ))
                        );
                      }}
                      {...{ onChange, value }}
                      error={error?.message}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Имя
                <Typography variant="caption" color="#F00" fontWeight="700">
                  *
                </Typography>
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: "Поле обязательно для заполнения" }}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                          width: "100%",
                        },
                      }}
                      onInput={(e: { target: { value: string } }) => {
                        return (
                          //@ts-ignore
                          (e.target.value = e.target.value.replace(
                            /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                            "",
                          ))
                        );
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Отчество (при наличии)
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="middleName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <WrapperInput
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                        },
                      }}
                      onInput={(e: { target: { value: string } }) => {
                        return (
                          //@ts-ignore
                          (e.target.value = e.target.value.replace(
                            /[^a-zA-Zа-яА-ЯЁё-\s]/g,
                            "",
                          ))
                        );
                      }}
                      {...{ onChange, value }}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Номер телефона
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <WrapperInput
                      inputProps={{ maxLength: 16 }}
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                          width: "100%",
                        },
                      }}
                      onInput={(e: any) => {
                        e.target.value = formatPhoneNumber(
                          e.target.value.replace(/[^0-9+\-()\s]/g, ""),
                        );
                      }}
                      {...{ onChange, value }}
                      error={error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: desktop ? "row" : "column",
              }}
            >
              <Typography variant="caption" color="#0D0C22">
                Промокод
              </Typography>
              <Box sx={{ maxWidth: 536, width: "100%" }}>
                <Controller
                  name="promocode"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <WrapperInput
                      inputProps={{ maxLength: 16 }}
                      backgroundColor="#FFFFFF"
                      fullWidth
                      sx={{
                        "& .MuiInputBase-root": {
                          height: "34px",
                          color: "#0D0C22 !important",
                          width: "100%",
                        },
                      }}
                      {...{ onChange, value }}
                      error={error?.message}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </form>
    </Box>
    );
}