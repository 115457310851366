import { RootState } from "../store/store";

export function getSliceDataSelectorFactory<T extends keyof RootState>(
  sliceName: T,
) {
  return function sliceDataSelectorFactory<R, P = never>(
    selector: (state: RootState[T], params?: P) => R,
  ): (state: RootState, params?: P) => R {
    return function sliceDataSelectorInstance(state: RootState, params?: P) {
      return selector(state[sliceName], params);
    };
  };
}

export const normalize_count = (number: number, words_arr: string[]) => {
  number = Math.abs(number);
  if (Number.isInteger(number)) {
    let options = [2, 0, 1, 1, 1, 2];
    return `${number} ${
      words_arr[
        number % 100 > 4 && number % 100 < 20
          ? 2
          : options[number % 10 < 5 ? number % 10 : 5]
      ]
    }`;
  }
  return `${number} ${words_arr[1]}`;
};

//Функция копирования текста в буфер
export const copyToClipboard = (string: string) => {
  let textarea;
  let result;

  try {
    textarea = document.createElement("textarea");
    textarea.setAttribute("readonly", "true");
    textarea.setAttribute("contenteditable", "true");
    textarea.style.position = "fixed";
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel && sel.removeAllRanges();
    sel && sel.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand("copy");
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    textarea && document.body.removeChild(textarea);
  }
  return result;
};

//Функция форматирования адреса сайта
export const formatSiteAddress = (inputValue: string) => {
  const pivot = "https://";
  if (inputValue.length === 1) return `${pivot}${inputValue}`;
  const cleaned = inputValue.replace(/[^A-Za-z-:\/\.]/g, "");
  return cleaned;
};

//Функция форматирования телефонного номера
export const formatPhoneNumber = (inputValue: string) => {
  if (inputValue === "+") return inputValue;
  const cleaned = String(inputValue).replace(/[^0-9]/g, "");
  const match = cleaned.match(
    /^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})$/,
  );
  if (!match) return cleaned;
  const internationalCode = match[1] ? "+" + match[1] + " " : "";
  const firstGroup = match[2] ? match[2] + " " : "";
  const secondGroup = match[3] ? match[3] + "-" : "";
  const thirdGroup = match[4] ? match[4] + "-" : "";
  const fourthGroup = match[5] ? match[5] : "";
  return (
    internationalCode +
    firstGroup +
    secondGroup +
    thirdGroup +
    fourthGroup
  )
    .trim()
    .replace(/[-\s]+$/, "");
};

/**Разделение разрядов числа пробелами
 * 99999999 -> 99 999 999
 */
export function numberWithSpaces(x: number, joinSymbol: string = "."): string {
  const parts = x.toString().split("."); //Для значений с плавающей точкой
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(joinSymbol);
}

//Не пустая строка
export function notEmptyString(string?: string) {
  return !!string?.length;
}