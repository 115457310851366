import { createTheme } from "@mui/material/styles";
import "./theme.css";

type TSurface = Record<"1" | "4" | "8" | "12" | "16" | "20" | "24", string>;

declare module "@mui/material/styles" {
  type TPalette = {
    accent: {
      main: string;
    };
    backgroundColor: {
      dark: string;
      light: string;
    };
    surface: TSurface;
    outline: {
      default: string;
      onColor: string;
    };
    shades: {
      black: string;
      white: string;
    };
  };

  interface Palette extends TPalette {}
  interface PaletteOptions extends TPalette {}

  export interface Theme {
    borderRadius: {
      none: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      pill: string;
      circle: string;
    };
  }

  export interface ThemeOptions {
    borderRadius?: {
      none: string;
      sm: string;
      md: string;
      lg: string;
      xl: string;
      pill: string;
      circle: string;
    };
  }
}

const spacing = [
  0, 4, 8, 12, 16, 20, 24, 28, 32, 36, 40, 44, 48, 52, 56, 60, 64, 96, 192,
];

const breakpoints = {
  values: {
    xs: 320,
    sm: 768,
    md: 1024,
    lg: 1280,
    xl: 1920,
  },
};

const borderRadius = {
  none: "0px",
  sm: "2px",
  md: "4px",
  lg: "8px",
  xl: "24px",
  pill: "9999px",
  circle: "100px",
};

const typography = {
  htmlFontSize: 16,
  h1: {
    fontFamily: "Inter",
    fontSize: "6rem",
    fontWeight: 100,
    lineHeight: 1,
  },
  h2: {
    fontFamily: "Inter",
    fontSize: "4.5rem",
    fontWeight: 300,
    lineHeight: 1.33,
  },
  h3: {
    fontFamily: "Inter",
    fontSize: "3rem",
    fontWeight: 400,
    lineHeight: 1,
  },
  h4: {
    fontFamily: "Inter",
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: 1,
  },
  h5: {
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontWeight: 700,
    lineHeight: 1.714285714,
  },
  subtitle1: {
    fontFamily: "Inter",
    fontSize: "1.3125rem",
    fontWeight: 500,
    lineHeight: 1.142857143,
  },
  subtitle2: {
    fontFamily: "Inter",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: 1.5,
  },
  body1: {
    fontFamily: "Inter",
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: 1.5,
  },
  body2: {
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 2,
  },
  caption: {
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: 1.25,
  },
  overline: {
    fontFamily: "Inter",
    fontSize: "0.75rem",
    fontWeight: 500,
    lineHeight: 2.67,
  },
};

export const theme = createTheme({
  spacing: spacing,
  breakpoints: breakpoints,
  borderRadius: borderRadius,
  palette: {
    mode: "light",
    primary: {
      main: "#7C4DFF",
    },
    secondary: {
      main: "#1DE9B6",
    },
    accent: {
      main: "#1DE9B6",
    },
    error: {
      main: "#FF5252",
    },
    warning: {
      main: "#FFC107",
    },
    success: {
      main: "#4CAF50",
    },
    info: {
      main: "#2196F3",
    },
    backgroundColor: {
      light: "#FAFAFA",
      dark: "#1A1A1A",
    },
    surface: {
      //Dark theme option
      "1": "#1F1F1F",
      "4": "#292929",
      "8": "#333333",
      "12": "#3D3D3D",
      "16": "#474747",
      "20": "#525252",
      "24": "#5C5C5C",
    },
    outline: {
      default: "#9E9E9E",
      onColor: "#949494",
    },
    shades: {
      black: "#000000",
      white: "#FFFFFF",
    },
  },
  typography: typography,
});

export const darkTheme = createTheme({
  spacing: spacing,
  breakpoints: breakpoints,
  borderRadius: borderRadius,
  palette: {
    mode: "dark",
    primary: {
      main: "#7C4DFF",
    },
    secondary: {
      main: "rgba(29, 233, 182, 0.5)",
    },
    accent: {
      main: "#1DE9B6",
    },
    error: {
      main: "#FF5252",
    },
    warning: {
      main: "#FFC107",
    },
    success: {
      main: "#4CAF50",
    },
    info: {
      main: "#2196F3",
    },
    backgroundColor: {
      light: "#FAFAFA",
      dark: "#1A1A1A",
    },
    surface: {
      //Dark theme option
      "1": "#1F1F1F",
      "4": "#292929",
      "8": "#333333",
      "12": "#3D3D3D",
      "16": "#474747",
      "20": "#525252",
      "24": "#5C5C5C",
    },
    outline: {
      default: "#9E9E9E",
      onColor: "#949494",
    },
    shades: {
      black: "#000000",
      white: "#FFFFFF",
    },
  },
  typography: typography,
});
