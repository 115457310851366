import React from "react";
import { Select, MenuItem, SelectProps } from "@mui/material";

type WrappedSelectProps = SelectProps & {
  options: { value: string | number; label: string }[];
  borderColor?: string;
  borderRadius?: string;
  backgroundColor?: string;
}

const WrappedSelect: React.FC<WrappedSelectProps> = ({
  options,
  borderColor = "linear-gradient(-45deg, #674099,#ED6985)",
  borderRadius = "60px",
  backgroundColor = "white",
  sx = [],
  error,
  ...props
}) => {
  return (
    <Select
      {...props }
      sx={[
        (theme) => ({
          width: "100%",
          height: "36px",
          position: "relative",
          borderRadius,
          backgroundColor,
          fieldset: {
            border: `none !important`,
          },
          "&:hover fieldset": {
            border: `none !important`,
          },
          label: {
            background:
              props.value && `${theme.palette.primary.main} !important`,
            lineHeight: "normal",
          },
          "& span": {
            backgroundImage: !error && borderColor,
            WebkitBackgroundClip: !error && "text",
            WebkitTextFillColor: !error && "transparent",
            color: error && `${theme.palette.error.main}`,
          },
          "& label.Mui-focused": {
            background: `${theme.palette.primary.main} !important`,
            lineHeight: "normal",
          },
          ".MuiInputBase-root": {
            ":hover": {
              border: "none",
            },
          },
          "&:after": {
            position: "absolute",
            top: -2,
            left: -2,
            right: -2,
            bottom: -2,
            background: error ? `${theme.palette.error.main}` : borderColor,
            opacity: props.disabled ? 0.4 : 1,
            content: '""',
            zIndex: -1,
            borderRadius: borderRadius,
          },
          "&:focus-within:after": {
            filter: "blur(2px)",
          },
          "input:-webkit-autofill": {
            WebkitBoxShadow: `0 0 0 30px #272727 inset !important`,
          },
          "button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-edgeEnd div":
            {
              fontSize: 0,
            },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {options.map((option: { value: string | number; label: string }) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default WrappedSelect;
