import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import DesktopTable from "./Render/DesktopTable";
import MobileTable from "./Render/MobileTable";
import { RenderDataTableProps, TSearchData, TSearchResponse } from "./types";
import WrapperButton from "../Button/WrappedButton";
import { createContext, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import useSortTable from "./Render/useSortTable";
import { Box } from "../../../components";
import Loader from "../Loader";

export type TSortContext = {
  updateSort: (columnId: string) => void;
  sortBy: string;
  direction: number;
};

/*Контекст для иконки направления сортировки в head. Надеюсь, потом переделаю без него*/
export const SortContext = createContext<TSortContext>({
  updateSort: (columnId: string) => {},
  sortBy: "",
  direction: 0,
});

const defaultSearchData: TSearchData = {
  page: 1,
  count: 10,
  sortItem: {
    fieldName: "id",
    direction: 0,
  },
  filterItems: [
    {
      fieldName: "",
      condition: 0,
      value: "",
      valueTo: "",
    },
  ],
};

export type DataTableProps<ResponseItem> = Omit<
  RenderDataTableProps<ResponseItem>,
  "rows"|"refreshTable"
> & {
  requestData: (
    data: TSearchData,
  ) => Promise<AxiosResponse<TSearchResponse<ResponseItem>>>;
  onResponse?: (response: AxiosResponse<TSearchResponse<ResponseItem>>) => void;
  searchOptions?: Partial<TSearchData>;
};

/**
 * -----Запрос данных таблицы
 * @param requestData - запрос очередной страницы данных. Должен принимать
 * объект типа TSearchData
 * @param searchOptions - объект, передаваемый в каждый запрос на бэк. Поля
 * page, fieldName и direction из него применятся один раз на первом рендере. Таким образом можно
 * указать с какой страницы начать загрузку и настроить первоначальную сортировку.
 * @param onResponse - коллбэк. Получает весь ответ сервера
 * Для правильной типизации коллбэка onResponse надо использовать дженерик
 * <DataTable<ResponseItem> .../>
 * ResponseItem - это тип элемента массива items, получаемого с бэка.
 *
 * -----Внешний вид десктопной таблицы
 * @param desktopTableProps - пропы десктопной таблицы. Содержит следующие
 * вложенные свойства:
 * @param tableContainer - пропы компонента <TableContainer> (@rns/ui-react)
 * @param table - пропы компонента <Table> (@rns/ui-react)
 * @param head - пропы компонента <TableHead> (@rns/ui-react)
 * @param body - пропы компонента <TableBody> (@rns/ui-react)
 * @param row - функция вида ({row, rowIndex}) => props,
 * возвращает объект с пропами компонента <TableRow> (@rns/ui-react)
 * @param headCell - функция вида ({column, columnIndex}) => props,
 * возвращает объект с пропами head-ячейки <TableCell> (@rns/ui-react)
 * @param bodyCell - функция вида ({row, column, rowIndex, columnIndex}) => props,
 * возвращает объект с пропами body-ячейки <TableCell> (@rns/ui-react)
 * @param renderValue - функция вида ({value, row, column, rowIndex, columnIndex}) => props,
 * возвращает компонент для рендера body-ячейки <TableCell> (@rns/ui-react)
 *
 * -----Внешний вид мобильной таблицы
 * @param mobileTableProps - пропы мобильной таблицы. Содержит следующие
 * вложенные свойства:
 * @param tableContainer - пропы контейнера всей таблицы. Компонент <Box> (@rns/ui-react)
 * Далее надо пояснить, что мобильная таблица представляет собой карточки с
 * несколькими строками. При этом строки десктопной таблицы здесь превращаются
 * в карточки, а ячейки - в строки.
 * @param card - функция вида ({row, rowIndex}) => props, возвращает объект
 * с пропами карточки. Компонент <Box> (@rns/ui-react)
 * @param row - функция вида ({row, column, rowIndex, columnIndex}) => props,
 * возвращает объект с пропами строки (карточки). Компонент <Box> (@rns/ui-react)
 * @param rowName - функция вида ({row, column, rowIndex, columnIndex}) => props,
 * возвращает объект с пропами для названия строки (карточки) (находится внутри row).
 * Компонент <Box> (@rns/ui-react)
 * @param rowValue - функция вида ({row, column, rowIndex, columnIndex}) => props,
 * возвращает объект с пропами для значения строки (карточки) (находится внутри row).
 * Компонент <Box> (@rns/ui-react)
 * @param renderValue - функция вида ({value, row, column, rowIndex, columnIndex}) => ReactElement,
 * возвращает компонент для рендера значения (карточки).
 * @param renderCard - функция вида ({row, rowIndex}) => ReactElement,
 * возвращает компонент для рендера всей карточки
 */
function DataTable<ResponseItem extends object>({
  requestData,
  onResponse,
  searchOptions = {},
  noData = 'Здесь пока ничего нет',
  ...props}: DataTableProps<ResponseItem>) {
    
  const [loadingStatus, setLoadingStatus] = useState<"pending" | "successed" | "failed">('successed');
  const initPageNumber = (searchOptions.page || defaultSearchData.page) - 1;
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));
  const [showButton, setShowButton] = useState(true);
  const [rows, setRows] = useState<ResponseItem[]>([]);
  const [pageNumber, setPageNumber] = useState(initPageNumber);
  const [pagesTotal, setPagesTotal] = useState<number | null>(null);
  const init = !!pagesTotal;

  const { sortBy, direction, updateSort } = useSortTable({
    initDirection: searchOptions.sortItem?.direction || 0,
    sort: searchOptions.sortItem?.fieldName || "",
  });

  //Первый рендер
  useEffect(() => {
    if (!init) nextPage();
  }, [pagesTotal]);

  //Если поменялась сортировка
  useEffect(() => {
    if (!init) return;
    refresh({ count: rows.length });
  }, [direction, sortBy]);

  //Если поменялись searchOptions
  useEffect(() => {
    if (!init) return;
    refresh();
  }, [JSON.stringify(searchOptions)]);

  /**Перезагрузить те данные, которые уже были получены с бэка.
   * Например, изменилась сортировка или удалена строка.
  */
  function refresh(args?: { count?: number; }) {
    const defaultCount = searchOptions.count || defaultSearchData.count;
    const data = { ...getSearchData(), ...{ page: 1, count: args?.count || defaultCount } };
    setLoadingStatus('pending');
    requestData(data).then((response) => {
      try {
        const {
          data: {
            data: { items, pagesTotal: resPagesTotal },
          },
        } = response;
        setRows(items);
        setPagesTotal(resPagesTotal);
        onResponse?.(response);
        setLoadingStatus('successed');
      } catch (e) {
        console.log("Ошибка при обработке результата запроса", e);
        setLoadingStatus('failed');
      }
    });
  }

  /**Получить следующую страницу */
  function nextPage() {
    const data = getSearchData();
    setLoadingStatus('pending');
    requestData(data).then((response) => {
      try {
        const {
          data: {
            data: {
              items,
              pageNumber: resPageNumber,
              pagesTotal: resPagesTotal,
            },
          },
        } = response;
        setRows([...rows, ...items]);
        setPageNumber(resPageNumber);
        setPagesTotal(resPagesTotal);
        if (resPageNumber === resPagesTotal) setShowButton(false);
        onResponse?.(response);
        setLoadingStatus('successed');
      } catch (e) {
        console.log("Ошибка при обработке результата запроса", e);
        setLoadingStatus('failed');
      }
    });
  }

  /**Сливаем дефолтные настройки поиска, пользовательские и из состояния таблицы */
  function getSearchData(): TSearchData {
    return {
      ...defaultSearchData,
      ...searchOptions,
      ...{
        page: pageNumber + 1,
      },
      ...{
        sortItem: {
          fieldName: sortBy,
          direction,
        },
      },
    };
  }

  return (
    <>
      {desktop ?
        <SortContext.Provider value={{ sortBy, direction, updateSort }}>
          <DesktopTable<ResponseItem>
            rows={rows}
            noData={init && noData}
            refreshTable={refresh}
            {...props}
          />
        </SortContext.Provider> :
        <MobileTable<ResponseItem>
          rows={rows}
          noData={init && noData}
          refreshTable={refresh}
          {...props}
        />}
      {showButton &&
          <Box sx={{ textAlign: 'center', marginTop: '25px' }}>
            {loadingStatus === 'pending' ?
              <Loader height='36px'/> :
              <WrapperButton
                //@ts-ignore
                label="Загрузить&nbsp;ещё"
                variant="contained"
                size={desktop ? "large" : "normal"}
                iconPosition="left"
                sx={{
                    width: desktop ? '226px' : '100%',
                    height: '36px'
                }}
                onClick={nextPage}
              />}
          </Box>}
    </>
  );
}

export default DataTable;
