import { Outlet } from "react-router-dom";
import { Box } from "../../components";
import { UnauthorizationHeader } from "../component/Header";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../theme/theme";
import UnauthorizationFooter from "../component/Footer/UnauthorizationFooter";

const PrivateUnauthorizationRouter = () => {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <Box
      component="section"
      sx={{
        display: "flex",
        height: "100%",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <UnauthorizationHeader />
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
            display: "flex",
            height: "100%",
            zIndex: 1,
            position: "relative",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "100%",
              flex: "1"
            }}
          >
            <Outlet />
          </Box>
          <Box
            sx={[
              (theme) => ({
                padding: desktop ? "29px 123px" : "12px 29px",
                backgroundColor: theme.palette.primary.main,
              }),
            ]}
          >
            <UnauthorizationFooter />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivateUnauthorizationRouter;
