export type AlertIconProps = {
    fill: string;
};

export function Success({ fill }: AlertIconProps) {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM7.29 14.29L3.7 10.7C3.31 10.31 3.31 9.68 3.7 9.29C4.09 8.9 4.72 8.9 5.11 9.29L8 12.17L14.88 5.29C15.27 4.9 15.9 4.9 16.29 5.29C16.68 5.68 16.68 6.31 16.29 6.7L8.7 14.29C8.32 14.68 7.68 14.68 7.29 14.29Z" fill={fill}/>
        </svg>
    );
}

export function Close({ fill }: AlertIconProps) {
    return(
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.2987 0.709727C12.9087 0.319727 12.2787 0.319727 11.8887 0.709727L6.99875 5.58973L2.10875 0.699727C1.71875 0.309727 1.08875 0.309727 0.69875 0.699727C0.30875 1.08973 0.30875 1.71973 0.69875 2.10973L5.58875 6.99973L0.69875 11.8897C0.30875 12.2797 0.30875 12.9097 0.69875 13.2997C1.08875 13.6897 1.71875 13.6897 2.10875 13.2997L6.99875 8.40973L11.8887 13.2997C12.2787 13.6897 12.9087 13.6897 13.2987 13.2997C13.6887 12.9097 13.6887 12.2797 13.2987 11.8897L8.40875 6.99973L13.2987 2.10973C13.6787 1.72973 13.6787 1.08973 13.2987 0.709727Z" fill={fill}/>
        </svg>
    );
}

export function Info({ fill }: AlertIconProps) {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM11 7H9V5H11V7Z" fill={fill}/>
        </svg>
    );
}

export function Warning({ fill }: AlertIconProps) {
    return(
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.47012 17.5037H17.5301C19.0701 17.5037 20.0301 15.8337 19.2601 14.5037L11.7301 1.49372C10.9601 0.163716 9.04012 0.163716 8.27012 1.49372L0.740121 14.5037C-0.0298787 15.8337 0.930121 17.5037 2.47012 17.5037ZM10.0001 10.5037C9.45012 10.5037 9.00012 10.0537 9.00012 9.50372V7.50372C9.00012 6.95372 9.45012 6.50372 10.0001 6.50372C10.5501 6.50372 11.0001 6.95372 11.0001 7.50372V9.50372C11.0001 10.0537 10.5501 10.5037 10.0001 10.5037ZM11.0001 14.5037H9.00012V12.5037H11.0001V14.5037Z" fill={fill}/>
        </svg>
    );
}

export function Error({ fill }: AlertIconProps) {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 11C9.45 11 9 10.55 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V10C11 10.55 10.55 11 10 11ZM11 15H9V13H11V15Z" fill={fill}/>
        </svg>

    );
}