import { Box, AccordionSummary as StyledAccordionSummary } from "../../../../components";
import { AccordionSummaryProps } from "@mui/material";
import ExpandIcon from "./expandIcon";

function AccordionSummary({ lightTheme, sx, children, ...props }:
    { lightTheme: boolean } & AccordionSummaryProps) {
        
    return(
        <StyledAccordionSummary
            { ...props }
            expandIcon={<ExpandIcon/>}
            sx={{
                backgroundColor: lightTheme ? "#F3F3F4" : "#1C1C1C",
                color: lightTheme ? "#61646C" : "#FFFFFF",
                borderRadius: lightTheme ? "0" : "8px",
                padding: lightTheme ? "0 13px" : "0 24px",
                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(90deg)"
                },
                "& .MuiAccordionSummary-content": {
                    display: 'block',
                    margin: '0px'
                },
                ...sx,
            }}
        >
            <Box sx={{
                fontSize: '0.875rem',
                paddingRight: '7px',
                width: '100%',
                fontWeight: 400,
                lineHeight: '1.0625rem',
                letterSpacing: '-0.02em',
            }}>
                { children }
            </Box>
        </StyledAccordionSummary>
    );
}

export default AccordionSummary;