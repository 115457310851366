import { Box } from "../../../components";
import { useMediaQuery } from "@mui/material";
import { darkTheme } from "../../theme/theme";
import CarouselBase from "../CarouselBase";

export type WrappedControlButtonType = {
  controlButtons: JSX.Element[][];
};

function WrappedControlButton({ controlButtons }: WrappedControlButtonType) {
  const desktop = useMediaQuery(darkTheme.breakpoints.up("desktop"));

  return (
    <>
      {desktop ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            position: "absolute",
            right: 0,
            transform: "translateY(calc(-100% - 20px))",
          }}
        >
          {controlButtons.map((arrayJSXElement, index) => {
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "15px",
                  justifyContent: "flex-end",
                }}
              >
                {arrayJSXElement.map((JSXElement) => {
                  return JSXElement;
                })}
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            justifyContent: "start",
            marginRight: "-23px",
            marginLeft: "-23px",
          }}
        >
          {controlButtons.map((arrayJSXElement, index) => {
            return (
              <CarouselBase
                key={index}
                items={arrayJSXElement}
                //paddingLeft={0}
                //paddingRight={0}
              />
            );
          })}
        </Box>
      )}
    </>
  );
}

export default WrappedControlButton;
