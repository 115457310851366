import { Box } from "../../../../components";
import { PropsWithChildren, useEffect, useRef, useState } from "react";
import Header from "./Header";

export type PageContainerProps = {
    lightTheme: boolean;
}

function PageContainer({ lightTheme, children }: PropsWithChildren<PageContainerProps>) {
    const headRef = useRef<HTMLHeadElement>(null);
    const [ height, setHeight ] = useState(0);  //Отступ сверху, чтобы не перекрыл хедер с position: fixed

    //TODO: Когда появится десктопная версия хедера, надо будет добавить пересчёт высоты по медиа-запросу
    useEffect(() => {
        if (!headRef.current) return;
        setHeight(headRef.current.offsetHeight)
    }, [ headRef.current ]);

    return(
        <>
            <Box sx={{ height: height + 'px' }}/>
            { children }
            <Header lightTheme={lightTheme} ref={headRef}/>
        </>
    );
}

export default PageContainer;